import React, { useState, useEffect, Fragment } from "react";
import { Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BranchSelectors, ConversationActions, ProfileSelectors, SystemActions, SystemSelectors } from "redux-store";
import { isMacOS, StorageUtil, toCamel } from "utils";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { AvatarInfo, AppDrawer } from "components";
import AccountCoverView from "./AccountCoverView";
import YourProfile from "./YourProfile";
import YourDevice from "./DeviceManagement/YourDevice";
import SettingApp from "./SettingApp";
import { BackupData, RestoreData } from "./BackupRestore";
import ChangingStatus from "./ChangingStatus";
import AccountLogout from "./AccountLogout";
import NotificationBadge from "../Notification/NotificationBadge";
import { getInteractor, LocalDeviceSettingService } from "services/local.service";
import { isEqual } from "lodash";
import ManageFileLink from "./ManageFileLink";
import AccountItem from "./AccountItem";
import { LockOutlined } from "@mui/icons-material";
import { getNSLang } from "utils/lang.utils";
import { getAccountList } from "pubsub/services/account.service";
import { useScreenLock } from "hooks";

const Account = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const dispatch = useDispatch();
  const { lockScreenApp } = useScreenLock();

  const accountObjContent = getLabel(LangConstant.OBJ_ACCOUNT_ME, { returnObjects: true });
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const updateProfile = useSelector(ProfileSelectors.getUpdateProfile);
  const screenLockSettingTimestamp = useSelector(SystemSelectors.screenLockSettingTimestamp);

  const [isShow, setIsShow] = useState(false);
  const [accountDetail, setAccountDetail] = useState({});
  const [hasScreenLockSetting, setHasScreenLockSetting] = useState(false);
  const [isAdminBranch, setIsAdminBranch] = useState(false);

  const handleChangeAccount = account => setAccountDetail(preAccount => ({ ...preAccount, ...account }));

  const handleSetAccountDetail = async () => {
    const accountRecord = await getInteractor(prefixKey).LocalAccountService.get(accountId, branchId);
    const newAccount = accountRecord ? toCamel(accountRecord) : {};
    if (false === isEqual(newAccount, accountDetail)) setAccountDetail(newAccount);
  };

  const handleCloseDrawer = () => {
    setIsShow(false);
    dispatch(
      SystemActions.systemSet({
        isShowMnRequest: false,
      }),
    );
  };

  const handleShowProductDetails = () => {
    dispatch(
      SystemActions.systemSet({
        isShowMnRequest: false,
      }),
    );

    dispatch(
      ConversationActions.conversationSet({
        selectedGroupId: null,
        threadingId: null,
      }),
    );
  };

  const handleShowManageRequest = () => {
    dispatch(
      SystemActions.systemSet({
        isShowMnRequest: true,
      }),
    );
  };

  useEffect(() => {
    handleSetAccountDetail();
  }, [selectedBranch, updateProfile]);

  useEffect(() => {
    LocalDeviceSettingService.count({
      setting_type: AppConstant.DEVICE_SETTING_TYPE.lockScreen,
      state: SystemConstant.STATE.active,
    }).then(count => {
      if (count > 0) {
        setHasScreenLockSetting(true);
      } else {
        setHasScreenLockSetting(false);
      }
    });
  }, [screenLockSettingTimestamp]);

  useEffect(() => {
    if (isShow) {
      getAccountList(prefixKey, [accountId]).then(response => {
        const remoteAccount = response[0];

        if (remoteAccount && remoteAccount.id && remoteAccount.admin_type) {
          setIsAdminBranch(true);
        } else {
          setIsAdminBranch(false);
        }
      });
    }
  }, [isShow]);

  if (Object.values(accountDetail).length === 0) return <Fragment />;

  const isActive = accountDetail.status === SystemConstant.STATE.active;
  return (
    <>
      <Box className={classes.avatarBox}>
        <AvatarInfo
          avatar={{
            name: accountDetail.name,
            avatarId: accountDetail.avatarId,
          }}
          title={accountDetail.name}
          size={AppConstant.SIZE_OBJ.large}
          onClick={() => setIsShow(true)}
        />

        {hasScreenLockSetting && (
          <IconButton
            title={getNSLang(
              LangConstant.NS_SCREEN_LOCK,
              isMacOS() ? "TXT_LOCK_SCREEN_ICON_TITLE_MAC" : "TXT_LOCK_SCREEN_ICON_TITLE",
            )}
            onClick={lockScreenApp}
          >
            <LockOutlined />
          </IconButton>
        )}

        <Box className={classes.notiBox}>
          <NotificationBadge />
        </Box>
      </Box>

      <AppDrawer open={isShow} onClose={handleCloseDrawer} title={accountObjContent.title}>
        <AccountCoverView
          isActive={isActive}
          backgroundId={accountDetail.backgroundId}
          name={accountDetail.name}
          avatarId={accountDetail.avatarId}
        />

        <ChangingStatus accountDetail={accountDetail} onChange={handleChangeAccount} />
        <YourProfile accountDetail={accountDetail} onChange={handleChangeAccount} />
        <YourDevice />
        {isAdminBranch && <AccountItem text={accountObjContent.manageRequestLogin} onClick={handleShowManageRequest} />}
        <ManageFileLink onClose={handleCloseDrawer} />
        <SettingApp />
        <BackupData />
        <RestoreData />
        <AccountItem text={accountObjContent.productDetails} onClick={handleShowProductDetails} />
        <AccountLogout />
      </AppDrawer>
    </>
  );
};

Account.propTypes = {};

export default Account;

const useStyles = makeStyles({
  avatarBox: {
    display: "flex",
    paddingRight: 16,
    alignItems: "center",
    "& *": { cursor: "pointer" },
  },

  logoutButton: {
    width: 40,
    height: 40,
  },

  notiBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoutText: {
    fontSize: "0.9375rem",
    fontWeight: 400,
    color: "#1E272E",
  },
});
