import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { all, call, put, select } from "redux-saga/effects";
import { getPrefixKey, toSnake } from "utils";
import { LocalDbManagement, getInteractor } from "services/local.service";
import store, { ConversationActions, ConversationSelectors, getReduxState, GroupInfoActions } from "redux-store";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services";
import { checkCurrentBranchByPrefix, DISPLAY_MSG_TYPES } from "./saga.helper";
import { Mutex } from "async-mutex";
import { isLoginBranch } from "utils/auth.utils";
import { checkCurrentGroup } from "utils/view.utils";
const HANDLE_QUEUE_LIMIT = 20;
window.delaySyncMsec = 500;
const mutex = new Mutex();
export function* executeQueue() {
  try {
    if (mutex.isLocked()) return;
    window.isQueueRunning = true;
    if (navigator.onLine) {
      // Get all active branches
      const activeBranchList = yield LocalDbManagement.find({ state: SystemConstant.STATE.active });
      // Handling queue on all active branch
      if (activeBranchList.length > 0) {
        let response = yield all(
          activeBranchList.map(item => call(handleQueueTasks, getPrefixKey(item.account_id, item.branch_id))),
        );
        let res = false;
        response.forEach(r => (res = res || r));
        if (!res) {
          return;
        }
      } else {
        return;
      }
    } else {
      return;
    }
  } catch (error) {
    console.log(error);
    return;
  } finally {
    mutex.release();
  }

  // Delay before continue to execute
  yield call(executeQueue);
}

export function* handleQueueTasks(prefixKey) {
  if (false === isLoginBranch() || window.isStopSynchronize) return;

  try {
    const apiList = yield getInteractor(prefixKey).LocalApiCallService.getApiQueue(HANDLE_QUEUE_LIMIT);

    const apiByGroupId = apiList.reduce((apiListByGroup, apiItem) => {
      const groupId = apiItem.group_id || "group_id"; // Using "group_id" for older version

      if (false === Array.isArray(apiListByGroup[groupId])) {
        apiListByGroup[groupId] = [];
      }

      apiListByGroup[groupId].push(apiItem);
      return apiListByGroup;
    }, {});

    if (apiList.length > 0) {
      yield all(Object.keys(apiByGroupId).map(groupId => sendMessageList(prefixKey, apiByGroupId[groupId])));

      // Render view if current group need to be updated
      const selectedGroupId = yield select(ConversationSelectors.getSelectedGroupId);
      if (
        checkCurrentBranchByPrefix(prefixKey) &&
        selectedGroupId &&
        Object.keys(apiByGroupId).includes(selectedGroupId)
      ) {
        yield put(
          ConversationActions.conversationSet({
            isUpdateViewMode: Date.now(),
          }),
        );
      }
    }
    return apiList.length > 0;
  } catch (error) {
    console.log(error);
  }
}

function* sendMessageList(prefixKey, apiList) {
  let sortedList = apiList.sort((a, b) => a.created - b.created);
  for (let index = 0; index < sortedList.length; index++) {
    const item = sortedList[index];
    const { data } = JSON.parse(item.content) || {};
    data["call_id"] = item.id;
    yield sendMessage2Server(prefixKey, data);
  }

  return true;
}

function* sendMessage2Server(prefixKey, data) {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  try {
    if (data.marks) delete data.marks;
    let response = yield call(remoteApiFactory.getBranchApi(prefixKey).sendMessage, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield handleQueueSuccess(prefixKey, data);

      if (data.sendType === SystemConstant.SEND_TYPE.leaveGroup && data.removingId === accountId) {
        const payload = {
          groupId: data.groupId,
        };

        if (data.adminId) {
          payload.adminId = data.adminId;
        }

        yield put(
          GroupInfoActions.deleteGroup({
            ...payload,
          }),
        );
      }
      yield getInteractor(prefixKey).LocalApiCallService.poll(data.call_id);
    } else {
      if (response.status === ApiConstant.STT_DUPLICATE_MESSAGE) {
        yield handleQueueSuccess(prefixKey, data);
        yield getInteractor(prefixKey).LocalApiCallService.poll(data.call_id);
      } else if (
        response.status === ApiConstant.STT_UNAUTHORIZED ||
        response.status === ApiConstant.STT_FORBIDDEN ||
        response.status === ApiConstant.STT_MAINTAIN_1 ||
        response.status === ApiConstant.STT_MAINTAIN_2 ||
        response.status === ApiConstant.STT_MAINTAIN_3
      ) {
        // handler refresh token in generic
      } else {
        let apiCall = yield getInteractor(prefixKey).LocalApiCallService.get(data.call_id) || {};
        if (apiCall.retry <= ApiConstant.MAX_RETRY) {
          if (
            response.status === ApiConstant.STT_NOT_FOUND ||
            response.status === ApiConstant.STT_BAD_REQUEST ||
            response.status === ApiConstant.STT_INTERNAL_SERVER
          ) {
            yield getInteractor(prefixKey).LocalApiCallService.save([{ ...apiCall, retry: apiCall.retry + 1 }]);
          }
        } else {
          let deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
          if (data && data.messages && data.messages.length > 0) {
            data.messages.map(async message => {
              if (message.deviceId === deviceId) {
                handleQueueFailed(prefixKey, message.messageId);
              }
            });
          }
          yield getInteractor(prefixKey).LocalApiCallService.poll(data.call_id);
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

const handleQueueSuccess = async (prefixKey, data) => {
  const localMessage = await getInteractor(prefixKey).LocalMessageService.findOne({
    source_id: data.sourceId,
  });
  if (localMessage && localMessage.id && localMessage.group_id) {
    const updateLocalMessage = { ...localMessage, modified: Date.now() };
    await getInteractor(prefixKey).LocalMessageService.update(
      {
        modified: updateLocalMessage.modified,
      },
      { id: localMessage.id },
    );

    // Trigger to UI
    if (checkCurrentGroup(localMessage.group_id) && DISPLAY_MSG_TYPES.includes(localMessage.send_type)) {
      store.dispatch(ConversationActions.receivedRemoteMessage(updateLocalMessage));
    }
  }
};

const handleQueueFailed = async (prefixKey, messageId) => {
  const messageFromDB = await getInteractor(prefixKey).LocalMessageService.get(messageId);
  if (messageFromDB) {
    const updateLocalMessage = { ...messageFromDB, state: SystemConstant.STATE.inactive, modified: Date.now() };
    await getInteractor(prefixKey).LocalMessageService.update(
      {
        modified: updateLocalMessage.modified,
      },
      { id: messageId },
    );

    // Trigger to UI
    const selectedGroupId = getReduxState(ConversationSelectors.getSelectedGroupId);
    if (updateLocalMessage.group_id === selectedGroupId && DISPLAY_MSG_TYPES.includes(updateLocalMessage.send_type)) {
      store.dispatch(ConversationActions.receivedRemoteMessage(updateLocalMessage));
    }
  }
};
