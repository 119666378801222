import { ApiConstant, SystemConstant } from "const";
import { remoteApiFactory } from "./remote-api/RemoteApiFactory";
import { formatPagingParams } from "sagas/saga.helper";
import { getDataFromPrefixKey, toCamel } from "utils";

const DEFAULT_REQUEST_LIST = {
  data: [],
  paging: 1,
  isEnd: false,
};

const DEFAULT_PAGING_UNIT = {
  paging: 0,
  offset: 0,
  asc: 0,
  limit: 1000,
};

const LIMIT_PAGING = 100;
export const getRequestList = async (prefixKey, paging = 1, branchRequestId) => {
  if (!prefixKey) return DEFAULT_REQUEST_LIST;

  const offset = (paging - 1) * LIMIT_PAGING;

  const data2Req = formatPagingParams({
    paging,
    asc: 0,
    statusIds: [SystemConstant.BRANCH_REQUEST_STATUS.pending],
    offset,
    limit: LIMIT_PAGING,
  });
  if (branchRequestId) data2Req.branch_request_id = branchRequestId;

  try {
    const response = await remoteApiFactory.getBranchApi(prefixKey).getRequestList(data2Req);
    if (response.status === ApiConstant.STT_OK) {
      const responseData = response.data;

      const isEnd = responseData.number_of_elements === 0;
      return { data: responseData.data, paging: paging, isEnd: isEnd };
    }
  } catch (error) {
    console.log("get request list error: ", error);
  }

  return { ...DEFAULT_REQUEST_LIST, paging: paging - 1 };
};

export const updateRequest = async (prefixKey, data) => {
  if (false === Boolean(prefixKey && data)) return false;

  try {
    const response = await remoteApiFactory.getBranchApi(prefixKey).updateRequest(data);
    if (response.status === ApiConstant.STT_OK) {
      return true;
    }
  } catch (error) {
    console.log("update request error: ", error);
  }

  return false;
};

export const getListUnit = async prefixKey => {
  if (!prefixKey) return [];
  try {
    const [_, branchId] = getDataFromPrefixKey(prefixKey);
    const response = await remoteApiFactory.getBranchApi(prefixKey).getListUnit(branchId, DEFAULT_PAGING_UNIT);
    if (response.status === ApiConstant.STT_OK) {
      return toCamel(response.data.data);
    }
  } catch (error) {
    console.log("get list unit error: ", error);
  }

  return [];
};

export const getListDepartment = async (prefixKey, unitId) => {
  if (false === Boolean(prefixKey && unitId)) return [];
  try {
    const [_, branchId] = getDataFromPrefixKey(prefixKey);
    const response = await remoteApiFactory
      .getBranchApi(prefixKey)
      .getListDepartment(branchId, unitId, DEFAULT_PAGING_UNIT);
    if (response.status === ApiConstant.STT_OK) {
      return toCamel(response.data.data);
    }
  } catch (error) {
    console.log("get list unit error: ", error);
  }

  return [];
};

export const getListPosition = async (prefixKey, unitId, departmentId) => {
  if (false === Boolean(prefixKey && unitId && departmentId)) return [];
  try {
    const [_, branchId] = getDataFromPrefixKey(prefixKey);
    const params = {
      ...DEFAULT_PAGING_UNIT,
      branch_id: branchId,
      filter: JSON.stringify({ unit_id: unitId, department_id: departmentId }),
    };
    const response = await remoteApiFactory.getBranchApi(prefixKey).getListPosition(params);
    if (response.status === ApiConstant.STT_OK) {
      return toCamel(response.data.data);
    }
  } catch (error) {
    console.log("get list unit error: ", error);
  }

  return [];
};
