import React, { memo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Paper, ClickAwayListener, Button } from "@mui/material";
import { AddCircleOutline, ArrowDropDown } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { ApiConstant, LangConstant, PathConstant } from "const";
import { useTranslation } from "react-i18next";
import { getPrefixKey } from "utils";
import { useDispatch } from "react-redux";
import { ConversationActions } from "redux-store";
import { useServerList, useServerMode, useUnreadMessage } from "hooks";
import { ServerItem } from "components";
import { remoteApiFactory } from "services";
import { useHistory } from "react-router-dom";
import { goToLoginPortal } from "utils/auth.utils";

const SelectServer = () => {
  const classes = useStyles();
  const history = useHistory();
  const anchorRef = useRef();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { selectedBranch, switchServer } = useServerMode();
  const { activeServerList } = useServerList();
  const { totalUnreadInBranch } = useUnreadMessage();

  const [isShow, setIsShow] = useState(false);
  const [branchStatuses, setBranchStatuses] = useState({});

  const handleShowDropdown = () => setIsShow(preIsShow => !preIsShow);

  const handleSelectServer = async serverItem => {
    setIsShow(false);
    if (serverItem && selectedBranch.id === serverItem.id) return;
    switchServer(serverItem);

    // Reset selecting group
    dispatch(
      ConversationActions.setSelectGroupId({
        threadingId: null,
        selectedGroupId: null,
      }),
    );
  };

  const handleAddBranch = () => {
    goToLoginPortal(history);
  };

  useEffect(() => {
    if (activeServerList.length > 0) {
      checkingBranchStatus(activeServerList, setBranchStatuses);
      const checkBranchStatusInterval = setInterval(() => {
        checkingBranchStatus(activeServerList, setBranchStatuses);
      }, 30000); // Retry after 30 seconds

      return () => clearInterval(checkBranchStatusInterval);
    }
  }, [activeServerList, isShow]);

  return (
    <ClickAwayListener onClickAway={() => setIsShow(false)}>
      <Box className={classes.root}>
        <ServerItem
          data={{ ...selectedBranch, totalUnread: totalUnreadInBranch }}
          isOnline={branchStatuses[selectedBranch.id]}
          onClick={handleShowDropdown}
          ref={anchorRef}
          classes={{ serverItem: classes.itemButton }}
          disablePadding
          secondaryAction={<ArrowDropDown />}
          isShowStatus={true}
        />

        <Box className={classes.listServerRoot}>
          <Paper elevation={1} className={clsx(classes.listServer, "server-border", !isShow && "hidden")}>
            {activeServerList.length > 0 && (
              <>
                <Typography className={classes.serverHeader}>{getLabel("TXT_SERVER_LIST")}</Typography>
                {activeServerList.map(item => (
                  <ServerItem
                    key={item.id}
                    data={item}
                    isOnline={branchStatuses[item.id]}
                    onClick={() => handleSelectServer(item)}
                    isShowStatus={true}
                  />
                ))}
              </>
            )}

            <Button
              variant="text"
              fullWidth
              startIcon={<AddCircleOutline />}
              className={classes.addBranchBtn}
              onClick={handleAddBranch}
            >
              {getLabel("TXT_ADD_SERVER")}
            </Button>
          </Paper>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

SelectServer.propTypes = { data: PropTypes.array, onChange: PropTypes.func };

export default memo(SelectServer);
const PADDING_LEFT = 16;

const checkingBranchStatus = (loginBranchList, onCallback) => {
  if (loginBranchList.length === 0) return;

  loginBranchList.forEach(branch => {
    const prefixKey = getPrefixKey(branch.accountId, branch.id);
    remoteApiFactory
      .getBranchApi(prefixKey)
      .checkingServerStatus()
      .then(response => {
        const isServerOnline = window.navigator.onLine && response.status === ApiConstant.STT_OK;
        onCallback(preState => ({ ...preState, [branch.id]: isServerOnline }));
      });
  });
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: `8px ${PADDING_LEFT}px`,
  },

  itemButton: {
    border: "2px solid #F2F2F4",
    boxSizing: "border-box",
    borderRadius: "10px",
  },

  serverHeader: {
    fontSize: 12,
    fontWeight: 600,
    color: "#6C7078",
    marginTop: 1.5,
    marginBottom: 1.5,
    marginLeft: `${PADDING_LEFT}px`,
  },

  listServerRoot: {
    display: "flex",
    position: "absolute",
    width: `calc(100% - ${PADDING_LEFT * 2}px)`,
    zIndex: theme.zIndex.drawer + 1,
    "& $listServer.server-border": {
      borderRadius: 10,
    },
  },

  listServer: {
    width: "100%",
    marginTop: 8,
    padding: "10px 8px",
  },

  serverAvatar: {
    backgroundColor: "transparent",
  },

  addBranchBtn: {
    padding: "14px 16px",
    textTransform: "none",
    justifyContent: "flex-start",
  },
}));
