import { Box, Button, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NoticeDialog, ServerItem } from "components";
import { AppConstant, ImageConstant, KeyConstant, PathConstant, SystemConstant } from "const";
import { useServerList, useServerMode } from "hooks";
import { useEffect, useState } from "react";
import { getInteractor } from "services/local.service";
import { remoteApiFactory } from "services";
import { StorageUtil, getPrefixKey, clearAllStorage } from "utils";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { goToLoginPortal, handlingLogoutBranch } from "utils/auth.utils";

const ChooseBranchPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t: getLabel } = useTranslation();

  const { activeServerList } = useServerList();
  const { switchServer } = useServerMode();

  const [isShowWarning, setIsShowWarning] = useState(false);
  const [masterBranch, setMasterBranch] = useState(null);

  const handleLogoutAll = async () => {
    let masterDeviceIndex = -1;
    for (let index = 0; index < activeServerList.length; index++) {
      const branch = activeServerList[index];
      const prefixKey = getPrefixKey(branch.accountId, branch.id);
      const currentDeviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
      const deviceList = await getInteractor(prefixKey).LocalDeviceService.getAllByAccount(branch.accountId);
      const activeDeviceList = deviceList.filter(item => item.state === SystemConstant.STATE.active);
      const masterDevice = activeDeviceList.find(item => item.id === currentDeviceId && item.master_f === 1);

      if (Boolean(masterDevice) && masterDeviceIndex < 0) {
        masterDeviceIndex = index;
      }
    }

    if (masterDeviceIndex >= 0) {
      setMasterBranch(activeServerList[masterDeviceIndex]);
      setIsShowWarning(true);
    } else {
      for (let index = 0; index < activeServerList.length; index++) {
        const branch = activeServerList[index];
        const prefixKey = getPrefixKey(branch.accountId, branch.id);
        const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
        await remoteApiFactory.getBranchApi(prefixKey).updateDevice({
          slaveDeviceId: [deviceId],
        });
        await handlingLogoutBranch(prefixKey);
      }

      goToLoginPortal(history);
      clearAllStorage();
    }
  };

  const handleCloseWarning = () => {
    setIsShowWarning(false);
    switchServer(masterBranch);
  };

  useEffect(() => {
    window.isStopSynchronize = true;

    return () => (window.isStopSynchronize = false);
  }, []);

  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <Box className={classes.listBox}>
          <Typography className={classes.header}>{getLabel("TXT_CHOOSE_BRANCH")}</Typography>
          <Box className={classes.activeListBox}>
            {activeServerList.map(item => (
              <ServerItem
                onClick={() => switchServer(item)}
                key={item.id}
                data={item}
                classes={{ statusIcon: classes.statusIcon }}
              />
            ))}
          </Box>

          <Button variant="outlined" fullWidth className={classes.logoutAllBtn} onClick={handleLogoutAll}>
            {getLabel("TXT_LOGOUT_ALL")}
          </Button>
        </Box>
        <Box className={classes.imageContainer}>
          <CardMedia component="img" src={ImageConstant.ImageLogin} className={classes.loginWelcomeImage} />
        </Box>
      </Box>

      {isShowWarning && (
        <NoticeDialog
          open
          title={getLabel("TXT_NOTIFICATION")}
          content={getLabel("TXT_LOGOUT_WARNING")}
          submitProps={{
            submitText: getLabel("TXT_CLOSE"),
            onClick: handleCloseWarning,
          }}
        />
      )}
    </Box>
  );
};

export default ChooseBranchPage;

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    height: "100%",
    padding: 0,
    position: "relative",
    backgroundColor: "#FAFAFA",
  },

  root: {
    display: "flex",
    height: "100%",
  },

  listBox: {
    width: "30%",
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.up(1500)]: {
      width: AppConstant.SIDEBAR_WIDTH.large,
    },
  },

  header: {
    width: "100%",
    height: 64,
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  imageContainer: {
    width: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loginWelcomeImage: {
    width: "60%",
  },

  logoutAllBtn: {
    textTransform: "none",
  },

  activeListBox: {
    height: "calc(100vh - 150px)",
    width: "100%",
    overflowY: "auto",
  },

  statusIcon: {
    display: "none",
  },
}));
