import { AppConstant } from "const";
import { AttachmentUtil, FileUtil } from "utils";

export const ElectronService = {
  setLogoApp: branchId => {
    // Emit event to electron main
    window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.setLogoApp, branchId);

    const localLogoPath = AttachmentUtil.getAppIcon(branchId);
    return FileUtil.getUrlFileByPath(localLogoPath);
  },

  showApplication: () => window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.showApplication),

  saveBackupStatus: status => {
    window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.saveBackupStatus, status);
  },

  openFolder: data => window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.openFolder, data), // {prefixKey, attachmentId, fileName}

  download: (prefixKey, fileIds) =>
    window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.download, { prefixKey, fileIds }),

  quitAndInstall: () => window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.quitAndInstall),

  checkForUpdates: () => window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.checkForUpdates),
};
