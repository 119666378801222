import { useEffect, useState } from "react";
import { Jimp } from "jimp";
import jsQR from "jsqr";
import { useAlertContext } from "components/context/AlertContext";
import { getNSLang } from "utils/lang.utils";
import { LangConstant } from "const";

const useQRCodeReader = file => {
  const { showAlert } = useAlertContext();

  const [qrData, setQRData] = useState();

  const decodeQR = async selectedFile => {
    // Reset data before executing
    setQRData();

    try {
      // Load the image with Jimp
      const buffer = await selectedFile.arrayBuffer();
      const image = await Jimp.read(buffer);

      // Get the image data
      const imageData = {
        data: new Uint8ClampedArray(image.bitmap.data),
        width: image.bitmap.width,
        height: image.bitmap.height,
      };

      // Use jsQR to decode the QR code
      const decodedQR = jsQR(imageData.data, imageData.width, imageData.height);

      if (!decodedQR) {
        throw new Error("QR code not found in the image.");
      }

      console.log("QR data: " + decodedQR.data);
      setQRData(decodedQR.data);
    } catch (error) {
      console.error("Error decoding QR code:", error);
      showAlert({ content: getNSLang(LangConstant.NS_LOGIN, "TXT_QR_ERROR"), severity: "error" });
    }
  };

  useEffect(() => {
    if (file) decodeQR(file);
  }, [file]);

  return { qrData };
};

export default useQRCodeReader;
