import PropTypes from "prop-types";
import { Snackbar as MuiSnackbar, Alert as MuiAlert } from "@mui/material";
import { styled } from "@mui/styles";

const AppAlert = ({ content, severity, alertProps, ...snackbarProps }) => {
  return (
    <Snackbar {...snackbarProps}>
      <Alert variant="filled" severity={severity} {...alertProps}>
        {content}
      </Alert>
    </Snackbar>
  );
};

AppAlert.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.string,
  severity: PropTypes.oneOf(["success", "info", "warning", "error"]),
  onClose: PropTypes.func,

  alertProps: PropTypes.object, // See AlertProps: https://mui.com/material-ui/api/alert/#props
};

AppAlert.defaultProps = {
  severity: "success",
};

export default AppAlert;

const Snackbar = styled(MuiSnackbar)({
  width: 350,
});

const Alert = styled(MuiAlert)({
  width: "100%",
  color: "#ffffff",
});
