import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const withCloseBadge = Component => {
  return ({ ComponentProps, IconProps }) => {
    const classes = useStyles();
    const { customIconStyle, ...otherProps } = IconProps;
    return (
      <Box className={classes.root}>
        <Component {...ComponentProps} />
        <Box className={clsx(classes.iconBox, customIconStyle)} {...otherProps}>
          <IconButton className={classes.iconBtn}>
            <Close sx={{ fontSize: "14px" }} />
          </IconButton>
        </Box>
      </Box>
    );
  };
};

export default withCloseBadge;

const useStyles = makeStyles({
  root: {
    position: "relative",
  },

  iconBox: {
    position: "absolute",
    top: 0,
    right: -4,
  },

  iconBtn: {
    padding: "2px",
    marginRight: "-4px",
    marginTop: "-8px",
    background: "#E0E0E0",
    color: "white",
    "&:hover": {
      background: "#9e9e9e",
    },
  },
});
