import { useEffect } from "react";
import { PubSubActions } from "redux-store";
import { useDispatch } from "react-redux";

const HandlingPubSub = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PubSubActions.initPubsub());

    return () => {
      // Ensure that all thead of logout branches are destroyed
      dispatch(PubSubActions.destroyLogoutBranchThread());
    };
  }, []);

  return null;
};
export default HandlingPubSub;
