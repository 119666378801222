import { KeyConstant } from "const";
import { initializeApp } from "firebase/app";
import { getRemoteConfig, fetchAndActivate, getValue } from "firebase/remote-config";
import { StorageUtil } from "utils";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC1dHXonhZ4ndYN252J132YRa0wtMgYE34",
  authDomain: "trios-8097e.firebaseapp.com",
  projectId: "trios-8097e",
  storageBucket: "trios-8097e.firebasestorage.app",
  messagingSenderId: "848031823259",
  appId: "1:848031823259:web:519df733c6028aa8789d3e",
  measurementId: "G-Z9F0MWCYRN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);

export const getFirebaseConfig = async () => {
  try {
    if (window.env?.TIME_INTERVAL_FETCH) {
      try {
        remoteConfig.settings.minimumFetchIntervalMillis = parseInt(window.env?.TIME_INTERVAL_FETCH);
      } catch (error) {
        console.warn(error);
      }
    }
    await fetchAndActivate(remoteConfig);
    const jsonString = getValue(remoteConfig, "force_update_desktop").asString();
    // Parse the string into a JSON object
    const jsonObject = JSON.parse(jsonString);
    console.log("JSON String:", jsonString);
    console.log("Parsed JSON:", jsonObject);

    StorageUtil.setCommonKey(KeyConstant.FORCE_UPGRADE_VERSION, Boolean(jsonObject.is_force_update));
    return jsonObject;
  } catch (error) {
    console.error("Error fetching Remote Config:", error);
  }

  return {};
};
