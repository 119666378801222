import { ApiConstant, KeyConstant, SystemConstant } from "const";
import qs from "qs";
import GlobalApi, { createApiWithDomain } from "./remote-api/api.config";
import { StorageUtil } from "utils";
import { getLoginBranchInfo } from "utils/auth.utils";

const getApiAuth = domain => {
  const apiDomain = domain || getLoginBranchInfo().domain;
  if (apiDomain) {
    // Using branch server corresponding domain
    return createApiWithDomain(apiDomain);
  }

  // Using global server
  const globalDomain = ApiConstant.BASE_URL.split("/")[2];
  StorageUtil.setCommonKey(KeyConstant.KEY_TMP_BRANCH, { id: SystemConstant.GLOBAL_BRANCH_ID, domain: globalDomain });

  return GlobalApi;
};

export const login = (data, domain) =>
  getApiAuth(domain).post(ApiConstant.POST_LOGIN, qs.stringify(data, { skipNulls: true }));

export const verify = data => getApiAuth().post(ApiConstant.POST_VERIFY, qs.stringify(data, { skipNulls: true }));

export const checkQrCode = (domain, qr_token, branch_id) =>
  getApiAuth(domain).post(ApiConstant.POST_QR_CHECK, qs.stringify({ qr_token, branch_id }, { skipNulls: true }));

// data = { qr_token, phone_number }
export const checkRequestLogin = (domain, phone_number) =>
  getApiAuth(domain).post(ApiConstant.POST_REQUEST_LOGIN_CHECK, qs.stringify({ phone_number }, { skipNulls: true }));

export const createRequestLogin = (domain, data) =>
  getApiAuth(domain).post(ApiConstant.POST_CREATE_REQUEST_LOGIN, qs.stringify(data, { skipNulls: true }));
