import { CallEventSubType, EventState, EventType, ExecuteEvent } from "pubsub/PubSub.const";
import IEvent from "./IEvent";
import { getInteractor } from "services/local.service";
import { EventDto, IEventDto } from "pubsub/dto";
import { refactorSavingEvent } from "pubsub/PubSub.helper";
import { checkCallMessage } from "pubsub/services/call.service";
import { StorageUtil } from "utils";
import { KeyConstant } from "const";

const callingUtil = window.electronLibs.libs.callingUtil;
const prefixKey = StorageUtil.getCurrentPrefixKey();
const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

export default class CallEvent extends IEvent {
  constructor(prefixKey: string) {
    super(prefixKey, EventType.CALL);
  }

  override handleEvent = async (limitRetry: number) => {
    const eventList: EventDto[] = (
      await getInteractor(this.prefixKey).LocalEventService.find({
        type: this.eventType,
        state: [EventState.NEW, EventState.RESENT],
      })
    ).map((item: IEventDto) => new EventDto(item));
    const savingEvents: IEventDto[] = [];

    for (let index = 0; index < eventList.length; index++) {
      const eventItem = eventList[index];

      const eventContent = eventItem.contentObj;
      const messageSourceId = eventContent.source_id;
      const groupId = eventContent.group_id;

      let executeEventValue: ExecuteEvent = ExecuteEvent.FAIL;
      const isValid = messageSourceId && groupId;
      if (isValid) {
        switch (eventItem.subtype) {
          case CallEventSubType.INVITE:
            executeEventValue = (await checkCallMessage(this.prefixKey, groupId, messageSourceId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            if (executeEventValue === ExecuteEvent.SUCCESSES) {
              // const [accountId, branchId] = getDataFromPrefixKey(this.prefixKey);
              // await handleVoiceCall(convertJSONObject(eventItem.content), branchId);
            }
            break;
          case CallEventSubType.ACK:
          case CallEventSubType.BYE:
          case CallEventSubType.CANCEL:
          case CallEventSubType.CLEAN_CALL_LEAVE:
            executeEventValue = (await checkCallMessage(this.prefixKey, groupId, messageSourceId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          default:
            break;
        }
      }

      // Update event before saving in local db
      const refactorEvent = refactorSavingEvent(eventItem, executeEventValue, limitRetry);
      if (refactorEvent) savingEvents.push(refactorEvent);
    }

    await getInteractor(this.prefixKey).LocalEventService.save(savingEvents);
    return true;
  };
}
