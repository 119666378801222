import { memo, useEffect, useState } from "react";
import { CommonBranchInfoService } from "services";
import { convertString2JSON } from "utils";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getCommonLang } from "utils/lang.utils";
import { ImageConstant } from "const";
import clsx from "clsx";
import AppAvatar from "./avatar/AppAvatar";

const DEFAULT_BRANCH_DETAILS = {
  id: "",
  branchIcon: "",
  name: "",
  information: "",
};
const BCY_NAME = "Ban Cơ yếu Chính phủ";

const Welcome = ({ domain, branchId }) => {
  const classes = useStyles();

  const [branch, setBranch] = useState(DEFAULT_BRANCH_DETAILS);

  const handleSetBranch = async (domain, branchId) => {
    const remoteBranch = await CommonBranchInfoService.getBranchWithoutToken(domain, branchId);
    const branchOptions = remoteBranch.options ? convertString2JSON(remoteBranch.options, {}) : {};
    const branchIcon = CommonBranchInfoService.getBranchAvatarUrl(domain, branchId);

    setBranch({
      id: branchId,
      branchIcon: branchIcon,
      name: remoteBranch.name,
      information: branchOptions.information,
    });
  };

  useEffect(() => {
    if (domain && branchId && branch.id !== branchId) {
      handleSetBranch(domain, branchId);
    } else {
      setBranch(DEFAULT_BRANCH_DETAILS);
    }
  }, [domain, branchId]);

  return (
    <Stack className={classes.root}>
      <Stack direction="row" alignItems="center" justifyContent="center" width="100%">
        <AppAvatar variant="square" src={branch.branchIcon} size={50} alt={branch.name} isServerLogo={true}>
          {branch.name}
        </AppAvatar>
        <Typography className={classes.welcomeText}>{branch.name}</Typography>
      </Stack>

      <Box
        className={clsx("ql-editor", classes.infoDetailBox)}
        dangerouslySetInnerHTML={{
          __html: branch.information,
        }}
      />

      <Stack alignItems="center">
        <Avatar variant="square" sx={{ width: 40, height: 40 }} src={ImageConstant.ImageBcy} alt={BCY_NAME}>
          {BCY_NAME}
        </Avatar>
        <Typography textAlign="center" fontSize="15px">
          {getCommonLang("TXT_PRODUCT_INFO_NOTE")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default memo(Welcome);

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F7F7F8",
    gap: 20,
    padding: "80px 16px 0 16px",
    "& > *": {
      maxWidth: 864,
    },
  },

  infoBox: {
    width: "80%",
    maxWidth: 1000,
  },

  welcomeText: {
    fontWeight: 700,
    fontSize: "24px",
    color: "#2A2C2F",
    paddingLeft: 8,
  },

  infoDetailBox: {
    height: "fit-content",
    overflow: "auto",
    flexGrow: 1,
  },
});
