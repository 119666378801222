import React, { memo } from "react";
import PropTypes from "prop-types";
import { Divider, ListItem, ListItemButton, Typography, ListItemText, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const AccountItem = ({ text, secondaryText, classes, onClick, startIcon, endIcon, disabled = false }) => {
  const defaultClasses = useStyles();

  return (
    <>
      <Divider sx={{ color: "#E6E8EB" }} />
      <ListItem disablePadding>
        <ListItemButton onClick={onClick} disabled={disabled}>
          {startIcon && <Box mr={1}>{startIcon}</Box>}
          <ListItemText
            primary={<Typography className={clsx(defaultClasses.text, classes?.text)}>{text}</Typography>}
            secondary={<Typography variant="body2">{secondaryText}</Typography>}
          />
          {endIcon}
        </ListItemButton>
      </ListItem>
    </>
  );
};

AccountItem.propTypes = { text: PropTypes.string, onClick: PropTypes.func };

export default memo(AccountItem);

const useStyles = makeStyles({
  text: {
    fontSize: "0.9375rem",
    fontWeight: 400,
    color: "#1E272E",
  },
});
