// Copyright 2020 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import React, { useRef, useEffect } from "react";
import classNames from "classnames";
import type { SetRendererCanvasType } from "../state/ducks/calling";
import type { ConversationType } from "../state/ducks/conversations";
import type { LocalizerType } from "../types/Util";
import { AvatarGroup } from "components";
import { useSelector } from "react-redux";

// import { Avatar, AvatarSize } from "./Avatar";
import { CallBackgroundBlur } from "./CallBackgroundBlur";

type PropsType = {
  conversation: ConversationType;
  hasRemoteVideo: boolean;
  i18n: LocalizerType;
  isReconnecting: boolean;
  setRendererCanvas: (_: SetRendererCanvasType) => void;
};

export function DirectCallRemoteParticipant({
  conversation,
  hasRemoteVideo,
  i18n,
  isReconnecting,
  setRendererCanvas,
}: PropsType): JSX.Element {
  const remoteVideoRef = useRef<HTMLCanvasElement | null>(null);
  const callingGroupDetailRing = useSelector((state: any) => state.callingRedux.callingGroupDetailRing || {});

  useEffect(() => {
    setRendererCanvas({ element: remoteVideoRef });
    return () => {
      setRendererCanvas({ element: undefined });
    };
  }, [setRendererCanvas, hasRemoteVideo]);

  return hasRemoteVideo ? (
    <canvas
      className={classNames(
        "module-ongoing-call__remote-video-enabled",
        isReconnecting && "module-ongoing-call__remote-video-enabled--reconnecting",
      )}
      ref={remoteVideoRef}
    />
  ) : (
    renderAvatar(i18n, callingGroupDetailRing, conversation)
  );
}

function renderAvatar(
  i18n: LocalizerType,
  callingGroupDetailRing: any,
  {
    acceptedMessageRequest,
    avatarUrl,
    color,
    isMe,
    phoneNumber,
    profileName,
    sharedGroupNames,
    title,
  }: Pick<
    ConversationType,
    | "acceptedMessageRequest"
    | "avatarUrl"
    | "color"
    | "isMe"
    | "phoneNumber"
    | "profileName"
    | "sharedGroupNames"
    | "title"
  >,
): JSX.Element {
  return (
    <div className="module-ongoing-call__remote-video-disabled">
      <CallBackgroundBlur avatarUrl={avatarUrl}>
        <div className="module-Avatar">
          <AvatarGroup
            avatarId={callingGroupDetailRing.avatarId}
            prefixKey={callingGroupDetailRing.prefixKey}
            group={{
              groupType: callingGroupDetailRing.groupType,
              groupName: callingGroupDetailRing.groupName,
              privateF: callingGroupDetailRing.privateF,
            }}
            size={80}
          />
        </div>

        {/* <Avatar
          acceptedMessageRequest={acceptedMessageRequest}
          avatarUrl={avatarUrl}
          badge={undefined}
          color={color || AvatarColors[0]}
          noteToSelf={false}
          conversationType="direct"
          i18n={i18n}
          isMe={isMe}
          phoneNumber={phoneNumber}
          profileName={profileName}
          title={title}
          sharedGroupNames={sharedGroupNames}
          size={AvatarSize.EIGHTY}
        /> */}
      </CallBackgroundBlur>
    </div>
  );
}
