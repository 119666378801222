// Copyright 2020 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import { get } from "lodash";

export type ConfigKeyType =
  | "desktop.calling.adhoc"
  | "desktop.calling.adhoc.create"
  | "desktop.calling.raiseHand"
  | "desktop.clientExpiration"
  | "desktop.backup.credentialFetch"
  | "desktop.internalUser"
  | "desktop.mediaQuality.levels"
  | "desktop.messageCleanup"
  | "desktop.retryRespondMaxAge"
  | "desktop.senderKey.retry"
  | "desktop.senderKeyMaxAge"
  | "desktop.experimentalTransport.enableAuth"
  | "desktop.experimentalTransportEnabled.alpha"
  | "desktop.experimentalTransportEnabled.beta"
  | "desktop.experimentalTransportEnabled.prod"
  | "desktop.cdsiViaLibsignal"
  | "global.attachments.maxBytes"
  | "global.attachments.maxReceiveBytes"
  | "global.calling.maxGroupCallRingSize"
  | "global.groupsv2.groupSizeHardLimit"
  | "global.groupsv2.maxGroupSize"
  | "global.nicknames.max"
  | "global.nicknames.min"
  | "global.textAttachmentLimitBytes";

type ConfigValueType = {
  name: ConfigKeyType;
  enabled: boolean;
  enabledAt?: number;
  value?: string;
};
export type ConfigMapType = {
  [key in ConfigKeyType]?: ConfigValueType;
};
type ConfigListenerType = (value: ConfigValueType) => unknown;
type ConfigListenersMapType = {
  [key: string]: Array<ConfigListenerType>;
};

let config: ConfigMapType = {};
const listeners: ConfigListenersMapType = {};

export function getValue(name: ConfigKeyType): string | undefined {
  return get(config, [name, "value"], undefined);
}
