import { useState } from "react";
import { convertString2JSON, StorageUtil, uuid } from "utils";
import { KeyConstant, SystemConstant } from "const";
import ConversationAction from "redux-store/conversation.redux";
import { getInteractor } from "services/local.service";
import { useDispatch } from "react-redux";

const isGroupOrChannelType = type => {
  return Boolean(
    type && (type === SystemConstant.GROUP_CHAT_TYPE.group || type === SystemConstant.GROUP_CHAT_TYPE.channel),
  );
};

const useSendMessage = () => {
  const dispatch = useDispatch();
  const onSendMessage = (data: any) => {
    const {
      content,
      parentId,
      threadId,
      callStatus,
      roomId,
      deviceList: customDeviceList = [],
      isReceiver,
      isRemovedFromGroup = false,
      sourceId = uuid(),
      msgId = uuid(),
      options,
      createdMessage,
      isVideoCall,
      accountId,
      callingGroupDetail,
      sendType,
      prefixKey,
    } = data;
    let deviceList = [];
    if (!Boolean(roomId)) return;
    let groupMembers = callingGroupDetail?.groupMembers;
    // Get device list of members in group
    if (groupMembers?.length > 0) {
      let memberIdArray = groupMembers.map(member => member.id) || [];
      if (callingGroupDetail?.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
        memberIdArray.push(accountId);
      }

      getInteractor()
        .LocalDeviceService.find({ account_id: memberIdArray })
        .then(data => {
          deviceList = data || [];
        });
    }

    let savedServer = convertString2JSON(StorageUtil.getCommonKey(KeyConstant.KEY_SELECTED_SERVER));

    let tmpOptions = "";
    if (sendType === SystemConstant.SEND_TYPE.conference && Boolean(options)) {
      tmpOptions = tmpOptions.concat(JSON.stringify(options));
    }

    if (!isGroupOrChannelType(callingGroupDetail.groupType)) {
      tmpOptions = tmpOptions.concat(
        JSON.stringify({
          is_video_call: Number(isVideoCall),
        }),
      );
    }

    if (isRemovedFromGroup) {
      tmpOptions = tmpOptions.concat(
        JSON.stringify({
          ignore_in_group_f: 1,
        }),
      );
    }

    if (!tmpOptions) {
      tmpOptions = "";
    }

    let saveMessage = {
      id: msgId,
      source_id: sourceId,
      state: 1,
      status: 1,
      thread_id: null,
      call_status: callStatus,
      group_detail: callingGroupDetail,
      send_type: sendType,
      content: content,
      parent_id: parentId,
      deviceList: [...deviceList, ...customDeviceList],
      branch_id: savedServer?.id,
      room_id: roomId,
      is_receiver: Boolean(isReceiver),
      option: tmpOptions,
      created: new Date().getTime(),
    };

    if (!Boolean(parentId)) {
      dispatch(
        ConversationAction.conversationSet({
          createdMessage: {
            ...createdMessage,
            [callingGroupDetail?.id]: saveMessage,
          },
        }),
      );
    }
    dispatch(
      ConversationAction.sendMessage(
        {
          groupId: callingGroupDetail.id,
          sendType: sendType,
          content: content,
          parentId: parentId,
          deviceList: [...deviceList, ...customDeviceList],
          branchId: savedServer?.id,
          mentionIdsArr: [],
          threadId: threadId,
          callStatus: callStatus,
          roomId: roomId,
          isReceiver: Boolean(isReceiver),
          option: tmpOptions,
          currentMessage: saveMessage,
        },
        prefixKey,
      ),
    );
  };

  return { onSendMessage };
};

export default useSendMessage;
