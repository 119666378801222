import React, { memo } from "react";
import { LangConstant } from "const";
import { AppDrawer } from "components";
import { getNSLang } from "utils/lang.utils";
import { withDrawerLayout } from "components/hoc";
import ScreenLock from "./ScreenLock";
import AccountItem from "../../AccountItem";
import { LockOutlined, NavigateNext } from "@mui/icons-material";

const ScreenLockDrawer = withDrawerLayout(
  ScreenLock,
  <AccountItem
    text={getNSLang(LangConstant.NS_SCREEN_LOCK, "TXT_SCREEN_LOCK")}
    startIcon={<LockOutlined />}
    endIcon={<NavigateNext />}
  />,
);
const SettingPrivateAndSecurity = ({ open, onClose }) => {
  return (
    <AppDrawer open={open} onClose={onClose} title={getNSLang(LangConstant.NS_HOME, "TXT_SETTING_PRIVATE")}>
      <ScreenLockDrawer />
    </AppDrawer>
  );
};

SettingPrivateAndSecurity.propTypes = {};

export default memo(SettingPrivateAndSecurity);
