import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Button, Container } from "@mui/material";
import { AppConstant, LangConstant, PathConstant } from "const";
import { LoginForm, OTPAuth } from "./components";
import { useHandleBranchInvite } from "pages/HomePage/HandlingParallel/HandlingBranchInvite";
import clsx from "clsx";
import { AuthActions, AuthSelectors } from "redux-store";
import { LocalKeyActionService } from "services/local.service";
import { getNSLang } from "utils/lang.utils";
import { Welcome } from "components";
import { getLoginBranchInfo, goToLoginPortal } from "utils/auth.utils";

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const tmpBranchStorage = getLoginBranchInfo();

  useHandleBranchInvite();

  const authLoginStatus = useSelector(AuthSelectors.authLoginStatus);

  const [isPreAuthSuccess, setIsPreAuthSuccess] = useState(false);
  const [loginBranchInfo, setLoginBranchInfo] = useState({});

  const handleBackToLogin = () => {
    handleResetLogin();
    goToLoginPortal(history);
  };

  const handleResetLogin = () => {
    setIsPreAuthSuccess(false);
    dispatch(AuthActions.authReset());
  };

  useEffect(() => {
    if (authLoginStatus === AppConstant.VERIFY_OTP_ERROR_TYPE.success) setIsPreAuthSuccess(true);
  }, [authLoginStatus]);

  useEffect(() => {
    return () => {
      setIsPreAuthSuccess(false);
      dispatch(AuthActions.authReset());
    };
  }, []);

  // Auto send preAuth if login by link invite
  useEffect(() => {
    const isAnotherBranch =
      loginBranchInfo.id !== tmpBranchStorage.id || loginBranchInfo.phone !== tmpBranchStorage.phone;
    if (tmpBranchStorage.isDeepLink && tmpBranchStorage.phone && isAnotherBranch) {
      LocalKeyActionService.getInitLoginData().then(initLoginData => {
        dispatch(
          AuthActions.requestLogin({
            ...initLoginData,
            phone: tmpBranchStorage.phone,
            regionCode: AppConstant.LOCAL_PHONE_CODE,
            version: window.env?.APP_VERSION || initLoginData.version,
          }),
        );
      });
      setIsPreAuthSuccess(true);
      setLoginBranchInfo(tmpBranchStorage);
    }
  }, [tmpBranchStorage]);

  return (
    <Container
      component="main"
      maxWidth="xl"
      classes={{
        root: classes.container,
        maxWidthXl: classes.container,
      }}
    >
      <Box className={classes.root}>
        <Box className={classes.inputComponent}>
          {isPreAuthSuccess ? <OTPAuth onChangePhone={handleResetLogin} /> : <LoginForm />}
          <Button
            fullWidth
            variant="text"
            className={clsx("semiBold-lg-txt", classes.continueButton)}
            onClick={handleBackToLogin}
          >
            {getNSLang(LangConstant.NS_LOGIN, "TXT_BACK_TO_LOGIN")}
          </Button>
        </Box>
        <Box className={classes.imageContainer}>
          <Welcome domain={tmpBranchStorage.domain} branchId={tmpBranchStorage.id} />
        </Box>
      </Box>
    </Container>
  );
};

export default Login;

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    padding: 0,
    position: "relative",
  },

  root: {
    display: "flex",
    height: "100%",
  },

  imageContainer: {
    width: "70%",
    height: "100%",
    padding: 20,
    backgroundColor: "#F7F7F8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loginWelcomeImage: {
    width: "60%",
  },

  inputComponent: {
    width: "30%",
    height: "100vh",
    minWidth: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 30px",

    [theme.breakpoints.up(1500)]: {
      width: AppConstant.SIDEBAR_WIDTH.large,
    },

    [theme.breakpoints.down("lg")]: {
      padding: "0 24px",
    },
  },

  continueButton: {
    marginTop: 15,
    width: "100%",
    padding: "14px 0",
    textDecoration: "underline !important",
  },
}));
