import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Close, Sync } from "@mui/icons-material";
import { Box, IconButton as MuiIconButton, Stack, Typography } from "@mui/material";
import { AppConstant, KeyConstant, LangConstant } from "const";
import { ElectronService } from "services";
import { StorageUtil } from "utils";
import { NoticeDialog, SuccessDialog, TransLanguage } from "components";
import { getFirebaseConfig } from "utils/firebase.utils";
import { useTranslation } from "react-i18next";

const UPGRADE_VIEW_MODE = {
  none: "none",
  forceUpdateVersion: "forceUpdateVersion",
  newVersion: "newVersion",
};
const UpgradeApp = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_UPGRADE_VERSION);

  const [viewMode, setViewMode] = useState(UPGRADE_VIEW_MODE.none);
  const [releaseNote, setReleaseNote] = useState();

  const handleHiddenUpgrade = event => {
    if (event.stopPropagation) event.stopPropagation();
    setViewMode(UPGRADE_VIEW_MODE.none);
  };

  const handleUpgrade = () => {
    console.log("click upgrade")
    ElectronService.quitAndInstall();
    setViewMode(UPGRADE_VIEW_MODE.none);
  };

  useEffect(() => {
    // Check desktop force update
    getFirebaseConfig();

    // Check data in storage
    const releaseNote = StorageUtil.getCommonKey(KeyConstant.NEW_VERSION_NOTE);
    if (releaseNote) {
      setReleaseNote(releaseNote.trim() || "");
      StorageUtil.setCommonKey(KeyConstant.NEW_VERSION_NOTE, "");
    }
    const newVersion = StorageUtil.getCommonKey(KeyConstant.NEW_VERSION_APP);
    if (newVersion?.releaseNote) {
      setViewMode(
        StorageUtil.getCommonKey(KeyConstant.FORCE_UPGRADE_VERSION)
          ? UPGRADE_VIEW_MODE.forceUpdateVersion
          : UPGRADE_VIEW_MODE.newVersion,
      );
    }

    // Listen event from electron
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.newVersion, () => {
      setViewMode(
        StorageUtil.getCommonKey(KeyConstant.FORCE_UPGRADE_VERSION)
          ? UPGRADE_VIEW_MODE.forceUpdateVersion
          : UPGRADE_VIEW_MODE.newVersion,
      );
    });

    // Interval checking new version
    const intervalTime = getCheckingTime();
    const checkingUpgrade = setInterval(() => {
      getFirebaseConfig();
      ElectronService.checkForUpdates();
    }, intervalTime);

    return () => {
      clearInterval(checkingUpgrade);
    };
  }, []);

  return (
    <Box px={2}>
      {viewMode === UPGRADE_VIEW_MODE.newVersion && (
        <UpgradeAppBox onClick={handleUpgrade}>
          <Sync />
          <Typography component="div">
            <TransLanguage i18nKey={getLabel("HAS_NEW_VERSION")} />
          </Typography>

          <IconButton onClick={handleHiddenUpgrade}>
            <Close />
          </IconButton>
        </UpgradeAppBox>
      )}

      {viewMode === UPGRADE_VIEW_MODE.forceUpdateVersion && (
        <NoticeDialog open content={getLabel("FORCE_UPDATE_CONTENT")} submitProps={{ onClick: handleUpgrade }} />
      )}

      {releaseNote && (
        <SuccessDialog
          open
          onClose={() => setReleaseNote()}
          content={
            <Stack>
              <Typography variant="subtitle1" fontSize={20}>
                {getLabel("FINISH_UPGRADE_VERSION")}
              </Typography>

              <ReleaseNote>
                <Typography component="p" variant="subtitle1">
                  {getLabel("WHAT_VERSION_NEWS")}
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: releaseNote.replaceAll("\n", "<br/>"),
                  }}
                />
              </ReleaseNote>
            </Stack>
          }
        />
      )}
    </Box>
  );
};

export default UpgradeApp;

const UpgradeAppBox = styled(Stack)(props => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: props.theme.palette.primary.main,
  borderRadius: 10,
  padding: "7px 10px",
  cursor: "pointer",

  "& *": {
    color: "white",
  },

  "&:hover": {
    backgroundColor: props.theme.palette.primary.dark,
  },
}));

const IconButton = styled(MuiIconButton)({
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
});

const ReleaseNote = styled(Box)({
  maxHeight: 400,
  backgroundColor: "#E8E8E8",
  padding: 12,
  borderRadius: 10,
  marginTop: 8,
  textAlign: "initial",
  overflow: "auto",

  "& p": {
    fontSize: 14,
    color: "black",
    lineHeight: "19px",

    "& + p": {
      marginTop: 2,
      fontSize: 15,
    },
  },
});

const getCheckingTime = () => {
  let checkingTime = 60 * 60 * 1000; // Default 1 hour
  if (window.env?.CHECK_VERSION_TIME) {
    try {
      checkingTime = parseInt(window.env?.CHECK_VERSION_TIME);
    } catch (error) {
      console.warn(error);
    }
  }

  return checkingTime;
};
