import { useCallback, useEffect, useState } from "react";
import { getInteractor } from "services/local.service";
import { StorageUtil, toCamel } from "utils";
import { uniqBy } from "lodash";
import { useSelector } from "react-redux";
import store, { BranchSelectors, CallingActions, CallingSelectors, SystemSelectors } from "redux-store";
import { AppConstant, KeyConstant } from "const";
import useCleanUpEffect from "./useCleanUpEffect";

const DEFAULT_CONDITIONS = {
  limit: 20,
  isGetLatest: false,
};
const useCallHistory = () => {
  const selectedServerRedux = useSelector(BranchSelectors.getSelectedBranch);
  const isFetchHistoryTimestamp = useSelector(CallingSelectors.getFetchTimestamp);
  const deleteCallRoomIds = useSelector(CallingSelectors.getDeleteCallRoomIds);
  const seenCallHistoryTimestamp = useSelector(SystemSelectors.seenCallHistoryTimestamp);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { isMounted } = useCleanUpEffect();

  const [numUnreadCallHistory, setNumUnreadCallHistory] = useState(0);
  const [viewMode, setViewMode] = useState(AppConstant.VIEW_MODE.fetching);

  const [pagination, setPagination] = useState({
    callHistoryList: [],
    isEnd: false,
  });

  const handleSetCallHistory = useCallback(async (prefixKey, currentCallList, conditions = {}) => {
    setViewMode(AppConstant.VIEW_MODE.fetching);

    const currentLastCall = currentCallList[currentCallList.length - 1];
    const compareTime = conditions.compareTime || currentLastCall?.created || Date.now();
    const queryConditions = { ...DEFAULT_CONDITIONS, ...conditions, compareTime };
    const endCallList = await getInteractor(prefixKey).LocalCallHistoryService.getNotCalling(queryConditions);

    const callDetailList = [];
    for (let index = 0; index < endCallList.length; index++) {
      const callHistory = endCallList[index];
      const groupDetail = await getInteractor(prefixKey).LocalGroupService.get(callHistory.group_id);
      const initCall = await getInteractor(prefixKey).LocalCallHistoryService.getFirstByRoomId(callHistory.room_id);
      const timeStart = initCall.created;

      callDetailList.push(
        toCamel({
          ...callHistory,
          groupDetail,
          timeStart,
        }),
      );
    }

    const newCallList = uniqBy(
      conditions.isGetLatest ? callDetailList.concat(currentCallList) : currentCallList.concat(callDetailList),
      "roomId",
    );

    if (isMounted()) {
      setPagination({
        callHistoryList: newCallList,
        isEnd: endCallList.length < DEFAULT_CONDITIONS.limit,
      });
      setViewMode(newCallList.length > 0 ? AppConstant.VIEW_MODE.list : AppConstant.VIEW_MODE.empty);
    }
  }, []);

  const handleUnreadCallHistory = async prefixKey => {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const newNumUnread = await getInteractor(prefixKey).LocalCallHistoryService.countUnreadCallHistory(accountId);

    if (numUnreadCallHistory !== newNumUnread) setNumUnreadCallHistory(newNumUnread);
  };

  const deleteCallHistory = roomIds => {
    if (false === Boolean(Array.isArray(roomIds) && roomIds.length > 0)) return;

    if (roomIds.includes("select_all")) {
      setPagination({
        callHistoryList: [],
        isEnd: true,
      });
      return;
    }

    const newCallHistory = pagination.callHistoryList.filter(item => false === Boolean(roomIds.includes(item.roomId)));
    if (newCallHistory.length < DEFAULT_CONDITIONS.limit && !pagination.isEnd) {
      handleSetCallHistory(prefixKey, [], { compareTime: Date.now() });
    } else {
      setPagination(state => ({
        callHistoryList: newCallHistory,
        isEnd: state.isEnd,
      }));
    }
  };

  useEffect(() => {
    handleSetCallHistory(prefixKey, [], { compareTime: Date.now() });
    handleUnreadCallHistory(prefixKey);
  }, [selectedServerRedux]);

  // Add new call history
  useEffect(() => {
    if (isFetchHistoryTimestamp && viewMode !== AppConstant.VIEW_MODE.fetching) {
      const latestCallHistory = pagination.callHistoryList[0] || {};
      const latestCreated = latestCallHistory.created;
      handleSetCallHistory(prefixKey, pagination.callHistoryList, { compareTime: latestCreated, isGetLatest: true });
      handleUnreadCallHistory(prefixKey);
    }
  }, [isFetchHistoryTimestamp]);

  // Delete call history
  useEffect(() => {
    if (Boolean(Array.isArray(deleteCallRoomIds) && deleteCallRoomIds.length > 0)) {
      deleteCallHistory(deleteCallRoomIds);
      handleUnreadCallHistory(prefixKey);
      store.dispatch(
        CallingActions.callingSet({
          deleteCallRoomIds: [],
        }),
      );
    }
  }, [deleteCallRoomIds]);

  useEffect(() => {
    handleUnreadCallHistory(prefixKey);
  }, [seenCallHistoryTimestamp]);

  return { pagination, numUnreadCallHistory, viewMode, handleSetCallHistory, deleteCallHistory };
};

export default useCallHistory;
