import React, { memo } from "react";
import { PathConstant } from "const";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { isLoginBranch } from "utils/auth.utils";

const AuthenticationRoute = props => {
  const { component: Component, ...rest } = props;
  const isChecked = isLoginBranch();

  return isChecked ? (
    <Route {...rest} render={matchProps => <Component {...matchProps} />} />
  ) : (
    <Redirect
      to={{
        pathname: PathConstant.LOGIN_PORT,
        state: {
          from: rest.path,
        },
      }}
    />
  );
};

AuthenticationRoute.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
};

export default memo(AuthenticationRoute);
