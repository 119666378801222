import { AppConstant, KeyConstant, SystemConstant } from "const";
import { useCleanUpEffect, useScreenLock } from "hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SystemSelectors } from "redux-store";
import { LocalDeviceSettingService } from "services/local.service";
import { convertString2JSON, isMacOS, StorageUtil, toCamel } from "utils";

const HandleScreenLock = () => {
  const { lockScreenApp } = useScreenLock();
  const isFocused = useSelector(SystemSelectors.isFocused);
  const screenLockSettingTimestamp = useSelector(SystemSelectors.screenLockSettingTimestamp);
  const isPlayingVideo = useSelector(SystemSelectors.isPlayingVideo);
  const { isMounted } = useCleanUpEffect();

  const [screenLockSetting, setScreenLockSetting] = useState(null);
  const [isInteracting, setIsInteracting] = useState(true);

  let screenLockTimeout = null;
  let interactorTimeout = null;

  const handleScreenLock = () => {
    const settingOptions = toCamel(convertString2JSON(screenLockSetting.options, {}));
    // Set storage
    StorageUtil.setCommonKey(KeyConstant.SCREEN_LOCK_TIME, Date.now() + settingOptions.lockTime);
    if (screenLockTimeout) clearTimeout(screenLockTimeout);
    screenLockTimeout = setTimeout(lockScreenApp, settingOptions.lockTime);
  };

  const updateMouseMoveStatus = () => {
    if (isMounted()) setIsInteracting(true);

    if (interactorTimeout) clearTimeout(interactorTimeout);
    interactorTimeout = setTimeout(() => {
      if (isMounted()) setIsInteracting(false);
    }, 1000);
  };

  // Add keydown listener
  const keyDownHandler = event => {
    // Windows: Ctrl + L
    if (event.ctrlKey && (event.key === "l" || event.key === "L")) {
      lockScreenApp();
    }

    // MacOS: Command + L
    if (isMacOS() && event.metaKey && (event.key === "l" || event.key === "L")) {
      lockScreenApp();
    }
  };

  useEffect(() => {
    // Checking with storage lock time
    const storageLockTime = StorageUtil.getCommonKey(KeyConstant.SCREEN_LOCK_TIME);
    if (storageLockTime && Date.now() >= storageLockTime) lockScreenApp();

    if (screenLockSetting && screenLockSetting.id) {
      window.addEventListener("keydown", keyDownHandler);

      window.addEventListener("mousemove", updateMouseMoveStatus);
      window.addEventListener("keydown", updateMouseMoveStatus);
      window.addEventListener("touchstart", updateMouseMoveStatus);
    }

    return () => {
      window.removeEventListener("keydown", keyDownHandler);

      window.addEventListener("mousemove", updateMouseMoveStatus);
      window.addEventListener("keydown", updateMouseMoveStatus);
      window.addEventListener("touchstart", updateMouseMoveStatus);

      if (interactorTimeout) clearTimeout(interactorTimeout);
      if (screenLockTimeout) clearTimeout(screenLockTimeout);
    };
  }, [screenLockSetting]);

  useEffect(() => {
    LocalDeviceSettingService.findOne({
      setting_type: AppConstant.DEVICE_SETTING_TYPE.lockScreen,
      state: SystemConstant.STATE.active,
    }).then(setting => {
      if (setting && setting.options && isMounted()) setScreenLockSetting(setting);
      else if (isMounted()) setScreenLockSetting(null);
    });
  }, [screenLockSettingTimestamp]);

  useEffect(() => {
    if (screenLockSetting && (false === Boolean(isFocused) || (isFocused && !isInteracting && !isPlayingVideo))) {
      handleScreenLock();
    } else {
      StorageUtil.setCommonKey(KeyConstant.SCREEN_LOCK_TIME, null);
    }

    return () => {
      if (screenLockTimeout) clearTimeout(screenLockTimeout);
    };
  }, [isFocused, isInteracting, screenLockSetting, isPlayingVideo]);

  return null;
};

export default HandleScreenLock;
