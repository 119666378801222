// Copyright 2019 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import { createSelector } from "reselect";

import { type LocalizerType } from "../../types/Util";
import type { AciString } from "../../types/ServiceId";
import type { MenuOptionsType } from "../../types/menu";

import type { StateType } from "../reducer";
import type { UserStateType } from "../ducks/user";

export const getUser = (state: StateType): UserStateType => state.user;

export const getUserNumber = createSelector(getUser, (state: UserStateType): string | undefined => state.ourNumber);

export const getUserConversationId = createSelector(
  getUser,
  (state: UserStateType): string | undefined => state.ourConversationId,
);

export const getUserACI = createSelector(getUser, (state: UserStateType): AciString | undefined => state.ourAci);

export const getIntl = createSelector(getUser, (state: UserStateType): LocalizerType => state.i18n);

export const getPlatform = createSelector(getUser, (state: UserStateType): string => state.platform);

export const getMenuOptions = createSelector(getUser, (state: UserStateType): MenuOptionsType => state.menuOptions);

export const getIsMacOS = createSelector(getPlatform, (platform: string): boolean => platform === "darwin");

export const getRegionCode = createSelector(getUser, (state: UserStateType): string | undefined => state.regionCode);
