import { memo, useEffect, useState } from "react";
import { AppDrawer, ConfirmDialog, IOSSwitch } from "components";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { Box, Button, Divider, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import AccountItem from "../../../AccountItem";
import { useAlertContext } from "components/context/AlertContext";
import { useDispatch } from "react-redux";
import { SystemActions } from "redux-store";
import { convertScreenLockPassword } from "utils/view.utils";

const CreateScreenPassword = ({ defaultSetting, onChangeSetting }) => {
  const classes = useStyles();
  const { t: getScreenLockLabel } = useTranslation(LangConstant.NS_SCREEN_LOCK);
  const { showAlert } = useAlertContext();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [screenLockOn, setScreenLockOn] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showConfirmTurnOff, setShowConfirmTurnOff] = useState(false);
  const [verifyPassword, setVerifyPassword] = useState("");

  const handleCreatePassword = async event => {
    event.preventDefault();

    onChangeSetting({
      options: {
        password,
        lock_time: AppConstant.ONE_MINUTE,
      },
      state: SystemConstant.STATE.active,
    });

    showAlert({ content: getScreenLockLabel("TXT_CREATE_PASSWORD_SUCCESS") });
    // update UI
    dispatch(
      SystemActions.systemSet({
        screenLockSettingTimestamp: Date.now(),
      }),
    );

    setPassword("");
    setConfirmPassword("");
    setError("");
    setIsOpen(false);
  };

  /**
   *
   * If turn on setting: open create password drawer
   * If turn off setting: update state = inactive to local db
   *
   */
  const handleTurnOnOff = async () => {
    const newSetting = !screenLockOn;

    // Turn off setting
    if (!newSetting) {
      // Show verify password dialog
      setShowConfirmTurnOff(true);
    } else {
      setScreenLockOn(true);
      setIsOpen(true);
      setShowConfirmTurnOff(false);
    }
  };

  const handleTurnOff = () => {
    if (verifyPassword !== defaultSetting.password) {
      setConfirmPasswordError(getScreenLockLabel("TXT_CONFIRM_PASSWORD_ERROR"));
    } else {
      setConfirmPasswordError("");
      onChangeSetting({
        state: SystemConstant.STATE.inactive,
        options: {
          password: "",
          lock_time: AppConstant.ONE_MINUTE,
        },
      });
      // update UI
      dispatch(
        SystemActions.systemSet({
          screenLockSettingTimestamp: Date.now(),
        }),
      );

      handleClose();
    }
  };

  const handleClose = () => {
    setScreenLockOn(defaultSetting.isOn);
    setPassword("");
    setConfirmPassword("");
    setError("");
    setIsOpen(false);
    setShowConfirmTurnOff(false);
    setVerifyPassword("");
    setConfirmPasswordError("");
  };

  const handleChangePassword = event => {
    const value = event.target.value;
    const newPassword = convertScreenLockPassword(value);
    setPassword(newPassword);

    if (newPassword.length < 4) {
      setError(getScreenLockLabel("TXT_PASSWORD_PLACEHOLDER"));
    } else {
      setError("");
    }

    if (Boolean(confirmPassword) && confirmPassword.length === 4 && confirmPassword !== newPassword) {
      setConfirmPasswordError(getScreenLockLabel("TXT_CONFIRM_PASSWORD_ERROR"));
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleChangeConfirm = event => {
    const value = event.target.value;
    const newConfirm = convertScreenLockPassword(value);
    setConfirmPassword(newConfirm);
    if (newConfirm.length === 4 && newConfirm !== password) {
      setConfirmPasswordError(getScreenLockLabel("TXT_CONFIRM_PASSWORD_ERROR"));
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleChangeVerify = event => {
    const value = event.target.value;
    const newConfirm = convertScreenLockPassword(value);
    setVerifyPassword(newConfirm);
  };

  useEffect(() => {
    setScreenLockOn(defaultSetting.isOn);
  }, [defaultSetting]);

  const isDisableCreate =
    false === Boolean(password && password.length === 4) ||
    false === Boolean(confirmPassword && confirmPassword.length === 4) ||
    Boolean(error) ||
    Boolean(confirmPasswordError);

  return (
    <>
      <AccountItem
        text={getScreenLockLabel("TXT_CREATE_PASSWORD")}
        secondaryText={getScreenLockLabel("TXT_SCREEN_LOCK_NOTE")}
        endIcon={<IOSSwitch checked={screenLockOn} />}
        onClick={handleTurnOnOff}
      />

      {isOpen && (
        <AppDrawer open={isOpen} onClose={handleClose} title={getScreenLockLabel("TXT_CREATE_PASSWORD")}>
          <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" className={classes.paddingRoot}>
              <Stack spacing={1}>
                <Typography className={classes.title}>{getScreenLockLabel("TXT_CREATE_PASSWORD")}</Typography>
                <Typography className={classes.subTitle}>{getScreenLockLabel("TXT_SCREEN_LOCK_NOTE")}</Typography>
              </Stack>
              <IOSSwitch checked={screenLockOn} />
            </Stack>

            <Divider />

            <Box component="form" onSubmit={handleCreatePassword}>
              <Stack spacing={1} className={classes.paddingRoot}>
                <Typography className={classes.title}>{getScreenLockLabel("TXT_PASSWORD")}</Typography>
                <TextField
                  fullWidth
                  type="password"
                  required
                  placeholder={getScreenLockLabel("TXT_PASSWORD_PLACEHOLDER")}
                  value={password}
                  onChange={handleChangePassword}
                  FormHelperTextProps={{ classes: { root: classes.helperError } }}
                  error={Boolean(error)}
                  helperText={error}
                />
              </Stack>

              <Stack spacing={1} className={classes.paddingRoot}>
                <Typography className={classes.title}>{getScreenLockLabel("TXT_CONFIRM_PASSWORD")}</Typography>
                <TextField
                  fullWidth
                  type="password"
                  required
                  placeholder={getScreenLockLabel("TXT_CONFIRM_PASSWORD_PLACEHOLDER")}
                  value={confirmPassword}
                  onChange={handleChangeConfirm}
                  error={Boolean(confirmPasswordError)}
                  helperText={confirmPasswordError}
                  FormHelperTextProps={{ classes: { root: classes.helperError } }}
                />
              </Stack>

              <Stack direction="row" spacing={1} className={classes.paddingRoot} justifyContent="flex-end">
                <Button variant="contained" className={classes.cancelButton} onClick={handleClose}>
                  {getCommonLang("TXT_CANCEL")}
                </Button>
                <Button type="submit" variant="contained" disabled={isDisableCreate}>
                  {getScreenLockLabel("LABEL_CREATE")}
                </Button>
              </Stack>
            </Box>
          </Box>
        </AppDrawer>
      )}

      {showConfirmTurnOff && (
        <ConfirmDialog
          open
          title={getScreenLockLabel("TXT_DELETE_PASSWORD_TITLE")}
          content={
            <Stack spacing={1} alignItems="flex-start">
              <Typography>{getScreenLockLabel("TXT_DELETE_PASSWORD_SUBTITLE")}</Typography>
              <TextField
                fullWidth
                type="password"
                required
                placeholder={getNSLang(LangConstant.NS_SCREEN_LOCK, "TXT_PASSWORD_PLACEHOLDER")}
                value={verifyPassword}
                onChange={handleChangeVerify}
                error={Boolean(confirmPasswordError)}
                helperText={confirmPasswordError}
                FormHelperTextProps={{ classes: { root: classes.helperError } }}
              />
            </Stack>
          }
          submitProps={{
            submitText: getScreenLockLabel("TXT_DELETE_PASSWORD_TITLE"),
            onClick: handleTurnOff,
            disabled: false === Boolean(verifyPassword),
          }}
          cancelProps={{
            onClick: () => {
              setShowConfirmTurnOff(false);
              setVerifyPassword("");
              setConfirmPasswordError("");
            },
          }}
        />
      )}
    </>
  );
};

export default memo(CreateScreenPassword);

const useStyles = makeStyles(theme => ({
  paddingRoot: {
    padding: "8px 16px",
  },

  title: {
    fontSize: "0.9375rem",
    fontWeight: 600,
  },

  subTitle: {
    fontSize: "0.75rem",
    fontWeight: 400,
  },

  cancelButton: {
    color: "#000000",
    backgroundColor: theme.palette.grey[100],
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },

  helperError: {
    margin: "4px 0px",
    textAlign: "right",
    fontSize: "0.75rem",
  },
}));
