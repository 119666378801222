export default {
    "AddUserToAnotherGroupModal__title":"Thêm vào một nhóm",
    "AddUserToAnotherGroupModal__confirm-title":"Thêm thành viên mới?",
    "AddUserToAnotherGroupModal__confirm-add":"Thêm",
    "AddUserToAnotherGroupModal__confirm-message":"Thêm “{contact}” vào nhóm “{group}”",
    "AddUserToAnotherGroupModal__search-placeholder":"Tìm kiếm",
    "AddUserToAnotherGroupModal__toast--user-added-to-group":"{contact} đã được thêm vào {group}",
    "AddUserToAnotherGroupModal__toast--adding-user-to-group":"Đang thêm {contact}…",
    "RecordingComposer__cancel":"Hủy",
    "RecordingComposer__send":"Gửi",
    "GroupListItem__message-default":"{count, plural, other {{count,number} thành viên}}",
    "GroupListItem__message-already-member":"Đã là thành viên",
    "GroupListItem__message-pending":"Đang chờ xử lý tư cách thành viên nhóm",
    "Preferences__sent-media-quality":"Chất lượng tập tin đa phương tiện đã gửi",
    "sentMediaQualityStandard":"Tiêu chuẩn",
    "sentMediaQualityHigh":"Cao",
    "softwareAcknowledgments":"Nhìn nhận về Phần mềm",
    "privacyPolicy":"Điều khoản và Chính sách bảo mật",
    "appleSilicon":"Apple silicon",
    "copyErrorAndQuit":"Sao chép lỗi và thoát",
    "unknownContact":"Liên hệ không rõ",
    "unknownGroup":"Nhóm chưa biết",
    "databaseError":"Lỗi Cơ sở dữ liệu",
    "databaseError__detail":"Đã xảy ra lỗi cơ sở dữ liệu. Bạn có thể sao chép lỗi và liên hệ bộ phận hỗ trợ của Signal để được hỗ trợ khắc phục vấn đề. Nếu bạn cần sử dụng Signal ngay, bạn có thể xóa dữ liệu của mình và khởi động lại.\n\nLiên hệ với bộ phận hỗ trợ bằng cách truy cập: {link}",
    "deleteAndRestart":"Xóa dữ liệu và khởi động lại",
    "databaseError__deleteDataConfirmation":"Xóa vĩnh viễn tất cả dữ liệu?",
    "databaseError__deleteDataConfirmation__detail":"Tất cả lịch sử tin nhắn và tệp đa phương tiện của bạn sẽ bị xóa vĩnh viễn khỏi thiết bị này. Bạn sẽ có thể sử dụng Signal trên thiết bị này sau khi liên kết lại. Thao tác này sẽ không xóa bất kỳ dữ liệu nào trên điện thoại của bạn.",
    "databaseError__startOldVersion":"Phiên bản cơ sở dữ liệu của bạn không khớp với phiên bản Signal này. Đảm bảo bạn đang sử dụng phiên bản Signal mới nhất trên máy tính của mình.",
    "databaseError__safeStorageBackendChange":"Không thể truy cập cơ sở dữ liệu khóa mã hóa vì phần backend của keyring của hệ điều hành đã được đổi từ {previousBackend} thành {currentBackend}. Điều này có thể xảy ra khi môi trường desktop thay đổi, ví dụ như giữa GNOME và KDE.\n\nVui lòng chuyển thành môi trường desktop trước đó.",
    "databaseError__safeStorageBackendChangeWithPreviousFlag":"Không thể truy cập cơ sở dữ liệu khóa mã hóa vì phần backend của keyring của hệ điều hành đã được đổi từ {previousBackend} thành {currentBackend}. Điều này có thể xảy ra khi môi trường desktop thay đổi, ví dụ như giữa GNOME và KDE.\n\nVui lòng chuyển thành môi trường desktop trước đó hoặc thử chạy Signal bằng dòng lệnh --password-store=\"{previousBackendFlag}\"",
    "mainMenuFile":"&Tập tin",
    "mainMenuCreateStickers":"Tạo/ tải lên gói nhãn dán",
    "mainMenuEdit":"&Sửa",
    "mainMenuView":"&Xem",
    "mainMenuWindow":"&Cửa sổ",
    "mainMenuHelp":"&Hỗ trợ",
    "mainMenuSettings":"Tùy chỉnh…",
    "appMenuServices":"Dịch vụ",
    "appMenuHide":"Ẩn",
    "appMenuHideOthers":"Ẩn các Cửa sổ khác",
    "appMenuUnhide":"Hiển thị Tất cả",
    "appMenuQuit":"Thoát Signal",
    "editMenuUndo":"Hoàn tác",
    "editMenuRedo":"Làm lại",
    "editMenuCut":"Cắt",
    "editMenuCopy":"Sao chép",
    "editMenuPaste":"Dán",
    "editMenuPasteAndMatchStyle":"Dán và Hợp với phong cách",
    "editMenuDelete":"Xóa",
    "editMenuSelectAll":"Chọn Tất cả",
    "editMenuStartSpeaking":"Bắt đầu nói",
    "editMenuStopSpeaking":"Dừng nói",
    "windowMenuClose":"Đóng Cửa sổ",
    "windowMenuMinimize":"Thu nhỏ",
    "windowMenuZoom":"Thu phóng",
    "windowMenuBringAllToFront":"Đưa Tất cả cửa sổ ra Trước",
    "viewMenuResetZoom":"Kích cỡ Thực tế",
    "viewMenuZoomIn":"Phóng to",
    "viewMenuZoomOut":"Thu nhỏ",
    "viewMenuToggleFullScreen":"Bật tắt chế độ Toàn màn hình",
    "viewMenuToggleDevTools":"Bật tắt Công cụ Nhà phát triển",
    "viewMenuOpenCallingDevTools":"Mở Công cụ Nhà phát triển của Cuộc gọi",
    "menuSetupAsNewDevice":"Thiết lập như Thiết bị Mới",
    "menuSetupAsStandalone":"Thiết lập như Thiết bị Độc lập",
    "messageContextMenuButton":"Các thao tác khác",
    "contextMenuCopyLink":"Sao chép Đường dẫn",
    "contextMenuCopyImage":"Sao chép Ảnh",
    "contextMenuNoSuggestions":"Không có Gợi ý",
    "avatarMenuViewArchive":"Xem Lưu trữ",
    "loading":"Đang tải…",
    "optimizingApplication":"Đang tối ưu hóa ứng dụng…",
    "migratingToSQLCipher":"Đang tối ưu hóa tin nhắn… hoàn thành {status}.",
    "archivedConversations":"Cuộc trò chuyện đã lưu trữ",
    "LeftPane--pinned":"Đã ghim",
    "LeftPane--chats":"Trò chuyện",
    "LeftPane--corrupted-username--text":"Có vấn đề với tên người dùng của bạn, tên này không còn gắn liền với tài khoản của bạn. Bạn có thể thử và đặt lại hoặc chọn một tên mới.",
    "LeftPane--corrupted-username--action-text":"Sửa ngay",
    "LeftPane--corrupted-username-link--text":"Có vấn đề với mã QR và đường dẫn tên người dùng của bạn, tên này không còn khả dụng. Hãy tạo đường dẫn mới để chia sẻ với mọi người.",
    "LeftPane--corrupted-username-link--action-text":"Sửa ngay",
    "LeftPane__compose__findByUsername":"Tìm theo tên người dùng",
    "LeftPane__compose__findByPhoneNumber":"Tìm theo số điện thoại",
    "LeftPaneFindByHelper__title--findByUsername":"Tìm theo tên người dùng",
    "LeftPaneFindByHelper__title--findByPhoneNumber":"Tìm theo số điện thoại",
    "LeftPaneFindByHelper__placeholder--findByUsername":"Tên người dùng",
    "LeftPaneFindByHelper__placeholder--findByPhoneNumber":"Số điện thoại",
    "LeftPaneFindByHelper__description--findByUsername":"Nhập tên người dùng, sau đó là một dấu chấm và dãy số của tên người dùng.",
    "CountryCodeSelect__placeholder":"Mã quốc gia",
    "CountryCodeSelect__Modal__title":"Mã quốc gia",
    "NavTabsToggle__showTabs":"Hiển thị các Tab",
    "NavTabsToggle__hideTabs":"Ẩn các Tab",
    "NavTabs__ItemIconLabel--HasError":"Có lỗi xảy ra",
    "NavTabs__ItemIconLabel--UnreadCount":"{count,number} chưa đọc",
    "NavTabs__ItemIconLabel--MarkedUnread":"Đã đánh dấu chưa đọc",
    "NavTabs__ItemLabel--Chats":"Trò chuyện",
    "NavTabs__ItemLabel--Calls":"Cuộc gọi",
    "NavTabs__ItemLabel--Stories":"Các Story",
    "NavTabs__ItemLabel--Settings":"Cài đặt",
    "NavTabs__ItemLabel--Update":"Cập nhật Signal",
    "NavTabs__ItemLabel--Profile":"Hồ sơ",
    "NavSidebar__BackButtonLabel":"Trở lại",
    "archiveHelperText":"Các cuộc trò chuyện này đã được lưu trữ và sẽ chỉ hiện trong Hộp thư đến nếu có tin nhắn mới được nhận.",
    "noArchivedConversations":"Không có cuộc trò chuyện đã lưu trữ.",
    "archiveConversation":"Lưu trữ",
    "markUnread":"Đánh dấu chưa đọc",
    "ConversationHeader__menu__selectMessages":"Chọn tin nhắn",
    "ConversationHeader__MenuItem--Accept":"Chấp nhận",
    "ConversationHeader__MenuItem--Block":"Chặn",
    "ConversationHeader__MenuItem--Unblock":"Bỏ chặn",
    "ConversationHeader__MenuItem--ReportSpam":"Báo cáo spam",
    "ConversationHeader__MenuItem--DeleteChat":"Xóa cuộc trò chuyện",
    "ContactListItem__menu":"Quản lý Danh bạ",
    "ContactListItem__menu__message":"Tin nhắn",
    "ContactListItem__menu__audio-call":"Gọi thoại",
    "ContactListItem__menu__video-call":"Cuộc gọi video",
    "ContactListItem__menu__remove":"Xóa",
    "ContactListItem__menu__block":"Chặn",
    "ContactListItem__remove--title":"Xóa {title}?",
    "ContactListItem__remove--body":"Bạn sẽ không nhìn thấy người này khi tìm kiếm. Bạn sẽ nhận được một yêu cầu gửi tin nhắn nếu người này nhắn tin cho bạn trong tương lai.",
    "ContactListItem__remove--confirm":"Xóa",
    "ContactListItem__remove-system--title":"Không thể xóa {title}",
    "ContactListItem__remove-system--body":"Người này được lưu trong Danh bạ trên thiết bị của bạn. Xóa liên hệ của người này trong Danh bạ trên thiết bị di động của bạn và thử lại.",
    "moveConversationToInbox":"Bỏ lưu trữ",
    "pinConversation":"Ghim trò chuyện",
    "unpinConversation":"Bỏ ghim trò chuyện",
    "pinnedConversationsFull":"Bạn chỉ có thể ghim tối đa 4 cuộc trò chuyện",
    "loadingMessages--other":"{daysAgo, plural, other {Đang tải tin nhắn từ {daysAgo,number} ngày trước…}}",
    "loadingMessages--yesterday":"Đang tải tin nhắn từ ngày hôm qua…",
    "loadingMessages--today":"Đang tải tin nhắn từ ngày hôm nay…",
    "view":"Xem",
    "youLeftTheGroup":"Bạn không còn là thành viên của nhóm.",
    "invalidConversation":"Nhóm này không có hiệu lực. Xin hãy tạo nhóm mới.",
    "scrollDown":"Cuộn xuống cuối cuộc trò chuyện",
    "messagesBelow":"Tin nhắn mới bên dưới",
    "mentionsBelow":"Các đề cập mới bên dưới",
    "unreadMessages":"{count, plural, other {{count,number} tin nhắn chưa đọc}}",
    "messageHistoryUnsynced":"Vì lý do bảo mật, lịch sử trò chuyện không được chuyển sang các thiết bị được liên kết mới.",
    "youMarkedAsVerified":"Bạn đã đánh dấu Số An toàn của bạn với {name} là đã được xác minh",
    "youMarkedAsNotVerified":"Bạn đã đánh dấu Số An toàn của bạn với{name} là chưa được xác minh",
    "youMarkedAsVerifiedOtherDevice":"Bạn đã đánh dấu Số An toàn của bạn với {name} là đã được xác minh từ thiết bị khác",
    "youMarkedAsNotVerifiedOtherDevice":"Bạn đã đánh dấu Số An toàn của bạn với {name} là chưa được xác minh từ thiế bị khác",
    "changedRightAfterVerify":"Số an toàn bạn đang cố để xác minh đã thay đổi. Xin hãy xem lại số an toàn mới của bạn với {name1}. Xin nhớ rằng, thay đổi này có thể có nghĩa là có người đang cố gắng chặn liên lạc của bạn hoặc đơn giản là {name2} đã cài đặt lại Signal.",
    "safetyNumberChangeDialog__message":"Những người sau có thể đã cài đặt lại Signal hoặc đã đổi thiết bị. Bấm vào một người nhận để xác nhận mã số an toàn mới của người đó. Thao tác này không bắt buộc.",
    "safetyNumberChangeDialog__pending-messages":"Gửi tin nhắn chờ",
    "safetyNumberChangeDialog__review":"Duyệt lại",
    "safetyNumberChangeDialog__many-contacts":"{count, plural, other {Bạn có {count,number} liên hệ có thể đã cài đặt lại Signal hoặc đã đổi thiết bị. Bạn có thể chọn duyệt lại mã số an toàn của họ trước khi gửi.}}",
    "safetyNumberChangeDialog__post-review":"Tất cả liên hệ đã được xem xét, nhấn gửi để tiếp tục.",
    "safetyNumberChangeDialog__confirm-remove-all":"{count, plural, other {Bạn có chắc muốn xóa {count,number} người nhận ở story {story}?}}",
    "safetyNumberChangeDialog__remove-all":"Gỡ tất cả",
    "safetyNumberChangeDialog__verify-number":"Xác minh mã số an toàn",
    "safetyNumberChangeDialog__remove":"Gỡ khỏi story",
    "safetyNumberChangeDialog__actions-contact":"Các hành động cho liên hệ {contact}",
    "safetyNumberChangeDialog__actions-story":"Các hành động cho story {story}",
    "sendAnyway":"Vẫn gửi",
    "safetyNumberChangeDialog_send":"Gửi",
    "safetyNumberChangeDialog_done":"Xong",
    "callAnyway":"Vẫn gọi",
    "joinAnyway":"Tiếp tục tham gia",
    "debugLogExplanation":"Khi bạn bấm Gửi, nhật ký của bạn sẽ được đăng online trong 30 ngày tại một URL độc nhất, chưa được xuất bản. Bạn có thể Lưu trên thiết bị trước.",
    "debugLogError":"Đã xảy ra sự cố với quá trình tải lên! Vui lòng gửi email tới support@signal.org và đính kèm nhật ký của bạn dưới dạng tệp văn bản.",
    "debugLogSuccess":"Đã gửi nhật ký gỡ lỗi",
    "debugLogSuccessNextSteps":"Nhật ký gỡ lỗi đã được tải lên. Khi bạn liên hệ với bộ phận hỗ trợ, hãy sao chép URL dưới đây và đính kèm nó với mô tả về sự cố bạn gặp và các bước để tái lập sự cố.",
    "debugLogLogIsIncomplete":"… để xem nhật ký đầy đủ, bấm Lưu",
    "debugLogCopy":"Sao chép Đường dẫn",
    "debugLogSave":"Lưu",
    "debugLogLinkCopied":"Đường dẫn đã được sao chép vào bộ nhớ tạm",
    "reportIssue":"Liên lạc Hỗ trợ",
    "submit":"Gửi",
    "SafetyNumberViewer__markAsVerified":"Đánh dấu đã xác minh",
    "SafetyNumberViewer__clearVerification":"Xóa xác nhận",
    "SafetyNumberViewer__hint":"Để xác minh bảo mật đầu cuối với {name}, hãy so sánh các số ở trên với thiết bị của họ. Người đó cũng có thể quét mã của bạn với thiết bị của họ.",
    "SafetyNumberViewer__learn_more":"Tìm hiểu thêm",
    "SafetyNumberNotReady__body":"Một mã số an toàn sẽ được tạo với người này sau khi hai bạn nhắn tin.",
    "SafetyNumberNotReady__learn-more":"Tìm hiểu thêm",
    "verified":"Đã xác minh",
    "newIdentity":"Số an toàn mới",
    "incomingError":"Lỗi xử lý tin nhắn đến",
    "media":"Đa phương tiện",
    "mediaEmptyState":"Bạn không có tệp đa phương tiện nào trong cuộc trò chuyện này",
    "allMedia":"Tất cả Tập tin đa phương tiện",
    "documents":"Tài liệu",
    "documentsEmptyState":"Bạn không có tài liệu nào trong cuộc trò chuyện này",
    "today":"Hôm nay",
    "yesterday":"Ngày hôm qua",
    "thisWeek":"Tuần này",
    "thisMonth":"Tháng này",
    "unsupportedAttachment":"Dạng tập tin đính kèm không được hỗ trợ. Nhấp để lưu.",
    "voiceMessage":"Tin nhắn Thoại",
    "dangerousFileType":"Tập tin đính kèm không khả dụng vì lý do bảo mật",
    "loadingPreview":"Đang tải Xem trước…",
    "stagedPreviewThumbnail":"Bản nháp hình thu nhỏ liên kết bản xem trước cho {domain}",
    "previewThumbnail":"Hình thu nhỏ liên kết bản xem trước cho {domain}",
    "stagedImageAttachment":"Bản nháp tập tin ảnh đính kèm: {path}",
    "decryptionErrorToast":"Máy tính để bàn gặp lỗi giải mã từ {name}, thiết bị {deviceId}",
    "Toast__ActionLabel--SubmitLog":"Gửi nhật ký",
    "Toast--FailedToSendWithEndorsements":"Failed to send message with endorsements",
    "cannotSelectPhotosAndVideosAlongWithFiles":"Bạn không thể chọn ảnh và video cùng các tập tin.",
    "cannotSelectMultipleFileAttachments":"Bạn chỉ có thể chọn một tập tin tại một thời điểm.",
    "maximumAttachments":"Bạn không thể thêm bất kì tập tin đính kèm nào cho tin nhắn này.",
    "fileSizeWarning":"Rất tiếc, tập tin đã chọn vượt quá giới hạn kích thước của tin nhắn. {limit,number} {units}",
    "unableToLoadAttachment":"Không thể tải tập tin đính kèm đã chọn.",
    "settingUpSecureCall":"Đang thiết lập cuộc gọi bảo mật",
    "disconnected":"Đã ngắt kết nối",
    "connecting":"Đang kết nối…",
    "connect":"Nhấp chuột để kết nối lại.",
    "connectingHangOn":"Không nên dài đâu",
    "offline":"Ngoại tuyến",
    "checkNetworkConnection":"Kiểm tra kết nối mạng của bạn.",
    "submitDebugLog":"Nhật ký gỡ lỗi",
    "debugLog":"Nhật ký Gỡ lỗi",
    "forceUpdate":"Buộc Cập nhật",
    "helpMenuShowKeyboardShortcuts":"Hiện Phím tắt bàn phím",
    "contactUs":"Liên lạc chúng tôi",
    "goToReleaseNotes":"Chuyển đến Ghi chú Phát hành",
    "goToForums":"Chuyển đến Diễn đàn",
    "goToSupportPage":"Chuyển đến Trang Hỗ trợ",
    "joinTheBeta":"Tham gia bản Beta",
    "signalDesktopPreferences":"Quyền ưu tiên Signal Desktop",
    "signalDesktopStickerCreator":"Tác giả gói nhãn dán",
    "aboutSignalDesktop":"Giới thiệu Signal Desktop",
    "screenShareWindow":"Chia sẻ màn hình",
    "callingDeveloperTools":"Công cụ Nhà phát triển của Cuộc gọi",
    "callingDeveloperToolsDescription":"Cửa sổ này được sử dụng trong quá trình phát triển để hiển thị thông tin chẩn đoán từ những cuộc gọi đang diễn ra.",
    "speech":"Nói",
    "show":"Hiển thị",
    "hide":"Ẩn",
    "quit":"Thoát",
    "signalDesktop":"Signal Desktop",
    "search":"Tìm kiếm",
    "clearSearch":"Xoá Tìm kiếm",
    "searchIn":"Tình kiếm cuộc trò chuyện",
    "noSearchResults":"Không có kết quả cho \"{searchTerm}\"",
    "noSearchResults--sms-only":"Liên hệ SMS/MMS không có sẵn trên Desktop.",
    "noSearchResultsInConversation":"Không có kết quả cho \"{searchTerm}\" trong {conversationName}",
    "conversationsHeader":"Trò chuyện",
    "contactsHeader":"Danh bạ",
    "groupsHeader":"Nhóm",
    "messagesHeader":"Tin nhắn",
    "findByUsernameHeader":"Tìm theo tên người dùng",
    "findByPhoneNumberHeader":"Tìm theo số điện thoại",
    "welcomeToSignal":"Chào mừng đến với Signal",
    "whatsNew":"Nhìn xem {whatsNew} trong bản cập nhật này",
    "viewReleaseNotes":"có gì mới",
    "typingAlt":"Hình động đang nhập cho cuộc trò chuyện này",
    "contactInAddressBook":"Người này có trong danh bạ của bạn.",
    "contactAvatarAlt":"Ảnh đại diện cho liên lạc {name}",
    "sendMessageToContact":"Gửi Tin nhắn",
    "home":"nhà",
    "work":"công việc",
    "mobile":"di động",
    "email":"email",
    "phone":"số điện thoại",
    "address":"địa chỉ",
    "poBox":"Hộp thư",
    "downloading":"Đang tải xuống",
    "downloadFullMessage":"Tải tin nhắn đầy đủ",
    "downloadAttachment":"Tải Tập tin đính kèm",
    "reactToMessage":"Bày tỏ về tin nhắn",
    "replyToMessage":"Trả lời Tin nhắn",
    "originalMessageNotFound":"Không tìm thấy tin nhắn ban đầu",
    "voiceRecording--start":"Bắt đầu ghi âm tin nhắn thoại",
    "voiceRecordingInterruptedMax":"Việc thu âm tin nhắn thoại dừng lại vì đã đạt mức giới hạn số lần tối đa.",
    "voiceNoteLimit":"Tin nhắn thoại được giới hạn trong một giờ. Việc thu âm sẽ dừng lại nếu bạn chuyển sang ứng dụng khác.",
    "voiceNoteMustBeOnlyAttachment":"Tin nhắn thoại chỉ được có một tệp đính kèm.",
    "voiceNoteError":"Có lỗi xảy ra đối với việc ghi âm tin nhắn thoại",
    "attachmentSaved":"Đã lưu đính kèm.",
    "attachmentSavedPlural":"{count, plural, one {Attachment saved} other {{count,number} attachments saved}}",
    "attachmentSavedShow":"Hiển thị trong thư mục",
    "attachmentStillDownloading":"{count, plural, one {Can't save attachment, since it hasn't finished downloading yet} other {Can't save attachments, since {count,number} haven't finished downloading yet}}",
    "you":"Bạn",
    "audioPermissionNeeded":"Để gửi tin nhắn thoại, cho phép Signal Desktop truy cập microphone.",
    "audioCallingPermissionNeeded":"Để thực hiện cuộc gọi, bạn cần phải cho phép Signal Desktop truy cập microphone của bạn.",
    "videoCallingPermissionNeeded":"Để thực hiện cuộc gọi video, bạn cần phải cho phép Signal Desktop truy cập camera của bạn.",
    "allowAccess":"Cho phép Truy cập",
    "audio":"Âm thanh",
    "video":"Video",
    "photo":"Ảnh",
    "text":"Văn bản",
    "cannotUpdate":"Không thể Cập nhật",
    "mute":"Tắt tiếng",
    "cannotUpdateDetail-v2":"Signal không thể cập nhật. <retryUpdateButton>Thử cập nhật lại</retryUpdateButton> hoặc truy cập {url} để cài thủ công. Sau đó, <contactSupportLink>liên hệ bộ phận hỗ trợ</contactSupportLink> về vấn đề này",
    "cannotUpdateRequireManualDetail-v2":"Signal không thể cập nhật. Truy cập {url} để cài thủ công. Sau đó, <contactSupportLink>liên hệ bộ phận hỗ trợ</contactSupportLink> về vấn đề này",
    "readOnlyVolume":"Signal Desktop đang trong tình trạng cách ly trên macOS, và sẽ không thể tự-cập nhật được. Xin hãy dời {app} đến {folder} với ứng dụng Finder.",
    "ok":"OK",
    "cancel":"Hủy",
    "discard":"Bỏ",
    "error":"Lỗi",
    "delete":"Xóa",
    "accept":"Chấp nhận",
    "edit":"Sửa",
    "forward":"Chuyển tiếp",
    "done":"Xong",
    "update":"Cập nhật",
    "next2":"Tiếp",
    "on":"Bật",
    "off":"Tắt",
    "deleteWarning":"Tin nhắn này sẽ được xoá khỏi thiết bị này.",
    "deleteForEveryoneWarning":"Tin nhắn này sẽ được xóa cho tất cả mọi người trong cuộc trò chuyện nếu họ sử dụng phiên bản mới của Signal. Họ sẽ có thể thấy rằng bạn đã xoá tin nhắn.",
    "from":"Từ",
    "searchResultHeader--sender-to-group":"{sender} đến {receiverGroup}",
    "searchResultHeader--sender-to-you":"{sender} gửi đến bạn",
    "searchResultHeader--you-to-group":"Bạn gửi đến {receiverGroup}",
    "searchResultHeader--you-to-receiver":"Bạn gửi đến {receiverContact}",
    "sent":"Đã gửi",
    "received":"Đã nhận",
    "sendMessage":"Tin nhắn",
    "showMembers":"Hiển thị thành viên",
    "showSafetyNumber":"Xem số an toàn",
    "AboutContactModal__title":"Thông tin",
    "AboutContactModal__title--myself":"Bạn",
    "AboutContactModal__TitleAndTitleWithoutNickname":"{nickname} <muted>({titleNoNickname})</muted>",
    "AboutContactModal__TitleWithoutNickname__Tooltip":"“{title}” là tên hồ sơ người này đặt cho chính mình trong Signal.",
    "AboutContactModal__verified":"Đã xác minh",
    "AboutContactModal__blocked":"{name} đã bị chặn",
    "AboutContactModal__message-request":"Yêu cầu gửi tin nhắn chưa duyệt",
    "AboutContactModal__no-dms":"Không có tin nhắn trực tiếp với {name}",
    "AboutContactModal__signal-connection":"Liên hệ Signal",
    "AboutContactModal__system-contact":"{name} có trong danh bạ của thiết bị",
    "NotePreviewModal__Title":"Ghi chú",
    "allMediaMenuItem":"Tất cả tập tin đa phương tiện",
    "back":"Trở lại",
    "goBack":"Quay lại",
    "moreInfo":"Thông tin khác",
    "copy":"Sao chép văn bản",
    "MessageContextMenu__select":"Chọn",
    "MessageTextRenderer--spoiler--label":"Spoiler",
    "retrySend":"Thử Gửi lại",
    "retryDeleteForEveryone":"Thử lại Xóa với Mọi người",
    "forwardMessage":"Chuyển tiếp tin nhắn",
    "MessageContextMenu__reply":"Trả lời",
    "MessageContextMenu__react":"Bày tỏ cảm xúc",
    "MessageContextMenu__download":"Tải xuống",
    "MessageContextMenu__deleteMessage":"Xóa",
    "MessageContextMenu__forward":"Chuyển tiếp",
    "MessageContextMenu__info":"Thông tin",
    "deleteMessagesInConversation":"Xóa tin nhắn",
    "ConversationHeader__DeleteMessagesInConversationConfirmation__title":"Xóa tin nhắn?",
    "ConversationHeader__DeleteMessagesInConversationConfirmation__description":"Tất cả tin nhắn trong cuộc trò chuyện này sẽ được xóa khỏi thiết bị này. Bạn vẫn có thể tìm cuộc trò chuyện này sau khi xóa tin nhắn.",
    "ConversationHeader__DeleteMessagesInConversationConfirmation__description-with-sync":"Tất cả tin nhắn trong cuộc trò chuyện này sẽ được xóa khỏi tất cả thiết bị của bạn. Bạn vẫn có thể tìm cuộc trò chuyện này sau khi xóa tin nhắn.",
    "deleteConversation":"Xóa",
    "ConversationHeader__DeleteConversationConfirmation__title":"Xóa cuộc trò chuyện?",
    "ConversationHeader__DeleteConversationConfirmation__description":"Tất cả tin nhắn trong cuộc trò chuyện này sẽ được xóa khỏi thiết bị này.",
    "ConversationHeader__DeleteConversationConfirmation__description-with-sync":"Tất cả tin nhắn trong cuộc trò chuyện này sẽ được xóa khỏi tất cả thiết bị của bạn.",
    "ConversationHeader__ContextMenu__LeaveGroupAction__title":"Rời nhóm",
    "ConversationHeader__LeaveGroupConfirmation__title":"Bạn có chắc chắn muốn rời khỏi nhóm?",
    "ConversationHeader__LeaveGroupConfirmation__description":"Bạn sẽ không thể gửi hoặc nhận tin nhắn trong nhóm này nữa.",
    "ConversationHeader__LeaveGroupConfirmation__confirmButton":"Rời",
    "ConversationHeader__CannotLeaveGroupBecauseYouAreLastAdminAlert__description":"Trước khi rời nhóm, bạn phải chọn ít nhất một quản trị viên mới cho nhóm này.",
    "sessionEnded":"Đặt lại phiên bảo mật",
    "ChatRefresh--notification":"Đã làm mới phiên trò chuyện",
    "ChatRefresh--learnMore":"Tìm hiểu thêm",
    "ChatRefresh--summary":"Signal sử dụng công nghệ mã hóa đầu cuối và đôi lúc cần làm mới phiên trò chuyện của bạn. Việc này không ảnh hưởng sự bảo mật của cuộc trò chuyện của bạn nhưng bạn có thể bỏ lỡ tin nhắn từ liên hệ này và bạn có thể nhờ người này gửi lại.",
    "ChatRefresh--contactSupport":"Liên lạc Hỗ trợ",
    "DeliveryIssue--preview":"Vấn đề truyền tải",
    "DeliveryIssue--notification":"Một tin nhắn từ {sender} không thể được gửi",
    "DeliveryIssue--learnMore":"Tìm hiểu thêm",
    "DeliveryIssue--title":"Lỗi chuyên",
    "DeliveryIssue--summary":"Một tin nhắn, nhãn dán, phản ứng, nhãn đã đọc hoặc đa phương tiện không thể được gửi đến bạn từ {sender}. Họ có thể đã cố gửi cho bạn trực tiếp, hoặc trong một nhóm.",
    "DeliveryIssue--summary--group":"Tin nhắn, nhãn dán, bày tỏ cảm xúc, thông báo đã đọc/xem hoặc tệp đa phương tiện không thể gửi đến bạn từ {sender} trong cuộc hội thoại này.",
    "ChangeNumber--notification":"{sender} đã đổi số điện thoại của họ",
    "JoinedSignal--notification":"Liên hệ bắt đầu dùng Signal",
    "ConversationMerge--notification":"{obsoleteConversationTitle} và {conversationTitle} là cùng một tài khoản. Lịch sử tin nhắn của bạn của các cuộc trò chuyện ở hai bên đều ở đây.",
    "ConversationMerge--notification--with-e164":"Lịch sử tin nhắn của bạn với {conversationTitle} và số điện thoại {obsoleteConversationNumber} của liên hệ này đã được hợp nhất.",
    "ConversationMerge--notification--no-title":"Lịch sử tin nhắn của bạn với {conversationTitle} và một cuộc trò chuyện khác của liên hệ này đã được hợp nhất.",
    "ConversationMerge--learn-more":"Tìm hiểu thêm",
    "ConversationMerge--explainer-dialog--line-1":"Sau khi nhắn tin với {obsoleteConversationTitle} bạn biết số này thuộc về {conversationTitle}. Số điện thoại của người đó riêng tư.",
    "ConversationMerge--explainer-dialog--line-2":"Lịch sử tin nhắn của các cuộc trò chuyện của bạn đã được hợp nhất tại đây.",
    "PhoneNumberDiscovery--notification--withSharedGroup":"{phoneNumber} thuộc về {conversationTitle}. Các bạn đều là thành viên của {sharedGroup}.",
    "PhoneNumberDiscovery--notification--noSharedGroup":"{phoneNumber} thuộc về {conversationTitle}",
    "TitleTransition--notification":"Bạn đã bắt đầu cuộc trò chuyện này với {oldTitle}",
    "imageAttachmentAlt":"Ảnh đã được đính kèm vào tin nhắn",
    "videoAttachmentAlt":"Hình chụp của video đã được đính kèm vào tin nhắn",
    "lightboxImageAlt":"Hình ảnh đã được gửi vào cuộc trò chuyện",
    "imageCaptionIconAlt":"Biểu tượng cho biết hình ảnh này có chú thích",
    "save":"Lưu",
    "reset":"Đặt lại",
    "linkedDevices":"Các thiết bị Được liên kết",
    "linkNewDevice":"Liên kết một thiết bị mới",
    "Install__learn-more":"Tìm hiểu thêm",
    "Install__scan-this-code":"Quét mã này trong ứng dụng Signal trên điện thoại của bạn.",
    "Install__instructions__1":"Mở Signal trên điện thoại của bạn",
    "Install__instructions__2":"Nhấn vào {settings}. sau đó nhấn {linkedDevices}",
    "Install__instructions__2__settings":"Cài đặt",
    "Install__instructions__3":"Nhấn {linkNewDevice}",
    "Install__qr-failed-load__error--timeout":"Không thể tải mã QR. Kiểm tra kết nối của bạn và thử lại.",
    "Install__qr-failed-load__error--unknown":"<paragraph>Có lỗi không mong muốn xảy ra.</paragraph><paragraph>Xin hãy thử lại.</paragraph>",
    "Install__qr-failed-load__error--network":"Signal không thể liên kết thiết bị này khi sử dụng mạng hiện tại của bạn.",
    "Install__qr-failed-load__retry":"Thử lại",
    "Install__qr-failed-load__get-help":"Hỗ trợ",
    "Install__support-link":"Cần hỗ trợ?",
    "Install__choose-device-name__description":"Bạn sẽ thấy tên này dưới \"Thiết Bị Được Liên Kết\" trên điện thoại của bạn",
    "Install__choose-device-name__placeholder":"Máy tính của tôi",
    "Preferences--phone-number":"Số điện thoại",
    "Preferences--device-name":"Tên thiết bị",
    "chooseDeviceName":"Chọn tên cho thiết bị này",
    "finishLinkingPhone":"Hoàn tất liên kết điện thoại",
    "initialSync":"Đồng bộ các liên lạc và nhóm",
    "initialSync__subtitle":"Lưu ý: Lịch sử trò chuyện của bạn sẽ không được đồng bộ trên thiết bị này",
    "installConnectionFailed":"Không thể kết nối đến máy chủ.",
    "installTooManyDevices":"Rất tiếc, bạn có quá nhiều thiết bị đã được liên kết. Hãy thử gỡ bỏ vài thiết bị.",
    "installTooOld":"Cập nhật Signal trên thiết bị này để kết nối với điện thoại của bạn.",
    "installErrorHeader":"Đã xảy ra lỗi!",
    "installUnknownError":"Có lỗi không mong muốn xảy ra. Xin hãy thử lại.",
    "installTryAgain":"Thử lại",
    "Preferences--theme":"Chủ đề",
    "calling":"Đang gọi",
    "calling__call-back":"Gọi lại",
    "calling__call-again":"Gọi Lại",
    "calling__join":"Tham gia cuộc gọi",
    "calling__return":"Quay trở lại cuộc gọi",
    "calling__lobby-automatically-muted-because-there-are-a-lot-of-people":"Microphone bị tắt tiếng do kích thước cuộc gọi quá lớn",
    "calling__toasts--aria-label":"Thông báo cuộc gọi",
    "calling__call-is-full":"Cuộc gọi đã đầy",
    "calling__cant-join":"Không thể tham gia cuộc gọi",
    "calling__call-link-connection-issues":"Không thể lấy thông tin đường dẫn cuộc gọi. Vui lòng kiểm tra kết nối mạng của bạn và thử lại.",
    "calling__call-link-copied":"Đã sao chép đường dẫn cuộc gọi.",
    "calling__call-link-no-longer-valid":"Đường dẫn cuộc gọi không còn hiệu lực.",
    "calling__call-link-default-title":"Cuộc gọi Signal",
    "calling__call-link-delete-failed":"Không thể xóa đường dẫn. Kiểm tra kết nối của bạn và thử lại.",
    "calling__join-request-denied":"Yêu cầu tham gia cuộc gọi này của bạn đã bị từ chối.",
    "calling__join-request-denied-title":"Yêu cầu tham gia đã bị từ chối",
    "calling__removed-from-call":"Có người đã gỡ bạn khỏi cuộc gọi.",
    "calling__removed-from-call-title":"Đã bị gỡ khỏi cuộc gọi",
    "CallingLobby__CallLinkNotice":"Bất kỳ ai tham gia cuộc gọi này qua đường dẫn sẽ thấy tên và ảnh của bạn.",
    "CallingLobby__CallLinkNotice--phone-sharing":"Bất kỳ ai tham gia cuộc gọi này qua đường dẫn sẽ thấy tên, ảnh, và số điện thoại của bạn.",
    "CallingLobby__CallLinkNotice--join-request-pending":"Đang chờ để được tham gia…",
    "CallingLobbyJoinButton--join":"Tham gia",
    "CallingLobbyJoinButton--start":"Bắt đầu",
    "CallingLobbyJoinButton--call-full":"Cuộc gọi đã đầy",
    "CallingLobbyJoinButton--ask-to-join":"Gửi yêu cầu tham gia",
    "calling__button--video-disabled":"Đã tắt Camera",
    "calling__button--video-off":"Tắt camera",
    "calling__button--video-on":"Bật camera",
    "calling__button--audio-disabled":"Microphone đã được tắt",
    "calling__button--audio-off":"Tắt tiếng micro",
    "calling__button--audio-on":"Bỏ tắt tiếng micro",
    "calling__button--presenting-disabled":"Tắt chế độ trình bày",
    "calling__button--presenting-on":"Bắt đầu trình bày",
    "calling__button--presenting-off":"Ngưng trình bày",
    "calling__button--react":"Bày tỏ cảm xúc",
    "calling__button--ring__disabled-because-group-is-too-large":"Nhóm quá lớn để rung chuông các thành viên",
    "CallingButton__ring-off":"Tắt đổ chuông",
    "CallingButton--ring-on":"Bật đổ chuông",
    "CallingButton--more-options":"Tùy chọn khác",
    "CallingPendingParticipants__ApproveUser":"Chấp nhận yêu cầu tham gia",
    "CallingPendingParticipants__DenyUser":"Từ chối yêu cầu tham gia",
    "CallingPendingParticipants__ApproveAll":"Chấp nhận tất cả",
    "CallingPendingParticipants__DenyAll":"Từ chối tất cả",
    "CallingPendingParticipants__ConfirmDialogTitle--ApproveAll":"{count, plural, other {Chấp nhận {count,number} yêu cầu?}}",
    "CallingPendingParticipants__ConfirmDialogTitle--DenyAll":"{count, plural, other {Từ chối {count,number} yêu cầu?}}",
    "CallingPendingParticipants__ConfirmDialogBody--ApproveAll":"{count, plural, other {{count,number} người sẽ được thêm vào cuộc gọi.}}",
    "CallingPendingParticipants__ConfirmDialogBody--DenyAll":"{count, plural, other {{count,number} người sẽ không được thêm vào cuộc gọi.}}",
    "CallingPendingParticipants__RequestsToJoin":"{count, plural, other {{count,number} yêu cầu tham gia cuộc gọi}}",
    "CallingPendingParticipants__WouldLikeToJoin":"Muốn tham gia…",
    "CallingPendingParticipants__AdditionalRequests":"{count, plural, other {+{count,number} yêu cầu}}",
    "CallingPendingParticipants__Toast--added-users-to-call":"{count, plural, other {{count,number} người đã được thêm vào cuộc gọi}}",
    "CallingRaisedHandsList__Title":"{count, plural, other {{count,number} người giơ tay}}",
    "CallingRaisedHandsList__TitleHint":"(đầu tiên đến cuối cùng)",
    "CallingReactions--me":"Bạn",
    "calling__your-video-is-off":"Camera của bạn đang tắt",
    "calling__pre-call-info--empty-group":"Không có ai khác đang ở đây",
    "calling__pre-call-info--1-person-in-call":"{first} đang trong cuộc gọi này",
    "calling__pre-call-info--another-device-in-call":"Một trong các thiết bị của bạn đang trong cuộc gọi này",
    "calling__pre-call-info--2-people-in-call":"{first} và {second} đang trong cuộc gọi này",
    "calling__pre-call-info--3-people-in-call":"{first}, {second}, và {third} \u001dđang trong cuộc gọi này",
    "calling__pre-call-info--many-people-in-call":"{others, plural, other {{first}, {second}, và {others,number} người khác đang trong cuộc gọi này}}",
    "calling__pre-call-info--will-ring-1":"Cuộc gọi được mã hóa đầu cuối",
    "calling__pre-call-info--will-ring-2":"Signal sẽ đổ chuông {0} và {1}",
    "calling__pre-call-info--will-ring-3":"Signal sẽ đổ chuông {0}, {1}, và {2}",
    "calling__pre-call-info--will-ring-many":"{others, plural, other {Signal sẽ đổ chuông {first}, {second}, và {others,number} người khác}}",
    "calling__pre-call-info--will-notify-1":"{person} sẽ được thông báo",
    "calling__pre-call-info--will-notify-2":"{first} và {second} sẽ được thông báo",
    "calling__pre-call-info--will-notify-3":"{first}, {second}, và {third} sẽ được thông báo.",
    "calling__pre-call-info--will-notify-many":"{others, plural, other {{first}, {second}, và {others,number} người khác sẽ được thông báo}}",
    "calling__in-this-call--zero":"Không có ai khác đang ở đây",
    "calling__in-this-call":"{people, plural, other {Trong cuộc gọi này · {people,number} người}}",
    "calling__blocked-participant":"{name} đã bị chặn",
    "calling__block-info-title":"{name} đã bị chặn",
    "calling__block-info":"Bạn sẽ không nhận được âm thanh hoặc video của họ và họ sẽ không nhận được của bạn.",
    "calling__missing-media-keys":"Không thể nhận được âm thanh và video từ {name}",
    "calling__missing-media-keys--unknown-contact":"Không thể nhận âm thanh và video",
    "calling__missing-media-keys-info":"Điều này có thể vì họ chưa xác minh việc thay đổi mã số an toàn của bạn, có vấn đề với thiết bị của họ, hoặc họ đã chặn bạn.",
    "calling__overflow__scroll-up":"Kéo lên",
    "calling__overflow__scroll-down":"Kéo xuống",
    "calling__presenting--notification-title":"Bạn đang trình bày với mọi người.",
    "calling__presenting--notification-body":"Nhấn vào đây để trả lời cuộc điện thoại khi bạn sẵn sàng ngưng trình bày.",
    "calling__presenting--reconnecting--notification-title":"Đang kết nối lại…",
    "calling__presenting--reconnecting--notification-body":"Kết nối của bạn đã mất. Signal đang kết nối lại.",
    "calling__presenting--info":"Signal đang chia sẻ {window}.",
    "calling__presenting--info--unknown":"Tính năng chia sẻ màn hình của Signal đang hoạt động",
    "calling__presenting--reconnecting":"Đang kết nối lại…",
    "calling__presenting--stop":"Ngưng chia sẻ",
    "calling__presenting--you-stopped":"Bạn đã ngưng trình bày",
    "calling__presenting--person-ongoing":"{name} đang trình bày",
    "calling__presenting--person-stopped":"{name} đã ngưng trình bày",
    "calling__presenting--permission-title":"Cần sự cho phép",
    "calling__presenting--macos-permission-description":"Signal cần sự cho phép để truy cập ghi màn hình của máy tính của bạn.",
    "calling__presenting--permission-instruction-step1":"Đi đến Tuỳ chỉnh Hệ thống.",
    "calling__presenting--permission-instruction-step2":"Nhấn vào biểu tượng ổ khoá ở góc bên trái phía dưới và nhập mật khẩu máy tính của bạn.",
    "calling__presenting--permission-instruction-step3":"Phía bên tay phải, đánh dấu vào ô cạnh chữ Signal. Nếu bạn không thấy Signal trong danh sách, nhấn dấu + để thêm vào.",
    "calling__presenting--permission-open":"Mở Tuỳ chỉnh Hệ thống.",
    "calling__presenting--permission-cancel":"Bỏ qua",
    "alwaysRelayCallsDescription":"Luôn chuyển tiếp cuộc gọi",
    "alwaysRelayCallsDetail":"Chuyển tiếp tất cả cuộc gọi qua máy chủ Signal để tránh lộ địa chỉ IP với đối tác. Bật tính năng này sẽ giảm chất lượng cuộc gọi.",
    "permissions":"Quyền",
    "mediaPermissionsDescription":"Cho phép truy cập microphone",
    "mediaCameraPermissionsDescription":"Cho phép truy cập camera",
    "spellCheckDescription":"Kiểm tra chính tả của văn bản được nhập vào ô soạn tin nhắn",
    "textFormattingDescription":"Hiển thị tiện ích định dạng văn bản hiển thị khi văn bản được chọn",
    "spellCheckWillBeEnabled":"Kiểm tra chính tả sẽ được bật lên lần tiếp theo Signal khởi động.",
    "spellCheckWillBeDisabled":"Kiểm tra chính tả đã được tắt lần tiếp theo Signal khởi động.",
    "SystemTraySetting__minimize-to-system-tray":"Thu nhỏ vào thanh hệ thống",
    "SystemTraySetting__minimize-to-and-start-in-system-tray":"Bắt đầu thu nhỏ vào thanh hệ thống",
    "autoLaunchDescription":"Mở khi đăng nhập máy tính",
    "clearDataHeader":"Xóa dữ liệu của ứng dụng",
    "clearDataExplanation":"Thao tác này sẽ xóa tất cả dữ liệu trong ứng dụng, xóa tất cả các tin nhắn và thông tin tài khoản đã lưu.",
    "clearDataButton":"Xóa dữ liệu",
    "deleteAllDataHeader":"Xóa tất cả dữ liệu ?",
    "deleteAllDataBody":"Xóa tất cả ứng dụng và tin nhắn khỏi phiên bản Signal Desktop này? Bạn luôn có thể liên kết lại với máy tính này, nhưng tin nhắn của bạn sẽ không được khôi phục. Tài khoản Signal và dữ liệu của bạn trên điện thoại hoặc các thiết bị đã liên kết sẽ không bị xóa.",
    "deleteAllDataProgress":"Ngắt kết nối và xóa toàn bộ dữ liệu",
    "deleteOldIndexedDBData":"Bạn có dữ liệu cũ từ một lần cài đặt trước của Signal Desktop. Nếu bạn chọn để tiếp tục, dữ liệu sẽ bị xoá và bạn sẽ bắt đầu lại từ đầu.",
    "deleteOldData":"Xoá Dữ liệu Cũ",
    "nameAndMessage":"Tên, nội dung, và hành động",
    "noNameOrMessage":"Không có tên hoặc nội dung",
    "nameOnly":"Chỉ tên",
    "newMessage":"Tin nhắn Mới",
    "notificationSenderInGroup":"{sender} trong {group}",
    "notificationReaction":"{sender}đã bày tỏ {emoji} trong tin nhắn của bạn",
    "notificationReactionMessage":"{sender} đã phản hồi {emoji} cho: {message}",
    "sendFailed":"Không gửi được",
    "deleteFailed":"Xóa không thành công",
    "editFailed":"Không thể chỉnh sửa, nhấn để xem chi tiết",
    "sendPaused":"Tạm dừng nhắn tin",
    "partiallySent":"Gửi một phần, nhấn vào để xem chi tiết",
    "partiallyDeleted":"Đã xóa một phần, bấm để thử lại",
    "expiredWarning":"Phiên bản này của Signal Desktop đã hết hạn. Vui lòng nâng cấp lên phiên bản mới nhất để tiếp tục nhắn tin.",
    "upgrade":"Nhấn để truy cập signal.org/download",
    "mediaMessage":"Tin nhắn Đa phương tiện",
    "sync":"Nhập danh bạ",
    "syncExplanation":"Nhập tất cả liên hệ và nhóm của Signal từ thiết bị di động của bạn.",
    "syncNow":"Nhập ngay",
    "syncing":"Đang nhập…",
    "syncFailed":"Nhập không thành công. Hãy chắc chắn rằng máy tính và điện thoại của bạn đã kết nối internet.",
    "timestamp_s":"vừa xong",
    "timestamp_m":"1 phút",
    "timestamp_h":"1 giờ",
    "hoursAgo":"{hours,number} giờ",
    "minutesAgo":"{minutes,number} phút",
    "justNow":"Vừa xong",
    "timestampFormat__long--today":"Hôm nay {time}",
    "timestampFormat__long--yesterday":"Hôm qua {time}",
    "messageLoop":"Signal có khả năng không thể xử lý tin nhắn đến.",
    "messageBodyTooLong":"Tin nhắn quá dài.",
    "unblockToSend":"Bỏ chặn liên hệ này để gửi tin nhắn.",
    "unblockGroupToSend":"Bỏ chặn nhóm này để gửi tin nhắn.",
    "youChangedTheTimer":"Bạn đã cài đặt thời gian tin nhắn tạm thời thành {time}.",
    "timerSetOnSync":"Đã cập nhật thời gian tin nhắn tạm thời thành {time}.",
    "timerSetByMember":"Một thành viên đã cài đặt thời gian tin nhắn tạm thời là {time}.",
    "theyChangedTheTimer":"{name} đã cài đặt thời gian tin nhắn tạm thời thành {time}.",
    "disappearingMessages__off":"tắt",
    "disappearingMessages":"Tin nhắn tự hủy",
    "disappearingMessagesDisabled":"Đã tắt tin nhắn tạm thời",
    "disappearingMessagesDisabledByMember":"Một thành viên đã tắt tin nhắn tạm thời.",
    "disabledDisappearingMessages":"{name} đã tắt tin nhắn tạm thời.",
    "youDisabledDisappearingMessages":"Bạn đã tắt chế độ tin nhắn tạm thời.",
    "timerSetTo":"Thời gian được đặt thành {time}",
    "audioNotificationDescription":"Âm thanh thông báo",
    "callRingtoneNotificationDescription":"Phát âm thanh gọi điện",
    "callSystemNotificationDescription":"Hiện thông báo cuộc gọi",
    "incomingCallNotificationDescription":"Cho phép cuộc gọi đến",
    "contactChangedProfileName":"{sender} đã đổi tên hồ sơ từ {oldProfile} thành {newProfile}.",
    "changedProfileName":"{oldProfile} đã đổi tên hồ sơ thành {newProfile}.",
    "SafetyNumberModal__title":"Xác minh Số An toàn",
    "safetyNumberChanged":"Số An toàn đã được thay đổi",
    "safetyNumberChanges":"Các thay đổi về Mã số an toàn",
    "safetyNumberChangedGroup":"Số An toàn với {name} đã thay đổi",
    "ConversationDetails__viewSafetyNumber":"Xem Số An toàn",
    "ConversationDetails__HeaderButton--Message":"Tin nhắn",
    "SafetyNumberNotification__viewSafetyNumber":"Xem Số An toàn",
    "cannotGenerateSafetyNumber":"Người dùng này không thể được xác minh cho tới khi bạn đã nhắn tin với họ.",
    "themeLight":"Sáng",
    "themeDark":"Tối",
    "themeSystem":"Hệ thống",
    "noteToSelf":"Ghi chú",
    "noteToSelfHero":"Bạn có thể thêm ghi chú cho mình trong cuộc trò chuyện này. Nếu tài khoản của bạn có các thiết bị được liên kết, các ghi chú mới sẽ được đồng bộ.",
    "notificationDrawAttention":"Chú ý vào cửa sổ này khi có thông báo đến",
    "hideMenuBar":"Ẩn thanh menu",
    "newConversation":"Cuộc trò truyện mới",
    "stories":"Các Story",
    "contactSearchPlaceholder":"Tên, tên người dùng hoặc số điện thoại",
    "noContactsFound":"Không tìm thấy liên hệ",
    "noGroupsFound":"Không tìm thấy nhóm",
    "noConversationsFound":"Không tìm thấy cuộc trò chuyện",
    "Toast--ConversationRemoved":"{title} đã được xóa.",
    "Toast--error":"Có lỗi xảy ra",
    "Toast--error--action":"Gửi nhật ký",
    "Toast--failed-to-fetch-username":"Tìm tên người dùng không thành công. Kiểm tra kết nối của bạn và thử lại.",
    "Toast--failed-to-fetch-phone-number":"Tìm số điện thoại không thành công. Kiểm tra kết nối của bạn và thử lại.",
    "ToastManager__CannotEditMessage_24":"Các chỉnh sửa chỉ có thể được thực hiện trong vòng 24 giờ kể từ lúc bạn gửi tin nhắn này.",
    "startConversation--username-not-found":"{atUsername} không phải là người dùng Signal. Hãy kiểm tra lại bạn đã nhập đầy đủ tên người dùng hay chưa.",
    "startConversation--phone-number-not-found":"Không tìm thấy người dùng. \"{phoneNumber}\" không phải là người dùng Signal.",
    "startConversation--phone-number-not-valid":"Không tìm thấy người dùng. Số điện thoại \"{phoneNumber}\" không hợp lệ.",
    "chooseGroupMembers__title":"Chọn thành viên",
    "chooseGroupMembers__back-button":"Trở lại",
    "chooseGroupMembers__skip":"Bỏ qua",
    "chooseGroupMembers__next":"Tiếp",
    "chooseGroupMembers__maximum-group-size__title":"Đã đạt kích cỡ nhóm tối đa",
    "chooseGroupMembers__maximum-group-size__body":"Nhóm Signal có thể có tối đa {max,number} thành viên.",
    "chooseGroupMembers__maximum-recommended-group-size__title":"Đã đạt giới hạn thành viên được khuyến nghị",
    "chooseGroupMembers__maximum-recommended-group-size__body":"Nhóm Signal hoạt động tốt nhất với {max,number} thành viên hoặc ít hơn. Thêm nhiều thành viên hơn sẽ gây trì hoãn trong việc nhận và gửi tin nhắn.",
    "setGroupMetadata__title":"Đặt tên nhóm này",
    "setGroupMetadata__back-button":"Quay về chọn thành viên",
    "setGroupMetadata__group-name-placeholder":"Tên nhóm (bắt buộc)",
    "setGroupMetadata__group-description-placeholder":"Miêu tả",
    "setGroupMetadata__create-group":"Tạo",
    "setGroupMetadata__members-header":"Thành viên",
    "setGroupMetadata__error-message":"Nhóm này không thể được tạo. Kiểm tra kết nối của bạn và thử lại.",
    "updateGroupAttributes__title":"Chỉnh sửa nhóm",
    "updateGroupAttributes__error-message":"Cập nhật nhóm không thành công. Kiểm tra kết nối của bạn và thử lại.",
    "unlinkedWarning":"Nhấp để liên kết lại Signal Desktop với thiết bị di động của bạn để tiếp tục nhắn tin.",
    "unlinked":"Đã bỏ liên kết",
    "autoUpdateNewVersionTitle":"Cập nhật có sẵn",
    "autoUpdateRetry":"Thử cập nhật lại",
    "autoUpdateNewVersionMessage":"Nhấp để khởi động lại Signal",
    "downloadNewVersionMessage":"Nhấp để tải bản cập nhật",
    "downloadFullNewVersionMessage":"Signal không thể cập nhật. Bấm để thử lại.",
    "autoUpdateRestartButtonLabel":"Khởi động lại Signal",
    "autoUpdateIgnoreButtonLabel":"Bỏ qua bản cập nhật",
    "leftTheGroup":"{name} đã rời nhóm.",
    "multipleLeftTheGroup":"{name} đã rời nhóm.",
    "updatedTheGroup":"{name} đã cập nhật nhóm.",
    "youUpdatedTheGroup":"Bạn đã cập nhật nhóm.",
    "updatedGroupAvatar":"Ảnh đại diện của nhóm đã được cập nhật.",
    "titleIsNow":"Tên của nhóm bây giờ là ''{name}'.",
    "youJoinedTheGroup":"Bạn đã tham gia nhóm.",
    "joinedTheGroup":"{name} đã tham gia nhóm.",
    "multipleJoinedTheGroup":"{names} đã tham gia nhóm.",
    "ConversationList__aria-label":"{unreadCount, plural, other {Cuộc trò chuyện với {title}, {unreadCount,number} tin nhắn mới, tin nhắn cuối cùng: {lastMessage}.}}",
    "ConversationList__last-message-undefined":"Tin nhắn cuối cùng có thể đã bị xóa.",
    "BaseConversationListItem__aria-label":"Đến cuộc trò chuyện với {title}",
    "ConversationListItem--message-request":"Yêu cầu gửi Tin nhắn",
    "ConversationListItem--blocked":"Đã chặn",
    "ConversationListItem--draft-prefix":"Nháp:",
    "message--getNotificationText--messageRequest":"Yêu cầu gửi Tin nhắn",
    "message--getNotificationText--gif":"Ảnh GIF",
    "message--getNotificationText--photo":"Ảnh",
    "message--getNotificationText--video":"Video",
    "message--getNotificationText--voice-message":"Tin nhắn Thoại",
    "message--getNotificationText--audio-message":"Tin nhắn âm thanh",
    "message--getNotificationText--file":"Tệp",
    "message--getNotificationText--stickers":"Nhãn dán tin nhắn",
    "message--getNotificationText--text-with-emoji":"{emoji} {text}",
    "message--getDescription--unsupported-message":"Tin nhắn không được hỗ trợ",
    "message--getDescription--disappearing-media":"Đa phương tiện xem một lần",
    "message--getDescription--disappearing-photo":"Ảnh xem một lần",
    "message--getDescription--disappearing-video":"Video xem một lần",
    "message--deletedForEveryone":"Tin nhắn này đã bị xóa.",
    "message--attachmentTooBig--one":"Không thể hiển thị tệp đính kèm vì quá lớn.",
    "message--attachmentTooBig--multiple":"Không thể hiển thị một số tệp đính kèm vì quá lớn.",
    "message--call-link-description":"Sử dụng đường dẫn này để tham gia cuộc gọi Signal",
    "donation--missing":"Không thể lấy thông tin ủng hộ",
    "message--donation--unopened--incoming":"Xem tin nhắn này trên điện thoại để mở",
    "message--donation--unopened--outgoing":"Nhấn vào tin nhắn này trên điện thoại để xem khoản ủng hộ của bạn",
    "message--donation--unopened--label":"{sender} đã thay mặt bạn ủng hộ Signal",
    "message--donation--unopened--toast--incoming":"Kiểm tra điện thoại của bạn để mở khoản ủng hộ này",
    "message--donation--unopened--toast--outgoing":"Kiểm tra điện thoại của bạn để xem khoản ủng hộ của bạn",
    "message--donation--preview--unopened":"{sender} đã ủng hộ thay cho bạn",
    "message--donation--preview--redeemed":"Bạn đã nhận một khoản ủng hộ",
    "message--donation--preview--sent":"Bạn đã ủng hộ thay cho {recipient}",
    "message--donation":"Khoản Ủng hộ",
    "quote--donation":"Khoản Ủng hộ",
    "message--donation--remaining--days":"{days, plural, other {Còn {days,number} ngày}}",
    "message--donation--remaining--hours":"{hours, plural, other {Còn {hours,number} giờ}}",
    "message--donation--remaining--minutes":"{minutes, plural, other {Còn {minutes,number} phút}}",
    "message--donation--expired":"Đã hết hạn",
    "message--donation--view":"Xem",
    "message--donation--redeemed":"Đã nhận",
    "messageAccessibilityLabel--outgoing":"Tin nhắn bạn gửi",
    "messageAccessibilityLabel--incoming":"Tin nhắn {author} gửi",
    "modal--donation--title":"Cảm ơn bạn đã đóng góp!",
    "modal--donation--description":"Bạn đã gửi một khoản ủng hộ cho Signal thay cho {name}. Họ sẽ có tuỳ chọn để thể hiện sự ủng hộ của mình trên hồ sơ.",
    "stickers--toast--InstallFailed":"Không thể cài đặt gói nhãn dán",
    "stickers--StickerManager--Available":"Sẵn có",
    "stickers--StickerManager--InstalledPacks":"Đã cài đặt",
    "stickers--StickerManager--InstalledPacks--Empty":"Không có nhãn dán nào được cài đặt",
    "stickers--StickerManager--BlessedPacks":"Tuyển tập Nghệ sĩ Signal",
    "stickers--StickerManager--BlessedPacks--Empty":"Không có sẵn nhãn dán Nghệ sĩ Signal",
    "stickers--StickerManager--ReceivedPacks":"Các Nhãn dán Bạn Đã nhận được",
    "stickers--StickerManager--ReceivedPacks--Empty":"Nhãn dán từ các tin nhắn đến sẽ xuất hiện ở đây",
    "stickers--StickerManager--Install":"Cài đặt",
    "stickers--StickerManager--Uninstall":"Gỡ cài đặt",
    "stickers--StickerManager--UninstallWarning":"Bạn có thể sẽ không cài lại được gói nhãn dán này nếu bạn không còn tin nhắn nguồn.",
    "stickers--StickerManager--Introduction--Image":"Xin giới thiệu nhãn dán: Bandit the Cat",
    "stickers--StickerManager--Introduction--Title":"Xin giới thiệu Nhãn dán",
    "stickers--StickerManager--Introduction--Body":"Tại sao phải sử dụng từ ngữ khi mà bạn có thể sử dụng nhãn dán?",
    "stickers--StickerPicker--Open":"Mở bộ chọn nhãn dán",
    "stickers--StickerPicker--AddPack":"Đã thêm bộ nhãn dán",
    "stickers--StickerPicker--NextPage":"Trang Tiếp theo",
    "stickers--StickerPicker--PrevPage":"Trang Trước",
    "stickers--StickerPicker--Recents":"Nhãn dán Gần đây",
    "stickers--StickerPicker--DownloadError":"Một vài nhãn dán đã không thể tải xuống.",
    "stickers--StickerPicker--DownloadPending":"Đang cài đặt gói nhãn dán…",
    "stickers--StickerPicker--Empty":"Không tìm thấy nhãn dán",
    "stickers--StickerPicker--Hint":"Gói nhãn dán mới từ tin nhắn của bạn có sẵn để cài đặt",
    "stickers--StickerPicker--NoPacks":"Không tìm thấy gói nhãn dán",
    "stickers--StickerPicker--NoRecents":"Các nhãn dán mói được sử dụng sẽ xuất hiện ở đây.",
    "stickers__StickerPicker__recent":"Gần đây",
    "stickers__StickerPicker__featured":"Nổi bật",
    "stickers__StickerPicker__analog-time":"Đồng hồ analog",
    "stickers--StickerPreview--Title":"Gói Nhãn dán",
    "stickers--StickerPreview--Error":"Lỗi khi mở gói nhãn dán. Kiểm tra kết nối internet của bạn và thử lại.",
    "EmojiPicker--empty":"Không tìm thấy biểu tượng cảm xúc",
    "EmojiPicker--search-close":"Đóng tìm kiếm emoji",
    "EmojiPicker--search-placeholder":"Tìm kiếm Biểu tượng cảm xúc",
    "EmojiPicker--skin-tone":"Màu da {tone}",
    "EmojiPicker__button--recents":"Gần đây",
    "EmojiPicker__button--emoji":"Emoji",
    "EmojiPicker__button--animal":"Thú vật",
    "EmojiPicker__button--food":"Thức ăn",
    "EmojiPicker__button--activity":"Hoạt động",
    "EmojiPicker__button--travel":"Du lịch",
    "EmojiPicker__button--object":"Vật dụng",
    "EmojiPicker__button--symbol":"Biểu tượng",
    "EmojiPicker__button--flag":"Cờ",
    "confirmation-dialog--Cancel":"Hủy",
    "Message__reaction-emoji-label--you":"Bạn đã bày tỏ cảm xúc với {emoji}",
    "Message__reaction-emoji-label--single":"{title} đã bày tỏ cảm xúc với {emoji}",
    "Message__reaction-emoji-label--many":"{count, plural, other {{count,number} người đã bày tỏ cảm xúc với {emoji}}}",
    "Message__role-description":"Tin nhắn",
    "MessageBody--read-more":"Đọc thêm",
    "MessageBody--message-too-long":"Không thể hiển thị thêm vì tin nhắn quá dài",
    "Message--unsupported-message":"{contact} đã gửi cho bạn một tin nhắn nhưng không thể xử lý hoặc hiển thị vì sử dụng tính năng Signal mới.",
    "Message--unsupported-message-ask-to-resend":"Bạn có thể yêu cầu {contact} gửi lại tin nhắn này khi bạn đang sử dụng phiên bản Signal mới cập nhật.",
    "Message--from-me-unsupported-message":"Một trong những thiết bị của bạn đã gửi một tin nhắn không thể xử lý hoặc hiển thị vì sử dụng tính năng Signal mới.",
    "Message--from-me-unsupported-message-ask-to-resend":"Các tin nhắn như thế này trong tương lai sẽ được đồng bộ hóa khi bạn đang sử dụng phiên bản  Signal cập nhật.",
    "Message--update-signal":"Cập nhật Signal",
    "Message--tap-to-view-expired":"Đã xem",
    "Message--tap-to-view--outgoing":"Đa phương tiện",
    "Message--tap-to-view--incoming--expired-toast":"Bạn đã sẵn sàng xem ảnh này",
    "Message--tap-to-view--outgoing--expired-toast":"Tin nhắn xem một lần không được lưu trong lịch sử trò chuyện.",
    "Message--tap-to-view--incoming":"Xem ảnh",
    "Message--tap-to-view--incoming-video":"Xem video",
    "Conversation--getDraftPreview--attachment":"(tập tin đính kèm)",
    "Conversation--getDraftPreview--quote":"(trích dẫn)",
    "Conversation--getDraftPreview--draft":"(nháp)",
    "Keyboard--focus-most-recent-message":"Tập trung vào tin nhắn chưa đọc cũ nhất hoặc mới nhất",
    "Keyboard--navigate-by-section":"Xem qua từng mục",
    "Keyboard--previous-conversation":"Cuộc trò chuyện trước",
    "Keyboard--next-conversation":"Cuộc trò chuyện tiếp theo",
    "Keyboard--previous-unread-conversation":"Cuộc trò chuyện chưa đọc trước",
    "Keyboard--next-unread-conversation":"Cuộc trò chuyện chưa đọc tiếp theo",
    "Keyboard--preferences":"Tùy chỉnh",
    "Keyboard--open-conversation-menu":"Mở menu trò chuyện",
    "Keyboard--new-conversation":"Bắt đầu cuộc trò chuyện mới",
    "Keyboard--archive-conversation":"Lưu trữ cuộc trò chuyện",
    "Keyboard--unarchive-conversation":"Bỏ lưu trữ cuộc trò chuyện",
    "Keyboard--search":"Tìm kiếm",
    "Keyboard--search-in-conversation":"Tìm trong cuộc trò chuyện",
    "Keyboard--focus-composer":"Trình soạn thảo tập trung",
    "Keyboard--open-all-media-view":"Xem tất cả tệp đa phương tiện",
    "Keyboard--open-emoji-chooser":"Mở bảng chọn emoji",
    "Keyboard--open-sticker-chooser":"Mở bảng chọn nhãn dán",
    "Keyboard--begin-recording-voice-note":"Bắt đầu ghi tin nhắn âm thanh",
    "Keyboard--default-message-action":"Thao tác mặc định cho tin nhắn được chọn",
    "Keyboard--view-details-for-selected-message":"Xem chi tiết tin nhắn đã chọn",
    "Keyboard--toggle-reply":"Bật tắt trả lời cho tin nhắn đã chọn",
    "Keyboard--toggle-reaction-picker":"Chuyển đổi bộ chọn emoji phản hồi cho tin nhắn đã chọn",
    "Keyboard--save-attachment":"Lưu tệp đính kèm từ tin nhắn đã chọn",
    "Keyboard--delete-messages":"Xóa các Tin nhắn Đã chọn",
    "Keyboard--forward-messages":"Chuyển tiếp các tin nhắn đã chọn",
    "Keyboard--add-newline":"Thêm dòng vào tin nhắn",
    "Keyboard--expand-composer":"Mở rộng ô soạn thảo",
    "Keyboard--send-in-expanded-composer":"Gửi (trong ô soạn thảo mở rộng)",
    "Keyboard--attach-file":"Đính kèm tệp",
    "Keyboard--remove-draft-link-preview":"Bỏ xem trước liên kết nháp",
    "Keyboard--remove-draft-attachments":"Bỏ tất cả tệp đính kèm nháp",
    "Keyboard--conversation-by-index":"Nhảy đến cuộc trò chuyện",
    "Keyboard--edit-last-message":"Chỉnh sửa tin nhắn trước đó",
    "Keyboard--Key--ctrl":"Ctrl",
    "Keyboard--Key--option":"Option",
    "Keyboard--Key--alt":"Alt",
    "Keyboard--Key--shift":"Shift",
    "Keyboard--Key--enter":"Nhập",
    "Keyboard--Key--tab":"Thẻ",
    "Keyboard--Key--one-to-nine-range":"1 đến 9",
    "Keyboard--header":"Phím tắt bàn phím",
    "Keyboard--navigation-header":"Đường dẫn",
    "Keyboard--messages-header":"Tin nhắn",
    "Keyboard--composer-header":"Trường soạn thảo",
    "Keyboard--composer--bold":"Định dạng in đậm cho văn bản được chọn",
    "Keyboard--composer--italic":"Định dạng in nghiêng cho văn bản được chọn",
    "Keyboard--composer--strikethrough":"Định dạng gạch ngang cho văn bản được chọn",
    "Keyboard--composer--monospace":"Định dạng khoảng cách ký tự bằng nhau cho văn bản được chọn",
    "Keyboard--composer--spoiler":"Định dạng nội dung spoiler cho văn bản được chọn",
    "Keyboard--open-context-menu":"Mở menu ngữ cảnh cho tin nhắn đã chọn",
    "FormatMenu--guide--bold":"Đậm",
    "FormatMenu--guide--italic":"Nghiêng",
    "FormatMenu--guide--strikethrough":"Có gạch ngang",
    "FormatMenu--guide--monospace":"Đơn cách",
    "FormatMenu--guide--spoiler":"Spoiler",
    "Keyboard--scroll-to-top":"Kéo lên trên cùng",
    "Keyboard--scroll-to-bottom":"Kéo xuống dưới cùng",
    "Keyboard--close-curent-conversation":"Đóng cuộc trò chuyện hiện tại",
    "Keyboard--calling-header":"Đang gọi",
    "Keyboard--toggle-audio":"Chuyển đổi bật tắt tắt tiếng",
    "Keyboard--toggle-video":"Chuyển đổi bật tắt video",
    "Keyboard--accept-video-call":"Trả lời cuộc gọi với video (chỉ cuộc gọi video)",
    "Keyboard--accept-call-without-video":"Trả lời cuộc gọi không có video",
    "Keyboard--start-audio-call":"Bắt đầu cuộc gọi thoại",
    "Keyboard--start-video-call":"Bắt đầu cuộc gọi video",
    "Keyboard--decline-call":"Từ chối cuộc gọi",
    "Keyboard--hang-up":"Kết thúc cuộc gọi",
    "close-popup":"Đóng cửa sổ popup",
    "addImageOrVideoattachment":"Thêm ảnh hoặc video đính kèm",
    "remove-attachment":"Bỏ tệp đính kèm",
    "backToInbox":"Trở về hộp thư đến",
    "conversationArchived":"Đã lưu trữ cuộc trò chuyện",
    "conversationArchivedUndo":"Hoàn tác",
    "conversationReturnedToInbox":"Cuộc trò chuyện đã được chuyển về hộp thư đến",
    "conversationMarkedUnread":"Cuộc trò chuyện đã được đánh dấu chưa đọc",
    "ArtCreator--Authentication--error":"Vui lòng cài Signal trên điện thoại và máy tính để sử dụng Công Cụ Tạo Nhãn Dán Signal",
    "Reactions--remove":"Gỡ biểu cảm",
    "Reactions--error":"Lỗi trong việc gửi bày tỏ cảm xúc. Xin thử lại.",
    "Reactions--more":"Thêm",
    "ReactionsViewer--all":"Tất cả",
    "SafetyTipsModal__Title":"Lời khuyên để giữ an toàn",
    "SafetyTipsModal__Description":"Hãy cẩn trọng khi chấp nhận yêu cầu gửi tin nhắn từ những người bạn không biết. Chú ý:",
    "SafetyTipsModal__TipTitle--Crypto":"Lừa đảo tiền điện tử hoặc tiền",
    "SafetyTipsModal__TipDescription--Crypto":"Nếu một người bạn không biết gửi tin nhắn về tiền điện tử (như Bitcoin) hay một cơ hội kiếm tiền, hãy cẩn trọng—đó có thể là nội dung spam.",
    "SafetyTipsModal__TipTitle--Vague":"Tin nhắn không liên quan",
    "SafetyTipsModal__TipDescription--Vague":"Người gửi spam thường gửi cho bạn một tin nhắn đơn giản như “Xin chào” để khiến bạn tò mò. Nếu bạn trả lời, họ có thể sẽ tiếp tục tương tác với bạn.",
    "SafetyTipsModal__TipTitle--Links":"Tin nhắn có chứa đường dẫn",
    "SafetyTipsModal__TipDescription--Links":"Hãy cẩn trọng với tin nhắn từ những người bạn không biết và có chứa đường dẫn đến các trang web. Đừng bao giờ bấm vào các đường dẫn từ những người bạn không tin tưởng.",
    "SafetyTipsModal__TipTitle--Business":"Doanh nghiệp và tổ chức giả mạo",
    "SafetyTipsModal__TipDescription--Business":"Hãy cẩn trọng khi có các doanh nghiệp hoặc cơ quan của chính phủ liên hệ với bạn. Tin nhắn có nội dung liên quan đến cơ quan thuế, giao hàng, v.v. có thể là spam.",
    "SafetyTipsModal__DotLabel":"Đến trang {page,number}",
    "SafetyTipsModal__Button--Previous":"Lời khuyên trước",
    "SafetyTipsModal__Button--Next":"Lời khuyên tiếp theo",
    "SafetyTipsModal__Button--Done":"Xong",
    "MessageRequests--message-direct":"Cho phép {name} nhắn tin cho bạn và chia sẻ tên và ảnh của bạn với họ? Họ sẽ không biết bạn đã xem tin nhắn của họ cho tới khi bạn đồng ý.",
    "MessageRequests--message-direct-hidden":"Cho phép {name} nhắn tin cho bạn và chia sẻ tên và ảnh của bạn với họ? Bạn đã xóa người này trước đây.",
    "MessageRequests--message-direct-blocked":"Cho phép {name} nhắn tin cho bạn và chia sẻ tên và ảnh của bạn với họ? Bạn sẽ không nhận được tin nhắn nào cho tới khi bạn bỏ chặn họ.",
    "MessageRequests--message-group":"Tham gia nhóm này và chia sẻ tên và ảnh của bạn với các thành viên nhóm? Họ sẽ không biết bạn đã đọc tin nhắn của họ cho tới khi bạn đồng ý.",
    "MessageRequests--message-group-blocked":"Bỏ chặn nhóm này và chia sẻ tên và ảnh đại diện của bạn với thành viên nhóm? Bạn sẽ không nhận thêm tin nhắn mới cho tới khi bạn bỏ chặn họ.",
    "MessageRequests--block":"Chặn",
    "MessageRequests--unblock":"Bỏ chặn",
    "MessageRequests--unblock-direct-confirm-title":"Bỏ chặn {name}?",
    "MessageRequests--unblock-direct-confirm-body":"Các bạn sẽ có thể nhắn tin và gọi cho nhau.",
    "MessageRequests--unblock-group-confirm-body":"Các thành viên trong nhóm có thể thêm lại bạn vào nhóm.",
    "MessageRequests--block-and-report-spam-success-toast":"Đã báo cáo spam và chặn.",
    "MessageRequests--block-direct-confirm-title":"Chặn {title}?",
    "MessageRequests--block-direct-confirm-body":"Người bị chặn sẽ không thể gọi hoặc gửi tin nhắn cho bạn.",
    "MessageRequests--block-group-confirm-title":"Chặn và Rời {title}?",
    "MessageRequests--block-group-confirm-body":"Bạn sẽ không thể nhận được tin nhắn hoặc cập nhật từ nhóm này và các thành viên sẽ không thể thêm bạn vào lại nhóm này.",
    "MessageRequests--reportAndMaybeBlock":"Báo cáo…",
    "MessageRequests--ReportAndMaybeBlockModal-title":"Báo cáo là spam?",
    "MessageRequests--ReportAndMaybeBlockModal-body--direct":"Signal sẽ nhận được thông báo rằng người này có thể đang gửi spam. Signal không thể xem nội dung của bất kỳ cuộc trò chuyện nào.",
    "MessageRequests--ReportAndMaybeBlockModal-body--group--unknown-contact":"Signal sẽ nhận được thông báo rằng người đã mời bạn vào nhóm này có thể đang gửi spam. Signal không thể xem nội dung của bất kỳ cuộc trò chuyện nào.",
    "MessageRequests--ReportAndMaybeBlockModal-body--group":"Signal sẽ nhận được thông báo rằng {name}, người đã mời bạn vào nhóm này, có thể đang gửi spam. Signal không thể xem nội dung của bất kỳ cuộc trò chuyện nào.",
    "MessageRequests--ReportAndMaybeBlockModal-report":"Báo cáo spam",
    "MessageRequests--ReportAndMaybeBlockModal-reportAndBlock":"Báo cáo và chặn",
    "MessageRequests--AcceptedOptionsModal--body":"Bạn đã chấp nhận yêu cầu gửi tin nhắn từ {name}. Nếu đó là do nhầm lẫn, bạn có thể chọn một thao tác dưới đây.",
    "MessageRequests--report-spam-success-toast":"Đã báo cáo là spam.",
    "MessageRequests--delete":"Xóa",
    "MessageRequests--delete-direct-confirm-title":"Xóa cuộc trò chuyện?",
    "MessageRequests--delete-direct-confirm-body":"Cuộc trò chuyện này sẽ được xóa khỏi tất cả thiết bị của bạn.",
    "MessageRequests--delete-group-confirm-title":"Xoá và Rời {title}?",
    "MessageRequests--delete-direct":"Xóa",
    "MessageRequests--delete-group":"Xoá và Rời",
    "MessageRequests--delete-group-confirm-body":"Bạn sẽ rời nhóm, và lịch sử trò chuyện sẽ bị xóa trên tất cả các thiết bị của bạn.",
    "MessageRequests--accept":"Chấp nhận",
    "MessageRequests--continue":"Tiếp tục",
    "MessageRequests--profile-sharing--group--link":"Tiếp tục cuộc trò chuyện của bạn với nhóm này và chia sẻ tên và ảnh của bạn với các thành viên nhóm? <learnMoreLink>Tìm hiểu thêm.</learnMoreLink>",
    "MessageRequests--profile-sharing--direct--link":"Tiếp tục cuộc trò chuyện này với {firstName} và chia sẻ tên và ảnh của bạn với người này? <learnMoreLink>Tìm hiểu thêm</learnMoreLink>",
    "ConversationHero--members":"{count, plural, other {{count,number} thành viên}}",
    "member-of-1-group":"Thành viên của {group}",
    "member-of-2-groups":"Thành viên của {group1} và {group2}",
    "member-of-3-groups":"Thành viên của {group1}, {group2}, và {group3}",
    "member-of-more-than-3-groups--one-more":"Thành viên của {group1}, {group2}, {group3} và một thành viên khác",
    "member-of-more-than-3-groups--multiple-more":"{remainingCount, plural, other {Thành viên của {group1}, {group2}, {group3} và {remainingCount,number} nhóm khác}}",
    "no-groups-in-common":"Không có nhóm chung",
    "no-groups-in-common-warning":"Không có nhóm chung. Hãy xem xét yêu cầu thật kĩ lưỡng.",
    "acceptCall":"Trả lời cuộc gọi",
    "acceptCallWithoutVideo":"Trả lời cuộc gọi không có video",
    "declineCall":"Từ chối",
    "declinedIncomingAudioCall":"Cuộc gọi thoại đã từ chối",
    "declinedIncomingVideoCall":"Cuộc gọi video đã từ chối",
    "acceptedIncomingAudioCall":"Cuộc gọi thoại đến",
    "acceptedIncomingVideoCall":"Cuộc gọi video đến",
    "missedIncomingAudioCall":"Cuộc gọi thoại bị nhỡ",
    "missedIncomingVideoCall":"Cuộc gọi video bị nhỡ",
    "acceptedOutgoingAudioCall":"Cuộc gọi thoại đi",
    "acceptedOutgoingVideoCall":"Cuộc gọi video đang gọi đi",
    "missedOrDeclinedOutgoingAudioCall":"Cuộc gọi thoại không được trả lời",
    "missedOrDeclinedOutgoingVideoCall":"Cuộc gọi video không được trả lời",
    "minimizeToTrayNotification--title":"Signal vẫn đang chạy",
    "minimizeToTrayNotification--body":"Signal sẽ tiếp tục chạy ở vùng thông báo. Bạn có thể thay đổi trong mục cài đặt của Signal.",
    "incomingAudioCall":"Cuộc gọi thoại đến",
    "incomingVideoCall":"Cuộc gọi video đến",
    "outgoingAudioCall":"Cuộc gọi thoại đi",
    "outgoingVideoCall":"Cuộc gọi video đang gọi đi",
    "incomingGroupCall__ringing-you":"{ringer} đang gọi bạn",
    "incomingGroupCall__ringing-1-other":"{ringer} đang gọi bạn và {otherMember}",
    "incomingGroupCall__ringing-2-others":"{ringer} đang gọi bạn và, {first}, và {second}",
    "incomingGroupCall__ringing-3-others":"{ringer} đang gọi bạn, {first}, {second}, và 1 người khác",
    "incomingGroupCall__ringing-many":"{remaining, plural, other {{ringer} đang gọi bạn, {first}, {second}, và {remaining,number}người khác}}",
    "outgoingCallRinging":"Đang đổ chuông…",
    "makeOutgoingCall":"Bắt đầu cuộc gọi",
    "makeOutgoingVideoCall":"Bắt đầu cuộc gọi video",
    "callEnded": "Cuộc gọi đã kết thúc",
    "joinOngoingCall":"Tham gia",
    "callNeedPermission":"{title} sẽ nhận được yêu cầu nhắn tin từ bạn. Bạn có thể gọi một khi yêu cầu nhắn tin của bạn đã được đồng ý.",
    "callReconnecting":"Đang kết nối lại…",
    "CallControls__InfoDisplay--participants":"{count, plural, other {{count,number} người}}",
    "CallControls__InfoDisplay--audio-call":"Cuộc gọi thoại",
    "CallControls__InfoDisplay--adhoc-call":"Đường dẫn cuộc gọi",
    "CallControls__InfoDisplay--group-call":"Cuộc gọi nhóm",
    "CallControls__InfoDisplay--adhoc-join-request-pending":"Đang chờ để được tham gia",
    "CallControls__JoinLeaveButton--hangup-1-1":"Kết thúc",
    "CallControls__JoinLeaveButton--hangup-group":"Rời",
    "CallControls__MutedToast--muted":"Tắt micro",
    "CallControls__MutedToast--unmuted":"Bật micro",
    "CallControls__RingingToast--ringing-on":"Đổ chuông đang bật",
    "CallControls__RingingToast--ringing-off":"Đổ chuông đang tắt",
    "CallControls__RaiseHandsToast--you":"Bạn giơ tay.",
    "CallControls__RaiseHandsToast--you-and-one":"Bạn và {otherName} giơ tay.",
    "CallControls__RaiseHandsToast--you-and-more":"{overflowCount, plural, other {Bạn, {otherName} và {overflowCount,number} giơ tay.}}",
    "CallControls__RaiseHandsToast--one":"{name} giơ tay.",
    "CallControls__RaiseHandsToast--two":"{name} và {otherName} giơ tay.",
    "CallControls__RaiseHandsToast--more":"{overflowCount, plural, other {{name}, {otherName} và {overflowCount,number} người khác giơ tay.}}",
    "CallControls__RaiseHands--open-queue":"Mở danh sách",
    "CallControls__RaiseHands--lower":"Hạ Tay",
    "CallControls__MenuItemRaiseHand":"Giơ Tay",
    "CallControls__MenuItemRaiseHand--lower":"Hạ Tay",
    "callingDeviceSelection__settings":"Cài đặt",
    "calling__participants--pluralized":"{people, plural, other {{people,number} trong cuộc gọi}}",
    "calling__call-notification__ended":"Cuộc gọi video đã kết thúc",
    "calling__call-notification__started-by-someone":"Một cuộc gọi video đã được bắt đầu",
    "calling__call-notification__started-by-you":"Bạn đã bắt đầu cuộc gọi video",
    "calling__call-notification__started":"{name} đã bắt đầu cuộc gọi video",
    "calling__in-another-call-tooltip":"Bạn đang trong một cuộc gọi khác",
    "calling__callee-in-another-call-tooltip":"Người nhận đang trong một cuộc gọi khác",
    "calling__call-notification__button__call-full-tooltip":"Cuộc gọi đã đạt giới hạn {max,number} người tham dự",
    "calling__pip--on":"Thu nhỏ cuộc gọi",
    "calling__pip--off":"Phóng toàn màn hình cuộc gọi",
    "calling__change-view":"Đổi chế độ hiển thị",
    "calling__view_mode--paginated":"Hiển thị theo ô",
    "calling__view_mode--overflow":"Hiển thị thanh bên",
    "calling__view_mode--speaker":"Hiển thị người phát biểu",
    "calling__view_mode--updated":"Chế độ hiển thị đã cập nhật",
    "calling__hangup":"Rời cuộc gọi",
    "calling__SelectPresentingSourcesModal--title":"Chia sẻ màn hình của bạn",
    "calling__SelectPresentingSourcesModal--confirm":"Bắt đầu chia sẻ",
    "calling__SelectPresentingSourcesModal--entireScreen":"Toàn bộ màn hình",
    "calling__SelectPresentingSourcesModal--screen":"Màn hình {id}",
    "calling__SelectPresentingSourcesModal--window":"Một cửa sổ",
    "calling__ParticipantInfoButton":"Thông tin thêm về liên hệ mới này",
    "CallingAdhocCallInfo__CopyLink":"Sao chép đường dẫn cuộc gọi",
    "CallingAdhocCallInfo__ShareViaSignal":"Chia sẻ đường dẫn cuộc gọi",
    "CallingAdhocCallInfo__RemoveClient":"Gỡ người này khỏi cuộc gọi",
    "CallingAdhocCallInfo__RemoveClientDialogBody":"Gỡ {name} khỏi cuộc gọi?",
    "CallingAdhocCallInfo__RemoveClientDialogButton--remove":"Xóa",
    "CallingAdhocCallInfo__RemoveClientDialogButton--block":"Chặn khỏi cuộc gọi",
    "CallingAdhocCallInfo__UnknownContactLabel":"{count, plural, other {{count,number} người}}",
    "CallingAdhocCallInfo__UnknownContactLabel--in-addition":"{count, plural, other {+{count,number} người khác}}",
    "CallingAdhocCallInfo__UnknownContactInfoButton":"Thông tin thêm về những liên hệ mới",
    "CallingAdhocCallInfo__UnknownContactInfoDialogBody":"Trước khi tham gia một cuộc gọi, bạn chỉ có thể thấy tên của những liên hệ có trên điện thoại, những người ở trong cùng một nhóm với bạn, hoặc những người bạn đã trò chuyện trực tiếp. Bạn sẽ thấy tất cả tên và ảnh khi bạn đã tham gia cuộc gọi.",
    "CallingAdhocCallInfo__UnknownContactInfoDialogOk":"Đã hiểu",
    "callingDeviceSelection__label--video":"Video",
    "callingDeviceSelection__label--audio-input":"Microphone",
    "callingDeviceSelection__label--audio-output":"Loa",
    "callingDeviceSelection__select--no-device":"Không có thiết bị có sẵn",
    "callingDeviceSelection__select--default":"Mặc định",
    "muteNotificationsTitle":"Tắt tiếng thông báo",
    "notMuted":"Đã bỏ tắt tiếng",
    "muteHour":"Tắt tiếng một giờ",
    "muteEightHours":"Tắt tiếng tám giờ",
    "muteDay":"Tắt tiếng một ngày",
    "muteWeek":"Tắt tiếng một tuần",
    "muteAlways":"Tắt tiếng mãi mãi",
    "unmute":"Bỏ tắt tiếng",
    "muteExpirationLabelAlways":"Đã tắt tiếng mãi mãi",
    "muteExpirationLabel":"Tắt tiếng cho tới {duration}",
    "EmojiButton__label":"Emoji",
    "ErrorModal--title":"Đã xảy ra lỗi!",
    "ErrorModal--description":"Xin hãy thử lại hoặc liên hệ hỗ trợ.",
    "Confirmation--confirm":"OK",
    "MessageMaxEditsModal__Title":"Không thể chỉnh sửa tin nhắn",
    "MessageMaxEditsModal__Description":"{max, plural, other {Chỉ {max,number} chỉnh sửa có thể được áp dụng cho tin nhắn này.}}",
    "unknown-sgnl-link":"Xin lỗi, đường dẫn sgnl:// không đúng!",
    "GroupV2--cannot-send":"Bạn không thể gửi tin nhắn cho nhóm đó.",
    "GroupV2--cannot-start-group-call":"Chỉ quản trị viên của nhóm mới có thể bắt đầu cuộc gọi.",
    "GroupV2--join--invalid-link--title":"Đường dẫn \u001dVô hiệu lực",
    "GroupV2--join--invalid-link":"Đây không phải là một đường dẫn nhóm có hiệu lực. Đảm bảo là toàn bộ đường dẫn được giữ nguyên và chính xác trước khi thử tham gia.",
    "GroupV2--join--prompt":"Bạn có muốn tham gia nhóm này và công khai tên và ảnh đại diện với các thành viên trong nhóm?",
    "GroupV2--join--already-in-group":"Bạn đã ở trong nhóm này rồi.",
    "GroupV2--join--already-awaiting-approval":"Bạn đã gửi yêu cầu chấp duyệt để tham gia nhóm này.",
    "GroupV2--join--unknown-link-version--title":"Không rõ phiên bản đường dẫn",
    "GroupV2--join--unknown-link-version":"Đường dẫn này không được hỗ trợ bởi phiên bản Signal Desktop này.",
    "GroupV2--join--link-revoked--title":"Không thể Tham gia Nhóm",
    "GroupV2--join--link-revoked":"Đường dẫn nhóm không còn hiệu lực.",
    "GroupV2--join--link-forbidden--title":"Không thể Tham gia Nhóm",
    "GroupV2--join--link-forbidden":"Bạn không thể tham gia nhóm này qua đường dẫn nhóm vì một quản trị viên đã xóa bạn khỏi nhóm.",
    "GroupV2--join--prompt-with-approval":"Cần một quản trị viên chấp nhận yêu cầu trước khi bạn tham gia nhóm này. Khi bạn yêu cầu tham gia, tên và ảnh đại diện của bạn sẽ được công khai với các thành viên nhóm.",
    "GroupV2--join--join-button":"Tham gia",
    "GroupV2--join--request-to-join-button":"Yêu cầu để Tham gia",
    "GroupV2--join--cancel-request-to-join":"Hủy yêu cầu",
    "GroupV2--join--cancel-request-to-join--confirmation":"Huỷ yêu cầu tham gia nhóm này của bạn?",
    "GroupV2--join--cancel-request-to-join--yes":"Có",
    "GroupV2--join--cancel-request-to-join--no":"Không",
    "GroupV2--join--group-metadata--full":"{memberCount, plural, other {Nhóm · {memberCount,number} thành viên}}",
    "GroupV2--join--requested":"Yêu cầu tham gia nhóm của bạn đã được gửi tới admin nhóm. Bạn sẽ được thông báo khi họ chọn hành động.",
    "GroupV2--join--general-join-failure--title":"Lỗi Đường dẫn",
    "GroupV2--join--general-join-failure":"Không thể vào nhóm. Thử lại sau.",
    "GroupV2--admin":"Quản trị viên",
    "GroupV2--only-admins":"Chỉ có Quản trị viên",
    "GroupV2--all-members":"Tất cả thành viên",
    "updating":"Đang cập nhật…",
    "GroupV2--create--you":"Bạn đã tạo nhóm.",
    "GroupV2--create--other":"{memberName} đã tạo nhóm.",
    "GroupV2--create--unknown":"Nhóm đã được tạo.",
    "GroupV2--title--change--other":"{memberName} đã thay đổi tên nhóm thành \"{newTitle}\".",
    "GroupV2--title--change--you":"Bạn đã thay đổi tên nhóm thành \"{newTitle}\".",
    "GroupV2--title--change--unknown":"Một thành viên đã thay đổi tên nhóm thành \"{newTitle}\".",
    "GroupV2--title--remove--other":"{memberName} đã gỡ bỏ tên nhóm.",
    "GroupV2--title--remove--you":"Bạn đã xoá tên nhóm.",
    "GroupV2--title--remove--unknown":"Một thành viên đã gỡ bỏ tên nhóm.",
    "GroupV2--avatar--change--other":"{memberName} đã thay đổi ảnh đại diện nhóm.",
    "GroupV2--avatar--change--you":"Bạn đã đổi ảnh nhóm.",
    "GroupV2--avatar--change--unknown":"Một thành viên đã thay đổi ảnh đại diện nhóm.",
    "GroupV2--avatar--remove--other":"{memberName} đã gỡ bỏ ảnh đại diện nhóm.",
    "GroupV2--avatar--remove--you":"Bạn đã gỡ bỏ ảnh đại diện nhóm.",
    "GroupV2--avatar--remove--unknown":"Một thành viên đã gỡ bỏ ảnh đại diện nhóm.",
    "GroupV2--access-attributes--admins--other":"{adminName} đã thay đổi người có thể chỉnh sửa thông tin nhóm sang \"Chỉ có admin.\"",
    "GroupV2--access-attributes--admins--you":"Bạn đã thay đổi người có thể chỉnh sửa thông tin nhóm thành \"Chỉ có admin.\"",
    "GroupV2--access-attributes--admins--unknown":"Một admin đã thay đổi người có thể chỉnh sửa thông tin nhóm thành \"Chỉ admin\".",
    "GroupV2--access-attributes--all--other":"{adminName} đã thay đổi người có thể chỉnh sửa thông tin nhóm thành \"Tất cả thành viên.\"",
    "GroupV2--access-attributes--all--you":"Bạn đã thay đổi người có thể chỉnh sửa thông tin nhóm thành \"All members.\"",
    "GroupV2--access-attributes--all--unknown":"Một admin đã thay đổi người có thể chỉnh sửa thông tin nhóm thành \"Tất cả thành viên.\"",
    "GroupV2--access-members--admins--other":"{adminName} đã thay đổi người có thể chỉnh sửa thành viên nhóm thành \"Chỉ admin.\"",
    "GroupV2--access-members--admins--you":"Bạn đã thay đổi người có thể chỉnh sửa thành viên nhóm thành \"Chỉ admin.\"",
    "GroupV2--access-members--admins--unknown":"Một admin đã thay đổi người có thể chỉnh sửa thành viên nhóm thành \"Chỉ admin.\"",
    "GroupV2--access-members--all--other":"{adminName} đã thay đổi người có thể chỉnh sửa thành viên nhóm thành \"Tất cả thành viên.\"",
    "GroupV2--access-members--all--you":"Bạn đã thay đổi người có thể chỉnh sửa thành viên nhóm thành \"Tất cả thành viên.\"",
    "GroupV2--access-members--all--unknown":"Một admin đã thay đổi người có thể chỉnh sửa thành viên nhóm thành \"Tất cả thành viên.\"",
    "GroupV2--access-invite-link--disabled--you":"Bạn đã tắt chấp duyệt của người quản lý cho đường dẫn nhóm.",
    "GroupV2--access-invite-link--disabled--other":"{adminName} đã tắt chấp duyệt của người quản lý cho đường dẫn nhóm.",
    "GroupV2--access-invite-link--disabled--unknown":"Chấp duyệt của người quản lý cho đường dẫn nhóm đã được tắt.",
    "GroupV2--access-invite-link--enabled--you":"Bạn đã bật chấp duyệt của người quản lý cho đường dẫn nhóm.",
    "GroupV2--access-invite-link--enabled--other":"{adminName} đã bật chấp duyệt của người quản lý cho đường dẫn nhóm.",
    "GroupV2--access-invite-link--enabled--unknown":"Chấp duyệt của người quản lý cho đường dẫn nhóm đã được bật.",
    "GroupV2--member-add--invited--you":"Bạn đã thêm thành viên được mời {inviteeName}.",
    "GroupV2--member-add--invited--other":"{memberName} đã thêm thành viên được mời {inviteeName}.",
    "GroupV2--member-add--invited--unknown":"Một thành viên đã thêm thành viên được mời {inviteeName}.",
    "GroupV2--member-add--from-invite--other":"{inviteeName} chấp nhận lời mời vào nhóm từ {inviterName}.",
    "GroupV2--member-add--from-invite--other-no-from":"{inviteeName} chấp nhận một lời mời vào nhóm.",
    "GroupV2--member-add--from-invite--you":"Bạn đã chấp nhận một lời mời vào nhóm từ {inviterName}.",
    "GroupV2--member-add--from-invite--you-no-from":"Bạn đã chấp nhận thư mời vào nhóm.",
    "GroupV2--member-add--from-invite--from-you":"{inviteeName} đã chấp nhận lời mời vào nhóm của bạn.",
    "GroupV2--member-add--other--other":"{adderName} đã thêm {addeeName}.",
    "GroupV2--member-add--other--you":"Bạn đã thêm {memberName}.",
    "GroupV2--member-add--other--unknown":"Một thành viên đã thêm {memberName}.",
    "GroupV2--member-add--you--other":"{memberName} đã thêm bạn vào nhóm.",
    "GroupV2--member-add--you--you":"Bạn đã tham gia nhóm.",
    "GroupV2--member-add--you--unknown":"Bạn đã được thêm vào nhóm.",
    "GroupV2--member-add-from-link--you--you":"Bạn đã tham gia nhóm qua đường dẫn nhóm.",
    "GroupV2--member-add-from-link--other":"{memberName} đã tham gia nhóm qua đường dẫn nhóm.",
    "GroupV2--member-add-from-admin-approval--you--other":"{adminName} đã chấp duyệt yêu cầu tham gia nhóm của bạn.",
    "GroupV2--member-add-from-admin-approval--you--unknown":"Yêu cầu tham gia nhóm của bạn đã được chấp nhận.",
    "GroupV2--member-add-from-admin-approval--other--you":"Bạn đã chấp duyệt yêu cầu tham gia nhóm từ {joinerName}.",
    "GroupV2--member-add-from-admin-approval--other--other":"{adminName} đã chấp duyệt yêu cầu tham gia nhóm từ {joinerName}.",
    "GroupV2--member-add-from-admin-approval--other--unknown":"Yêu cầu để tham gia nhóm từ {joinerName} đã được chấp duyệt.",
    "GroupV2--member-remove--other--other":"{adminName} đã gỡ bỏ {memberName}.",
    "GroupV2--member-remove--other--self":"{memberName} đã rời nhóm.",
    "GroupV2--member-remove--other--you":"Bạn đã gỡ bỏ {memberName}.",
    "GroupV2--member-remove--other--unknown":"Một thành viên đã gỡ bỏ {memberName}.",
    "GroupV2--member-remove--you--other":"{adminName} đã gỡ bỏ bạn.",
    "GroupV2--member-remove--you--you":"Bạn đã rời nhóm.",
    "GroupV2--member-remove--you--unknown":"Bạn đã bị loại bỏ khỏi nhóm.",
    "GroupV2--member-privilege--promote--other--other":"{adminName} đã đặt {memberName} làm admin.",
    "GroupV2--member-privilege--promote--other--you":"Bạn đã đặt {memberName} làm admin.",
    "GroupV2--member-privilege--promote--other--unknown":"Một admin đã đặt {memberName} làm admin.",
    "GroupV2--member-privilege--promote--you--other":"{adminName} đã đặt bạn làm admin.",
    "GroupV2--member-privilege--promote--you--unknown":"Một admin đã đặt bạn làm admin.",
    "GroupV2--member-privilege--demote--other--other":"{adminName} đã thu hồi quyền quản trị của bạn cho {memberName}.",
    "GroupV2--member-privilege--demote--other--you":"Bạn đã thu hồi quyền quản trị của {memberName}.",
    "GroupV2--member-privilege--demote--other--unknown":"Một admin đã thu hồi quyền quản trị của {memberName}.",
    "GroupV2--member-privilege--demote--you--other":"{adminName} đã thu hồi quyền quản trị của bạn.",
    "GroupV2--member-privilege--demote--you--unknown":"Một admin đã thu hồi quyền quản trị của bạn.",
    "GroupV2--pending-add--one--other--other":"{memberName} đã mời 1 người vào nhóm.",
    "GroupV2--pending-add--one--other--you":"Bạn đã mời {inviteeName} vào nhóm.",
    "GroupV2--pending-add--one--other--unknown":"Một người đã được mời vào nhóm.",
    "GroupV2--pending-add--one--you--other":"{memberName} đã mời bạn vào nhóm.",
    "GroupV2--pending-add--one--you--unknown":"Bạn đã được mời vào nhóm.",
    "GroupV2--pending-add--many--other":"{count, plural, other {{memberName} đã mời {count,number} người vào nhóm.}}",
    "GroupV2--pending-add--many--you":"{count, plural, other {Bạn đã mời {count,number} người vào nhóm.}}",
    "GroupV2--pending-add--many--unknown":"{count, plural, other {{count,number} người đã được mời vào nhóm.}}",
    "GroupV2--pending-remove--decline--other":"1 người được mời bởi {memberName} đã từ chối \u001d\u001dlời mời vào nhóm.",
    "GroupV2--pending-remove--decline--you":"{inviteeName} đã từ chối lời mời tham gia nhóm của bạn.",
    "GroupV2--pending-remove--decline--from-you":"Bạn đã từ chối lời mời vào nhóm.",
    "GroupV2--pending-remove--decline--unknown":"1 người đã từ chối lời mời tham gia nhóm của họ.",
    "GroupV2--pending-remove--revoke--one--other":"{memberName} đã thu hồi lời mời vào nhóm cho 1 người.",
    "GroupV2--pending-remove--revoke--one--you":"Bạn đã thu hồi lời mời vào nhóm cho 1 người.",
    "GroupV2--pending-remove--revoke-own--to-you":"{inviterName} đã thu hồi lời mời cho bạn.",
    "GroupV2--pending-remove--revoke-own--unknown":"{inviterName} đã thu hồi lời mời của họ cho 1 người.",
    "GroupV2--pending-remove--revoke--one--unknown":"Một admin đã thu hồi lời mời vào nhóm cho 1 người.",
    "GroupV2--pending-remove--revoke--many--other":"{count, plural, other {{memberName} đã thu hồi lời mời vào nhóm cho {count,number} người.}}",
    "GroupV2--pending-remove--revoke--many--you":"{count, plural, other {Bạn đã thu hồi lời mời vào nhóm cho {count,number} người.}}",
    "GroupV2--pending-remove--revoke--many--unknown":"{count, plural, other {Một quản trị viên đã thu hồi lời mời vào nhóm cho {count,number} người.}}",
    "GroupV2--pending-remove--revoke-invite-from--one--other":"{adminName} đã thu hồi lời mời vào nhóm cho 1 người được mời bởi {memberName}.",
    "GroupV2--pending-remove--revoke-invite-from--one--you":"Bạn đã thu hồi lời mời vào nhóm cho 1 người được mời bởi {memberName}.",
    "GroupV2--pending-remove--revoke-invite-from--one--unknown":"Một admin đã thu hồi lời mời vào nhóm cho 1 người được mời bởi {memberName}.",
    "GroupV2--pending-remove--revoke-invite-from-you--one--other":"{adminName} đã thu hồi lời mời vào nhóm bạn đã gửi cho {inviteeName}.",
    "GroupV2--pending-remove--revoke-invite-from-you--one--you":"Bạn đã huỷ bỏ lời mời cho {inviteeName}.",
    "GroupV2--pending-remove--revoke-invite-from-you--one--unknown":"Một admin đã thu hồi lời mời vào nhóm bạn gửi cho {inviteeName}.",
    "GroupV2--pending-remove--revoke-invite-from--many--other":"{count, plural, other {{adminName} đã thu hồi lời mời vào nhóm cho {count,number} người đã được mời bởi {memberName}.}}",
    "GroupV2--pending-remove--revoke-invite-from--many--you":"{count, plural, other {Bạn đã thu hồi lời mời vào nhóm cho {count,number} người được mời bởi {memberName}.}}",
    "GroupV2--pending-remove--revoke-invite-from--many--unknown":"{count, plural, other {Một quản trị viên đã thu hồi lời mời vào nhóm cho {count,number} người được mời bởi {memberName}.}}",
    "GroupV2--pending-remove--revoke-invite-from-you--many--other":"{count, plural, other {{adminName} đã thu hồi lời mời vào nhóm bạn gửi cho {count,number} người.}}",
    "GroupV2--pending-remove--revoke-invite-from-you--many--you":"{count, plural, other {Bạn đã huỷ lời mời của bạn cho {count,number} người.}}",
    "GroupV2--pending-remove--revoke-invite-from-you--many--unknown":"{count, plural, other {Một quản trị viên đã thu hồi lời mời vào nhóm bạn đã gửi cho {count,number} người.}}",
    "GroupV2--admin-approval-add-one--you":"Bạn đã gửi yêu cầu tham gia nhóm.",
    "GroupV2--admin-approval-add-one--other":"{joinerName} đã yêu cầu để tham gia qua đường dẫn nhóm.",
    "GroupV2--admin-approval-remove-one--you--you":"Bạn đã hủy yêu cầu tham gia nhóm.",
    "GroupV2--admin-approval-remove-one--you--unknown":"Yêu cầu tham gia nhóm của bạn đã bị từ chối bởi một quản trị viên.",
    "GroupV2--admin-approval-remove-one--other--you":"Bạn đã từ chối yêu cầu tham gia nhóm từ {joinerName}.",
    "GroupV2--admin-approval-remove-one--other--own":"{joinerName} đã huỷ yêu cầu tham gia nhóm của họ.",
    "GroupV2--admin-approval-remove-one--other--other":"{adminName} từ chối yêu cầu tham gia nhóm từ {joinerName}.",
    "GroupV2--admin-approval-remove-one--other--unknown":"Yêu cầu tham gia nhóm từ {joinerName} đã bị từ chối.",
    "GroupV2--admin-approval-bounce--pluralized":"{numberOfRequests, plural, other {{joinerName} đã yêu cầu và hủy {numberOfRequests,number} yêu cầu tham gia qua đường dẫn nhóm}}",
    "GroupV2--group-link-add--disabled--you":"Bạn đã bật đường dẫn nhóm mà không cần chấp duyệt của người quản lý.",
    "GroupV2--group-link-add--disabled--other":"{adminName} đã bật đường dẫn nhóm mà không cần chấp duyệt của người quản lý.",
    "GroupV2--group-link-add--disabled--unknown":"Đường dẫn nhóm đã được bật đường dẫn nhóm mà không cần chấp duyệt của người quản lý.",
    "GroupV2--group-link-add--enabled--you":"Bạn đã bật đường dẫn nhóm mà cần chấp duyệt của người quản lý.",
    "GroupV2--group-link-add--enabled--other":"{adminName} đã bật đường dẫn nhóm mà cần chấp duyệt của người quản lý.",
    "GroupV2--group-link-add--enabled--unknown":"Đường dẫn nhóm đã được bật đường dẫn nhóm mà cần chấp duyệt của người quản lý.",
    "GroupV2--group-link-remove--you":"Bạn đã tắt đường dẫn nhóm.",
    "GroupV2--group-link-remove--other":"{adminName} đã tắt đường dẫn nhóm.",
    "GroupV2--group-link-remove--unknown":"Đường dẫn nhóm đã được tắt.",
    "GroupV2--group-link-reset--you":"Bạn đã đặt lại đường dẫn nhóm.",
    "GroupV2--group-link-reset--other":"{adminName} đã đặt lại đường dẫn nhóm.",
    "GroupV2--group-link-reset--unknown":"Đường dẫn nhóm đã được đặt lại.",
    "GroupV2--description--remove--you":"Bạn đã gỡ bỏ miêu tả nhóm.",
    "GroupV2--description--remove--other":"{memberName} đã gỡ bỏ miêu tả nhóm.",
    "GroupV2--description--remove--unknown":"Miêu tả nhóm đã được gỡ bỏ.",
    "GroupV2--description--change--you":"Bạn đã sửa mô tả nhóm",
    "GroupV2--description--change--other":"{memberName} đã thay đổi miêu tả nhóm.",
    "GroupV2--description--change--unknown":"Miêu tả nhóm đã được thay đổi.",
    "GroupV2--announcements--admin--you":"Bạn đã thay đổi cài đặt nhóm để chỉ cho phép người quản trị gửi tin nhắn.",
    "GroupV2--announcements--admin--other":"{memberName} đã thay đổi cài đặt nhóm để chỉ cho phép quản trị viên gửi tin nhắn.",
    "GroupV2--announcements--admin--unknown":"Nhóm đã được thay đổi để chỉ cho phép quản trị viên gửi tin nhắn.",
    "GroupV2--announcements--member--you":"Bạn đã thay đổi cài đặt nhóm để cho phép tất cả thành viên gửi tin nhắn.",
    "GroupV2--announcements--member--other":"{memberName} đã thay đổi cài đặt nhóm để cho phép tất cả các thành viên gửi tin nhắn.",
    "GroupV2--announcements--member--unknown":"Nhóm đã được thay đổi để cho phép tất cả các thành viên gửi tin nhắn.",
    "GroupV2--summary":"Các thành viên của nhóm này hoặc các cài đặt đã thay đổi.",
    "GroupV1--Migration--disabled--link":"Cập nhật nhóm này để kích hoạt các tính năng mới như @nhắc tên và quản trị. Các thành viên chưa chia sẻ tên hoặc ảnh trong nhóm này sẽ được mời tham gia. <learnMoreLink>Tìm hiểu thêm.</learnMoreLink>",
    "GroupV1--Migration--was-upgraded":"Nhóm này đã được nâng cấp Nhóm Mới.",
    "GroupV1--Migration--learn-more":"Tìm hiểu thêm",
    "GroupV1--Migration--migrate":"Nâng cấp",
    "GroupV1--Migration--info--title":"Nhóm Mới là gì?",
    "GroupV1--Migration--migrate--title":"Nâng cấp lên Nhóm Mới",
    "GroupV1--Migration--info--summary":"Nhóm Mới có các tính năng như @nhắc và admin nhóm, và sẽ hỗ trợ nhiều tính năng trong tương lai.",
    "GroupV1--Migration--info--keep-history":"Tất cả lịch sử tin nhắn và đa phương tiện đã được giữ lại trước khi cập nhật.",
    "GroupV1--Migration--migrate--keep-history":"Tất cả lịch sử tin nhắn và đa phương tiện sẽ được giữ lại từ trước khi nâng cấp.",
    "GroupV1--Migration--info--invited--you":"Bạn sẽ cần phải chấp nhận lời mời để tham gia nhóm này một lần nữa, và sẽ không nhận tin nhắn nhóm cho tới khi bạn chấp nhận.",
    "GroupV1--Migration--info--invited--many":"Những thành viên này sẽ cần đồng ý lời mời tham gia nhóm trở lại, và sẽ không nhận được tin nhắn cho tới khi họ đồng ý:",
    "GroupV1--Migration--info--invited--one":"Thành viên này cần đồng ý lời mời tham gia nhóm trở lại, và sẽ không nhận được tin nhắn cho tới khi họ đồng ý:",
    "GroupV1--Migration--info--invited--count":"{count, plural, other {{count,number} thành viên sẽ cần đồng ý lời mời để tham gia nhóm lần nữa và sẽ không nhận được tin nhắn nhóm cho tới khi họ đồng ý.}}",
    "GroupV1--Migration--info--removed--before--many":"Những thành viên này không thể tham gia các Nhóm Mới, và sẽ bị gỡ bỏ khỏi nhóm:",
    "GroupV1--Migration--info--removed--before--one":"Thành viên này không thể tham gia các Nhóm Mới, và sẽ bị gỡ bỏ khỏi nhóm:",
    "GroupV1--Migration--info--removed--before--count":"{count, plural, other {{count,number} thành viên không có khả năng tham gia Nhóm Mới, và sẽ được xóa khỏi nhóm.}}",
    "GroupV1--Migration--info--removed--after--many":"Những thành viên này không có khả năng tham gia Nhóm Mới, và đã bị loại bỏ khỏi nhóm:",
    "GroupV1--Migration--info--removed--after--one":"Thành viên này không có khả năng tham gia Nhóm Mới, và đã được loại bỏ khỏi nhóm:",
    "GroupV1--Migration--info--removed--after--count":"{count, plural, other {{count,number} thành viên không có khả năng tham gia Nhóm Mới, và sẽ được xóa khỏi nhóm.}}",
    "GroupV1--Migration--invited--you":"Bạn không thể được thêm vào Nhóm Mới và đã được mời tham dự.",
    "GroupV1--Migration--invited--one":"{contact} không thể được thêm vào Nhóm Mới và đã được mời tham gia.",
    "GroupV1--Migration--invited--many":"{count, plural, other {{count,number} thành viên không thể được thêm vào Nhóm Mới và đã được mời tham gia.}}",
    "GroupV1--Migration--removed--one":"{contact} đã được loại bỏ khỏi nhóm.",
    "GroupV1--Migration--removed--many":"{count, plural, other {{count,number} thành viên đã được loại bỏ khỏi nhóm.}}",
    "close":"Đóng",
    "previous":"trước",
    "next":"tiếp theo",
    "BadgeDialog__become-a-sustainer-button":"Ủng hộ Signal",
    "BadgeSustainerInstructions__header":"Ủng hộ Signal",
    "BadgeSustainerInstructions__subheader":"Signal được hỗ trợ bởi những người như bạn. Đóng góp và nhận huy hiệu.",
    "BadgeSustainerInstructions__instructions__1":"Mở Signal trên điện thoại của bạn",
    "BadgeSustainerInstructions__instructions__2":"Nhấn vào ảnh đại diện của bạn ở góc trên bên trái để mở Cài đặt",
    "BadgeSustainerInstructions__instructions__3":"Nhấn nút \"Ủng hộ Signal\" và đăng ký ủng hộ",
    "BackupImportScreen__title":"Đang đồng bộ tin nhắn",
    "BackupImportScreen__progressbar-hint":"Hiện tải được {currentSize} của tổng {totalSize} ({fractionComplete,number,percent})...",
    "BackupImportScreen__progressbar-hint--processing":"Đang hoàn tất việc chuyển tin nhắn...",
    "BackupImportScreen__progressbar-hint--preparing":"Đang chuẩn bị tải xuống...",
    "BackupImportScreen__description":"Quá trình này có thể mất vài phút tùy vào dung lượng của bản sao lưu",
    "BackupImportScreen__cancel":"Hủy chuyển",
    "BackupImportScreen__cancel-confirmation__title":"Hủy chuyển?",
    "BackupImportScreen__cancel-confirmation__body":"Tin nhắn và tập tin đa phương tiện của bạn chưa được hoàn tất khôi phục. Nếu bạn chọn hủy, bạn có thể tiến hành chuyển dữ liệu lại từ mục Cài đặt.",
    "BackupImportScreen__cancel-confirmation__cancel":"Tiếp tục chuyển",
    "BackupImportScreen__cancel-confirmation__confirm":"Hủy chuyển",
    "BackupImportScreen__error__title":"Có lỗi khi chuyển tin nhắn của bạn",
    "BackupImportScreen__error__body":"Tin nhắn của bạn không thể được chuyển. Kiểm tra kết nối internet của bạn và thử lại.",
    "BackupImportScreen__error__confirm":"Thử lại",
    "BackupImportScreen__skip":"Bỏ qua",
    "BackupImportScreen__skip-confirmation__title":"Bỏ qua việc chuyển tin nhắn?",
    "BackupImportScreen__skip-confirmation__body":"Nếu bạn chọn bỏ qua, bạn sẽ không có quyền truy cập bất kỳ tin nhắn hoặc tập tin đa phương tiện nào trên thiết bị này. Bạn có thể bắt đầu một quá trình chuyển mới sau khi bỏ qua từ mục Cài đặt > Trò chuyện > Chuyển.",
    "BackupImportScreen__skip-confirmation__cancel":"Hủy",
    "BackupMediaDownloadProgress__title-in-progress":"Đang khôi phục tập tin đa phương tiện",
    "BackupMediaDownloadProgress__title-paused":"Khôi phục được tạm dừng",
    "BackupMediaDownloadProgress__button-pause":"Tạm dừng chuyển",
    "BackupMediaDownloadProgress__button-resume":"Tiếp tục chuyển",
    "BackupMediaDownloadProgress__button-cancel":"Hủy chuyển",
    "BackupMediaDownloadProgress__button-more":"Tùy chọn khác",
    "BackupMediaDownloadProgress__title-complete":"Hoàn tất khôi phục",
    "BackupMediaDownloadProgress__progressbar-hint":"{currentSize} trên tổng số {totalSize}",
    "BackupMediaDownloadCancelConfirmation__title":"Hủy chuyển tập tin đa phương tiện?",
    "BackupMediaDownloadCancelConfirmation__description":"Tin nhắn và tập tin đa phương tiện của bạn chưa được hoàn tất khôi phục. Nếu bạn chọn hủy, bạn có thể tiến hành chuyển dữ liệu lại từ mục Cài đặt.",
    "BackupMediaDownloadCancelConfirmation__button-continue":"Tiếp tục chuyển",
    "BackupMediaDownloadCancelConfirmation__button-confirm-cancel":"Hủy chuyển",
    "CompositionArea--expand":"Mở rộng",
    "CompositionArea--attach-file":"Đính kèm tệp",
    "CompositionArea--sms-only__title":"Người này đang không dùng Signal",
    "CompositionArea--sms-only__body":"Signal Desktop không hỗ trợ nhắn tin cho các liên hệ ngoài-Signal. Hãy hỏi người này cài đặt Signal để có trải nghiệm nhắn tin bảo mật hơn.",
    "CompositionArea--sms-only__spinner-label":"Kiểm tra trạng thái đăng kí của liên hệ",
    "CompositionArea__edit-action--discard":"Hủy bỏ tin nhắn",
    "CompositionArea__edit-action--send":"Gửi tin nhắn đã chỉnh sửa",
    "CompositionInput__editing-message":"Chỉnh sửa tin nhắn",
    "countMutedConversationsDescription":"Bao gồm các cuộc trò chuyện đã tắt tiếng vào số lượng hiển thị",
    "ContactModal--nickname":"Biệt danh",
    "ContactModal--rm-admin":"Xóa với tư cách quản trị viên",
    "ContactModal--make-admin":"Đặt admin",
    "ContactModal--make-admin-info":"{contact} sẽ có thể chỉnh sửa nhóm và các thành viên nhóm.",
    "ContactModal--rm-admin-info":"Xóa {contact} với tư cách quản trị viên của nhóm?",
    "ContactModal--add-to-group":"Thêm vào một nhóm khác",
    "ContactModal--remove-from-group":"Xóa khỏi nhóm",
    "ContactModal--voice":"Thoại",
    "showChatColorEditor":"Màu cuộc trò chuyện",
    "showConversationDetails":"Cài đặt nhóm",
    "showConversationDetails--direct":"Cài đặt trò chuyện",
    "ConversationDetails__unmute--title":"Bỏ tắt tiếng cuộc trò chuyện này?",
    "ConversationDetails--group-link":"Đường dẫn nhóm",
    "ConversationDetails--disappearing-messages-label":"Tin nhắn tự hủy",
    "ConversationDetails--disappearing-messages-info--group":"Khi được bật, tin nhắn được gửi và nhận trong nhóm này sẽ biến mất sau khi chúng được xem.",
    "ConversationDetails--disappearing-messages-info--direct":"Khi được bật, các tin nhắn được gửi và nhận trong cuộc trò chuyện 1:1 này sẽ biến mất sau khi đã được đọc.",
    "ConversationDetails--nickname-label":"Biệt danh",
    "ConversationDetails--nickname-actions":"Các thao tác",
    "ConversationDetails--nickname-actions--delete":"Xóa",
    "ConversationDetails__ConfirmDeleteNicknameAndNote__Title":"Xóa biệt danh?",
    "ConversationDetails__ConfirmDeleteNicknameAndNote__Description":"Thao tác này sẽ xóa vĩnh viễn biệt danh này và nội dung ghi chú.",
    "ConversationDetails--notifications":"Thông báo",
    "ConversationDetails--group-info-label":"Ai có thể sửa thông tin nhóm",
    "ConversationDetails--group-info-info":"Chọn ai có thể tuỳ chỉnh tên nhóm, ảnh, miêu tả, và đồng hồ đếm tin nhắn biến mất.",
    "ConversationDetails--add-members-label":"Ai có thể thêm thành viên",
    "ConversationDetails--add-members-info":"Chọn người có thể thêm thành viên vào nhóm này.",
    "ConversationDetails--announcement-label":"Ai có thể giử tin nhắn",
    "ConversationDetails--announcement-info":"Chọn người có thể gửi tin nhắn cho nhóm.",
    "ConversationDetails--requests-and-invites":"Yêu cầu & Lời mời",
    "ConversationDetailsActions--leave-group":"Rời nhóm",
    "ConversationDetailsActions--block-group":"Chặn nhóm",
    "ConversationDetailsActions--unblock-group":"Bỏ chặn nhóm",
    "ConversationDetailsActions--leave-group-must-choose-new-admin":"Trước khi rời nhóm, bạn phải chọn ít nhất một quản trị viên mới cho nhóm này.",
    "ConversationDetailsActions--leave-group-modal-title":"Bạn có chắc chắn muốn rời khỏi nhóm?",
    "ConversationDetailsActions--leave-group-modal-content":"Bạn sẽ không thể gửi hoặc nhận tin nhắn trong nhóm này nữa.",
    "ConversationDetailsActions--leave-group-modal-confirm":"Rời khỏi",
    "ConversationDetailsActions--unblock-group-modal-title":"Bỏ chặn Nhóm \"{groupName}\"?",
    "ConversationDetailsActions--block-group-modal-title":"Chặn và Rời Nhóm \"{groupName}\"?",
    "ConversationDetailsActions--block-group-modal-content":"Bạn sẽ không còn nhận tin nhắn hay cập nhật từ nhóm này nữa.",
    "ConversationDetailsActions--block-group-modal-confirm":"Chặn",
    "ConversationDetailsActions--unblock-group-modal-content":"Các liên hệ của bạn sẽ được thêm vào nhóm này.",
    "ConversationDetailsActions--unblock-group-modal-confirm":"Bỏ chặn",
    "ConversationDetailsHeader--members":"{number, plural, other {{number,number} thành viên}}",
    "ConversationDetailsMediaList--shared-media":"Tệp đa phương tiện đã chia sẻ",
    "ConversationDetailsMediaList--show-all":"Xem tất cả",
    "ConversationDetailsMembershipList--title":"{number, plural, other {{number,number} thành viên}}",
    "ConversationDetailsMembershipList--add-members":"Thêm thành viên",
    "ConversationDetailsMembershipList--show-all":"Xem tất cả",
    "ConversationDetailsGroups--title":"{count, plural, other {{count,number} nhóm chung}}",
    "ConversationDetailsGroups--title--with-zero-groups-in-common":"Không có nhóm chung",
    "ConversationDetailsGroups--add-to-group":"Thêm vào một nhóm",
    "ConversationDetailsGroups--show-all":"Xem tất cả",
    "EditNicknameAndNoteModal__Title":"Biệt danh",
    "EditNicknameAndNoteModal__Description":"Biệt danh và ghi chú được lưu trữ với Signal và được mã hóa đầu cuối của Signal. Những nội dung này chỉ hiển thị với bạn.",
    "EditNicknameAndNoteModal__FirstName__Label":"Tên",
    "EditNicknameAndNoteModal__FirstName__Placeholder":"Tên",
    "EditNicknameAndNoteModal__LastName__Label":"Họ",
    "EditNicknameAndNoteModal__LastName__Placeholder":"Họ",
    "EditNicknameAndNoteModal__Note__Label":"Ghi chú",
    "EditNicknameAndNoteModal__Note__Placeholder":"Ghi chú",
    "ConversationNotificationsSettings__mentions__label":"Nhắc tên",
    "ConversationNotificationsSettings__mentions__info":"Nhận thông báo khi bạn được nhắc tới trong các cuộc trò chuyện đã tắt tiếng",
    "ConversationNotificationsSettings__mentions__select__always-notify":"Luôn luôn thông báo",
    "ConversationNotificationsSettings__mentions__select__dont-notify-for-mentions-if-muted":"Không thông báo nếu đã tắt tiếng",
    "GroupLinkManagement--clipboard":"Đường dẫn nhóm đã được sao chép.",
    "GroupLinkManagement--share":"Sao chép đường dẫn",
    "GroupLinkManagement--confirm-reset":"Bạn có muốn đặt lại đường dẫn nhóm? Người dùng khác sẽ không thể tham gia nhóm bằng đường dẫn hiện tại.",
    "GroupLinkManagement--reset":"Đặt lại đường dẫn",
    "GroupLinkManagement--approve-label":"Cần chấp thuận từ quản trị viên",
    "GroupLinkManagement--approve-info":"Yêu cầu người quản lý chấp duyệt thành viên mới tham gia qua đường dẫn nhóm",
    "PendingInvites--tab-requests":"Yêu cầu ({count,number})",
    "PendingInvites--tab-invites":"Lời mời ({count,number})",
    "PendingRequests--approve-for":"Chấp duyệt yêu cầu từ \"{name}\"?",
    "PendingRequests--deny-for":"Từ chối yêu cầu từ \"{name}\"?",
    "PendingRequests--deny-for--with-link":"Từ chối yêu cầu từ \"{name}\"? Người này sẽ không thể yêu cầu tham gia lại qua đường dẫn nhóm.",
    "PendingInvites--invited-by-you":"Được mời bởi bạn",
    "PendingInvites--invited-by-others":"Được mời bởi người khác",
    "PendingInvites--invited-count":"Được mời bởi {number,number}",
    "PendingInvites--revoke-for-label":"Thu hồi lời mời tham gia nhóm",
    "PendingInvites--revoke-for":"Thu hồi lời mời tham gia nhóm cho \"{name}\"?",
    "PendingInvites--revoke-from":"{number, plural, other {Thu hồi {number,number} lời mời gửi bởi \"{name}\"?}}",
    "PendingInvites--revoke":"Thu hồi",
    "PendingRequests--approve":"Chấp duyệt Yêu cầu",
    "PendingRequests--deny":"Từ chối Yêu cầu",
    "PendingRequests--info":"Những người trên danh sách này đang cố tham gia \"{name}\" qua đường dẫn nhóm.",
    "PendingInvites--info":"Chi tiết về những người được mời tham gia nhóm này sẽ không được hiện cho đến khi họ tham gia. Những người được mời sẽ chỉ thấy các tin nhắn sau khi họ tham gia nhóm.",
    "PendingRequests--block--button":"Chặn yêu cầu",
    "PendingRequests--block--title":"Chặn yêu cầu?",
    "PendingRequests--block--contents":"{name} sẽ không thể tham gia hoặc yêu cầu tham gia nhóm này qua đường dẫn nhóm. Người này vẫn có thể được thêm vào nhóm bằng cách thủ công.",
    "PendingRequests--block--confirm":"Chặn Yêu cầu",
    "SelectModeActions--exitSelectMode":"Thoát chế độ chọn",
    "SelectModeActions--selectedMessages":"{count, plural, other {Đã chọn {count,number}}}",
    "SelectModeActions--deleteSelectedMessages":"Xóa các tin nhắn đã chọn",
    "SelectModeActions--forwardSelectedMessages":"Chuyển tiếp các tin nhắn đã chọn",
    "DeleteMessagesModal--title":"{count, plural, other {Xóa {count,number} tin nhắn?}}",
    "DeleteMessagesModal--description":"{count, plural, other {Bạn muốn xoá các tin nhắn này cho ai?}}",
    "DeleteMessagesModal--description--noteToSelf":"{count, plural, other {Bạn muốn xóa các tin nhắn này trên thiết bị nào?}}",
    "DeleteMessagesModal--description--noteToSelf--deleteSync":"{count, plural, other {Những tin nhắn này sẽ được xóa trên tất cả thiết bị của bạn.}}",
    "DeleteMessagesModal--deleteForMe":"Xóa cho tôi",
    "DeleteMessagesModal--deleteFromThisDevice":"Xóa trên thiết bị này",
    "DeleteMessagesModal--deleteForEveryone":"Xóa cho mọi người",
    "DeleteMessagesModal--deleteFromAllDevices":"Xóa trên tất cả thiết bị",
    "DeleteMessagesModal--noteToSelf--deleteSync":"Xóa",
    "DeleteMessagesModal__toast--TooManyMessagesToDeleteForEveryone":"{count, plural, other {Bạn chỉ có thể chọn tối đa {count,number} tin nhắn để xóa cho mọi người}}",
    "SelectModeActions__toast--TooManyMessagesToForward":"Bạn chỉ có thể chuyển tiếp tối đa 30 tin nhắn",
    "ContactPill--remove":"Gỡ bỏ liên hệ",
    "NewlyCreatedGroupInvitedContactsDialog--title":"{count, plural, other {Đã gửi {count,number} lời mời}}",
    "NewlyCreatedGroupInvitedContactsDialog--body--user-paragraph--one":"{name} không thể được thêm tự động vào nhóm này bởi bạn.",
    "NewlyCreatedGroupInvitedContactsDialog--body--user-paragraph--many":"Những người dùng này không thể được thêm tự động vào nhóm này bởi bạn.",
    "NewlyCreatedGroupInvitedContactsDialog--body--info-paragraph":"Họ đã được mời để tham gia, và sẽ không thấy các tin nhắn nhóm cho đến khi họ đồng ý.",
    "NewlyCreatedGroupInvitedContactsDialog--body--learn-more":"Tìm hiểu thêm",
    "AddGroupMembersModal--title":"Thêm thành viên",
    "AddGroupMembersModal--continue-to-confirm":"Cập nhật",
    "AddGroupMembersModal--confirm-title--one":"Thêm {person} vào \"{group}\"?",
    "AddGroupMembersModal--confirm-title--many":"Thêm {count,number} thành viên vào \"{group}\"?",
    "AddGroupMembersModal--confirm-button--one":"Thêm thành viên",
    "AddGroupMembersModal--confirm-button--many":"Thêm thành viên",
    "createNewGroupButton":"Tạo nhóm mới",
    "selectContact":"Chọn liên hệ {name}",
    "deselectContact":"Bỏ chọn liên hệ {name}",
    "cannotSelectContact":"Không thể lựa chọn liên hệ {name}",
    "alreadyAMember":"Đã là thành viên",
    "MessageAudio--play":"Phát đính kèm âm thanh",
    "MessageAudio--pause":"Ngưng phát đính kèm âm thanh",
    "MessageAudio--download":"Tải đính kèm âm thanh",
    "MessageAudio--pending":"Đang tải đính kèm âm thanh…",
    "MessageAudio--slider":"Thời gian phát lại của đính kèm âm thanh",
    "MessageAudio--playbackRate1":"1",
    "MessageAudio--playbackRate1p5":"1.5",
    "MessageAudio--playbackRate2":"2",
    "MessageAudio--playbackRatep5":".5",
    "emptyInbox__title":"Không có cuộc trò chuyện",
    "emptyInbox__subtitle":"Các cuộc trò chuyện gần nhất sẽ hiển thị ở đây.",
    "ForwardMessageModal__title":"Chuyển tiếp Đến",
    "ForwardMessageModal__ShareCallLink":"Chia sẻ đường dẫn cuộc gọi",
    "ForwardMessageModal--continue":"Tiếp tục",
    "ForwardMessagesModal__toast--CannotForwardEmptyMessage":"Không thể chuyển tiếp tin nhắn trống hoặc đã xóa",
    "ShareCallLinkViaSignal__DraftMessageText":"Sử dụng đường dẫn này để tham gia cuộc gọi Signal: {url}",
    "MessageRequestWarning__learn-more":"Tìm hiểu thêm",
    "MessageRequestWarning__safety-tips":"Lời khuyên để giữ an toàn",
    "MessageRequestWarning__dialog__details":"Bạn không có chung nhóm nào với người này. Xem qua các yêu cầu cẩn thận trước khi đồng ý để tránh các tin nhắn không muốn.",
    "MessageRequestWarning__dialog__learn-even-more":"Về Yêu cầu Nhắn tin",
    "ContactSpoofing__same-name--link":"Kiểm tra các yêu cầu một cách kĩ lưỡng. Signal tìm thấy một liên hệ cùng tên. <reviewRequestLink>Kiểm tra yêu cầu</reviewRequestLink>",
    "ContactSpoofing__same-name-in-group--link":"{count, plural, other {{count,number} thành viên nhóm có tên giống nhau. <reviewRequestLink>Kiểm tra thành viên</reviewRequestLink>}}",
    "ContactSpoofing__same-names-in-group--link":"{count, plural, other {{count,number} tên thành viên xung đột trong nhóm này. <reviewRequestLink>Kiểm tra thành viên</reviewRequestLink>}}",
    "ContactSpoofingReviewDialog__title":"Xem lại yêu cầu",
    "ContactSpoofingReviewDialog__description":"Nếu bạn không chắc yêu cầu từ người nào, xem các liên hệ bên dưới và chọn hành động.",
    "ContactSpoofingReviewDialog__possibly-unsafe-title":"Yêu cầu",
    "ContactSpoofingReviewDialog__safe-title":"Liên hệ của bạn",
    "ContactSpoofingReviewDialog__group__title":"Xem lại thành viên",
    "ContactSpoofingReviewDialog__group__description":"{count, plural, other {{count,number} thành viên nhóm có tên giống nhau, duyệt các thành viên dưới đây hoặc chọn để thực hiện hành động.}}",
    "ContactSpoofingReviewDialog__group__multiple-conflicts__description":"{count, plural, other {{count,number} tên thành viên xung đột trong nhóm này. Kiểm tra các thành viên dưới hoặc chọn hành động.}}",
    "ContactSpoofingReviewDialog__group__members__no-shared-groups":"Không có nhóm chung nào khác",
    "ContactSpoofingReviewDialog__signal-connection":"Liên hệ Signal",
    "ContactSpoofingReviewDialog__group__name-change-info":"Gần đây đã đổi tên hồ sơ từ {oldName} thành {newName}",
    "RemoveGroupMemberConfirmation__remove-button":"Xóa khỏi nhóm",
    "RemoveGroupMemberConfirmation__description":"Xóa\"{name}\" khỏi nhóm?",
    "RemoveGroupMemberConfirmation__description__with-link":"Xóa \"{name}\" khỏi nhóm? Người này sẽ không thể tham gia lại qua đường dẫn nhóm.",
    "CaptchaDialog__title":"Hãy xác minh để tiếp tục nhắn tin.",
    "CaptchaDialog__first-paragraph":"Để hạn chế spam trên Signal, vui lòng thực hiện xác minh.",
    "CaptchaDialog__second-paragraph":"Sau khi xác minh, bạn có thể tiếp tục nhắn tin. Những tin nhắn đang tạm dừng sẽ được tự động gửi.",
    "CaptchaDialog--can-close__title":"Tiếp tục mà không cần xác minh?",
    "CaptchaDialog--can-close__body":"Nếu bạn chọn để bỏ qua xác minh, bạn có thể bỏ lỡ tin nhắn từ người khác và tin nhắn của bạn có thể sẽ không gửi được.",
    "CaptchaDialog--can_close__skip-verification":"Bỏ qua xác minh",
    "verificationComplete":"Xác minh hoàn tất.",
    "verificationFailed":"Xác minh không thành công. Xin thử lại sau.",
    "deleteForEveryoneFailed":"Xoá tin nhắn cho mọi người không thành công. Xin thử lại sau.",
    "ChatColorPicker__delete--title":"Xoá màu",
    "ChatColorPicker__delete--message":"{num, plural, other {Màu tuỳ chỉnh này được dùng trong {num,number} cuộc trò chuyện. Bạn có muốn xóa màu này cho tất cả các cuộc trò chuyện?}}",
    "ChatColorPicker__menu-title":"Màu Cuộc trò chuyện",
    "ChatColorPicker__reset":"Đặt lại màu cuộc trò chuyện",
    "ChatColorPicker__resetDefault":"Đặt lại màu cuộc trò chuyện",
    "ChatColorPicker__resetAll":"Đặt lại tất cả màu cuộc trò chuyện",
    "ChatColorPicker__confirm-reset-default":"Đặt lại mặc định",
    "ChatColorPicker__confirm-reset":"Đặt lại",
    "ChatColorPicker__confirm-reset-message":"Bạn có muốn ghi đè tất cả các màu cuộc trò chuyện?",
    "ChatColorPicker__custom-color--label":"Hiện bảng chỉnh sửa màu tuỳ chỉnh",
    "ChatColorPicker__sampleBubble1":"Đây là mẫu xem trước của màu cuộc trò chuyện.",
    "ChatColorPicker__sampleBubble2":"Một bong bóng khác.",
    "ChatColorPicker__sampleBubble3":"Màu này chỉ có bạn thấy được.",
    "ChatColorPicker__context--edit":"Tuỳ chỉnh màu",
    "ChatColorPicker__context--duplicate":"Sao chép",
    "ChatColorPicker__context--delete":"Xóa",
    "CustomColorEditor__solid":"Đặc",
    "CustomColorEditor__gradient":"Theo nhiệt độ",
    "CustomColorEditor__hue":"Sắc",
    "CustomColorEditor__saturation":"Bão hoà",
    "CustomColorEditor__title":"Màu tuỳ chỉnh",
    "GradientDial__knob-start":"Bắt đầu chuyển sắc",
    "GradientDial__knob-end":"Kết thúc chuyển sắc",
    "customDisappearingTimeOption":"Thời gian tuỳ chỉnh…",
    "selectedCustomDisappearingTimeOption":"Thời gian tuỳ chỉnh",
    "DisappearingTimeDialog__label--value":"Số",
    "DisappearingTimeDialog__label--units":"Đơn vị thời gian",
    "DisappearingTimeDialog__title":"Thời gian Tuỳ chỉnh",
    "DisappearingTimeDialog__body":"Chọn thời gian tuỳ chỉnh cho tin nhắn biến mất.",
    "DisappearingTimeDialog__set":"Cài",
    "DisappearingTimeDialog__seconds":"Giây",
    "DisappearingTimeDialog__minutes":"Phút",
    "DisappearingTimeDialog__hours":"Giờ",
    "DisappearingTimeDialog__days":"Ngày",
    "DisappearingTimeDialog__weeks":"Tuần",
    "settings__DisappearingMessages__footer":"Đặt đồng hồ tin nhắn biến mất mặc định cho tất cả các cuộc trò chuyện mới được bạn bắt đầu.",
    "settings__DisappearingMessages__timer__label":"Đồng hồ mặc định cho tất cả cuộc trò chuyện mới",
    "UniversalTimerNotification__text":"Thời gian biến mất tin nhắn sẽ được cài thành {timeValue} khi bạn nhắn tin họ.",
    "ContactRemovedNotification__text":"Bạn đã xóa người này, nếu nhắn tin lại thì người này sẽ được thêm lại vào danh sách của bạn.",
    "ErrorBoundaryNotification__text":"Không thể hiển thị tin nhắn này. Nhấp để gửi nhật ký gỡ lỗi.",
    "GroupDescription__read-more":"đọc thêm",
    "EditConversationAttributesModal__description-warning":"Miêu tả nhóm sẽ được nhìn thấy bởi thành viên của nhóm này và những người đã được mời.",
    "ConversationDetailsHeader--add-group-description":"Thêm miêu tả nhóm…",
    "MediaQualitySelector--button":"Chọn chất lượng đa phương tiện",
    "MediaQualitySelector--title":"Chất lượng Đa phương tiện",
    "MediaQualitySelector--standard-quality-title":"Tiêu chuẩn",
    "MediaQualitySelector--standard-quality-description":"Nhanh hơn, ít dữ liệu",
    "MediaQualitySelector--high-quality-title":"Cao",
    "MediaQualitySelector--high-quality-description":"Chậm hơn, nhiều dữ liệu",
    "MessageDetailsHeader--Failed":"Chưa gửi",
    "MessageDetailsHeader--Pending":"Đang chờ",
    "MessageDetailsHeader--Sent":"Gửi từ",
    "MessageDetailsHeader--Delivered":"Đã chuyển tới",
    "MessageDetailsHeader--Read":"Đã đọc bởi",
    "MessageDetailsHeader--Viewed":"Được xem bởi",
    "MessageDetail--disappears-in":"Tự biến mất trong",
    "MessageDetail__view-edits":"Xem lịch sử chỉnh sửa",
    "ProfileEditor--about":"Thông tin",
    "ProfileEditor--username":"Tên người dùng",
    "ProfileEditor--username--corrupted--body":"Có vấn đề với tên người dùng của bạn, tên này không còn gắn liền với tài khoản của bạn. Bạn có thể thử và đặt lại hoặc chọn một tên mới.",
    "ProfileEditor--username--corrupted--fix-button":"Sửa ngay",
    "ProfileEditor__username-link":"Mã QR hoặc Đường Dẫn",
    "ProfileEditor__username__error-icon":"Cần đặt lại tên người dùng",
    "ProfileEditor__username-link__error-icon":"Cần đặt lại đường dẫn tên người dùng",
    "ProfileEditor__username-link__tooltip__title":"Chia sẻ tên người dùng của bạn",
    "ProfileEditor__username-link__tooltip__body":"Cho phép người khác bắt đầu một cuộc trò chuyện với bạn bằng cách chia sẻ mã QR hoặc đường dẫn riêng của bạn.",
    "ProfileEditor--username--title":"Chọn tên người dùng của bạn",
    "ProfileEditor--username--check-characters":"Tên người dùng chỉ có thể chứa a-z, 0-9 và _",
    "ProfileEditor--username--check-starting-character":"Tên người dùng không thể bắt đầu bằng một chữ số.",
    "ProfileEditor--username--check-character-min-plural":"{min, plural, other {Tên người dùng phải có ít nhất {min,number} ký tự.}}",
    "ProfileEditor--username--check-character-max-plural":"{max, plural, other {Tên người dùng phải có tối đa {max,number} ký tự.}}",
    "ProfileEditor--username--check-discriminator-min":"Tên người dùng không hợp lệ, nhập tối thiểu 2 số.",
    "ProfileEditor--username--check-discriminator-all-zero":"Số này không thể là 00. Nhập một ký tự trong khoảng 1-9",
    "ProfileEditor--username--check-discriminator-leading-zero":"Số với nhiều hơn 2 ký tự không thể bắt đầu với số 0",
    "ProfileEditor--username--too-many-attempts":"Vượt quá số lần thử, vui lòng thử lại sau",
    "ProfileEditor--username--unavailable":"Tên người dùng này không khả dụng",
    "ProfileEditor--username--general-error":"Tên người dùng của bạn không thể lưu. Kiểm tra kết nối của bạn và thử lại.",
    "ProfileEditor--username--reservation-gone":"{username} không còn khả dụng. Một dãy số sẽ được ghép với tên người dùng của bạn, vui lòng thử lưu lại.",
    "ProfileEditor--username--delete-general-error":"Tên người dùng của bạn không thể xóa. Kiểm tra kết nối của bạn và thử lại.",
    "ProfileEditor--username--copied-username":"Đã sao chép tên người dùng",
    "ProfileEditor--username--copied-username-link":"Đã sao chép đường dẫn",
    "ProfileEditor--username--deleting-username":"Đang xóa tên người dùng",
    "ProfileEditor--username--confirm-delete-body-2":"Thao tác này sẽ xóa tên người dùng của bạn, đồng thời vô hiệu hóa mã QR và đường dẫn của bạn. \"{username}\" sẽ có thể được sử dụng bởi thành viên khác. Bạn có chắc không?",
    "ProfileEditor--username--confirm-delete-button":"Xóa",
    "ProfileEditor--username--context-menu":"Sao chép hoặc xóa tên người dùng",
    "ProfileEditor--username--copy":"Sao chép tên người dùng",
    "ProfileEditor--username--delete":"Xóa",
    "ProfileEditor--about-placeholder":"Viết vài điều về bản thân bạn…",
    "ProfileEditor--first-name":"Tên Đầu (Bắt buộc)",
    "ProfileEditor--last-name":"Tên Họ (Không bắt buộc)",
    "ConfirmDiscardDialog--discard":"Bạn có muốn bỏ các thay đổi này?",
    "ConfirmationDialog__Title--in-call-close-requested":"Đóng Signal và kết thúc cuộc gọi?",
    "ConfirmationDialog__Title--close-requested-not-now":"Để sau",
    "ProfileEditor--edit-photo":"Tùy chỉnh ảnh",
    "ProfileEditor--info--general":"Hồ sơ và các thay đổi trên hồ sơ của bạn sẽ chỉ hiển thị với những người bạn nhắn tin, với các liên hệ và các nhóm.",
    "ProfileEditor--info--pnp":"Tên người dùng, mã QR và đường dẫn không hiển thị trên hồ sơ của bạn. Chỉ chia sẻ chúng với người bạn tin tưởng.",
    "ProfileEditor--info--pnp--no-username":"Giờ đây mọi người có thể gửi tin nhắn cho bạn qua tên người dùng tùy chọn của bạn nên bạn không cần cung cấp số điện thoại của mình.",
    "Bio--speak-freely":"Nói chuyện tự do",
    "Bio--encrypted":"Đã được mã hóa",
    "Bio--free-to-chat":"Rảnh để trò chuyện",
    "Bio--coffee-lover":"Nghiện cà phê",
    "Bio--taking-break":"Đang nghỉ ngơi",
    "ProfileEditorModal--profile":"Hồ sơ",
    "ProfileEditorModal--name":"Tên của Bạn",
    "ProfileEditorModal--about":"Thông tin",
    "ProfileEditorModal--avatar":"Hình đại diện của bạn",
    "ProfileEditorModal--username":"Tên người dùng",
    "ProfileEditorModal--error":"Hồ sơ của bạn không thể cập nhật. Vui lòng thử lại.",
    "ProfileEditor__invalid-about__title":"Ký tự không hợp lệ",
    "ProfileEditor__invalid-about__body":"Có một hoặc nhiều ký tự bạn nhập không hợp lệ. Thử lại.",
    "AnnouncementsOnlyGroupBanner--modal":"Nhắn tin cho quản trị viên",
    "AnnouncementsOnlyGroupBanner--announcements-only":"Chỉ {admins} có thể gửi tin nhắn",
    "AnnouncementsOnlyGroupBanner--admins":"người quản trị",
    "AvatarEditor--choose":"Chọn Anhr đại diện",
    "AvatarColorPicker--choose":"Chọn một màu sắc",
    "LeftPaneSetGroupMetadataHelper__avatar-modal-title":"Hình đại diện nhóm",
    "Preferences__message-audio-title":"Âm thanh tin nhắn khi trò chuyện",
    "Preferences__message-audio-description":"Nghe một âm thanh thông báo cho tin nhắn gửi đi hoặc nhận được khi trò chuyện.",
    "Preferences__button--general":"Chung",
    "Preferences__button--appearance":"Diện mạo",
    "Preferences__button--chats":"Trò chuyện",
    "Preferences__button--calls":"Cuộc gọi",
    "Preferences__button--notifications":"Thông báo",
    "Preferences__button--privacy":"Riêng tư",
    "Preferences--lastSynced":"Lần nhập cuối cùng vào lúc {date} {time}",
    "Preferences--system":"Hệ thống",
    "Preferences--zoom":"Mức độ phóng to",
    "Preferences__link-previews--title":"Tạo xem trước liên kết",
    "Preferences__link-previews--description":"Để thay đổi cài đặt này, hãy mở ứng dụng Signal trên thiết bị di động của bạn và điều hướng đến Cài đặt > Trò chuyện",
    "Preferences__auto-convert-emoji--title":"Chuyển đổi biểu tượng cảm xúc khi bạn nhập thành emoji",
    "Preferences__auto-convert-emoji--description":"Ví dụ, :-) sẽ được chuyển đổi thành <emojify>🙂</emojify>",
    "Preferences--advanced":"Nâng cao",
    "Preferences--notification-content":"Nội dung thông báo",
    "Preferences--blocked":"Đã chặn",
    "Preferences--blocked-count":"{num, plural, other {{num,number} liên hệ}}",
    "Preferences__privacy--description":"Để thay đổi các cài đặt này, hãy mở ứng dụng Signal trên thiết bị di động của bạn và điều hướng đến Cài đặt> Quyền riêng tư",
    "Preferences__pnp__row--title":"Số điện thoại",
    "Preferences__pnp__row--body":"Chọn người có thể thấy số điện thoại của bạn và người có thể liên hệ với bạn trên Signal với số đó.",
    "Preferences__pnp__row--button":"Đổi…",
    "Preferences__pnp__sharing--title":"Ai Có Thể Thấy Số Điện Thoại Của Tôi",
    "Preferences__pnp__sharing--description--everyone":"Số điện thoại của bạn sẽ được hiển thị với những người và nhóm bạn nhắn tin.",
    "Preferences__pnp__sharing--description--nobody":"Số điện thoại của bạn sẽ không hiển thị với bất kỳ ai trừ trường hợp người đó đã lưu số của bạn trong danh bạ trên máy họ.",
    "Preferences__pnp__sharing--description--nobody--not-discoverable":"Số điện thoại của bạn sẽ không hiển thị với bất kỳ ai.",
    "Preferences__pnp--page-title":"Số điện thoại",
    "Preferences__pnp__sharing__everyone":"Bất cứ ai",
    "Preferences__pnp__sharing__nobody":"Không ai",
    "Preferences__pnp__discoverability--title":"Ai Có Thể Tìm Tôi Bằng Số Điện Thoại",
    "Preferences__pnp__discoverability--description--everyone":"Bất kỳ ai có số điện thoại của bạn sẽ thấy bạn trên Signal và có thể bắt đầu trò chuyện với bạn.",
    "Preferences__pnp__discoverability--description--nobody":"Sẽ không có ai nhìn thấy số điện thoại của bạn trên Signal, trừ khi bạn nhắn tin với người đó hoặc đã có một cuộc trò chuyện với người đó.",
    "Preferences__pnp__discoverability__everyone":"Bất cứ ai",
    "Preferences__pnp__discoverability__nobody":"Không ai",
    "Preferences__pnp__discoverability__nobody__confirmModal__title":"Bạn có chắc không?",
    "Preferences__pnp__discoverability__nobody__confirmModal__description":"Việc bạn đổi “{settingTitle}” thành “{nobodyLabel}” sẽ làm mọi người khó tìm được bạn hơn trên Signal.",
    "Preferences--messaging":"Nhắn tin",
    "Preferences--read-receipts":"Thông báo đã xem",
    "Preferences--typing-indicators":"Thông báo đang nhập",
    "Preferences--updates":"Cập nhật",
    "Preferences__download-update":"Tự động tải các bản cập nhật",
    "Preferences__enable-notifications":"Bật thông báo",
    "Preferences__devices":"Các thiết bị",
    "Preferences__turn-stories-on":"Bật story",
    "Preferences__turn-stories-off":"Tắt story",
    "Preferences__turn-stories-off--action":"Tắt",
    "Preferences__turn-stories-off--body":"Bạn sẽ không thể tiếp tục chia sẻ hoặc xem story. Các cập nhật story gần đây của bạn cũng sẽ được xóa.",
    "Preferences__Language__Label":"Ngôn ngữ",
    "Preferences__Language__ModalTitle":"Ngôn ngữ",
    "Preferences__Language__SystemLanguage":"Ngôn ngữ Hệ thống",
    "Preferences__Language__SearchLanguages":"Tìm ngôn ngữ",
    "Preferences__Language__NoResults":"Không có kết quả cho “{searchTerm}”",
    "Preferences__LanguageModal__Set":"Thiết lập",
    "Preferences__LanguageModal__Restart__Title":"Khởi động lại Signal để áp dụng",
    "Preferences__LanguageModal__Restart__Description":"Để đổi ngôn ngữ, cần khởi động lại ứng dụng.",
    "Preferences__LanguageModal__Restart__Button":"Khởi động lại",
    "DialogUpdate--version-available":"Cập nhật liên phiên bản {version} có sẵn",
    "DialogUpdate__downloading":"Đang tải Bản Cập Nhật…",
    "DialogUpdate__downloaded":"Đã tải Bản Cập Nhật",
    "DialogNetworkStatus__outage":"Signal đang gặp trục trặc kĩ thuật. Chúng thôi đang cố hết sức để khôi phục dịch vụ càng sớm càng tốt.",
    "InstallScreenUpdateDialog--unsupported-os__title":"Yêu cầu Cập nhật",
    "InstallScreenUpdateDialog--auto-update__body":"Để tiếp tục sử dụng Signal, bạn phải cập nhật phiên bản mới nhất của Signal.",
    "InstallScreenUpdateDialog--manual-update__action":"Tải {downloadSize}",
    "InstallScreenUpdateDialog--downloaded__body":"Khởi động lại Signal để cài đặt bản cập nhật.",
    "InstallScreenUpdateDialog--cannot-update__body":"Signal Desktop cập nhật không thành công, nhưng hiện có phiên bản mới. Truy cập {downloadUrl} và cài đặt thủ công phiên bản mới, sau đó liên hệ bộ phận hỗ trợ hoặc báo cáo về vấn đề này.",
    "NSIS__retry-dialog--first-line":"Signal không thể đóng",
    "NSIS__retry-dialog--second-line":"Vui lòng đóng nó theo cách thủ công và nhấp vào Thử lại để tiếp tục.",
    "NSIS__appRunning":"{appName} đang hoạt động.\nBấm OK để đóng.\nNếu ứng dụng vẫn không đóng, hãy thử đóng bằng cách thủ công.",
    "NSIS__decompressionFailed":"Giải nén tập tin không thành công. Vui lòng thử chạy lại bộ cài đặt.",
    "NSIS__uninstallFailed":"Xóa tập tin của ứng dụng cũ không thành công. Vui lòng thử chạy lại bộ cài đặt.",
    "NSIS__semver-downgrade":"Đã có phiên bản mới hơn của Signal được cài đặt. Bạn có chắc muốn tiếp tục?",
    "CrashReportDialog__title":"Ứng dụng bị trở ngại",
    "CrashReportDialog__body":"Signal sẽ tự khởi động lại sau khi bị lỗi. Bạn có thể thông báo lỗi này để giúp Signal tìm hiểu và khắc phục.",
    "CrashReportDialog__submit":"Gửi",
    "CrashReportDialog__erase":"Không Gửi",
    "CustomizingPreferredReactions__title":"Tuỳ chỉnh phản ứng",
    "CustomizingPreferredReactions__subtitle":"Nhấp để thay thế một biểu tượng cảm xúc",
    "CustomizingPreferredReactions__had-save-error":"Đã xảy ra lỗi khi lưu cài đặt của bạn. Vui lòng thử lại.",
    "MediaEditor__input-placeholder":"Tin nhắn",
    "MediaEditor__clock-more-styles":"Thêm phong cách",
    "MediaEditor__control--draw":"Kéo",
    "MediaEditor__control--text":"Thêm ký tự",
    "MediaEditor__control--crop":"Cắt và xoay",
    "MediaEditor__control--undo":"Hoàn tác",
    "MediaEditor__control--redo":"Làm lại",
    "MediaEditor__text--regular":"Bình thường",
    "MediaEditor__text--highlight":"Làm nổi bật",
    "MediaEditor__text--outline":"Đường viền",
    "MediaEditor__draw--pen":"Bút",
    "MediaEditor__draw--highlighter":"Bút đánh dấu",
    "MediaEditor__draw--thin":"Gầy",
    "MediaEditor__draw--regular":"Bình thường",
    "MediaEditor__draw--medium":"Vừa",
    "MediaEditor__draw--heavy":"Nặng",
    "MediaEditor__crop--reset":"Đặt lại",
    "MediaEditor__crop--rotate":"Xoay",
    "MediaEditor__crop--flip":"Ghim",
    "MediaEditor__crop--lock":"Khóa",
    "MediaEditor__crop-preset--freeform":"Freeform",
    "MediaEditor__crop-preset--square":"Vuông",
    "MediaEditor__crop-preset--9-16":"9:16",
    "MyStories__title":"Các Story của tôi",
    "MyStories__list_item":"Các Story của tôi",
    "MyStories__story":"Story của bạn",
    "MyStories__download":"Tải story",
    "MyStories__more":"Tùy chọn khác",
    "MyStories__views":"{views, plural, other {{views,number} lượt xem}}",
    "MyStories__views--strong":"{views, plural, other {<strong>{views,number}</strong> lượt xem}}",
    "MyStories__views-off":"Đã tắt thông báo đã xem",
    "MyStories__replies":"{replyCount, plural, other {<strong>{replyCount,number}</strong> câu trả lời}}",
    "MyStories__delete":"Xóa story này? Story cũng sẽ được xóa với tất cả những người đã nhận nó.",
    "payment-event-notification-message-you-label":"Bạn đã gửi một khoản thanh toán cho {receiver}",
    "payment-event-notification-message-you-label-without-receiver":"Bạn đã gửi một khoản thanh toán",
    "payment-event-notification-message-label":"{sender} đã gửi một khoản thanh toán cho bạn",
    "payment-event-activation-request-label":"{sender} muốn bạn bật tính năng Thanh toán. Chỉ gửi khoản thanh toán đến những người bạn tin tưởng. Tính năng Thanh toán có thể được bật trên thiết bị di động của bạn bằng cách vào Cài đặt -> Thanh toán.",
    "payment-event-activation-request-you-label":"Bạn đã gửi cho {receiver} một yêu cầu bật tính năng Thanh toán.",
    "payment-event-activation-request-you-label-without-receiver":"Bạn đã gửi một yêu cầu bật tính năng Thanh toán.",
    "payment-event-activated-label":"{sender} giờ đã có thể nhận Thanh toán.",
    "payment-event-activated-you-label":"Bạn đã bật tính năng Thanh toán.",
    "payment-event-notification-label":"Thanh toán",
    "payment-event-notification-check-primary-device":"Kiểm tra thiết bị chính của bạn để xem trang thái của khoản thanh toán này",
    "MessageRequestResponseNotification__Message--Accepted":"Bạn đã chấp nhận yêu cầu gửi tin nhắn",
    "MessageRequestResponseNotification__Message--Reported":"Đã báo cáo là spam",
    "MessageRequestResponseNotification__Message--Blocked":"Bạn đã chặn người này",
    "MessageRequestResponseNotification__Message--Blocked--Group":"Bạn đã chặn nhóm",
    "MessageRequestResponseNotification__Message--Unblocked":"Bạn đã bỏ chặn người này",
    "MessageRequestResponseNotification__Message--Unblocked--Group":"Bạn đã bỏ chặn nhóm",
    "MessageRequestResponseNotification__Button--Options":"Tùy chọn",
    "MessageRequestResponseNotification__Button--LearnMore":"Tìm hiểu thêm",
    "SignalConnectionsModal__title":"Liên hệ Signal",
    "SignalConnectionsModal__header":"{connections} là những người bạn chọn để tin tưởng, bằng việc:",
    "SignalConnectionsModal__bullet--1":"Bắt đầu một cuộc trò chuyện",
    "SignalConnectionsModal__bullet--2":"Chấp nhận yêu cầu gửi tin nhắn",
    "SignalConnectionsModal__bullet--3":"Có những người này trong danh bạ của thiết bị",
    "SignalConnectionsModal__footer":"Các liên hệ của bạn có thể thấy tên và ảnh của bạn, và có thể thấy các bài đăng lên \"Story của tôi\" trừ khi bạn ẩn story với các liên hệ",
    "LocalDeleteWarningModal__header":"Việc xóa được đồng bộ trên các thiết bị của bạn",
    "LocalDeleteWarningModal__description":"Khi bạn xóa tin nhắn hoặc cuộc trò chuyện, chúng sẽ được xóa trên tất cả thiết bị của bạn.",
    "LocalDeleteWarningModal__confirm":"Đã hiểu",
    "Stories__title":"Các Story",
    "Stories__mine":"Story của tôi",
    "Stories__add":"Thêm một story",
    "Stories__add-story--text":"Story dạng văn bản",
    "Stories__add-story--media":"Ảnh hoặc video",
    "Stories__hidden-stories":"Các story đã ẩn",
    "Stories__list-empty":"Không có story gần đây để hiển thị vào lúc này",
    "Stories__list__empty--title":"Không có story",
    "Stories__list__empty--subtitle":"Các cập nhật mới sẽ hiển thị ở đây.",
    "Stories__list--sending":"Đang gửi…",
    "Stories__list--send_failed":"Không gửi được",
    "Stories__list--partially-sent":"Đã gửi một phần",
    "Stories__list--retry-send":"Bấm để thử lại",
    "Stories__placeholder--text":"Bấm để xem một story",
    "Stories__placeholder-with-icon--text-2":"Bấm <newStoryButtonIcon></newStoryButtonIcon> để cập nhật.",
    "Stories__from-to-group":"{name} đến {group}",
    "Stories__toast--sending-reply":"Đang gửi câu trả lời…",
    "Stories__toast--sending-reaction":"Đang gửi bày tỏ cảm xúc…",
    "Stories__toast--hasNoSound":"Story này không có âm thanh",
    "Stories__failed-send":"Không thể gửi story đến một số người. Kiểm tra kết nối của bạn và thử lại.",
    "StoriesSettings__title":"Sự Riêng tư của Story",
    "StoriesSettings__description":"Các story tự động biến mất sau 24 giờ. Chọn người có thể xem story của bạn hoặc tạo các story mới cho các nhóm và người xem nhất định.",
    "StoriesSettings__my_stories":"Các Story của tôi",
    "StoriesSettings__new-list":"Story Mới",
    "StoriesSettings__custom-story-subtitle":"Story tùy chỉnh",
    "StoriesSettings__group-story-subtitle":"Story nhóm",
    "StoriesSettings__viewers":"{count, plural, other {{count,number} người xem}}",
    "StoriesSettings__who-can-see":"Ai có thể xem story này",
    "StoriesSettings__add-viewer":"Thêm người xem",
    "StoriesSettings__remove--action":"Xóa",
    "StoriesSettings__remove--title":"Gỡ {title}",
    "StoriesSettings__remove--body":"Người này sẽ không còn xem được story của bạn.",
    "StoriesSettings__replies-reactions--title":"Câu trả lời & Bày tỏ cảm xúc",
    "StoriesSettings__replies-reactions--label":"Cho phép trả lời & bày tỏ cảm xúc",
    "StoriesSettings__replies-reactions--description":"Cho phép những người có thể xem story của bạn bày tỏ cảm xúc và trả lời.",
    "StoriesSettings__delete-list":"Xóa story tùy chỉnh",
    "StoriesSettings__delete-list--confirm":"Bạn có chắc muốn xóa \"{name}\"? Các cập nhật cho story này cũng sẽ được xóa.",
    "StoriesSettings__choose-viewers":"Chọn Người Xem",
    "StoriesSettings__name-story":"Đặt tên cho story",
    "StoriesSettings__name-placeholder":"Tên của story (bắt buộc)",
    "StoriesSettings__hide-story":"Ẩn story với",
    "StoriesSettings__mine__all--label":"Tất cả liên hệ Signal",
    "StoriesSettings__mine__exclude--label":"Tất Cả Trừ…",
    "StoriesSettings__mine__only--label":"Chỉ chia sẻ với…",
    "StoriesSettings__mine__disclaimer--link":"Chọn người có thể xem story của bạn. Thay đổi không có hiệu lực với các story bạn đã gửi. <learnMoreLink>Tìm hiểu thêm.</learnMoreLink>",
    "StoriesSettings__context-menu":"Sự Riêng tư của Story",
    "StoriesSettings__view-receipts--label":"Thông báo Đã xem",
    "StoriesSettings__view-receipts--description":"Để thay đổi cài đặt này, hãy mở ứng dụng Signal trên thiết bị di động của bạn và điều hướng đến Cài đặt -> Trò chuyện",
    "GroupStorySettingsModal__members_title":"Ai có thể xem story này",
    "GroupStorySettingsModal__members_help":"Các thành viên của nhóm \"{groupTitle}\" có thể xem và trả lời story này. Bạn có thể cập nhật quyền thành viên của cuộc trò chuyện này trong nhóm.",
    "GroupStorySettingsModal__remove_group":"Gỡ story nhóm",
    "StoriesSettings__remove_group--confirm":"Bạn có chắc chắn muốn xóa \"{groupTitle}\"?",
    "SendStoryModal__choose-who-can-view":"Chọn người có thể xem story của bạn",
    "SendStoryModal__title":"Gửi đến",
    "SendStoryModal__send":"Gửi story",
    "SendStoryModal__custom-story":"Story tùy chỉnh",
    "SendStoryModal__group-story":"Story nhóm",
    "SendStoryModal__only-share-with":"Chỉ chia sẻ với",
    "SendStoryModal__excluded":"{count, plural, other {{count,number} Được loại trừ}}",
    "SendStoryModal__new":"Mới",
    "SendStoryModal__new-custom--title":"Story tùy chỉnh mới",
    "SendStoryModal__new-custom--name-visibility":"Chỉ bạn có thể thấy tên của story này.",
    "SendStoryModal__new-custom--description":"Chỉ hiện thị với một số người nhất định",
    "SendStoryModal__new-group--title":"Story nhóm mới",
    "SendStoryModal__new-group--description":"Chia sẻ với một nhóm sẵn có",
    "SendStoryModal__choose-groups":"Chọn nhóm",
    "SendStoryModal__my-stories-privacy":"Sự Riêng tư của Story của tôi",
    "SendStoryModal__privacy-disclaimer--link":"Chọn các liên hệ Signal có thể xem story của bạn. Bạn luôn có thể thay đổi trong phần cài đặt sự riêng tư. <learnMoreLink>Tìm hiểu thêm.</learnMoreLink>",
    "SendStoryModal__delete-story":"Xóa story",
    "SendStoryModal__confirm-remove-group":"Bạn muốn gỡ story? Thao tác này sẽ gỡ story được chọn khỏi danh sách của bạn, nhưng bạn sẽ vẫn có thể xem các story từ nhóm này.",
    "SendStoryModal__announcements-only":"Chỉ có quản trị viên mới có thể gửi story vào nhóm này.",
    "Stories__settings-toggle--title":"Chia sẻ & Xem Story",
    "Stories__settings-toggle--description":"Nếu bạn tắt tính năng story, bạn sẽ không thể tiếp tục chia sẻ hoặc xem story.",
    "Stories__settings-toggle--button":"Tắt story",
    "StoryViewer__pause":"Tạm ngưng",
    "StoryViewer__play":"Chạy",
    "StoryViewer__reply":"Trả lời",
    "StoryViewer__reply-placeholder":"Trả lời {firstName}",
    "StoryViewer__reply-group":"Trả lời cho Nhóm",
    "StoryViewer__mute":"Tắt tiếng",
    "StoryViewer__unmute":"Bỏ tắt tiếng",
    "StoryViewer__views-off":"Đã tắt thông báo đã xem",
    "StoryViewer__sending":"Đang gửi…",
    "StoryViewer__failed":"Không gửi được. Bấm để thử lại",
    "StoryViewer__partial-fail":"Đã gửi một phần. Bấm để thử lại",
    "StoryDetailsModal__sent-time":"Đã gửi lúc {time}",
    "StoryDetailsModal__file-size":"Kích thước tập tin {size}",
    "StoryDetailsModal__disappears-in":"Tự biến mất trong {countdown}",
    "StoryDetailsModal__copy-timestamp":"Sao chép dấu thời gian",
    "StoryDetailsModal__download-attachment":"Tải Tập tin đính kèm",
    "StoryViewsNRepliesModal__read-receipts-off":"Bật thông báo đã xem để biết ai đã xem story của bạn. Hãy mở ứng dụng Signal trên thiết bị di động của bạn và điều hướng đến Cài đặt > Story.",
    "StoryViewsNRepliesModal__no-replies":"Chưa có câu trả lời",
    "StoryViewsNRepliesModal__no-views":"Chưa có lượt xem",
    "StoryViewsNRepliesModal__tab--views":"Lượt xem",
    "StoryViewsNRepliesModal__tab--replies":"Câu trả lời",
    "StoryViewsNRepliesModal__reacted--you":"Đã bày tỏ cảm xúc với story",
    "StoryViewsNRepliesModal__reacted--someone-else":"Đã bày tỏ cảm xúc với story",
    "StoryViewsNRepliesModal__not-a-member":"Bạn không thể trả lời story này vì bạn không còn là thành viên của nhóm này.",
    "StoryViewsNRepliesModal__delete-reply":"Xóa cho tôi",
    "StoryViewsNRepliesModal__delete-reply-for-everyone":"Xóa cho mọi người",
    "StoryViewsNRepliesModal__copy-reply-timestamp":"Sao chép dấu thời gian",
    "StoryListItem__label":"Story",
    "StoryListItem__unhide":"Bỏ ẩn story",
    "StoryListItem__hide":"Ẩn story",
    "StoryListItem__go-to-chat":"Đến cuộc trò chuyện",
    "StoryListItem__delete":"Xóa",
    "StoryListItem__info":"Thông tin",
    "StoryListItem__hide-modal--body":"Ẩn story? Cập nhật story mới từ {name} sẽ không còn hiện ở đầu danh sách story.",
    "StoryListItem__hide-modal--confirm":"Ẩn",
    "StoryImage__error2":"Không thể tải story xuống. {name} sẽ cần chia sẻ lại.",
    "StoryImage__error--you":"Không thể tải story xuống. Bạn sẽ cần chia sẻ lại.",
    "StoryCreator__error--video-unsupported":"Không thể đăng video vào story vì video có định dạng không được hỗ trợ",
    "StoryCreator__error--video-too-long":"{maxDurationInSec, plural, other {Không thể đăng đăng video vào story vì video có độ dài hơn {maxDurationInSec,number} giây.}}",
    "StoryCreator__error--video-too-big":"Không thể đăng đăng video vào story vì video có độ dài hơn {limit,number}{units}.",
    "StoryCreator__error--video-error":"Tải video không thành công",
    "StoryCreator__text-bg--background":"Văn bản có màu nền là màu trắng",
    "StoryCreator__text-bg--inverse":"Văn bản có màu nền là màu đã chọn",
    "StoryCreator__text-bg--none":"Văn bản không có màu nền",
    "StoryCreator__story-bg":"Thay đổi màu nền của story",
    "StoryCreator__next":"Tiếp",
    "StoryCreator__add-link":"Thêm liên kết",
    "StoryCreator__text--regular":"Bình thường",
    "StoryCreator__text--bold":"Đậm",
    "StoryCreator__text--serif":"Serif",
    "StoryCreator__text--script":"Script",
    "StoryCreator__text--condensed":"Thu gọn",
    "StoryCreator__control--text":"Thêm văn bản cho story",
    "StoryCreator__control--link":"Thêm một liên kết",
    "StoryCreator__link-preview-placeholder":"Gõ hoặc dán một URL",
    "StoryCreator__link-preview-empty":"Thêm một đường dẫn cho người xem story của bạn",
    "Stories__failed-send--full":"Không gửi được story",
    "Stories__failed-send--partial":"Không gửi được story đến tất cả người nhận",
    "TextAttachment__placeholder":"Thêm ký tự",
    "TextAttachment__preview__link":"Truy cập đường dẫn",
    "Quote__story":"Story",
    "Quote__story-reaction":"Đã bày tỏ cảm xúc với story của {name}",
    "Quote__story-reaction--you":"Đã bày tỏ cảm xúc với story của bạn",
    "Quote__story-reaction--single":"Đã bày tỏ cảm xúc với một story",
    "Quote__story-reaction-notification--incoming":"Đã bày tỏ cảm xúc {emoji} với story của bạn",
    "Quote__story-reaction-notification--outgoing":"Bạn đã bày tỏ cảm xúc {emoji} với một story của {name}",
    "Quote__story-reaction-notification--outgoing--nameless":"Bạn đã bày tỏ cảm xúc {emoji} với một story",
    "Quote__story-unavailable":"Không thể xem được nữa",
    "ContextMenu--button":"Menu chuột phải",
    "EditUsernameModalBody__username-placeholder":"Tên người dùng",
    "EditUsernameModalBody__username-helper":"Tên người dùng luôn được ghép với một dãy số.",
    "EditUsernameModalBody__learn-more":"Tìm hiểu thêm",
    "EditUsernameModalBody__learn-more__title":"Số này là gì?",
    "EditUsernameModalBody__learn-more__body":"Các số này giúp giữ bí mật tên người dùng của bạn giúp bạn tránh các tin nhắn ngoài ý muốn. Chỉ chia sẻ tên người dùng của bạn với những người hoặc nhóm bạn muốn trò chuyện. Nếu bạn đổi tên người dùng, bạn sẽ nhận được một dãy số mới.",
    "EditUsernameModalBody__change-confirmation":"Thay đổi tên người dùng sẽ đặt lại mã QR và đường dẫn hiện tại của bạn. Bạn có chắc không?",
    "EditUsernameModalBody__change-confirmation__continue":"Tiếp tục",
    "EditUsernameModalBody__recover-confirmation":"Khôi phục lại tên người dùng sẽ đặt lại mã QR và đường dẫn hiện tại của bạn. Bạn có chắc không?",
    "EditUsernameModalBody__username-recovered__text":"Mã QR và đường dẫn của bạn đã được đặt lại và tên người dùng của bạn là {username}",
    "UsernameLinkModalBody__hint":"Quét mã QR này bằng điện thoại của bạn để trò chuyện với tôi trên Signal.",
      "descrption": "Text of the hint displayed below generated QR code on the printable image.",
    "UsernameLinkModalBody__save":"Lưu",
    "UsernameLinkModalBody__color":"Màu",
    "UsernameLinkModalBody__copy":"Sao chép vào Bảng kẹp",
    "UsernameLinkModalBody__help":"Chỉ chia sẻ mã QR và đường dẫn của bạn với người bạn tin tưởng. Khi được chia sẻ, mọi người sẽ có thể thấy tên người dùng và bắt đầu cuộc trò chuyện với bạn.",
    "UsernameLinkModalBody__reset":"Đặt lại",
    "UsernameLinkModalBody__done":"Xong",
    "UsernameLinkModalBody__color__radio":"Màu của đường dẫn tên người dùng, {index,number} của tổng số {total,number}",
    "UsernameLinkModalBody__reset__confirm":"Nếu đặt lại mã QR và đường dẫn, mã QR và đường dẫn hiện tại của bạn sẽ không còn hiệu lực nữa.",
    "UsernameLinkModalBody__resetting-link":"Đang đặt lại đường dẫn…",
    "UsernameLinkModalBody__error__text":"Có vấn đề với mã QR và đường dẫn của bạn, chúng không còn khả dụng. Thử đặt lại để tạo mã QR và đường dẫn mới.",
    "UsernameLinkModalBody__error__fix-now":"Sửa ngay",
    "UsernameLinkModalBody__recovered__text":"Mã QR và đường dẫn của bạn đã được đặt lại, một mã QR và đường dẫn mới đã được tạo.",
    "UsernameOnboardingModalBody__title":"Cách Mới để Kết Nối",
    "UsernameOnboardingModalBody__row__number__title":"Sự riêng tư cho số điện thoại",
    "UsernameOnboardingModalBody__row__number__body":"Số điện thoại của bạn không còn hiển thị trong các cuộc trò chuyện. Nếu số của bạn đã được lưu vào danh bạ của một người bạn, họ vẫn có thể nhìn thấy số đó.",
    "UsernameOnboardingModalBody__row__username__title":"Tên người dùng",
    "UsernameOnboardingModalBody__row__username__body":"Giờ đây mọi người có thể gửi tin nhắn cho bạn qua tên người dùng tùy chọn của bạn nên bạn không cần cung cấp số điện thoại của mình. Tên người dùng không hiển thị trên hồ sơ của bạn.",
    "UsernameOnboardingModalBody__row__qr__title":"Mã QR và Đường Dẫn",
    "UsernameOnboardingModalBody__row__qr__body":"Tên người dùng có một mã QR độc nhất cùng đường dẫn để bạn có thể chia sẻ để bạn bè có thể nhanh chóng bắt đầu trò chuyện với bạn.",
    "UsernameOnboardingModalBody__continue":"Đặt tên người dùng",
    "UsernameOnboardingModalBody__skip":"Để sau",
    "UsernameMegaphone__title":"Cách Mới để Kết Nối",
    "UsernameMegaphone__body":"Sự riêng tư cho số điện thoại, tên người dùng và đường dẫn tùy chọn.",
    "UsernameMegaphone__learn-more":"Tìm hiểu thêm",
    "UsernameMegaphone__dismiss":"Bỏ qua",
    "UnsupportedOSWarningDialog__body":"Signal desktop sẽ sớm không còn hỗ trợ phiên bản hiện tại của hệ điều hành {OS} trên máy tính bạn. Để có thể tiếp tục sử dụng Signal, cập nhật phiên bản hệ điều hành trên máy tính bạn trước {expirationDate}. <learnMoreLink>Tìm hiểu thêm</learnMoreLink>",
    "UnsupportedOSErrorDialog__body":"Signal desktop không còn hoạt động được trên máy tính này. Để có thể tiếp tục sử dụng Signal desktop, cập nhật phiên bản của hệ điều hành {OS} trên máy tính bạn. <learnMoreLink>Tìm hiểu thêm</learnMoreLink>",
    "UnsupportedOSErrorToast":"Signal desktop không còn hoạt động được trên máy tính này. Để có thể tiếp tục sử dụng Signal desktop, cập nhật phiên bản của hệ điều hành {OS} trên máy tính bạn.",
    "MessageMetadata__edited":"Đã chỉnh sửa",
    "EditHistoryMessagesModal__title":"Lịch sử chỉnh sửa",
    "ResendMessageEdit__body":"Không thể gửi chỉnh sửa này. Kiểm tra kết nối của bạn và thử lại",
    "ResendMessageEdit__button":"Gửi lại",
    "StoriesTab__MoreActionsLabel":"Các thao tác khác",
    "CallsTab__HeaderTitle--CallsList":"Cuộc gọi",
    "CallsTab__HeaderTitle--NewCall":"Cuộc gọi mới",
    "CallsTab__NewCallActionLabel":"Cuộc gọi mới",
    "CallsTab__MoreActionsLabel":"Các thao tác khác",
    "CallsTab__ClearCallHistoryLabel":"Xóa lịch sử cuộc gọi",
    "CallsTab__ConfirmClearCallHistory__Title":"Xóa lịch sử cuộc gọi?",
    "CallsTab__ConfirmClearCallHistory__Body":"Thao tác này sẽ xóa vĩnh viễn tất cả lịch sử cuộc gọi.",
    "CallsTab__ConfirmClearCallHistory__Body--call-links":"Thao tác này sẽ xóa vĩnh viễn tất cả lịch sử cuộc gọi. Các đường dẫn cuộc gọi bạn đã tạo không còn hiệu lực cho những người được bạn gửi đến. ",
    "CallsTab__ConfirmClearCallHistory__ConfirmButton":"Xoá",
    "CallsTab__ToastCallHistoryCleared":"Đã xóa lịch xử cuộc gọi",
    "CallsTab__ClearCallHistoryError--call-links":"Không thể xóa một số đường dẫn cuộc gọi. Kiểm tra kết nối của bạn và thử lại.",
    "CallsTab__ClearCallHistoryError":"Không thể xóa một số lịch sử cuộc gọi. Kiểm tra kết nối của bạn và thử lại.",
    "CallsTab__EmptyStateText--with-icon-2":"Bấm <newCallButtonIcon></newCallButtonIcon> để bắt đầu một cuộc gọi thoại hoặc cuộc gọi video mới.",
    "CallsList__SearchInputPlaceholder":"Tìm kiếm",
    "CallsList__ToggleFilterByMissedLabel":"Lọc cuộc gọi nhỡ",
    "CallsList__ToggleFilterByMissed__RoleDescription":"Bật tắt",
    "CallsList__EmptyState--noQuery__title":"Không có cuộc gọi",
    "CallsList__EmptyState--noQuery__subtitle":"Các cuộc gọi gần nhất sẽ hiển thị ở đây.",
    "CallsList__EmptyState--noQuery--missed__title":"Không có cuộc gọi nhỡ",
    "CallsList__EmptyState--noQuery--missed__subtitle":"Các cuộc gọi nhỡ sẽ hiển thị ở đây.",
    "CallsList__EmptyState--hasQuery":"Không có kết quả cho “{query}”",
    "CallsList__CreateCallLink":"Tạo Đường dẫn Cuộc gọi",
    "CallsList__ItemCallInfo--Incoming":"Cuộc gọi đến",
    "CallsList__ItemCallInfo--Outgoing":"Cuộc gọi đi",
    "CallsList__ItemCallInfo--Missed":"Cuộc gọi nhỡ",
    "CallsList__ItemCallInfo--Declined":"Đã từ chối",
    "CallsList__ItemCallInfo--CallLink":"Đường dẫn cuộc gọi",
    "CallsList__ItemCallInfo--Active":"Đang hoạt động",
    "CallsList__LeaveCallDialogTitle":"Rời khỏi cuộc gọi hiện tại?",
    "CallsList__LeaveCallDialogBody":"Bạn phải rời khỏi cuộc gọi hiện tại trước khi bắt đầu hoặc tham gia một cuộc gọi mới.",
    "CallsList__LeaveCallDialogButton--leave":"Rời cuộc gọi",
    "CallsNewCall__EmptyState--noQuery":"Không có cuộc trò chuyện gần đây.",
    "CallsNewCall__EmptyState--hasQuery":"Không có kết quả cho “{query}”",
    "CallsNewCallButton--return":"Quay về",
    "CallHistory__Description--Adhoc":"Đường dẫn cuộc gọi",
    "CallHistory__DescriptionVideoCall--Default":"{direction, select, Outgoing {Cuộc gọi video đang gọi đi} other {Cuộc gọi video đang đến}}",
    "CallHistory__DescriptionVideoCall--Missed":"Cuộc gọi video bị nhỡ",
    "CallHistory__DescriptionVideoCall--Unanswered":"Cuộc gọi video không được trả lời",
    "CallHistory__DescriptionVideoCall--Declined":"Cuộc gọi video đã từ chối",
    "CallLinkDetails__Join":"Tham gia",
    "CallLinkDetails__AddCallNameLabel":"Thêm tên cuộc gọi",
    "CallLinkDetails__EditCallNameLabel":"Sửa tên cuộc gọi",
    "CallLinkDetails__ApproveAllMembersLabel":"Cần chấp thuận từ quản trị viên",
    "CallLinkDetails__SettingTooltip--disabled-for-active-call":"Không thể thay đổi cài đặt này khi đang có cuộc gọi",
    "CallLinkDetails__CopyLink":"Sao chép đường dẫn",
    "CallLinkDetails__ShareLinkViaSignal":"Chia sẻ đường dẫn qua Signal",
    "CallLinkDetails__DeleteLink":"Xóa liên kết",
    "CallLinkDetails__DeleteLinkModal__Title":"Xóa đường dẫn cuộc gọi?",
    "CallLinkDetails__DeleteLinkModal__Body":"Đường dẫn này sẽ không còn hoạt động với bất kỳ ai.",
    "CallLinkDetails__DeleteLinkModal__Cancel":"Hủy",
    "CallLinkDetails__DeleteLinkModal__Delete":"Xóa",
    "CallLinkDetails__DeleteLinkTooltip--disabled-for-active-call":"Không thể xóa đường dẫn này khi đang có cuộc gọi",
    "CallLinkEditModal__Title":"Chi tiết đường dẫn cuộc gọi",
    "CallLinkEditModal__JoinButtonLabel":"Tham gia",
    "CallLinkEditModal__AddCallNameLabel":"Thêm tên cuộc gọi",
    "CallLinkEditModal__EditCallNameLabel":"Sửa tên cuộc gọi",
    "CallLinkEditModal__InputLabel--ApproveAllMembers":"Cần chấp thuận từ quản trị viên",
    "CallLinkPendingParticipantModal__ApproveButtonLabel":"Chấp thuận",
    "CallLinkPendingParticipantModal__DenyButtonLabel":"Từ chối",
    "CallLinkRestrictionsSelect__Option--Off":"Tắt",
    "CallLinkRestrictionsSelect__Option--On":"Bật",
    "CallLinkAddNameModal__Title":"Thêm tên cuộc gọi",
    "CallLinkAddNameModal__Title--Edit":"Sửa tên cuộc gọi",
    "CallLinkAddNameModal__NameLabel":"Tên cuộc gọi",
    "TypingBubble__avatar--overflow-count":"{count, plural, other {{count,number} người khác đang nhập.}}",
    "TransportError":"Experimental WebSocket Transport is seeing too many errors. Please submit a debug log",
    "WhoCanFindMeReadOnlyToast":"Để đổi cài đặt này, đặt mục “Ai có thể thấy số của tôi” thành “Không ai cả”.",
    "WhatsNew__modal-title":"Có gì mới",
    "WhatsNew__bugfixes":"Phiên bản này có một số tinh chỉnh và sửa lỗi để giữ cho Signal hoạt động mượt mà.",
    "WhatsNew__bugfixes--1":"Các tinh chỉnh, sửa lỗi, và cải thiện hiệu năng khác. Cảm ơn bạn đã sử dụng Signal!",
    "WhatsNew__bugfixes--2":"Nhiều lỗi được sửa để ứng dụng của bạn có thể chạy một cách trơn tru. Các thay đổi thú vị hơn nữa sắp xuất hiện!",
    "WhatsNew__bugfixes--3":"Các tinh chỉnh, sửa lỗi, và cải thiện hiệu năng. Bạn có thể tiếp tục nhắn tin, gọi thoại và video như thường nhé.",
    "WhatsNew__bugfixes--4":"Nỗ lực sửa các lỗi và cải thiện hiệu năng để ứng dụng của bạn có thể chạy một cách trơn tru. ",
    "WhatsNew__bugfixes--5":"Các tinh chỉnh, sửa lỗi, và kế hoạch khác cho tương lai.",
    "WhatsNew__bugfixes--6":"Các tinh chỉnh, sửa lỗi, và cải thiện hiệu năng. Cảm ơn bạn đã sử dụng Signal!",
    "WhatsNew__v7.29--0":"Bản cập nhật này tăng tốc độ khởi động lên khoảng 5%.",
    "WhatsNew__v7.30--header":"Giới thiệu Đường dẫn Cuộc gọi: Công cụ hữu ích cho những cuộc gọi bất chợt.",
    "WhatsNew__v7.30--0":"Giờ đây bạn có thể nhanh chóng tạo một đường dẫn để bất kỳ người dùng Signal nào cũng có thể dễ dàng sử dụng để tham gia cuộc gọi nhóm mà không cần tham gia nhóm.",
    "WhatsNew__v7.30--1":"Đường dẫn cuộc gọi có thể được sử dụng lại và hữu dụng cho những cuộc hẹn gọi lặp lại với bạn bè hay trao đổi mỗi tuần với đồng nghiệp.",
    "WhatsNew__v7.30--2":"Bạn có thể quản lý đường dẫn cuộc gọi, kiểm soát cài đặt chấp thuận, và sao chép đường dẫn từ tab cuộc gọi để chia sẻ một cách nhanh chóng.",
    "WhatsNew__v7.31--0":"Now you can quickly download every photo in an album when you receive a message with multiple attachments. But if you were a gallery curator with impeccable taste in another life, you can still save them individually too. Thanks, <linkMajorMayer>@major-mayer</linkMajorMayer>!"
  }
  