import store, { CallingActions } from "redux-store";
import { useEffect, useRef } from "react";
import { AppConstant, SystemConstant } from "const";
import { useCallingActions } from "./ring-rtc/state/ducks/calling";
import { useSelector } from "react-redux";
import { useSendMessage } from 'hooks'; // Adjust the import path as necessary
import { setRedisChannel, subscribeToRedisTopic } from "./services/redisService";
import { uuid  } from "utils";
import PubSubFactory from "pubsub/PubSubFactory";
const callingUtil = window.electronLibs.libs.callingUtil;

const HandleRingEvents = () => {
  const { refreshIODevices, receiveIncomingDirectCall, callStateChange, remoteVideoChange, outgoingCall, remoteSharingScreenChange, hangUpActiveCall } = useCallingActions();
  const { onSendMessage } = useSendMessage();
  const callingGroupDetailRing = useSelector((state) => state.callingRedux.callingGroupDetailRing || {});
  const isInAnotherCall = useSelector(state => state.callingRedux.isInAnotherCall);
  const isCalleeInAnotherCall = useSelector(state => state.callingRedux.isCalleeInAnotherCall);

  const callingGroupDetailRef = useRef(callingGroupDetailRing);
  const isInAnotherCallRef = useRef(isInAnotherCall);
  const isCalleeInAnotherCallRef = useRef(isCalleeInAnotherCall);
  useEffect(() => {
    callingGroupDetailRef.current = callingGroupDetailRing;
    isInAnotherCallRef.current = isInAnotherCall;
    isCalleeInAnotherCallRef.current = isCalleeInAnotherCall;
  }, [callingGroupDetailRing, isInAnotherCall, isCalleeInAnotherCall]);

  const onHandRefreshIODevices = (payload) => {
    refreshIODevices(payload);
  };

  useEffect(() => {
    const startDirectCall = async (payload) => {
      const _roomId = uuid();
      callingUtil.joinRoomByCaller()
      const msgSourceId = payload.msgSrcId
      try {
        await setRedisChannel(_roomId);
        await subscribeToRedisTopic(() => {});
      } catch (error) {
        console.error("Error in handleStartDirectCall:", error);
      }
      const msgContent = JSON.stringify({
        room_id: _roomId,
      });
      
      if(isInAnotherCallRef.current) {
        setTimeout(() => {
          hangUpActiveCall("You are in another call");
        }, 5000);
        return;
      }

      onSendMessage({
        sendType: payload?.isVideoCall? SystemConstant.SEND_TYPE.personalVideoCall : SystemConstant.SEND_TYPE.personalCall,
        content: msgContent,
        parentId: null,
        threadId: null,
        callStatus: SystemConstant.MESSAGE_CALL_STATUS.waiting,
        roomId: _roomId,
        callingGroupDetail: callingGroupDetailRef.current,
        isReceiver: false,
        sourceId: msgSourceId,
        prefixKey: payload.prefixKey,
      });

      if(isCalleeInAnotherCallRef.current) {
        setTimeout(() => {
          hangUpActiveCall("Callee are in another call");
        }, 5000);
        return;
      }
    };
    const acceptDirectCall = async (payload) => {
      const msgContent = JSON.stringify({
        room_id: payload.roomId,
        type: "answer",
        aes_key: "",
      });

      onSendMessage({
        sendType: payload?.isVideoCall? SystemConstant.SEND_TYPE.personalVideoCall : SystemConstant.SEND_TYPE.personalCall,
        parentId: payload.msgSrcId,
        threadId: null,
        callStatus: SystemConstant.MESSAGE_CALL_STATUS.accept,
        content: msgContent,
        roomId: payload.roomId,
        callingGroupDetail: callingGroupDetailRef.current,
        prefixKey: payload.prefixKey,
        isReceiver: true,
      });
    }; 
    const declineDirectCall = async (payload) => {
      let endContent = {
        room_id: payload.roomId,
      };

      onSendMessage({
        sendType: payload?.isVideoCall? SystemConstant.SEND_TYPE.personalVideoCall : SystemConstant.SEND_TYPE.personalCall,
        content: JSON.stringify(endContent),
        parentId: payload.msgSrcId,
        threadId: null,
        callStatus: SystemConstant.MESSAGE_CALL_STATUS.reject,
        roomId: payload.roomId,
        callingGroupDetail: callingGroupDetailRef.current,
        prefixKey: payload.prefixKey,
        isReceiver: true
      });
    };
    const hangupDirectCall = async (payload) => {
      let endContent = {
        room_id: payload.roomId,
      };
      if(isInAnotherCallRef.current) return;
      onSendMessage({
        sendType: payload?.isVideoCall? SystemConstant.SEND_TYPE.personalVideoCall : SystemConstant.SEND_TYPE.personalCall,
        content: JSON.stringify(endContent),
        parentId: payload.msgSrcId,
        threadId: null,
        callStatus: payload?.isRinging? SystemConstant.MESSAGE_CALL_STATUS.missed :
          SystemConstant.MESSAGE_CALL_STATUS.end,
        roomId: payload.roomId,
        callingGroupDetail: callingGroupDetailRef.current,
        prefixKey: payload.prefixKey,
        isReceiver: true
      });
    };

    const handleRefreshIODevices = (_, payload) => onHandRefreshIODevices(payload);
    const handleStartDirectCall = (_, payload) => startDirectCall(payload);
    const handleAcceptDirectCall = (_, payload) => {console.log("acceptDirectCall", payload); acceptDirectCall(payload)};
    const handleDeclineDirectCall = (_, payload) => {console.log("declineDirectCall", payload); declineDirectCall(payload)};
    const handleHangupDirectCall = (_, payload) => {console.log("hangupDirectCall", payload); hangupDirectCall(payload)};
    const handleReceiveIncomingDirectCall = (_, payload) => receiveIncomingDirectCall(payload);
    const handleCallStateChange = (_, payload) => {console.log("callStateChange", payload); callStateChange(payload)};
    const handleRemoteVideoChange = (_, payload) => {console.log("remoteVideoChange", payload); remoteVideoChange(payload);};
    const handleOutgoingCall = (_, payload) => {console.log("outgoingCall", payload); outgoingCall(payload);};
    const handleRemoteSharingScreenChange = (_, payload) => {console.log("remoteSharingScreenChange", payload); remoteSharingScreenChange(payload);};
    const fetchEvents = (_, payload) => {
      console.log("fetchEvents", payload);
      PubSubFactory.getEvent(payload?.prefixKey, payload?.domain).fetchNewEvent();
    }

    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.refreshIODevices, handleRefreshIODevices);
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.handleStartDirectCall, handleStartDirectCall);
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.handleAcceptDirectCall, handleAcceptDirectCall);
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.handleDeclineDirectCall, handleDeclineDirectCall);
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.handleHangupDirectCall, handleHangupDirectCall);
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.receiveIncomingDirectCall, handleReceiveIncomingDirectCall);
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.callStateChange, handleCallStateChange);
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.remoteVideoChange, handleRemoteVideoChange);
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.outgoingCall, handleOutgoingCall);
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.remoteSharingScreenChange, handleRemoteSharingScreenChange);
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.resetCallingGroupDetailRing, handleResetCallingGroupDetailRing);
    window.electronEvent.addListener(AppConstant.TRIOS_EMIT_EVENT_KEY.handleFetchEvents, fetchEvents);
    
    return () => {
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.refreshIODevices, handleRefreshIODevices);
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.handleStartDirectCall, handleStartDirectCall);
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.handleAcceptDirectCall, handleAcceptDirectCall);
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.handleDeclineDirectCall, handleDeclineDirectCall);
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.handleHangupDirectCall, handleHangupDirectCall);
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.receiveIncomingDirectCall, handleReceiveIncomingDirectCall);
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.callStateChange, handleCallStateChange);
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.remoteVideoChange, handleRemoteVideoChange);
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.outgoingCall, handleOutgoingCall);
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.remoteSharingScreenChange, handleRemoteSharingScreenChange);
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.resetCallingGroupDetailRing, handleResetCallingGroupDetailRing);
      window.removeEventListener(AppConstant.TRIOS_EMIT_EVENT_KEY.handleFetchEvents, fetchEvents);
    };
  }, []);
  return null;
};

export default HandleRingEvents;


const handleResetCallingGroupDetailRing = (_) => {
  console.log("handleResetCallingGroupDetailRing");
  store.dispatch(
    CallingActions.callingSet({
      callingGroupDetailRing: null,
      callingGroupDetail: null,
    }),
  );
  store.dispatch(
    CallingActions.callingReset(),
  ); 
};
