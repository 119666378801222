import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KeyConstant, PathConstant } from "const";
import { BranchActions, BranchSelectors, ConversationActions } from "redux-store";
import { StorageUtil } from "utils";
import { useHistory } from "react-router-dom";
import { isLoginBranch } from "utils/auth.utils";
import { changeBranchServer, getSavedServer } from "utils/branch.utils";

const useServerMode = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedServerRedux = useSelector(BranchSelectors.getSelectedBranch);

  const [selectedBranch, setSelectedBranch] = useState({});

  const switchServer = selectedServer => {
    if (isLoginBranch(selectedServer)) {
      setSelectedBranch(selectedServer);
      changeBranchServer(selectedServer);
      history.replace(PathConstant.ROOT);
    } else {
      StorageUtil.setCommonKey(KeyConstant.KEY_TMP_BRANCH, selectedServer);
      dispatch(
        BranchActions.branchSet({
          loginBranch: selectedServer,
        }),
      );

      dispatch(
        ConversationActions.conversationSet({
          threadingId: null,
          selectedGroupId: null,
        }),
      );

      history.push(PathConstant.LOGIN);
    }
  };

  useEffect(() => {
    const storedServer = getSavedServer();
    const newServerMode = selectedServerRedux || storedServer;

    if (!selectedServerRedux?.id && newServerMode.id) {
      dispatch(BranchActions.updateSelectedBranch(newServerMode));
      return;
    }

    if (
      (newServerMode.id && newServerMode.id !== selectedBranch.id) ||
      Object.keys(newServerMode).length !== Object.keys(selectedBranch).length
    ) {
      setSelectedBranch(newServerMode);
    }
  }, [selectedServerRedux]);

  return {
    selectedBranch,
    switchServer,
  };
};

export default useServerMode;
