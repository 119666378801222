import React, { memo, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Clear, Done, PushPin } from "@mui/icons-material";
import { Box, IconButton, Input, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import { deepCloneJsonObject, isOnlyLink, StorageUtil } from "utils";
import LinkPreview from "./LinkPreview";
import { saveMessageInQueue, useConversationContext } from "../ConversationContext";
import ChatTypography from "./ChatTypography";
import { replaceId2Name, replaceName2Id } from "utils/message.utils";
import { getCreatedTimeText, useMessageStyle } from "./ChatItem.helper";
import FullEmojiLayout from "../MessengerChatInput/FullEmojiLayout";
import { useInputEvent } from "hooks";
import { AppAvatar } from "components";
import { SCROLL_TRIGGER_CLASS } from "../useQueryMessage";
import { isGroupOrChannelType } from "utils/view.utils";
import { SystemConstant } from "const";

const MessengerPeople = ({ message, isMine, isEditing, setIsEditing }) => {
  const hasExternalLink = isOnlyLink(message.content);
  const isAvatar = Boolean(message.isAvatar);

  return (
    <>
      {isMine ? (
        <MyMessage
          message={message}
          hasExternalLink={hasExternalLink}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      ) : (
        <OtherMessage message={message} hasExternalLink={hasExternalLink} isAvatar={isAvatar} />
      )}
    </>
  );
};

export default memo(MessengerPeople);

export const MyMessage = ({ message, isEditing, hasExternalLink, setIsEditing, customStyles }) => {
  const classes = useStyles();
  const messengerClasses = useMessageStyle();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { groupDetail } = useConversationContext();

  const [modifiedMessage, setModifiedMessage] = useState();
  const [defaultContent, setDefaultContent] = useState();
  const [messageContent, setMessageContent] = useState();

  const handleCancel = () => setIsEditing(false);

  const handleEditMessage = async content => {
    if (content !== defaultContent) {
      const mentionList = deepCloneJsonObject(groupDetail.groupMembers);
      if (isGroupOrChannelType(groupDetail.groupType)) mentionList.push(SystemConstant.MENTION_ALL);

      await saveMessageInQueue({
        prefixKey,
        groupId: message.groupId,
        threadId: message.threadId,
        content: replaceName2Id(content, mentionList),
        parentId: message.sourceId,
        mentionIds: message.mentions,
      });

      setDefaultContent(content);
      setMessageContent(content);
      setModifiedMessage(Date.now());
    }
    setIsEditing(false);
  };

  useEffect(() => {
    if (modifiedMessage && modifiedMessage > message.modified) return;

    let isMounted = true;

    replaceId2Name(message.content, groupDetail.groupMembers, false).then(displayContent => {
      if (isMounted) {
        setDefaultContent(displayContent);
        setMessageContent(displayContent);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [message]);

  return (
    <Stack direction="row">
      {message.isPin && <PushPin className={classes.pinIcon} />}
      <Stack
        direction="column"
        className={clsx(
          messengerClasses.messengerRoot,
          messengerClasses.myMessage,
          {
            [classes.previewLink]: hasExternalLink,
          },
          customStyles,
          SCROLL_TRIGGER_CLASS,
        )}
      >
        {isEditing ? (
          <EditMessageContent
            classes={classes}
            defaultContent={defaultContent}
            onSubmit={handleEditMessage}
            onCancel={handleCancel}
          />
        ) : (
          <>
            <ChatTypography
              messageContent={message.content}
              className={clsx(messengerClasses.spacing, "preview-chat")}
              messageId={message.id}
            />

            {hasExternalLink && (
              <LinkPreview
                message={{
                  ...message,
                  content: messageContent,
                }}
              />
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export const OtherMessage = ({ message, hasExternalLink, isAvatar }) => {
  const classes = useStyles();
  const messengerClasses = useMessageStyle();
  const msgCreatedTime = getCreatedTimeText(message);
  const { groupDetail } = useConversationContext();

  return (
    <Stack direction="row" spacing={2}>
      <AppAvatar
        avatarId={message.avatarId}
        size={40}
        className={clsx(!isAvatar && messengerClasses.hiddenAvatar)}
        isServerLogo={groupDetail.isBot}
      />

      <Box>
        {msgCreatedTime && (
          <Typography className={clsx("ellipsis", messengerClasses.createdTimeText, "preview-time-text")}>
            {msgCreatedTime}
          </Typography>
        )}

        <Stack direction="row">
          <Stack
            direction="column"
            className={clsx(messengerClasses.messengerRoot, SCROLL_TRIGGER_CLASS, {
              [classes.previewLink]: hasExternalLink,
            })}
          >
            <ChatTypography
              messageContent={message.content}
              mentions={message.mentions}
              className={clsx(messengerClasses.spacing, "preview-chat")}
              messageId={message.id}
            />

            {hasExternalLink && <LinkPreview message={message} />}
          </Stack>
          {message.isPin && <PushPin className={classes.pinIcon} />}
        </Stack>
      </Box>
    </Stack>
  );
};

const EditMessageContent = ({ defaultContent, onSubmit, onCancel }) => {
  const classes = useStyles();
  const { inputRef, inputValue, insertContentInput, updateContentInput } = useInputEvent();

  const handleChangeContent = event => updateContentInput(event.target.value);

  const handleUpdateContent = value => insertContentInput(value);

  const handleSubmit = () => onSubmit(inputValue.trim());

  useEffect(() => {
    updateContentInput(defaultContent);
  }, []);

  return (
    <Box className={classes.editMessageRoot}>
      <Input
        classes={{
          root: classes.editMessageInputRoot,
          input: classes.editMessageInput,
        }}
        inputRef={inputRef}
        onChange={handleChangeContent}
        disableUnderline
        multiline
      />

      <Box className={classes.editActionBox}>
        <FullEmojiLayout onSelect={handleUpdateContent} />
        <Box>
          <IconButton onClick={onCancel}>
            <Clear />
          </IconButton>
          <IconButton color="primary" onClick={handleSubmit} disabled={inputValue === defaultContent}>
            <Done />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  previewLink: {
    width: 300,
    maxWidth: "100%",
  },

  editMessageRoot: {
    backgroundColor: "#F3F3F5",
  },

  editMessageInputRoot: {
    width: "100%",
    padding: "16px 30px",
  },

  editMessageInput: {
    width: 520,
    maxWidth: "100%",
  },

  editActionBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    paddingTop: 0,
  },

  pinIcon: {
    fontSize: 14,
    transform: "rotate(45deg)",
    color: theme.palette.grey[500],
  },
}));
