import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { PrimaryTab, SearchBar, TabPanel, InfiniteScroll } from "components";
import DataListByLetter from "../../AddingContact/DataListByLetter";
import AddingContact from "../../AddingContact";
import { useAccountList, useContactList, useLazyEffect } from "hooks";
import { getFirstLetter, StorageUtil } from "utils";
import { getSearchResult } from "utils/view.utils";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import { Sync } from "@mui/icons-material";
import { remoteApiFactory } from "services";
import { formatPagingParams } from "sagas/saga.helper";
import { getCommonLang } from "utils/lang.utils";
import { useAlertContext } from "components/context/AlertContext";
import { getRemoteBranchAccount } from "pubsub/services/branch.service";

const ContactListTab = ({ onClick }) => {
  const classes = useStyles();
  const searchRef = useRef();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const { contactList, getAllContactByName } = useContactList();
  const { accountList, getAccountList } = useAccountList();
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const isBranchServer = selectedBranch.type === SystemConstant.SERVER_TYPE.branch;
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const [selectedTab, setSelectedTab] = useState(CHAT_TAB_INDEX.personal);
  const [orderContactByName, setOrderContactByName] = useState({}); // Group contact
  const [filterGroupList, setFilterGroupList] = useState([]);

  const contactTabArray = [
    {
      label: getLabel(isBranchServer ? LangConstant.TXT_MEMBERS : LangConstant.TXT_MY_CONTACT),
    },

    {
      label: getLabel(LangConstant.TXT_GROUP),
    },
  ];

  const onChangeSearchInput = content => {
    searchRef.current = content;
    let viewList = [];

    if (selectedTab === CHAT_TAB_INDEX.personal) {
      // if user is searching and branch server, using account list to filter
      if (isBranchServer && content) {
        viewList = getSearchResult(content, accountList || [], ["name", "phone"]);
      } else {
        viewList = getSearchResult(content, contactList || [], ["name", "phone"]);
      }

      setOrderContactByName(groupDataByLetter(viewList, "name"));
    } else if (selectedTab === CHAT_TAB_INDEX.group) {
      getInteractor(prefixKey)
        .LocalGroupService.searchGroupByCondition({
          keyword: content,
          group_type: SystemConstant.GROUP_CHAT_TYPE.group,
          account_id: accountId,
          branch_id: branchId,
        })
        .then(sortListGroup => setFilterGroupList(groupDataByLetter(sortListGroup, "groupName")));
    }
  };

  const handleSynchSuccess = () => {
    isBranchServer ? getAccountList() : getAllContactByName();
  };

  useLazyEffect(() => {
    onChangeSearchInput(searchRef.current);
  }, [accountList, contactList, isBranchServer, selectedTab]);

  useEffect(() => {
    setOrderContactByName({});
    setFilterGroupList([]);
    searchRef.current = "";
    onChangeSearchInput("");
  }, [selectedBranch]);

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.fixedHeader}>
          <PrimaryTab
            tabArray={contactTabArray}
            onChange={setSelectedTab}
            TabIndicatorProps={{
              className: "hidden",
            }}
            selectedTabIndex={selectedTab}
            classes={{
              flexContainer: classes.flexContainer,
              tabButton: classes.tabButton,
              tabLabel: clsx(classes.tabLabel, "bold-sm-txt"),
              tabLabelSelected: classes.tabLabelSelected,
              tabButtonSelected: classes.tabButtonSelected,
            }}
          />

          <Stack direction="row">
            <SearchBar
              value={searchRef.current}
              onChange={onChangeSearchInput}
              classes={{
                root: classes.searchBarRoot,
              }}
            />

            <SyncContactIcon isBranchServer={isBranchServer} onSuccess={handleSynchSuccess} />
          </Stack>
        </Box>

        <TabPanel value={selectedTab} index={CHAT_TAB_INDEX.personal}>
          <InfiniteScroll className={classes.contactListBox}>
            {Object.keys(orderContactByName).map((firstLetter, index) => (
              <DataListByLetter
                key={index}
                dataList={orderContactByName[firstLetter]}
                letter={firstLetter}
                onItemClick={onClick}
              />
            ))}
          </InfiniteScroll>
        </TabPanel>

        <TabPanel value={selectedTab} index={CHAT_TAB_INDEX.group}>
          <InfiniteScroll className={classes.contactListBox}>
            {Object.keys(filterGroupList).map((firstLetter, index) => (
              <DataListByLetter
                key={index}
                dataList={filterGroupList[firstLetter]}
                letter={firstLetter}
                onItemClick={onClick}
                isGroup={true}
              />
            ))}
          </InfiniteScroll>
        </TabPanel>
      </Box>

      <AddingContact isGroupTab={selectedTab === CHAT_TAB_INDEX.group} />
    </>
  );
};

ContactListTab.propTypes = {
  onClick: PropTypes.func,
};

ContactListTab.defaultProps = {
  onClick: () => {},
};

const CHAT_TAB_INDEX = {
  personal: 0,
  group: 1,
};

export default memo(ContactListTab);

const SyncContactIcon = ({ isBranchServer, onSuccess }) => {
  const { showAlert } = useAlertContext();

  const [isFetching, setIsFetching] = useState(false);

  const handleSynchContact = async () => {
    setIsFetching(true);
    let isSuccessContact = false;
    let isSuccessAccount = !isBranchServer;
    const prefixKey = StorageUtil.getCurrentPrefixKey();

    // Force to synch contact
    try {
      const contactResponse = await remoteApiFactory
        .getBranchApi(prefixKey)
        .getContactList(formatPagingParams({ limit: 10000 }));

      if (contactResponse.status === ApiConstant.STT_OK && Array.isArray(contactResponse.data?.data)) {
        const responseData = contactResponse.data.data;
        await getInteractor(prefixKey).LocalContactService.save(responseData);
        isSuccessContact = true;
      }
    } catch (error) {
      console.log("Synch contact", error);
    }

    // Force to synch account
    if (isBranchServer) {
      try {
        const accountResponse = await getRemoteBranchAccount(prefixKey);
        if (Array.isArray(accountResponse)) {
          isSuccessAccount = true;
        }
      } catch (error) {
        console.log("Synch contact", error);
      }
    }

    if (isSuccessContact && isSuccessAccount) {
      if (onSuccess) onSuccess();
      showAlert({ content: getCommonLang("SYNCH_CONTACT_SUCCESS") });
    } else {
      showAlert({ content: getCommonLang("SYNCH_CONTACT_FAILED"), severity: "error" });
    }
    setIsFetching(false);
  };

  return (
    <IconButton color="primary" sx={{ mx: 0.5 }} onClick={handleSynchContact} disabled={isFetching}>
      <Sync />
    </IconButton>
  );
};

const groupDataByLetter = (dataList, groupField) => {
  if (!dataList || dataList.length === 0) return [];

  const handleContactsObj = dataList.reduce((newContactList, contact) => {
    const firstLetter = contact[groupField] ? getFirstLetter(contact[groupField]).toUpperCase() : "#";
    newContactList[firstLetter] = (newContactList[firstLetter] || []).concat(contact);

    return newContactList;
  }, {});

  return handleContactsObj;
};

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },

  flexContainer: {
    padding: "12px 14px",
  },

  tabButton: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    height: 30,
    minHeight: "unset",
    borderRadius: 15.5,
  },

  tabLabel: {
    marginTop: 0,
    color: "#7F7F7F",
  },

  tabLabelSelected: {
    color: "#1E272E",
  },

  tabButtonSelected: {
    backgroundColor: theme.palette.grey[100],
  },

  fixedHeader: {
    position: "sticky",
  },

  searchBarRoot: {
    marginLeft: 14,
    maxWidth: "calc(100% - 28px)",
  },

  contactListBox: {
    maxHeight: "calc(100vh - 330px)",
    overflow: "auto",
    padding: "16px 14px",
  },
}));
