import { KeyConstant } from "const";
import { StorageUtil, textNormalize } from "utils";

export const getFilterArray = (memberArray, search) => {
  let filterItems = [];
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  if (memberArray && memberArray.length > 0) {
    filterItems = memberArray.filter(
      member => member.id !== accountId && textNormalize(member.name).includes(textNormalize(search)),
    );
  }
  return filterItems;
};

export const getWordAtCursor = (text, cursorPosition) => {
  // Find the start of the current word
  let start = cursorPosition;
  while (start > 0 && /\S/.test(text[start - 1])) {
    start--;
  }

  // Find the end of the current word
  let end = cursorPosition;
  while (end < text.length && /\S/.test(text[end])) {
    end++;
  }

  const wordAtCursor = text.substring(start, end);
  return wordAtCursor;
};

export const replaceByMentionName = (strContent = "", mentionName) => {
  const isValid = strContent && strContent.trim().length > 1;
  if (!isValid) return mentionName;

  const arrContent = strContent.split(" "); // Split by space
  const lastWord = arrContent[arrContent.length - 1];
  arrContent.pop(); // Remove last word that is replaced by mentionName

  let hasSymbol = false;
  if (lastWord) {
    const startMentionIndex = lastWord.lastIndexOf("@");

    if (startMentionIndex >= 0) {
      arrContent.push(lastWord.slice(0, startMentionIndex) + mentionName);
      hasSymbol = true;
    }
  }

  // Default replace by mentionName
  if (!hasSymbol) {
    arrContent.push(mentionName);
  }

  return arrContent.join(" ");
};
