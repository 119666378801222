import { PersonAddAltOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { formatSentTime } from "utils/date.utils";
import { getNSLang } from "utils/lang.utils";
import { useStyles } from "./NotificationInviteItem";
import { useDispatch } from "react-redux";
import { SystemActions } from "redux-store";
import { StorageUtil } from "utils";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

const NotificationBranchReqItem = ({ data, ...otherProps }) => {
  const classes = useStyles();
  const branchReqStyles = useBranchReqStyles();
  const dispatch = useDispatch();

  const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO);

  const handleSelectNotice = () => {
    dispatch(
      SystemActions.systemSet({
        requestNotice: data.requestNotice,
      }),
    );
  };

  const isAccepted = data.state === SystemConstant.BRANCH_REQ_NOTICE_STATE.active;
  const isRejected = data.state === SystemConstant.BRANCH_REQ_NOTICE_STATE.reject;

  return (
    <Box
      className={clsx(classes.mentionNotificationItemRoot, (isAccepted || isRejected) && branchReqStyles.disabled)}
      onClick={handleSelectNotice}
      {...otherProps}
    >
      <Box className={classes.detailContainer}>
        <Box className={classes.avatarBox}>
          <Avatar className={classes.iconImage} sx={{ color: "#000000" }}>
            <PersonAddAltOutlined />
          </Avatar>
        </Box>
        <Box className={classes.notificationContentBox}>
          <Typography className={clsx(classes.messageContent)} fontWeight={700} fontSize="0.875rem">
            {getNSLang(LangConstant.NS_HOME, "FM_REQUEST_FROM", {
              serverName: branchInfo.name,
              accountName: data.requestNotice.name,
            })}
          </Typography>
          <Typography className={classes.createdTimeNotification}>
            {isAccepted &&
              getNSLang(LangConstant.NS_HOME, "FM_ACCEPTED_REQUEST", { accountName: data.requestNotice.name })}
            {isRejected &&
              getNSLang(LangConstant.NS_HOME, "FM_REJECTED_REQUEST", { accountName: data.requestNotice.name })}
          </Typography>
          <Typography className={classes.createdTimeNotification}>{formatSentTime(data.created)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationBranchReqItem;

const useBranchReqStyles = makeStyles({
  disabled: {
    pointerEvents: "none",
    cursor: "default",
  },
});
