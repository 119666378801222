// Copyright 2020 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import type { DirectIncomingCall } from "../../components/CallManager";
import { CallManager } from "../../components/CallManager";
import { isConversationTooBigToRing as getIsConversationTooBigToRing } from "../../conversations/isConversationTooBigToRing";
// import * as log from "../../../../public/ring-rtc/logging/log";
import { FALLBACK_NOTIFICATION_TITLE, NotificationSetting, NotificationType } from "../../services/notifications";
// import { bounceAppIconStart, bounceAppIconStop } from "../../shims/bounceAppIcon";
import type {
  ActiveCallBaseType,
  ActiveCallType,
  ActiveDirectCallType,
  CallingConversationType,
} from "../../types/Calling";
import { CallState } from "../../types/Calling";
import { CallMode } from "../../types/CallDisposition";
// import { strictAssert } from "../../util/assert";
// import { callingTones } from "../../util/callingTones";
// import { missingCaseError } from "../../util/missingCaseError";
import { useAudioPlayerActions } from "../ducks/audioPlayer";
import { getActiveCall, useCallingActions } from "../ducks/calling";
import type { StateType } from "../reducer";
import { getHasInitialLoadCompleted } from "../selectors/app";
import { getActiveCallState, getAvailableCameras, getIncomingCall } from "../selectors/calling";
import { getConversationSelector, getMe } from "../selectors/conversations";
import { getIntl } from "../selectors/user";
import { SmartCallingDeviceSelection } from "./CallingDeviceSelection";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import type { LocalizerType } from "../../types/Util";

// import { isSharingPhoneNumberWithEverybody as getIsSharingPhoneNumberWithEverybody } from "../../util/phoneNumberSharingMode";
const log = window.electronLibs.libs.log;
const strictAssert = window.electronLibs.libs.strictAssert;
const callingTones = window.electronLibs.libs.callingTones;
const missingCaseError = window.electronLibs.libs.missingCaseError;
const getIsSharingPhoneNumberWithEverybody = window.electronLibs.libs.isSharingPhoneNumberWithEverybody;
const bounceAppIconStart = window.electronLibs.libs.shims.bounceAppIconStart;
const bounceAppIconStop = window.electronLibs.libs.shims.bounceAppIconStop;
const notificationService = window.electronLibs.libs.notificationUtil;
function renderDeviceSelection(): JSX.Element {
  return <SmartCallingDeviceSelection />;
}

async function notifyForCall(
  conversationId: string,
  title: string,
  isVideoCall: boolean,
  getLabel: any,
): Promise<void> {
  // Todo
  // const shouldNotify =
  //   !window.SignalContext.activeWindowService.isActive() && window.Events.getCallSystemNotification();

  const shouldNotify = true;
  if (!shouldNotify) {
    return;
  }

  let notificationTitle = title;

  // const notificationSetting = notificationService.getNotificationSetting();
  // switch (notificationSetting) {
  //   case NotificationSetting.Off:
  //   case NotificationSetting.NoNameOrMessage:
  //     notificationTitle = FALLBACK_NOTIFICATION_TITLE;
  //     break;
  //   case NotificationSetting.NameOnly:
  //   case NotificationSetting.NameAndMessage:
  //     notificationTitle = title;
  //     break;
  //   default:
  //     log.error(missingCaseError(notificationSetting));
  //     notificationTitle = FALLBACK_NOTIFICATION_TITLE;
  //     break;
  // }
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const conversation = await getInteractor(prefixKey).LocalGroupService.get(conversationId);
  strictAssert(conversation, "notifyForCall: conversation not found");

  // const { url, absolutePath } = await conversation.getAvatarOrIdenticon();

  notificationService.notify({
    conversationId,
    notificationTitle,
    // iconPath: absolutePath,
    // iconUrl: url,
    message: isVideoCall ? getLabel("incomingVideoCall") : getLabel("incomingAudioCall"),
    sentAt: 0,
    // The ringtone plays so we don't need sound for the notification
    silent: true,
    type: NotificationType.IncomingCall,
  });
}

export const mapStateToActiveCallProp = (state: StateType): undefined | ActiveCallType => {
  const { calling } = state;
  const activeCallState = getActiveCallState(state);

  if (!activeCallState) {
    return undefined;
  }

  const call = getActiveCall(calling);
  if (!call) {
    log.error("There was an active call state but no corresponding call");
    return undefined;
  }

  const conversationSelector = getConversationSelector(state);
  let conversation: CallingConversationType;
  conversation = conversationSelector(activeCallState.conversationId);
  if (!conversation) {
    log.error("The active call has no corresponding conversation");
    return undefined;
  }

  const baseResult: ActiveCallBaseType = {
    conversation,
    hasLocalAudio: activeCallState.hasLocalAudio,
    hasLocalVideo: activeCallState.hasLocalVideo,
    localAudioLevel: activeCallState.localAudioLevel,
    viewMode: activeCallState.viewMode,
    viewModeBeforePresentation: activeCallState.viewModeBeforePresentation,
    joinedAt: activeCallState.joinedAt,
    outgoingRing: activeCallState.outgoingRing,
    pip: activeCallState.pip,
    presentingSource: activeCallState.presentingSource,
    presentingSourcesAvailable: activeCallState.presentingSourcesAvailable,
    settingsDialogOpen: activeCallState.settingsDialogOpen,
    showNeedsScreenRecordingPermissionsWarning: Boolean(activeCallState.showNeedsScreenRecordingPermissionsWarning),
    showParticipantsList: activeCallState.showParticipantsList,
    reactions: activeCallState.reactions,
  };

  switch (call.callMode) {
    case CallMode.Direct:
      if (call.isIncoming && (call.callState === CallState.Prering || call.callState === CallState.Ringing)) {
        // return;
      }

      return {
        ...baseResult,
        callEndedReason: call.callEndedReason,
        callMode: CallMode.Direct,
        callState: call.callState,
        peekedParticipants: [],
        remoteParticipants: [
          {
            hasRemoteVideo: Boolean(call.hasRemoteVideo),
            presenting: Boolean(call.isSharingScreen),
            title: conversation.title,
            serviceId: conversation.serviceId,
          },
        ],
      };
    default:
      throw missingCaseError(call);
  }
};

export const mapStateToIncomingCallProp = (state: StateType): DirectIncomingCall | null => {
  const call = getIncomingCall(state);
  if (!call) {
    return null;
  }
  const conversation = getConversationSelector(state)(call.conversationId);
  if (!conversation) {
    log.error("The incoming call has no corresponding conversation");
    return null;
  }

  switch (call.callMode) {
    case CallMode.Direct:
      return {
        callMode: CallMode.Direct as const,
        callState: call.callState,
        callEndedReason: call.callEndedReason,
        conversation: { ...conversation, id: call.conversationId },
        isVideoCall: call.isVideoCall,
      };
    default:
      throw missingCaseError(call);
  }
};

export const SmartCallManager = memo(function SmartCallManager() {
  const { t: getLabel } = useTranslation(LangConstant.NS_RING);
  const i18n: LocalizerType = { t: getLabel };
  const activeCall = useSelector(mapStateToActiveCallProp);
  const incomingCall = useSelector(mapStateToIncomingCallProp);
  const availableCameras = useSelector(getAvailableCameras);
  const hasInitialLoadCompleted = useSelector(getHasInitialLoadCompleted);
  const me = useSelector(getMe);
  const isConversationTooBigToRing = incomingCall ? getIsConversationTooBigToRing(incomingCall.conversation) : false;
  const {
    changeCallView,
    closeNeedPermissionScreen,
    getPresentingSources,
    cancelCall,
    startCall,
    toggleParticipants,
    acceptCall,
    declineCall,
    openSystemPreferencesAction,
    cancelPresenting,
    selectPresentingSource,
    setIsCallActive,
    setLocalAudio,
    setLocalVideo,
    setLocalPreview,
    setOutgoingRing,
    setRendererCanvas,
    switchToPresentationView,
    switchFromPresentationView,
    hangUpActiveCall,
    togglePip,
    toggleScreenRecordingPermissionsDialog,
    toggleSettings,
  } = useCallingActions();
  const { pauseVoiceNotePlayer } = useAudioPlayerActions();
  useEffect(() => {
    console.log("activeCall", activeCall);
  }, [activeCall]);
  useEffect(() => {
    console.log("incomingCall", incomingCall);
  }, [incomingCall]);

  // if (!activeCall)
  //   return (
  //     <h1
  //       onClick={() => {
  //         onOutgoingAudioCallInConversation("1");
  //       }}
  //     >
  //       Hello, World!
  //     </h1>
  //   );

  return (
    <CallManager
      acceptCall={acceptCall}
      activeCall={activeCall}
      availableCameras={availableCameras}
      bounceAppIconStart={bounceAppIconStart}
      bounceAppIconStop={bounceAppIconStop}
      cancelCall={cancelCall}
      cancelPresenting={cancelPresenting}
      changeCallView={changeCallView}
      closeNeedPermissionScreen={closeNeedPermissionScreen}
      declineCall={declineCall}
      getIsSharingPhoneNumberWithEverybody={getIsSharingPhoneNumberWithEverybody}
      getPresentingSources={getPresentingSources}
      hangUpActiveCall={hangUpActiveCall}
      hasInitialLoadCompleted={hasInitialLoadCompleted}
      i18n={i18n}
      incomingCall={incomingCall}
      isConversationTooBigToRing={isConversationTooBigToRing}
      me={me}
      notifyForCall={(conversationId, title, isVideoCall) =>
        notifyForCall(conversationId, title, isVideoCall, getLabel)
      }
      openSystemPreferencesAction={openSystemPreferencesAction}
      pauseVoiceNotePlayer={pauseVoiceNotePlayer}
      playRingtone={() => callingTones.playRingtone()}
      renderDeviceSelection={renderDeviceSelection}
      // renderEmojiPicker={renderEmojiPicker}
      // renderReactionPicker={renderReactionPicker}
      selectPresentingSource={selectPresentingSource}
      setIsCallActive={setIsCallActive}
      setLocalAudio={setLocalAudio}
      setLocalPreview={setLocalPreview}
      setLocalVideo={setLocalVideo}
      setOutgoingRing={setOutgoingRing}
      setRendererCanvas={setRendererCanvas}
      showContactModal={() => {}}
      startCall={startCall}
      stopRingtone={() => callingTones.stopRingtone()}
      switchFromPresentationView={switchFromPresentationView}
      switchToPresentationView={switchToPresentationView}
      toggleParticipants={toggleParticipants}
      togglePip={togglePip}
      toggleScreenRecordingPermissionsDialog={toggleScreenRecordingPermissionsDialog}
      toggleSettings={toggleSettings}
    />
  );
});
