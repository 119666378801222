import { useDispatch, useSelector } from "react-redux";
import { ConversationActions, ConversationSelectors, SystemSelectors } from "redux-store";
import { useLazyEffect } from "hooks";

const HandlingUnreadMessage = () => {
  const dispatch = useDispatch();
  const selectedGroupId = useSelector(ConversationSelectors.getSelectedGroupId);
  const threadingId = useSelector(ConversationSelectors.getThreadingId);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const isFocused = useSelector(SystemSelectors.isFocused);
  const isShowMnRequest = useSelector(SystemSelectors.isShowMnRequest);

  useLazyEffect(() => {
    if (isFocused && (selectedGroupId || threadingId) && false === isShowMnRequest) {
      dispatch(ConversationActions.markReadMessageInGroup(selectedGroupId, threadingId));
    }
  }, [isUpdateViewMode, selectedGroupId, threadingId, isFocused, isShowMnRequest]);

  return null;
};

export default HandlingUnreadMessage;
