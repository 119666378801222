import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyConstant, LangConstant } from "const";
import { AvatarInfo } from "components";
import { useCreateConversation } from "hooks";
import { StorageUtil } from "utils";
import { getSavedServer } from "utils/branch.utils";
import { VerifiedIcon } from "components/icons";

const ContactSearchPopup = ({ data, keyword, onClick }) => {
  const classes = useStyles();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const { createWithPersonal } = useCreateConversation();
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);

  const onCreateNewHistoryKeyword = () => {
    const savedServer = getSavedServer();
    if (keyword) {
      let historyKeywords = StorageUtil.getItem(KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS) || {};
      let historyKeywordsInServer = historyKeywords[savedServer?.id] || [];
      historyKeywordsInServer.unshift(keyword);
      StorageUtil.setItem(
        KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS,
        Object.assign(historyKeywords, { [savedServer?.id]: historyKeywordsInServer }),
      );
    }
  };

  const onClickSearchItem = contact => {
    onCreateNewHistoryKeyword();

    createWithPersonal(contact);
    onClick();
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.heading}>
        <Typography className={clsx(classes.title, "semiBold-sm-txt")}>
          {getLabel(LangConstant.TXT_CONTACT_FOUND)}
        </Typography>
      </Box>
      <Box className={classes.scrollBox}>
        <Box className={classes.contactWrapper}>
          {data.map(contact => (
            <Box key={contact.id} className={classes.contactItem} onClick={() => onClickSearchItem(contact)}>
              <AvatarInfo
                secondTitle={contact.phone}
                title={contact.contactName || contact.name || contact.phone || "No name"}
                avatar={{ avatarId: contact.avatarId, name: contact.name, isServerLogo: contact.isBot }}
                button={true}
                sendToMe={contact.id === accountId || contact.contactId === accountId}
                BadgeIcon={contact.isBot && <VerifiedIcon />}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ContactSearchPopup);

ContactSearchPopup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      avatarId: PropTypes.string,
      name: PropTypes.string,
    }),
  ),

  keyword: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 12px",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.white,
    zIndex: 1,
  },

  title: {
    color: "#7F7F80",
    lineHeight: "1rem",
  },

  contactWrapper: {
    padding: "0 0px",
  },

  contactItem: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },

  avatar: {
    width: 50,
    height: 50,
    marginRight: 12,
  },

  contactName: {
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    color: theme.palette.black,
    paddingRight: 12,
    width: "100%",
  },
}));
