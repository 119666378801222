// Copyright 2020 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import { createSelector } from "reselect";

import type { StateType } from "../reducer";
import type {
  CallingStateType,
  CallsByConversationType,
  DirectCallStateType,
  ActiveCallStateType,
} from "../ducks/calling";
import { getIncomingCall as getIncomingCallHelper } from "../ducks/callingHelpers";
import { getUserACI } from "./user";
// import { getOwn } from "../../util/getOwn";
import type { AciString } from "../../types/ServiceId";
const getOwn = window.electronLibs.libs.getOwn;

export type CallStateType = DirectCallStateType;

const getCalling = (state: StateType): CallingStateType => state.calling;

export const getAvailableMicrophones = createSelector(getCalling, ({ availableMicrophones }) => availableMicrophones);

export const getSelectedMicrophone = createSelector(getCalling, ({ selectedMicrophone }) => selectedMicrophone);

export const getAvailableSpeakers = createSelector(getCalling, ({ availableSpeakers }) => availableSpeakers);

export const getSelectedSpeaker = createSelector(getCalling, ({ selectedSpeaker }) => selectedSpeaker);

export const getAvailableCameras = createSelector(getCalling, ({ availableCameras }) => availableCameras);

export const getSelectedCamera = createSelector(getCalling, ({ selectedCamera }) => selectedCamera);

export const getActiveCallState = createSelector(getCalling, (state: CallingStateType) => {
  if (state.activeCallState?.state !== "Active") {
    return undefined;
  }

  return state.activeCallState;
});

export const getCallsByConversation = createSelector(
  getCalling,
  (state: CallingStateType): CallsByConversationType => state.callsByConversation,
);

export type CallSelectorType = (conversationId: string) => CallStateType | undefined;
export const getCallSelector = createSelector(
  getCallsByConversation,
  (callsByConversation: CallsByConversationType): CallSelectorType =>
    (conversationId: string) =>
      getOwn(callsByConversation, conversationId),
);

export const getActiveCall = createSelector(
  getActiveCallState,
  getCallSelector,
  (activeCallState, callSelector): undefined | CallStateType => {
    const { callMode, conversationId } = activeCallState || {};
    if (!conversationId) {
      return undefined;
    }

    return callSelector(conversationId);
  },
);

export const isInCall = createSelector(getActiveCall, (call: CallStateType | undefined): boolean => Boolean(call));

export const isInFullScreenCall = createSelector(
  getActiveCallState,
  (activeCallState: undefined | ActiveCallStateType): boolean => Boolean(activeCallState?.pip),
);

export const getIncomingCall = createSelector(
  getCallsByConversation,
  getUserACI,
  (callsByConversation: CallsByConversationType, ourAci: AciString | undefined): undefined | DirectCallStateType => {
    console.log("getIncomingCall", ourAci);
    return getIncomingCallHelper(callsByConversation);
  },
);

export const areAnyCallsActiveOrRinging = createSelector(
  getActiveCall,
  getIncomingCall,
  (activeCall, incomingCall): boolean => Boolean(activeCall || incomingCall),
);
