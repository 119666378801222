import { PersonAddAltOutlined } from "@mui/icons-material";
import {
  ListItemButton as MuiListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar as MuiAvatar,
  Button,
} from "@mui/material";
import { styled } from "@mui/styles";
import { FormatConstant, KeyConstant, LangConstant } from "const";
import { useState } from "react";
import { getNSLang } from "utils/lang.utils";
import RequestFormDialog from "./RequestFormDialog";
import { convertMillisecondsToDate } from "utils/date.utils";
import { StorageUtil } from "utils";

const RequestItem = ({ request, updateRequestList }) => {
  const currentBranchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO) || {};
  const branchName = currentBranchInfo.name;
  const [openForm, setOpenForm] = useState(false);

  const handleOpenRequest = () => {
    setOpenForm(true);
  };

  return (
    <>
      <ListItemButton onClick={handleOpenRequest}>
        <ListItemAvatar>
          <Avatar>
            <PersonAddAltOutlined />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={getNSLang(LangConstant.NS_HOME, "FM_REQUEST_FROM", {
            serverName: branchName,
            accountName: request.name,
          })}
          secondary={convertMillisecondsToDate(request.created, FormatConstant.FM_HH_MM)}
          primaryTypographyProps={{ fontWeight: 600 }}
        />
        <Button variant="text" className="view-btn">
          {getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_VIEW_PIN")}
        </Button>
      </ListItemButton>

      {openForm && (
        <RequestFormDialog
          open
          onClose={() => setOpenForm(false)}
          request={request}
          onUpdateRequest={updateRequestList}
        />
      )}
    </>
  );
};

export default RequestItem;

const ListItemButton = styled(MuiListItemButton)(props => ({
  borderRadius: 5,
  backgroundColor: props.theme.palette.grey[100],

  "& .view-btn": {
    display: "none",
    textTransform: "none",
    textDecoration: "underline",
  },

  "&:hover": {
    backgroundColor: props.theme.palette.grey[200],
    "& .view-btn": {
      display: "block",
    },
  },
}));

const Avatar = styled(MuiAvatar)(props => ({
  color: "#000000",
  backgroundColor: props.theme.palette.grey[300],
}));
