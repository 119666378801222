import { AppConstant } from "const";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContactActions } from "redux-store";
import { createSelector } from "reselect";
import { getInteractor } from "services/local.service";

// Hook to defined all action related contact like: add, update (name), remove

const memoizedReduxState = createSelector([state => state.contactRedux.executeContact], executeContact => {
  return { executeContact };
});
const useManageContact = () => {
  const dispatch = useDispatch();
  const { executeContact } = useSelector(memoizedReduxState);

  const [actionStatus, setActionStatus] = useState(AppConstant.PROCESS_STATUS.nothing);

  const addContact = useCallback(async (phoneNumber, contactName) => {
    if (phoneNumber) {
      setActionStatus(AppConstant.PROCESS_STATUS.calling);

      const contact = await getInteractor().LocalContactService.getContactByPhone(phoneNumber);
      if (contact?.id) {
        updateContact(contact.id, contactName);
      } else {
        dispatch(
          ContactActions.addContact({
            phoneNumber: phoneNumber,
            contactName: contactName,
          }),
        );
      }
    } else {
      setActionStatus(AppConstant.PROCESS_STATUS.error);
    }
  }, []);

  const updateContact = useCallback((contactRecordId, accountName) => {
    if (contactRecordId) {
      setActionStatus(AppConstant.PROCESS_STATUS.calling);
      dispatch(
        ContactActions.updateContact({
          id: contactRecordId,
          contactName: accountName,
        }),
      );
    } else {
      setActionStatus(AppConstant.PROCESS_STATUS.error);
    }
  }, []);

  const removeContact = useCallback(contactRecordId => {
    if (contactRecordId) {
      setActionStatus(AppConstant.PROCESS_STATUS.calling);
      dispatch(ContactActions.removeContact(contactRecordId));
    } else {
      setActionStatus(AppConstant.PROCESS_STATUS.error);
    }
  }, []);

  const resetData = useCallback(() => setActionStatus(AppConstant.PROCESS_STATUS.nothing), []);

  useEffect(() => {
    if (actionStatus === AppConstant.PROCESS_STATUS.calling) {
      if (executeContact?.contactId) {
        setActionStatus(AppConstant.PROCESS_STATUS.success);
      } else {
        setActionStatus(AppConstant.PROCESS_STATUS.error);
      }
    }
  }, [executeContact]);

  return { executeContact, actionStatus, addContact, updateContact, removeContact, resetData };
};

export default useManageContact;
