export default {
  // Text
  TXT_GROUP_CALL: "Cuộc gọi nhóm",
  TXT_PERSONAL_CALL: "Cuộc gọi đơn",
  TXT_CALLING: "Đang thiết lập kết nối bảo mật...",
  TXT_RINGING: "Đang đổ chuông...",
  TXT_YOU: "Bạn",
  TXT_CALL_AGAIN: "Gọi lại",
  TXT_CALL_FINISH: "Cuộc gọi kết thúc",
  TXT_CALL_FAILED: "Cuộc gọi thất bại",
  TXT_MISSED_CALL: "Người nhận không nhấc máy",
  TXT_CALL_REJECTED: "Cuộc gọi bị từ chối",
  TXT_REQUEST_DEVICE_FAIL: "Không tìm thấy thiết bị đầu vào",
  TXT_INTERNET_CONNECTION_FAILED: "Không có kết nối mạng",
  TXT_SETTING: "Cài đặt",
  TXT_DEVICE_NOT_FOUND: "Không tìm thấy thiết bị",
  TXT_INPUT_DEVICE: "Microphone",
  TXT_OUTPUT_DEVICE: "Loa",
  TXT_DONE: "Xong",
  TXT_IN_ANOTHER_CALL: "Bạn đang trong một cuộc gọi khác",
  TXT_RECEIVER_IN_ANOTHER_CALL: "Người nhận đang ở trong một cuộc gọi khác",
  TXT_INCOMING_CALL: "Cuộc gọi đến",
  TXT_INCOMING_VIDEO_CALL: "Cuộc gọi video đến",
  TXT_INCOMING_CALL_DESC: "Cuộc gọi sẽ bắt đầu khi bạn trả lời cuộc gọi",
  TXT_GROUP_ITEM_OUTGOING_CALL: "Cuộc gọi đi",
  TXT_GROUP_ITEM_INCOMING_CALL: "Cuộc gọi đến",
  TXT_GROUP_CALLING: "Cuộc gọi nhóm đang diễn ra",
  TXT_CALL_ENDED: "Cuộc gọi thoại đã kết thúc",
  TXT_CALL_CONFERENCE_ENDED: "Cuộc gọi hội nghị đã kết thúc",
  TXT_VIDEO_CALL_ENDED: "Cuộc gọi video đã kết thúc",
  TXT_JOIN_CALL: "Tham gia",
  TXT_TURN_OFF_MIC: "Tắt âm thanh",
  TXT_MIC_TURNED_OFF: "Đã tắt âm",
  TXT_CONNECTION_LOST: "Mất kết nối",
  TXT_RECONNECTING: "Đang kết nối lại...",
  TXT_FAILED_TO_RECONNECT: "Không thể kết nối lại",
  TXT_START_CAPTURING: "Bắt đầu chia sẻ",
  TXT_SHARE_SCREEN_MESSAGE:
    "Nếu chọn mục này thì camera của bạn sẽ tắt và người tham gia cuộc gọi sẽ nhìn thấy những gì hiển thị trên màn hình của bạn. Hãy lựa chọn chế độ chia sẻ",
  TXT_SHARE_SCREEN_OPTIONS: "Tùy chọn chia sẻ màn hình",
  TXT_SHARE_SCREEN: "Chia sẻ màn hình",
  TXT_SHARE_WINDOW: "Chia sẻ cửa sổ",
  TXT_PIN_USER: "Ghim người dùng",
  TXT_UNPIN: "Bỏ ghim",
  TXT_STOP_SHARING: "Dừng chia sẻ",
  TXT_CURRENTLY_SHARING: "Đang chia sẻ màn hình",
  TXT_SHARING_TITLE: "Tùy chọn chia sẻ màn hình",
  TXT_CURRENTLY_CRIPPLE: "Camera và micro của bạn đang tắt",
  TXT_CONFIRM_TITLE: "Làm người trình bày chính",
  TXT_GROUP_CALL_INIT: "Thông tin cuộc gọi",
  TXT_CALL: "Gọi",
  TXT_CANCEL_CALL: "Hủy",
  TXT_ALL_MEMBERS: "Toàn bộ thành viên",
  TXT_DELETE_ALL_MEMBERS: "Xóa toàn bộ",
  TXT_REMOVE_MEMBER: "Xóa khỏi cuộc gọi",
  TXT_EDIT_CALL_INFO: "Cập nhật",
  TXT_RAISE_HAND: "{name} đang giơ tay phát biểu",
  TXT_USER_IN_CALL_TITLE: "Mọi người",
  TXT_COPY_LINK_CALL: "Sao chép link cuộc gọi",
  TXT_USER_IN_CALL: "Đang trong cuộc gọi ({0})",
  TXT_USER_WAITING: "Đang chờ ({0})",
  TXT_WAITING: "Chờ đồng ý",
  TXT_MEET_OBJ: "Không lấy được thông tin cuộc gọi",

  TXT_VOICE_CALL_OUTGOING: "Cuộc gọi thoại đi",
  TXT_VOICE_CALL_INCOMING: "Cuộc gọi thoại đến",
  TXT_VOICE_CALL_NOT_ANSWER: "Cuộc gọi thoại không được trả lời",
  TXT_VOICE_CALL_MISSED: "Cuộc gọi thoại bị nhỡ	",
  TXT_VIDEO_CALL_OUTGOING: "Cuộc gọi video đi",
  TXT_VIDEO_CALL_INCOMING: "Cuộc gọi video đến",
  TXT_VIDEO_CALL_NOT_ANSWER: "Cuộc gọi video không được trả lời",
  TXT_VIDEO_CALL_MISSED: "Cuộc gọi video bị nhỡ	",

  // Format
  FM_RECENT_CALL: "{{ first }} đã gọi {{ second }}",
  FM_RECENT_VIDEO_CALL: "{{ first }} đã gọi video cho {{ second }}",
  FM_MISSED_CALL: "{{ first }} đã lỡ cuộc gọi từ {{ second }}",
  FM_MISSED_VIDEO_CALL: "{{ first }} đã lỡ cuộc gọi video từ {{ second }}",
  FM_MISSED_CALL_RING: "Cuộc gọi thoại bị nhỡ",
  FM_MISSED_VIDEO_CALL_RING: "Cuộc gọi video bị nhỡ",
  FM_MISSED_CALL_SIDE_BAR: "{{ first }} đã lỡ cuộc gọi",
  FM_MISSED_VIDEO_CALL_SIDE_BAR: "{{ first }} đã lỡ cuộc gọi video",
  FM_INCOMING_CALL_TITLE: "{{ senderName }} đang gọi cho bạn",
  FM_INCOMING_VIDEO_CALL_TITLE: "{{ senderName }} đang gọi video cho bạn",
  FM_INCOMING_GROUP_CALL_TITLE: "{{ senderName }} đã bắt đầu cuộc gọi nhóm {{ groupName }}",
  FM_INCOMING_GROUP_VIDEO_CALL_TITLE: "{{ senderName }} đã bắt đầu cuộc gọi hội nghị {{ groupName }}",
  FM_OTHER_CALL_PARTICIPANT: "+{{ number }} người gọi khác",
  FM_FOCUS_SHARING_CHANGED: " {{ after }} đã thay thế {{ before }} trở thành người chia sẻ chính",
  FM_USER_BECAME_FOCUS_SHARING: "{{ name }} đã trở thành người chia sẻ chính",
  FM_CONFIRM_SHARE_SCREEN: "Khi thực hiện thao tác này, bạn sẽ làm người trình bày chính thay cho {{ name }}",
  FM_STARTED_CALL: "<b>{{ user }}</b> đã bắt đầu cuộc gọi nhóm",
  FM_JOINED_CALL: "<b>{{ user }}</b> đã tham gia cuộc gọi",
  FM_LEAVE_CALL: "<b>{{ user }}</b> đã rời cuộc gọi",

  // Object
  OBJ_CALL_LENGTH: {
    FM_HH: "{{ hours }} giờ,",
    FM_MM: "{{ minutes }} phút,",
    FM_SS: "{{ seconds }} giây,",
    FM_HH_MM: "{{ hours }} giờ {{ minutes }} phút,",
    FM_MM_SS: "{{ minutes }} phút {{ seconds }} giây,",
    FM_HH_MM_SS: "{{ hours }} giờ {{ minutes }} phút {{ seconds }} giây,",
  },
};
