import { useDispatch, useSelector } from "react-redux";
import RequestFormDialog from "../../ManageRequest/RequestFormDialog";
import { SystemActions, SystemSelectors } from "redux-store";
import { useEffect, useState } from "react";

const HandleRequestNotice = () => {
  const dispatch = useDispatch();
  const requestNotice = useSelector(SystemSelectors.getRequestNotice);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    dispatch(
      SystemActions.systemSet({
        requestNotice: null,
      }),
    );
  };

  useEffect(() => {
    if (requestNotice && requestNotice.id) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [requestNotice]);

  return open && <RequestFormDialog open onClose={handleClose} request={requestNotice} />;
};

export default HandleRequestNotice;
