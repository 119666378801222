// Copyright 2020 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import React from "react";
// import { Avatar, AvatarSize } from "./Avatar";
import { CallBackgroundBlur } from "./CallBackgroundBlur";
import { DirectCallRemoteParticipant } from "./DirectCallRemoteParticipant";
import type { LocalizerType } from "../types/Util";
import type { ActiveCallType } from "../types/Calling";
import { CallMode } from "../types/CallDisposition";
import { AvatarColors } from "../types/Colors";
import type { SetRendererCanvasType } from "../state/ducks/calling";
// import { missingCaseError } from "../util/missingCaseError";
// import { isReconnecting } from "../util/callingIsReconnecting";
// import { assertDev } from "../util/assert";
import type { CallingImageDataCache } from "./CallManager";
import { AvatarGroup } from "components";
import { useSelector } from "react-redux";

const assertDev = window.electronLibs.libs.assertDev;
const missingCaseError = window.electronLibs.libs.missingCaseError;
const isReconnecting = window.electronLibs.libs.isReconnecting;

// This value should be kept in sync with the hard-coded CSS height. It should also be
//   less than `MAX_FRAME_HEIGHT`.

function NoVideo({ activeCall, i18n }: { activeCall: ActiveCallType; i18n: LocalizerType }): JSX.Element {
  const {
    acceptedMessageRequest,
    avatarUrl,
    color,
    type: conversationType,
    isMe,
    phoneNumber,
    profileName,
    title,
  } = activeCall.conversation;
  const callingGroupDetailRing = useSelector((state: any) => state.callingRedux.callingGroupDetailRing || {});

  return (
    <div className="module-calling-pip__video--remote">
      <CallBackgroundBlur avatarUrl={callingGroupDetailRing.avatarId}>
        <div className="module-calling-pip__video--avatar">
          <div className="module-Avatar">
            <AvatarGroup
              avatarId={callingGroupDetailRing.avatarId}
              prefixKey={callingGroupDetailRing.prefixKey}
              group={{
                groupType: callingGroupDetailRing.groupType,
                groupName: callingGroupDetailRing.groupName,
                privateF: callingGroupDetailRing.privateF,
              }}
              size={48}
            />
          </div>
        </div>
      </CallBackgroundBlur>
    </div>
  );
}

export type PropsType = {
  activeCall: ActiveCallType;
  i18n: LocalizerType;
  imageDataCache: React.RefObject<CallingImageDataCache>;
  setRendererCanvas: (_: SetRendererCanvasType) => void;
};

export function CallingPipRemoteVideo({ activeCall, i18n, setRendererCanvas }: PropsType): JSX.Element {
  const { conversation } = activeCall;

  switch (activeCall.callMode) {
    case CallMode.Direct: {
      const { hasRemoteVideo } = activeCall.remoteParticipants[0];
      if (!hasRemoteVideo) {
        return <NoVideo activeCall={activeCall} i18n={i18n} />;
      }
      assertDev(
        conversation.type === "direct",
        "CallingPipRemoteVideo for direct call must be associated with direct conversation",
      );
      return (
        <div className="module-calling-pip__video--remote">
          <DirectCallRemoteParticipant
            conversation={conversation}
            hasRemoteVideo={hasRemoteVideo}
            i18n={i18n}
            isReconnecting={isReconnecting(activeCall)}
            setRendererCanvas={setRendererCanvas}
          />
        </div>
      );
    }
    default:
      throw missingCaseError(activeCall);
  }
}
