import { call, put } from "redux-saga/effects";
import { removeDuplicateInArray, toCamel, toSnake } from "utils";
import { getInteractor, LocalDbManagement } from "services/local.service";
import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { BranchActions } from "redux-store";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services";
import { checkCurrentBranchByPrefix } from "./saga.helper";
import { syncRemoteAccountList } from "pubsub/services/account.service";
import { saveFileFromBranchList } from "pubsub/services/branch.service";
import uniqBy from "lodash/uniqBy";
const redis = window.electronLibs.libs.redis;

export function* getBranches(prefixKey) {
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();
  const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
  const savedServer = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey) || {};

  try {
    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).getBranchList);
    if (response.status === ApiConstant.STT_OK) {
      const responseData = response.data;

      if (Array.isArray(responseData) && responseData.length > 0) {
        yield getInteractor(prefixKey).LocalBranchService.save(responseData);
        const currentBranchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
        const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
        const updateBranchData = responseData.find(item => item.id === currentBranchId);
        redis.updateRedisInstances(updateBranchData, prefixKey);
        yield saveFileFromBranchList(prefixKey, responseData);

        const dbManagement = yield LocalDbManagement.findOne({
          account_id: currentAccountId,
          branch_id: currentBranchId,
        });
        const branchDataResponse = responseData.find(item => item.id === currentBranchId);
        const isValidData = currentAccountId && dbManagement.owner_name && branchDataResponse;
        if (isValidData) {
          yield LocalDbManagement.save([
            {
              ...dbManagement,
              branch_name: branchDataResponse.name,
              logo_app: branchDataResponse.attachment_id,
              branch_domain: branchDataResponse.domain,
              modified: new Date().getTime(),
            },
          ]);
        }

        let selectedBranch = {
          account_id: currentAccountId,
          phone: dbManagement.owner_name,
          login_state: dbManagement.state,
        };
        selectedBranch = toCamel({ ...selectedBranch, ...branchDataResponse });

        // Select branch
        const isNeedUpdate = !savedServer || JSON.stringify(savedServer) !== JSON.stringify(selectedBranch);
        if (isValidData && isNeedUpdate) {
          StorageUtil.setItem(KeyConstant.KEY_BRANCH_INFO, selectedBranch, prefixKey);
          StorageUtil.setCommonKey(KeyConstant.KEY_SELECTED_SERVER, selectedBranch);
          if (isCurrentBranch) {
            yield put(BranchActions.updateSelectedBranch(selectedBranch));
          }

          if (isCurrentBranch) {
            yield put(
              BranchActions.branchSuccess({
                fetchBranchTimestamp: new Date().getTime(),
              }),
            );
          }

          return true;
        } else if (!branchDataResponse?.id) {
          console.warn("NOT FOUND BRANCH ON SERVER");
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getBranchAccount(prefixKey) {
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();
  const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
  const savedServer = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey) || {};

  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

    const branchAccount = yield getInteractor(prefixKey).LocalBranchService.getActiveBranch();
    const currentBranches = yield getInteractor(prefixKey).LocalBranchService.getAll();
    const idsArr = branchAccount.map(item => ({
      branchId: item.id,
      lastTime: item.created + 1,
    }));

    const getBranchAccountResponse = yield call(
      yield remoteApiFactory.getBranchApi(prefixKey).getBranchAccount,
      JSON.stringify(toSnake(idsArr)),
    );

    if (getBranchAccountResponse.status === ApiConstant.STT_OK) {
      const branchAccountArray = getBranchAccountResponse.data;
      const camelAccountArr = toCamel(branchAccountArray);
      const accountIdArr = removeDuplicateInArray(camelAccountArr.map(item => item.accountId));

      yield syncRemoteAccountList(prefixKey, accountIdArr);
      yield getInteractor(prefixKey).LocalBranchAccountService.save(branchAccountArray);

      const firstActiveBranch = branchAccountArray.find(
        item2 => item2.state === SystemConstant.BRANCH_STATE.active && item2.account_id === accountId,
      );
      const selectedBranch = [...branchAccount, ...currentBranches].find(
        item => item.id === firstActiveBranch?.branch_id,
      );

      selectedBranch.account_id = accountId;

      const isNeedUpdate = !savedServer || Object.keys(savedServer).length !== Object.keys(selectedBranch || {}).length;

      if (isNeedUpdate) {
        if (isCurrentBranch && selectedBranch) {
          StorageUtil.setCommonKey(KeyConstant.KEY_SELECTED_SERVER, toCamel(selectedBranch));
          yield put(BranchActions.updateSelectedBranch(toCamel(selectedBranch)));
        } else {
          return;
        }
      }

      // Check if current account status in any branch !== 1 (active) -> remove that branch from selected server list
      const inactiveBranchList = branchAccountArray.filter(
        item =>
          item.status === SystemConstant.STATUS.inactive &&
          item.state !== SystemConstant.STATE.inactive &&
          item.account_id === accountId,
      );

      if (inactiveBranchList.length > 0) {
        const inactiveBranchId = uniqBy(inactiveBranchList, "branch_id").map(item => item.branch_id);
        if (isCurrentBranch) {
          yield put(
            BranchActions.branchSet({
              inactiveBranchArray: inactiveBranchId,
            }),
          );
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
