import { ApiConstant, KeyConstant, SystemConstant } from "const";
import store, {
  AccountActions,
  BranchActions,
  ConversationSelectors,
  GroupInfoActions,
  ProfileActions,
  getReduxState,
} from "redux-store";
import { checkCurrentBranchByPrefix, formatArray2Key } from "sagas/saga.helper";
import { remoteApiFactory } from "services";
import { LocalDbManagement, getInteractor } from "services/local.service";
import { getDataFromPrefixKey, StorageUtil } from "utils";
import { handleLogoutAndChooseBranch } from "./device.service";
import { getRemoteBranchAccount } from "./branch.service";
import { updateGroupNameAfterChangingContact } from "services/group.service";
import { ExecuteEvent } from "pubsub/PubSub.const";
import { getConversation } from "./conversation.service";

export const syncAccountService = async (prefixKey, accountId) => {
  try {
    await syncRemoteAccountList(prefixKey, [accountId]);
    return true;
  } catch (error) {
    console.log("account new service fail: ", error);
  }
  return false;
};

export const accountUpdateService = async (prefixKey, accountId) => {
  try {
    const remoteBranchAccount = (
      await getRemoteBranchAccount(prefixKey, {
        account_id: accountId,
      })
    ).find(item => item.account_id === accountId);
    if (false === Boolean(remoteBranchAccount && remoteBranchAccount.id)) return false;

    // Trigger UI to show notification that current account is inactive and system will logout it
    const branchAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const isUpdateCurrentAccount = accountId === branchAccountId;
    if (
      isUpdateCurrentAccount &&
      remoteBranchAccount.status === SystemConstant.STATUS.inactive &&
      remoteBranchAccount.state !== SystemConstant.STATE.inactive
    ) {
      store.dispatch(
        BranchActions.branchSet({
          inactiveBranchArray: [remoteBranchAccount.branch_id],
        }),
      );
    }

    // Check/ update UI when user chatting with inactive user
    const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const selectedGroupId = getReduxState(ConversationSelectors.getSelectedGroupId);
    if (selectedGroupId) {
      const group = await getInteractor(prefixKey).LocalGroupService.get(selectedGroupId);
      const chattingMember = group.groupMembers?.find(item => item.id !== currentAccountId);
      const chattingMemberRemote = remoteBranchAccount.account_id === chattingMember?.id ? remoteBranchAccount : null;
      if (group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal && chattingMemberRemote) {
        store.dispatch(
          AccountActions.accountSet({
            updateChattingMember: {
              id: chattingMemberRemote.account_id,
              state: chattingMemberRemote.state,
              status: chattingMemberRemote.status,
            },
          }),
        );
      }
    }

    // Check/ update group_name if account is changed name
    const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
    const localAccount = await getInteractor(prefixKey).LocalAccountService.get(accountId, branchId);
    // Just executing when updatingAccount is not current account and updatingAccount does not have contact_name
    if (localAccount && localAccount.id !== branchAccountId && !localAccount.contact_name) {
      const group = await updateGroupNameAfterChangingContact(prefixKey, localAccount.id);
      if (group) {
        if (checkCurrentBranchByPrefix(prefixKey)) {
          // Trigger update group name
          store.dispatch(
            GroupInfoActions.groupInfoSet({
              updatingGroupData: { id: group.id, groupName: group.groupName },
            }),
          );
        }
      }
    }

    return true;
  } catch (error) {
    console.log("update account service fail: ", error);
  }

  return false;
};

export const accountDeleteService = async (prefixKey, deleteAccountId) => {
  try {
    const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const [accountId, branchId] = getDataFromPrefixKey(prefixKey);
    if (currentAccountId === deleteAccountId) {
      await LocalDbManagement.deleteWithCondition({ account_id: accountId, branch_id: branchId });
      await handleLogoutAndChooseBranch(prefixKey);
    } else {
      const remoteBranchAccount = await getRemoteBranchAccount(prefixKey, {
        account_id: deleteAccountId,
      });
      const updateChattingMember = remoteBranchAccount.find(item => item.account_id === deleteAccountId);

      if (updateChattingMember) {
        store.dispatch(
          AccountActions.accountSet({
            updateChattingMember: {
              id: deleteAccountId,
              state: updateChattingMember.state,
              status: updateChattingMember.status,
            },
          }),
        );
      }
    }

    return true;
  } catch (error) {
    console.log("delete account service fail: ", error);
  }

  return false;
};

export const syncRemoteAccountList = async (prefixKey, accountIds) => {
  try {
    const remoteData = await getAccountList(prefixKey, accountIds);
    await getInteractor(prefixKey).LocalAccountService.save(remoteData);

    // Trigger UI to update current profile
    const remoteAccountIds = remoteData.map(item => item.id);
    const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    if (remoteAccountIds.includes(currentAccountId)) {
      store.dispatch(
        ProfileActions.profileSet({
          updatedProfile: Date.now(),
        }),
      );
    }

    return true;
  } catch (error) {
    console.log("get remote accounts fail: ", error);
  }

  return false;
};

export const favoriteChatService = async (prefixKey, accountId, groupId) => {
  try {
    const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    if (currentAccountId !== accountId) return ExecuteEvent.SUCCESSES;

    await getConversation(prefixKey, groupId);
    store.dispatch(
      GroupInfoActions.groupInfoSuccess({
        updateGroupCategory: {
          id: groupId,
          modified: Date.now(),
        },
      }),
    );

    return ExecuteEvent.SUCCESSES;
  } catch (error) {
    console.log("favoriteChatService fail: ", error);
  }

  return ExecuteEvent.FAIL;
};

export const getAccountList = async (prefixKey, accountIds) => {
  try {
    const params = formatArray2Key({
      account_ids: accountIds,
    });
    const response = await remoteApiFactory.getBranchApi(prefixKey).getAccountByIds(params);
    const responseData = response.data;
    if (response.status === ApiConstant.STT_OK && responseData && Array.isArray(responseData)) {
      return responseData;
    }
  } catch (error) {
    console.log("get remote account list: ", error);
  }

  return [];
};
