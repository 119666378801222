import { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, IconButton, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { ConfirmDialog } from "components";
import { FormatConstant, LangConstant, SystemConstant } from "const";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { StorageUtil } from "utils";
import { getListDepartment, getListPosition, getListUnit, updateRequest } from "services/manageRequest.service";
import { useAlertContext } from "components/context/AlertContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format, isValid, parseISO } from "date-fns";

const RequestFormDialog = ({ open, onClose, request, onUpdateRequest }) => {
  const classes = useStyles();
  const profileObjContent = getNSLang(LangConstant.NS_HOME, "OBJ_ACCOUNT_PROFILE", { returnObjects: true });
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { showAlert } = useAlertContext();

  const [showConfirm, setShowConfirm] = useState(false);
  const [requestDetails, setRequestDetails] = useState({
    birthday: null,
    unitId: "",
    departmentId: "",
    positionId: "",
  });
  const [unitList, setUnitList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [positionList, setPositionList] = useState([]);

  const handleUpdateRequest = status => async () => {
    const birthday = requestDetails.birthday;
    const isValidDate = birthday && (isValid(parseISO(birthday)) || isValid(birthday));
    const strBirthday = isValidDate
      ? format(isValid(birthday) ? birthday : parseISO(birthday), FormatConstant.FM_DD_MM_YYYY).toString()
      : "";

    const data2Req = {
      branch_request_ids: [request.id],
      status,
      birthday: strBirthday,
      unit_id: requestDetails.unitId,
      department_id: requestDetails.departmentId,
      position_id: requestDetails.positionId,
    };

    const isUpdateSuccess = await updateRequest(prefixKey, data2Req);

    if (isUpdateSuccess && onUpdateRequest) onUpdateRequest(request.id);

    const isUpdateAccept = status === SystemConstant.BRANCH_REQUEST_STATUS.accepted;
    if (isUpdateAccept && isUpdateSuccess) {
      showAlert({ content: getNSLang(LangConstant.NS_HOME, "TXT_ACCEPT_REQ_SUCCESS") });
    } else if (isUpdateAccept && !isUpdateSuccess) {
      showAlert({ content: getNSLang(LangConstant.NS_HOME, "TXT_ACCEPT_REQ_FAIL"), severity: "error" });
    }

    setShowConfirm(false);
    if (onClose) onClose();
  };

  const handleSelectUnit = unit => async () => {
    const remoteDepartmentList = await getListDepartment(prefixKey, unit.unitId);
    setDepartmentList(remoteDepartmentList);
    setRequestDetails(state => ({ ...state, unitId: unit.unitId }));
  };

  const handleSelectDepartment = department => async () => {
    const remotePositionList = await getListPosition(prefixKey, requestDetails.unitId, department.departmentId);
    setPositionList(remotePositionList);
    setRequestDetails(state => ({ ...state, departmentId: department.departmentId }));
  };

  const handleSelectPosition = position => () => {
    setRequestDetails(state => ({ ...state, positionId: position.positionId }));
  };

  const handleGetUnitList = async () => {
    const unitList = await getListUnit(prefixKey);
    setUnitList(unitList);
  };

  const handleChangeBirthDay = value => {
    setRequestDetails(state => ({ ...state, birthday: value }));
  };

  useEffect(() => {
    handleGetUnitList();
  }, []);

  const isDisableActions = request.status !== SystemConstant.BRANCH_REQUEST_STATUS.pending;

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Typography variant="h6" fontWeight={600}>
          {getNSLang(LangConstant.NS_HOME, "TXT_REQUEST_TITLE")}
        </Typography>
        <IconButton className={classes.closeBtn} edge="end" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Box>
            <Typography variant="subtitle2">{getNSLang(LangConstant.NS_HOME, "TXT_REQUEST_NOTE")}</Typography>
            <Typography textAlign="justify" sx={{ maxHeight: 100, overflow: "auto" }}>
              {request.note}
            </Typography>
          </Box>
          <TextField
            fullWidth
            label={`${getNSLang(LangConstant.NS_LOGIN, "TXT_FULL_NAME")} (*)`}
            InputLabelProps={{ shrink: true }}
            value={request.name}
            disabled
          />
          <TextField
            fullWidth
            label={`${profileObjContent.phone} (*)`}
            InputLabelProps={{ shrink: true }}
            value={request.phone}
            disabled
          />
          <TextField
            fullWidth
            label={profileObjContent.email}
            InputLabelProps={{ shrink: true }}
            value={request.email}
            disabled
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={profileObjContent.birthday}
              disableFuture
              format={FormatConstant.FM_DD_MM_YYYY}
              value={requestDetails.birthday}
              onChange={handleChangeBirthDay}
              renderInput={props => <TextField {...props} InputLabelProps={{ shrink: true }} />}
            />
          </LocalizationProvider>

          <TextField fullWidth select label={profileObjContent.unit} defaultValue="" InputLabelProps={{ shrink: true }}>
            {unitList.map(item => (
              <MenuItem key={item.unitId} value={item.unitId} onClick={handleSelectUnit(item)}>
                {item.unitName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label={profileObjContent.department}
            defaultValue=""
            InputLabelProps={{ shrink: true }}
          >
            {departmentList.map(item => (
              <MenuItem key={item.departmentId} value={item.departmentId} onClick={handleSelectDepartment(item)}>
                {item.departmentName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label={profileObjContent.position}
            defaultValue=""
            InputLabelProps={{ shrink: true }}
          >
            {positionList.map(item => (
              <MenuItem key={item.positionId} value={item.positionId} onClick={handleSelectPosition(item)}>
                {item.positionName}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </DialogContent>

      <Stack p={2} spacing={1}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleUpdateRequest(SystemConstant.BRANCH_REQUEST_STATUS.accepted)}
          disabled={isDisableActions}
        >
          {getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_ADD")}
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="error"
          onClick={() => setShowConfirm(true)}
          disabled={isDisableActions}
        >
          {getCommonLang("TXT_DENIED")}
        </Button>
      </Stack>

      {showConfirm && (
        <ConfirmDialog
          open
          title={getNSLang(LangConstant.NS_HOME, "TXT_REJECT_REQ")}
          content={getNSLang(LangConstant.NS_HOME, "TXT_REJECT_REQ_CONTENT")}
          submitProps={{
            submitText: getCommonLang("TXT_CONFIRM"),
            onClick: handleUpdateRequest(SystemConstant.BRANCH_REQUEST_STATUS.rejected),
          }}
          cancelProps={{ onClick: () => setShowConfirm(false) }}
        />
      )}
    </Dialog>
  );
};

export default RequestFormDialog;

const DialogTitle = styled(Stack)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  margin: 0,
  padding: 16,
  paddingBottom: 0,
});

const useStyles = makeStyles({
  paper: {
    width: 500,
  },

  dialogActions: {
    margin: 0,
    flexDirection: "column",
    padding: 16,
  },
});
