import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { AvatarInfo } from "components";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";
import { getSavedServer } from "utils/branch.utils";
import { VerifiedIcon } from "components/icons";

const MessageSearchPopup = ({ data, keyword, onClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);
  const [foundResults, setFoundResults] = useState([]);

  const onCreateNewHistoryKeyword = () => {
    const savedServer = getSavedServer();
    if (keyword) {
      let historyKeywords = StorageUtil.getItem(KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS) || {};
      let historyKeywordsInServer = historyKeywords[savedServer?.id] || [];
      historyKeywordsInServer.unshift(keyword);
      StorageUtil.setItem(
        KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS,
        Object.assign(historyKeywords, { [savedServer?.id]: historyKeywordsInServer }),
      );
    }
  };

  useEffect(() => {
    if (Array.isArray(data)) {
      setFoundResults(data.filter(mes => !SystemConstant.ARR_CALLING_TYPES.includes(mes.sendType)));
    } else {
      setFoundResults([]);
    }
  }, [data]);

  const onClickSearchItem = message => {
    onCreateNewHistoryKeyword();
    onClick();
    dispatch(
      ConversationActions.setSelectGroupId({
        selectedGroupId: message.groupId,
        threadingId: message.threadId,
        scrollToChildId: message.parentId || message.sourceId,
      }),
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.heading}>
        <Typography className={clsx(classes.title, "semiBold-sm-txt")}>
          {getLabel(LangConstant.TXT_MESSAGE_FOUND)}
        </Typography>
      </Box>
      <Box className={classes.scrollBox}>
        <Box className={classes.msgWrapper}>
          {foundResults.map(msg => (
            <Box key={msg.id} className={classes.msgItem} onClick={() => onClickSearchItem(msg)}>
              <AvatarInfo
                secondTitle={msg.content}
                title={msg?.accountName || "No name"}
                avatar={{
                  avatarId: msg?.accountAvatarId,
                  name: msg?.accountName,
                  isServerLogo: msg?.sendType === SystemConstant.SEND_TYPE.botMessage,
                }}
                button={true}
                BadgeIcon={msg?.sendType === SystemConstant.SEND_TYPE.botMessage && <VerifiedIcon />}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(MessageSearchPopup);

MessageSearchPopup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      accountAvatarId: PropTypes.string,
      accountName: PropTypes.string,
      content: PropTypes.string,
    }),
  ),

  keyword: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 12px",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.white,
    zIndex: 1,
  },

  title: {
    color: "#7F7F80",
    lineHeight: "1rem",
  },

  msgWrapper: {
    padding: "0 0px",
  },

  msgItem: {
    display: "flex",
    alignItems: "flex-start",
    cursor: "pointer",
  },

  avatar: {
    width: 50,
    height: 50,
    marginRight: 12,
  },
}));
