import { useEffect } from "react";
import { Provider } from "react-redux";
import { CssBaseline } from "@mui/material";
import store from "./redux-store";
import { AppConstant, KeyConstant } from "const";
import { HandleThirdToken, HandleAppDialog, Toast, HandlingPubSub } from "components";
import { SmartCallManager } from "./ring-rtc/state/smart/CallManager";

import "emoji-mart/css/emoji-mart.css";
import "react-phone-input-2/lib/style.css";
import "react-multi-carousel/lib/styles.css";
import "./language";
import "theme/styles";
import { StorageUtil } from "utils";
import AppRouters from "AppRouters";
import AppTopLayer from "AppTopLayout";
import HandleRingEvents from "HandleRingEvents";

const App = () => {
  useEffect(() => {
    let appFontSize = StorageUtil.getCommonKey(KeyConstant.KEY_FONT_SIZE);
    if (false === Boolean(appFontSize)) {
      appFontSize = AppConstant.BASE_FONT_SIZE;
      StorageUtil.setCommonKey(KeyConstant.KEY_FONT_SIZE, appFontSize);
    }
    document.documentElement.style.setProperty("--font-size", `${appFontSize}px`);

    window.addEventListener("error", e => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <CssBaseline />
      <HandleThirdToken />
      <HandleAppDialog />
      <Toast />
      <SmartCallManager />
      <HandlingPubSub />
      <AppTopLayer />
      <HandleRingEvents/>

      <AppRouters />
    </Provider>
  );
};

export default App;
