import React, { memo, useState } from "react";
import { CardMedia, Fab } from "@mui/material";
import { LangConstant, ImageConstant, AppConstant } from "const";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import AccountInfoDrawer from "./AccountInfoDrawer";
import AddingGroupDrawer from "../AddingChat/AddingGroup/AddingGroupDrawer";
import { NoticeDialog } from "components";
import FindingAccountDrawer from "./FindingAccountDrawer";
import { BranchSelectors } from "redux-store";
import { useFindAccount } from "hooks";

const AddingContact = ({ isGroupTab }) => {
  const classes = useStyles();
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const contactObjContent = getLabel(LangConstant.OBJ_ADDING_CONTACT, { returnObjects: true });

  const { actionStatus, findAccountList, findContact, resetData } = useFindAccount();
  const hasContact = Boolean(
    actionStatus === AppConstant.PROCESS_STATUS.success && Array.isArray(findAccountList) && findAccountList[0]?.id,
  );
  const isNotFound = Boolean(
    actionStatus === AppConstant.PROCESS_STATUS.error ||
      (actionStatus === AppConstant.PROCESS_STATUS.success &&
        Array.isArray(findAccountList) &&
        findAccountList.length === 0),
  );

  const [isShow, setIsShow] = useState(false);

  const handleShowDrawer = open => () => setIsShow(open);

  if (isBranchServer) return null;
  return (
    <>
      <Fab color="primary" aria-label="add" className={classes.btnAdding} onClick={handleShowDrawer(true)}>
        <CardMedia component="img" src={ImageConstant.AddContact} className={classes.logoImg} />
      </Fab>

      {isGroupTab ? (
        <AddingGroupDrawer open={isShow} onComplete={handleShowDrawer(false)} onClose={handleShowDrawer(false)} />
      ) : (
        <>
          {isShow && <FindingAccountDrawer open onClose={handleShowDrawer(false)} onSubmit={findContact} />}

          {hasContact && (
            <AccountInfoDrawer
              open={hasContact}
              data={findAccountList[0]}
              onClose={resetData}
              onBackToMain={handleShowDrawer(false)}
            />
          )}

          {isNotFound && (
            <NoticeDialog
              open
              submitProps={{
                className: classes.popupNoticeDialog,
                onClick: resetData,
              }}
              title={contactObjContent.notification}
              content={contactObjContent.notRegisterPhone}
            />
          )}
        </>
      )}
    </>
  );
};

AddingContact.propTypes = {};

export default memo(AddingContact);

const useStyles = makeStyles(theme => ({
  btnAdding: {
    boxShadow: "none",
    position: "absolute",
    bottom: 72,
    right: 16,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: "none",
    },
  },

  logoImg: {
    width: 24,
    height: 24,
  },

  title: {
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
  },

  popupNoticeDialog: {
    "&:hover": {
      boxShadow: "none",
    },
  },
}));
