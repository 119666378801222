import { ArrowBack } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import BranchInfo from "components/BranchInfo";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { useHistory } from "react-router-dom";
import { AuthActions } from "redux-store";
import { ApiConstant, AppConstant, LangConstant, PathConstant, SystemConstant } from "const";
import { getLoginBranchInfo } from "utils/auth.utils";
import { AuthService, CommonBranchInfoService } from "services";
import { NoticeDialog } from "components";
import { useAlertContext } from "components/context/AlertContext";
import { uuid } from "utils";
import { LocalDbManagement } from "services/local.service";

const DEFAULT_USER_INFO = {
  name: "",
  email: "",
  note: "",
};

const RequestLoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { showAlert } = useAlertContext();
  const tmpLoginBranch = getLoginBranchInfo();

  const [userInfo, setUserInfo] = useState(DEFAULT_USER_INFO);
  const [requestStatus, setRequestStatus] = useState(AppConstant.PROCESS_STATUS.nothing);
  const [branchName, setBranchName] = useState("");

  const handleChangeName = event => {
    const accountName = event.target.value;
    setUserInfo(state => ({ ...state, name: accountName }));
  };

  const handleChangeEmail = event => {
    const email = event.target.value;
    setUserInfo(state => ({ ...state, email }));
  };

  const handleChangeNote = event => {
    const requestNote = event.target.value;
    setUserInfo(state => ({ ...state, note: requestNote }));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    setRequestStatus(AppConstant.PROCESS_STATUS.calling);
    const requestData = {
      ...userInfo,
      phone_number: tmpLoginBranch.phone,
      qr_token: tmpLoginBranch.qrToken,
    };

    const response = await AuthService.createRequestLogin(tmpLoginBranch.domain, requestData);
    if (response.status === ApiConstant.STT_OK) {
      setRequestStatus(AppConstant.PROCESS_STATUS.success);
      await saveSuggestBranch(tmpLoginBranch);
    } else {
      showAlert({ content: getCommonLang("TXT_SERVER_ERROR"), alertProps: { severity: "error" } });
      setRequestStatus(AppConstant.PROCESS_STATUS.error);
    }
  };

  const handleBack = () => {
    dispatch(AuthActions.authReset());
    history.replace(`${PathConstant.LOGIN}?qr=1`);
  };

  const handleCloseNotice = () => {
    setRequestStatus(AppConstant.PROCESS_STATUS.nothing);
    handleBack();
  };

  useEffect(() => {
    CommonBranchInfoService.getBranchWithoutToken(tmpLoginBranch.domain, tmpLoginBranch.id).then(branch => {
      setBranchName(branch.name);
    });

    return () => {
      setRequestStatus(AppConstant.PROCESS_STATUS.nothing);
      setUserInfo(DEFAULT_USER_INFO);
    };
  }, []);

  const isDisableCreate = false === Boolean(userInfo.name);
  const isPendingApi = requestStatus === AppConstant.PROCESS_STATUS.calling;
  const isShowNoticeDialog = AppConstant.PROCESS_STATUS.success === requestStatus;

  return (
    <Stack width="100%" height="100vh" justifyContent="center" padding={4} spacing={5}>
      <IconButton className={classes.backBtn} onClick={handleBack}>
        <ArrowBack />
      </IconButton>

      <Stack alignItems="center">
        <BranchInfo isShowWelcomeText={false} />
      </Stack>

      <Box component="form" onSubmit={handleSubmit}>
        <Stack spacing={1} className={classes.paddingRoot} width="100%">
          <Typography className={classes.title}>
            {getNSLang(LangConstant.NS_LOGIN, "TXT_FULL_NAME")} <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            fullWidth
            type="text"
            required
            placeholder={getNSLang(LangConstant.NS_LOGIN, "TXT_FULL_NAME_PLACEHOLDER")}
            value={userInfo.name}
            onChange={handleChangeName}
          />
        </Stack>

        <Stack spacing={1} className={classes.paddingRoot} width="100%">
          <Typography className={classes.title}>{getNSLang(LangConstant.NS_LOGIN, "TXT_EMAIL")}</Typography>
          <TextField
            fullWidth
            type="text"
            placeholder={getNSLang(LangConstant.NS_LOGIN, "TXT_EMAIL_PLACEHOLDER")}
            value={userInfo.email}
            onChange={handleChangeEmail}
          />
        </Stack>

        <Stack spacing={1} className={classes.paddingRoot}>
          <Typography className={classes.title}>{getNSLang(LangConstant.NS_LOGIN, "TXT_REQUEST_NOTE")}</Typography>
          <TextField
            fullWidth
            type="text"
            placeholder={getNSLang(LangConstant.NS_LOGIN, "TXT_REQUEST_NOTE_PLACEHOLDER")}
            value={userInfo.note}
            onChange={handleChangeNote}
          />
        </Stack>

        <Button
          fullWidth
          type="submit"
          variant="contained"
          className={clsx("semiBold-lg-txt", classes.continueButton)}
          disabled={isDisableCreate || isPendingApi}
        >
          {getCommonLang("TXT_CONTINUE")}
          {isPendingApi && <Box className={clsx("loader", classes.loading)} />}
        </Button>
      </Box>

      {isShowNoticeDialog && (
        <NoticeDialog
          open={isShowNoticeDialog}
          title={getNSLang(LangConstant.NS_LOGIN, "TXT_REQUEST_LOGIN")}
          content={getNSLang(LangConstant.NS_LOGIN, "FM_REQUEST_LOGIN", { serverName: branchName })}
          submitProps={{
            submitText: getCommonLang("TXT_GOT_IT"),
            onClick: handleCloseNotice,
          }}
        />
      )}
    </Stack>
  );
};

export default RequestLoginForm;

const saveSuggestBranch = async branch => {
  const localDbBranch = await LocalDbManagement.findOne({ branch_id: branch.id });
  if (false === Boolean(localDbBranch && localDbBranch.id)) {
    const branchCommonInfo = (await CommonBranchInfoService.getBranchWithoutToken(branch.domain, branch.id)) || {};

    const branchInfo = {
      id: uuid(),
      account_id: "",
      branch_id: branch.id,
      owner_name: branch.phone,
      state: SystemConstant.STATE.suggested,
      options: null,
      token: null,
      branch_name: branchCommonInfo.name || AppConstant.GLOBAL_BRANCH_NAME,
      branch_domain: branch.domain,
      logo_app: branchCommonInfo.attachment_id || "",
      created: Date.now(),
      modified: Date.now(),
    };

    await LocalDbManagement.save([branchInfo]);
  }
};

const useStyles = makeStyles({
  paddingRoot: {
    padding: "8px 0px",
  },

  continueButton: {
    marginTop: 15,
    width: "100%",
    padding: "14px 0",
  },

  backBtn: {
    position: "absolute",
    top: 20,
  },

  loading: {
    width: 20,
    marginLeft: 8,
  },
});
