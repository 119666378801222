import { useEffect, useState } from "react";
import { KeyConstant, SystemConstant } from "const";
import { isArray, toCamel } from "utils";
import { sortBy } from "lodash";
import { useSelector } from "react-redux";
import { BranchSelectors, SystemSelectors } from "redux-store";
import { StorageUtil } from "utils";
import { getInteractor } from "../services/local.service";
import { createSelector } from "reselect";
import uniqBy from "lodash/uniqBy";

const memoizedReduxState = createSelector(
  [
    BranchSelectors.getSelectedBranch,
    SystemSelectors.isSystemSynchronizing,
    state => state.contactRedux.executeContact,
  ],
  (selectedBranch, isSynchronizing, executeContact) => {
    return {
      selectedBranch,
      isSynchronizing,
      executeContact: executeContact?.contactId ? executeContact : null,
    };
  },
);

const useContactList = () => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  const { selectedBranch, executeContact, isSynchronizing } = useSelector(memoizedReduxState);

  const [isFetching, setIsFetching] = useState(false);
  const [contactList, setContactList] = useState([]); // Include current account
  const [searchingName, setSearchingName] = useState("");

  const handleDataFromResponse = response => {
    if (response && isArray(response)) {
      response = toCamel(response).map(contact => ({
        ...contact,
        name: contact.contactName || contact.accountName || "",
      }));

      const sortContactList = sortBy(response, [contact => contact.name.toLowerCase().trim()]);
      setContactList(sortContactList);
    } else {
      console.error("useContactList - Response is not array");
    }
  };

  /**
   * In Branch Mode
   * contactList = distinct(contact + personalChat)
   *
   * In Global Mode
   * contactList = contact
   *
   */
  const getAllContactByName = async (name = "") => {
    setIsFetching(true);
    const keyword = name ? name.toLowerCase().trim() : "";
    setSearchingName(keyword);
    let contactRecordList = await getInteractor(prefixKey).LocalContactService.searchContactList({
      account_id: accountId,
      branch_id: branchId,
      keyword: keyword,
    });

    // Add myself contact
    const currentAccount = await getInteractor(prefixKey).LocalAccountService.get(accountId);
    if (currentAccount) {
      contactRecordList.push({
        ...currentAccount,
        account_name: currentAccount.name,
        phone: currentAccount.phone,
        avatar_id: currentAccount.avatar_id,
        contact_id: currentAccount.id,
      });
    }

    // If branch server => add account inbox
    let inboxAccountList = [];
    if (selectedBranch.type === SystemConstant.SERVER_TYPE.branch) {
      const personalMsgList = await getInteractor(prefixKey).LocalGroupService.searchGroupByCondition({
        group_type: SystemConstant.GROUP_CHAT_TYPE.personal,
        account_id: accountId,
        branch_id: branchId,
      });
      personalMsgList.forEach(group => {
        if (group.groupMembers[0]) {
          inboxAccountList.push(group.groupMembers[0]); // Ensure that member is valid data
        }
      });

      inboxAccountList = inboxAccountList.map(item => ({
        ...item,
        account_name: item.name,
        phone: item.phone,
        avatar_id: item.avatarId,
        contact_id: item.id,
      }));
    }

    contactRecordList = contactRecordList.concat(inboxAccountList);

    // Checking contact is valid: condition: branchAccount.state = active && branchAccount.status = active
    const contactIds = contactRecordList.map(item => item.contact_id);
    const branchAccountList = await getInteractor(prefixKey).LocalBranchAccountService.find({
      account_id: contactIds,
      state: SystemConstant.STATE.active,
      status: SystemConstant.STATUS.active,
      branch_id: branchId,
    });
    const activeAccountIds = branchAccountList.map(item => item.account_id);
    contactRecordList = contactRecordList.filter(item => activeAccountIds.includes(item.contact_id));
    contactRecordList = uniqBy(contactRecordList, "contact_id");
    handleDataFromResponse(contactRecordList);
    setIsFetching(false);
  };

  useEffect(() => {
    getAllContactByName(searchingName);
  }, [selectedBranch, executeContact, isSynchronizing]);

  return { isFetching, contactList, getAllContactByName };
};

export default useContactList;
