// Copyright 2020 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import React, { useRef, useEffect } from "react";
import type { LocalizerType } from "../types/Util";
// import { AvatarColors } from "../types/Colors";
// import { Avatar, AvatarSize } from "./Avatar";
import { I18n } from "./I18n";
import { ContactName } from "./conversation/ContactName";
import type { ConversationType } from "../state/ducks/conversations";
import { AvatarGroup } from "components";
import { useSelector } from "react-redux";

export type Props = {
  conversation: Pick<
    ConversationType,
    | "acceptedMessageRequest"
    | "avatarUrl"
    | "color"
    | "isMe"
    | "name"
    | "phoneNumber"
    | "profileName"
    | "sharedGroupNames"
    | "title"
    | "unblurredAvatarUrl"
  >;
  i18n: LocalizerType;
  close: () => void;
};

const AUTO_CLOSE_MS = 10000;

export function CallNeedPermissionScreen({ conversation, i18n, close }: Props): JSX.Element {
  const title = conversation.title || i18n.t("unknownContact");
  const callingGroupDetailRing = useSelector((state: any) => state.callingRedux.callingGroupDetailRing || {});

  const autoCloseAtRef = useRef<number>(Date.now() + AUTO_CLOSE_MS);
  useEffect(() => {
    const timeout = setTimeout(close, autoCloseAtRef.current - Date.now());
    return clearTimeout.bind(null, timeout);
  }, [autoCloseAtRef, close]);

  return (
    <div className="module-call-need-permission-screen">
      <div className="module-Avatar">
        <AvatarGroup
          avatarId={callingGroupDetailRing.avatarId}
          prefixKey={callingGroupDetailRing.prefixKey}
          group={{
            groupType: callingGroupDetailRing.groupType,
            groupName: callingGroupDetailRing.groupName,
            privateF: callingGroupDetailRing.privateF,
          }}
          size={80}
        />
      </div>

      {/* <Avatar
        acceptedMessageRequest={conversation.acceptedMessageRequest}
        avatarUrl={conversation.avatarUrl}
        badge={undefined}
        color={conversation.color || AvatarColors[0]}
        noteToSelf={false}
        conversationType="direct"
        i18n={i18n}
        isMe={conversation.isMe}
        phoneNumber={conversation.phoneNumber}
        profileName={conversation.profileName}
        title={conversation.title}
        sharedGroupNames={conversation.sharedGroupNames}
        size={AvatarSize.EIGHTY}
      /> */}

      <p className="module-call-need-permission-screen__text">
        <I18n
          i18n={i18n}
          id="callNeedPermission"
          components={{
            title: <ContactName title={title} />,
          }}
        />
      </p>

      <button
        type="button"
        className="module-call-need-permission-screen__button"
        onClick={() => {
          close();
        }}
      >
        {i18n.t("close")}
      </button>
    </div>
  );
}
