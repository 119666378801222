// Copyright 2021 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import type { ThunkAction } from "redux-thunk";
import type { ReadonlyDeep } from "type-fest";
// import type { ExplodePromiseResultType } from "../../util/explodePromise";
import type { RecipientsByConversation } from "./stories";
import type { SafetyNumberChangeSource } from "../../components/SafetyNumberChangeDialog";
import type { StateType as RootStateType } from "../reducer";
import * as SingleServePromise from "../../services/singleServePromise";
import { UsernameOnboardingState } from "../../types/globalModals";
import type { BoundActionCreatorsMapObject } from "../../hooks/useBoundActions";
import { useBoundActions } from "../../hooks/useBoundActions";
import type { ButtonVariant } from "../../components/Button";
import type { StartCallData } from "../../components/ConfirmLeaveCallModal";
type ExplodePromiseResultType<T> = Readonly<{
  promise: Promise<T>;
  resolve: (value: T) => void;
  reject: (error: unknown) => void;
}>;
// State

export type SafetyNumberChangedBlockingDataType = ReadonlyDeep<{
  promiseUuid: SingleServePromise.SingleServePromiseIdString;
  source?: SafetyNumberChangeSource;
}>;

export type GlobalModalsStateType = ReadonlyDeep<{
  confirmLeaveCallModalState: StartCallData | null;
  contactModalState?: ContactModalStateType;
  errorModalProps?: {
    buttonVariant?: ButtonVariant;
    description?: string;
    title?: string;
  };
  hasConfirmationModal: boolean;
  isProfileEditorVisible: boolean;
  isShortcutGuideModalVisible: boolean;
  isSignalConnectionsVisible: boolean;
  isStoriesSettingsVisible: boolean;
  isWhatsNewVisible: boolean;
  usernameOnboardingState: UsernameOnboardingState;
  profileEditorHasError: boolean;
  safetyNumberChangedBlockingData?: SafetyNumberChangedBlockingDataType;
  safetyNumberModalContactId?: string;
  stickerPackPreviewId?: string;
  userNotFoundModalState?: UserNotFoundModalStateType;
}>;

// Actions

export const SHOW_SEND_ANYWAY_DIALOG = "globalModals/SHOW_SEND_ANYWAY_DIALOG";
export const SHOW_ERROR_MODAL = "globalModals/SHOW_ERROR_MODAL";
const TOGGLE_CONFIRM_LEAVE_CALL_MODAL = "globalModals/TOGGLE_CONFIRM_LEAVE_CALL_MODAL";

export type ContactModalStateType = ReadonlyDeep<{
  contactId: string;
  conversationId?: string;
}>;

export type UserNotFoundModalStateType = ReadonlyDeep<
  | {
      type: "phoneNumber";
      phoneNumber: string;
    }
  | {
      type: "username";
      username: string;
    }
>;

export type ToggleConfirmLeaveCallModalActionType = ReadonlyDeep<{
  type: typeof TOGGLE_CONFIRM_LEAVE_CALL_MODAL;
  payload: StartCallData | null;
}>;

export type ShowSendAnywayDialogActionType = ReadonlyDeep<{
  type: typeof SHOW_SEND_ANYWAY_DIALOG;
  payload: SafetyNumberChangedBlockingDataType & {
    untrustedByConversation: RecipientsByConversation;
  };
}>;

export type ShowErrorModalActionType = ReadonlyDeep<{
  type: typeof SHOW_ERROR_MODAL;
  payload: {
    buttonVariant?: ButtonVariant;
    description?: string;
    title?: string;
  };
}>;
export type GlobalModalsActionType = ReadonlyDeep<
  ShowErrorModalActionType | ShowSendAnywayDialogActionType | ToggleConfirmLeaveCallModalActionType
>;

// Action Creators

export const actions = {
  showBlockingSafetyNumberChangeDialog,
  showErrorModal,
  toggleConfirmLeaveCallModal,
};

export const useGlobalModalActions = (): BoundActionCreatorsMapObject<typeof actions> => useBoundActions(actions);

export function toggleConfirmLeaveCallModal(payload: StartCallData | null): ToggleConfirmLeaveCallModalActionType {
  return {
    type: TOGGLE_CONFIRM_LEAVE_CALL_MODAL,
    payload,
  };
}

function showBlockingSafetyNumberChangeDialog(
  untrustedByConversation: RecipientsByConversation,
  explodedPromise: ExplodePromiseResultType<boolean>,
  source?: SafetyNumberChangeSource,
): ThunkAction<void, RootStateType, unknown, ShowSendAnywayDialogActionType> {
  const promiseUuid = SingleServePromise.set<boolean>(explodedPromise);

  return dispatch => {
    dispatch({
      type: SHOW_SEND_ANYWAY_DIALOG,
      payload: {
        untrustedByConversation,
        promiseUuid,
        source,
      },
    });
  };
}

function showErrorModal({
  buttonVariant,
  description,
  title,
}: {
  buttonVariant?: ButtonVariant;
  description?: string;
  title?: string;
}): ShowErrorModalActionType {
  return {
    type: SHOW_ERROR_MODAL,
    payload: {
      buttonVariant,
      description,
      title,
    },
  };
}

// Reducer

export function getEmptyState(): GlobalModalsStateType {
  return {
    hasConfirmationModal: false,
    confirmLeaveCallModalState: null,
    isProfileEditorVisible: false,
    isShortcutGuideModalVisible: false,
    isSignalConnectionsVisible: false,
    isStoriesSettingsVisible: false,
    isWhatsNewVisible: false,
    usernameOnboardingState: UsernameOnboardingState.NeverShown,
    profileEditorHasError: false,
  };
}

export function reducer(
  state: Readonly<GlobalModalsStateType> = getEmptyState(),
  action: Readonly<GlobalModalsActionType>,
): GlobalModalsStateType {
  if (action.type === TOGGLE_CONFIRM_LEAVE_CALL_MODAL) {
    return {
      ...state,
      confirmLeaveCallModalState: action.payload,
    };
  }

  if (action.type === SHOW_SEND_ANYWAY_DIALOG) {
    const { promiseUuid, source } = action.payload;

    return {
      ...state,
      safetyNumberChangedBlockingData: {
        promiseUuid,
        source,
      },
    };
  }

  if (action.type === SHOW_ERROR_MODAL) {
    return {
      ...state,
      errorModalProps: action.payload,
    };
  }

  return state;
}
