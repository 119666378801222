import { defaultLocale, defaultMessages, defaultI18n } from "./defaults";
import { LocalizerType } from "../types/Util";
import { LocaleMessagesType } from "../types/I18N";

let currentLocale = defaultLocale;

export const i18n: LocalizerType = {
  t: (key: string): string => {
    try {
      return defaultMessages[currentLocale][key] || key;
    } catch (error) {
      console.error(error);
      return key;
    }
  },
  setLocale: (locale: string): void => {
    try {
      if (defaultMessages[locale]) {
        currentLocale = locale;
      } else {
        console.warn(`Locale ${locale} not found, falling back to default locale.`);
      }
    } catch (error) {
      console.error(error);
    }
  },
  getLocale: (): string => {
    try {
      return currentLocale;
    } catch (error) {
      console.error(error);
      return "abc";
    }
  },
  getLocaleMessages: (): LocaleMessagesType => {
    try {
      return defaultMessages[currentLocale];
    } catch (error) {
      console.error(error);
      return { abc: "abc" };
    }
  },
};
