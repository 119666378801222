import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store, { ConversationActions, ConversationSelectors, GroupInfoActions, SystemSelectors } from "redux-store";
import useInputEvent from "./useInputEvent";
import useLazyEffect from "./useLazyEffect";
import { createSelector } from "reselect";
import { getInteractor } from "services/local.service";
import { replaceId2Name } from "utils/message.utils";
import { StorageUtil, toCamel } from "utils";

const memoizedReduxState = createSelector(
  [
    ConversationSelectors.getSelectedGroupId,
    ConversationSelectors.getThreadingId,
    state => state.conversationRedux.scrollToChildId,
  ],
  (selectedGroupId, selectedThreadId) => {
    return {
      selectedThreadId,
      selectedGroupId,
      categoryId: selectedThreadId || selectedGroupId,
    };
  },
);

const useDraftMessage = isAllowAttach => {
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  const isFocused = useSelector(SystemSelectors.isFocused);
  const draftMessages = useSelector(ConversationSelectors.getDraftMessages);
  const { categoryId, selectedThreadId, selectedGroupId } = useSelector(memoizedReduxState);
  const { inputRef, inputValue, insertContentInput, updateContentInput } = useInputEvent();
  const draftDataRef = useRef({ selectedThreadId, selectedGroupId, prefixKey });

  const [isInit, setIsInit] = useState(false);
  const [isShowUploadIcon, setIsShowUploadIcon] = useState(isAllowAttach);

  const isHandlingDraft = isAllowAttach && isInit;

  const updateMessageContent = useCallback(
    content => {
      updateContentInput(content);
      setIsShowUploadIcon(Boolean(isAllowAttach && false === Boolean(content?.trim())));
    },
    [isAllowAttach],
  );

  const resetDraftMessage = useCallback(() => {
    if (isAllowAttach) {
      saveDraftMessage("", draftDataRef.current);
    }
  }, [isAllowAttach]);

  const initDraftMessage = async () => {
    const isValid = selectedGroupId || selectedThreadId;
    if (!isValid) return;

    setIsInit(false);
    let draftMsgContent = draftMessages[categoryId];
    if (!draftMsgContent) {
      const group = await getInteractor().LocalGroupService.get(selectedGroupId);
      const thread = toCamel(await getInteractor().LocalThreadService.getByThreadId(selectedThreadId));

      const draftMessageDB = selectedThreadId ? thread.draftMessage : group.draftMessage;
      draftMsgContent = draftMessageDB ? await replaceId2Name(draftMessageDB, group.groupMembers, false) : null;
    }

    updateMessageContent(draftMsgContent || "");
    setIsInit(true);
  };

  // Save draft after typing about <delay_time>
  useLazyEffect(
    () => {
      if (isHandlingDraft) saveDraftMessage(inputValue, draftDataRef.current);
    },
    [isHandlingDraft, inputValue],
    DELAY_TYPING_TO_SAVE_DB,
  );

  // Save draft after unfocused app
  useLazyEffect(() => {
    if (isHandlingDraft && !isFocused) {
      saveDraftMessage(inputValue, draftDataRef.current);
    }
  }, [isHandlingDraft, isFocused]);

  // Save draft in redux when user typing
  useLazyEffect(() => {
    if (isHandlingDraft) {
      // Save draft message in redux when user typing
      draftMessages[categoryId] = inputValue;
      dispatch(
        ConversationActions.conversationSet({
          draftMessages: draftMessages,
        }),
      );
    }
  }, [isHandlingDraft, inputValue]);

  // Init data
  useLazyEffect(() => {
    if (isAllowAttach && categoryId && !isInit) {
      initDraftMessage();
    }
  }, [categoryId, draftMessages, isInit]);

  // Effect to handle when changing group/ thread/ branch
  useEffect(() => {
    if (isAllowAttach && isInit) {
      // Save draft message
      saveDraftMessage(inputValue, draftDataRef.current);
    }

    // Reset data
    draftDataRef.current = { selectedGroupId, selectedThreadId, prefixKey };
    setIsInit(false);
  }, [categoryId]);

  return {
    inputRef,
    isShowUploadIcon,
    insertContentInput,
    updateMessageContent,
    resetDraftMessage,
  };
};

export default useDraftMessage;

const DELAY_TYPING_TO_SAVE_DB = 60000; // 60 seconds

const saveDraftMessage = async (inputValue, data) => {
  const { prefixKey, selectedGroupId, selectedThreadId } = data || {};
  const isValid = selectedGroupId || selectedThreadId;
  if (!isValid) return;

  const validValue = inputValue?.trim();
  selectedThreadId
    ? await getInteractor(prefixKey).LocalThreadService.update({ draft_message: validValue }, { id: selectedThreadId })
    : await getInteractor(prefixKey).LocalGroupService.update({ draft_message: validValue }, { id: selectedGroupId });

  if (selectedGroupId && !selectedThreadId) {
    store.dispatch(
      GroupInfoActions.groupInfoSet({
        updatingGroupData: {
          id: selectedGroupId,
          draftMessage: validValue,
        },
      }),
    );
  }
};
