import { Call, CallingNotification } from "pages";
import { useHandleNotice } from "hooks";
import { useSelector } from "react-redux";
import { AppConstant } from "const";
import { SwitchBranchProcessing, HandleTimezoneWarning, NoticeDialog } from "components";
import { isLoginBranch } from "utils/auth.utils";
const HandleAppDialog = () => {
  const { noticeInfo, handleClose: handleCloseAlert } = useHandleNotice();

  const isOpenCallingDialog = useSelector(state => state.callingRedux.isOpenCallingDialog);
  
  const isChecked = isLoginBranch();
  return (
    <>
      {/* Show dialog when expire token */}
      {noticeInfo.open && (
        <NoticeDialog open={true} content={noticeInfo.content} submitProps={{ onClick: handleCloseAlert }} />
      )}

      {isChecked && (
        <>
          {isOpenCallingDialog === AppConstant.CALLING_STATUS.notStart && <CallingNotification />}
          {isOpenCallingDialog !== AppConstant.CALLING_STATUS.notStart && <Call />}
        </>
      )}

      <HandleTimezoneWarning />

      <SwitchBranchProcessing />
    </>
  );
};

export default HandleAppDialog;
