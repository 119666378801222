export enum RedisTopic {
  NONE = "NONE",
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  JOIN_ROOM = "JOIN_ROOM",
  JINGLE = "JINGLE",
  CLIENT_JINGLE = "CLIENT_JINGLE",
  LEAVE_ROOM = "LEAVE_ROOM",
  OFFER = "OFFER",
  ANSWER = "ANSWER",
  ICE_CANDIDATE = "ICE_CANDIDATE",
  HANG_UP = "HANG_UP",
  BUSY = "BUSY",
  OPAQUE = "OPAQUE",
}
