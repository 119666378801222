// Copyright 2019 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import type { ThunkAction } from "redux-thunk";
import {
  omit,
} from 'lodash';
import type { ReadonlyDeep } from "type-fest";
// import { DataWriter } from "../../sql/Client";
import type { StateType as RootStateType } from "../reducer";
import type { DurationInSeconds } from "../../util/durations";
import type { ShowSendAnywayDialogActionType } from "./globalModals";

import type { AvatarColorType, ConversationColorType, CustomColorType } from "../../types/Colors";
import type { DraftBodyRanges, HydratedBodyRangesType } from "../../types/BodyRange";
import type { MediaItemType } from "../../types/MediaItem";
import type { ServiceIdString, AciString, PniString } from "../../types/ServiceId";
// import type { AvatarDataType } from "../../types/Avatar";
import type { BoundActionCreatorsMapObject } from "../../hooks/useBoundActions";
import { useBoundActions } from "../../hooks/useBoundActions";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";

// State

export const InteractionModes = ["mouse", "keyboard"] as const;
export type InteractionModeType = ReadonlyDeep<(typeof InteractionModes)[number]>;

export const ConversationTypes = ["direct", "group"] as const;
export type ConversationTypeType = ReadonlyDeep<(typeof ConversationTypes)[number]>;

export type DraftPreviewType = ReadonlyDeep<{
  text: string;
  prefix?: string;
  bodyRanges?: HydratedBodyRangesType;
}>;

export type ConversationRemovalStage = ReadonlyDeep<"justNotification" | "messageRequest">;

export type ConversationType = ReadonlyDeep<
  {
    id: string;
    serviceId?: ServiceIdString;
    pni?: PniString;
    e164?: string;
    name?: string;
    nicknameGivenName?: string;
    nicknameFamilyName?: string;
    note?: string;
    systemGivenName?: string;
    systemFamilyName?: string;
    systemNickname?: string;
    familyName?: string;
    firstName?: string;
    profileName?: string;
    profileLastUpdatedAt?: number;
    username?: string;
    about?: string;
    aboutText?: string;
    aboutEmoji?: string;
    avatars?: ReadonlyArray<any>;
    avatarUrl?: string;
    rawAvatarPath?: string;
    avatarHash?: string;
    profileAvatarUrl?: string;
    unblurredAvatarUrl?: string;
    areWeAdmin?: boolean;
    areWePending?: boolean;
    areWePendingApproval?: boolean;
    canChangeTimer?: boolean;
    canEditGroupInfo?: boolean;
    canAddNewMembers?: boolean;
    color?: AvatarColorType;
    conversationColor?: ConversationColorType;
    customColor?: CustomColorType;
    customColorId?: string;
    discoveredUnregisteredAt?: number;
    hideStory?: boolean;
    isArchived?: boolean;
    isBlocked?: boolean;
    isReported?: boolean;
    reportingToken?: string;
    removalStage?: ConversationRemovalStage;
    isGroupV1AndDisabled?: boolean;
    isPinned?: boolean;
    isUntrusted?: boolean;
    isVerified?: boolean;
    activeAt?: number;
    timestamp?: number;
    lastMessageReceivedAt?: number;
    lastMessageReceivedAtMs?: number;
    inboxPosition?: number;
    left?: boolean;
    markedUnread?: boolean;
    phoneNumber?: string;
    membersCount?: number;
    hasMessages?: boolean;
    accessControlAddFromInviteLink?: number;
    accessControlAttributes?: number;
    accessControlMembers?: number;
    announcementsOnly?: boolean;
    announcementsOnlyReady?: boolean;
    expireTimer?: DurationInSeconds;
    memberships?: ReadonlyArray<{
      aci: AciString;
      isAdmin: boolean;
    }>;
    pendingMemberships?: ReadonlyArray<{
      serviceId: ServiceIdString;
      addedByUserId?: AciString;
    }>;
    pendingApprovalMemberships?: ReadonlyArray<{
      aci: AciString;
    }>;
    bannedMemberships?: ReadonlyArray<ServiceIdString>;
    muteExpiresAt?: number;
    dontNotifyForMentionsIfMuted?: boolean;
    isMe: boolean;
    lastUpdated?: number;
    // This is used by the CompositionInput for @mentions
    sortedGroupMembers?: ReadonlyArray<ConversationType>;
    title: string;
    titleNoDefault?: string;
    titleNoNickname?: string;
    searchableTitle?: string;
    unreadCount?: number;
    unreadMentionsCount?: number;
    isSelected?: boolean;
    isFetchingUUID?: boolean;
    typingContactIdTimestamps?: Record<string, number>;
    recentMediaItems?: ReadonlyArray<MediaItemType>;
    profileSharing?: boolean;
    sharingPhoneNumber?: boolean;

    shouldShowDraft?: boolean;
    // Full information for re-hydrating composition area
    draftText?: string;
    draftBodyRanges?: DraftBodyRanges;
    // Summary for the left pane
    draftPreview?: DraftPreviewType;

    sharedGroupNames: ReadonlyArray<string>;
    groupDescription?: string;
    groupVersion?: 1 | 2;
    groupId?: string;
    groupLink?: string;
    acceptedMessageRequest: boolean;
    secretParams?: string;
    publicParams?: string;
    profileKey?: string;
    voiceNotePlaybackRate?: number;

    badges: ReadonlyArray<
      | {
          id: string;
        }
      | {
          id: string;
          expiresAt: number;
          isVisible: boolean;
        }
    >;
  } & {
    type: "direct";
    storySendMode?: undefined;
    acknowledgedGroupNameCollisions?: undefined;
  }
>;

// Actions

export const TARGETED_CONVERSATION_CHANGED = "conversations/TARGETED_CONVERSATION_CHANGED";

export type ConversationAddedActionType = ReadonlyDeep<{
  type: 'CONVERSATION_ADDED';
  payload: {
    id: string;
    data: ConversationType;
  };
}>;

export type ConversationChangedActionType = ReadonlyDeep<{
  type: "CONVERSATION_CHANGED";
  payload: {
    id: string;
    data: ConversationType;
  };
}>;
export type ConversationRemovedActionType = ReadonlyDeep<{
  type: "CONVERSATION_REMOVED";
  payload: {
    id: string;
  };
}>;
export type TargetedConversationChangedActionType = ReadonlyDeep<{
  type: typeof TARGETED_CONVERSATION_CHANGED;
  payload: {
    conversationId?: string;
    messageId?: string;
    switchToAssociatedView?: boolean;
  };
}>;

// eslint-disable-next-line local-rules/type-alias-readonlydeep
export type ConversationActionType =
  | ConversationChangedActionType
  | ConversationRemovedActionType
  | TargetedConversationChangedActionType
  | ShowSendAnywayDialogActionType
  | ConversationAddedActionType;

// Action Creators

export const actions = {
  setVoiceNotePlaybackRate,
  conversationAdded
};

export const useConversationsActions = (): BoundActionCreatorsMapObject<typeof actions> => useBoundActions(actions);

// update the conversation voice note playback rate preference for the conversation
export function setVoiceNotePlaybackRate({
  conversationId,
  rate,
}: {
  conversationId: string;
  rate: number;
}): ThunkAction<void, RootStateType, unknown, ConversationChangedActionType> {
  return async dispatch => {
    const prefixKey = StorageUtil.getCurrentPrefixKey();
    const conversationModel = await getInteractor(prefixKey).LocalGroupService.get(conversationId);
    // const conversationModel = window.ConversationController.get(conversationId);
    if (conversationModel) {
      conversationModel.set({
        voiceNotePlaybackRate: rate === 1 ? undefined : rate,
      });
      // await DataWriter.updateConversation(conversationModel.attributes);
    }

    const conversation = conversationModel?.format();

    if (conversation) {
      dispatch({
        type: "CONVERSATION_CHANGED",
        payload: {
          id: conversationId,
          data: {
            ...conversation,
            voiceNotePlaybackRate: rate,
          },
        },
      });
    }
  };
}


export function conversationAdded({
  id,
  data,
}:{
  id: string;
  data: ConversationType;
}): ThunkAction<void, RootStateType, unknown, ConversationAddedActionType> {
  return async dispatch => {
    if (data) {
      dispatch({
        type: "CONVERSATION_ADDED",
        payload: {
          id: id,
          data: data
        },
      });
    }
  };
}


export function getEmptyState(): ConversationsStateType {
  return {
    conversationLookup: {},
    conversationsByE164: {},
    conversationsByServiceId: {},
    conversationsByGroupId: {},
    conversationsByUsername: {},
    targetedMessage: undefined,
    targetedMessageCounter: 0,
    targetedMessageSource: undefined,
    selectedMessageIds: undefined,
    showArchived: false,
    hasContactSpoofingReview: false,
    targetedConversationPanels: {
      isAnimating: false,
      wasAnimated: false,
      direction: undefined,
      stack: [],
      watermark: -1,
    },
  };
}
export type PreJoinConversationType = ReadonlyDeep<{
  avatar?: {
    loading?: boolean;
    url?: string;
  };
  groupDescription?: string;
  memberCount: number;
  title: string;
  approvalRequired: boolean;
}>;
export type ConversationLookupType = ReadonlyDeep<{
  [key: string]: ConversationType;
}>;
export enum TargetedMessageSource {
  Reset = "Reset",
  NavigateToMessage = "NavigateToMessage",
  Focus = "Focus",
}

export type ConversationsStateType = ReadonlyDeep<{
  preJoinConversation?: PreJoinConversationType;
  invitedServiceIdsForNewlyCreatedGroup?: ReadonlyArray<ServiceIdString>;
  conversationLookup: ConversationLookupType;
  conversationsByE164: ConversationLookupType;
  conversationsByServiceId: ConversationLookupType;
  conversationsByGroupId: ConversationLookupType;
  conversationsByUsername: ConversationLookupType;
  selectedConversationId?: string;
  targetedMessage: string | undefined;
  targetedMessageCounter: number;
  targetedMessageSource: TargetedMessageSource | undefined;
  targetedConversationPanels: {
    isAnimating: boolean;
    wasAnimated: boolean;
    direction: "push" | "pop" | undefined;
    stack: ReadonlyArray<any>;
    watermark: number;
  };
  selectedMessageIds: ReadonlyArray<string> | undefined;

  showArchived: boolean;
  hasContactSpoofingReview: boolean;
}>;

export function reducer(
  state: Readonly<ConversationsStateType> = getEmptyState(),
  action: Readonly<ConversationActionType>,
): ConversationsStateType {
  if (action.type === 'CONVERSATION_ADDED') {
    const { payload } = action;
    const { id, data } = payload;
    const { conversationLookup } = state;

    return {
      ...state,
      conversationLookup: {
        ...conversationLookup,
        [id]: data,
      },
      ...updateConversationLookups(data, undefined, state),
    };
  }
  return state;
}



export function updateConversationLookups(
  added: ConversationType | undefined,
  removed: ConversationType | undefined,
  state: ConversationsStateType
): Pick<
  ConversationsStateType,
  | 'conversationsByE164'
  | 'conversationsByServiceId'
  | 'conversationsByGroupId'
  | 'conversationsByUsername'
  > {
  const result = {
    conversationsByE164: state.conversationsByE164,
    conversationsByServiceId: state.conversationsByServiceId,
    conversationsByGroupId: state.conversationsByGroupId,
    conversationsByUsername: state.conversationsByUsername,
  };

  if (removed && removed.e164) {
    result.conversationsByE164 = omit(result.conversationsByE164, removed.e164);
  }
  if (removed && removed.serviceId) {
    result.conversationsByServiceId = omit(
      result.conversationsByServiceId,
      removed.serviceId
    );
  }
  if (removed && removed.pni) {
    result.conversationsByServiceId = omit(
      result.conversationsByServiceId,
      removed.pni
    );
  }
  if (removed && removed.groupId) {
    result.conversationsByGroupId = omit(
      result.conversationsByGroupId,
      removed.groupId
    );
  }
  if (removed && removed.username) {
    result.conversationsByUsername = omit(
      result.conversationsByUsername,
      removed.username
    );
  }

  if (added && added.e164) {
    result.conversationsByE164 = {
      ...result.conversationsByE164,
      [added.e164]: added,
    };
  }
  if (added && added.serviceId) {
    result.conversationsByServiceId = {
      ...result.conversationsByServiceId,
      [added.serviceId]: added,
    };
  }
  if (added && added.pni) {
    result.conversationsByServiceId = {
      ...result.conversationsByServiceId,
      [added.pni]: added,
    };
  }
  if (added && added.groupId) {
    result.conversationsByGroupId = {
      ...result.conversationsByGroupId,
      [added.groupId]: added,
    };
  }
  if (added && added.username) {
    result.conversationsByUsername = {
      ...result.conversationsByUsername,
      [added.username]: added,
    };
  }

  return result;
}



