import { Avatar, Box, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ApiConstant, ImageConstant, LangConstant, SystemConstant } from "const";
import { useEffect, useState } from "react";
import { CommonBranchInfoService } from "services";
import { BGlobalServerImage } from "const/image.const";
import { getLoginBranchInfo } from "utils/auth.utils";
import { getNSLang } from "utils/lang.utils";

const BranchInfo = ({ isShowWelcomeText = true }) => {
  const classes = useStyles();

  const loginBranchInfo = getLoginBranchInfo();

  const [branch, setBranch] = useState({
    branchId: "",
    avatarUrl: "",
    branchName: "",
  });

  const handleUpdateBranchInfo = async (domain, branchId) => {
    if (false === Boolean(domain) && false === Boolean(branchId)) {
      branchId = SystemConstant.GLOBAL_BRANCH_ID;
      domain = ApiConstant.BASE_URL.split("/")[2];
    }
    const remoteBranch = await CommonBranchInfoService.getBranchWithoutToken(domain, branchId);

    // Branch logo
    const avatarUrl = CommonBranchInfoService.getBranchAvatarUrl(domain, branchId);
    setBranch({
      branchId: branchId,
      avatarUrl: avatarUrl || BGlobalServerImage,
      branchName: remoteBranch.name || "",
    });
  };

  useEffect(() => {
    if (branch.branchId !== loginBranchInfo.id) {
      handleUpdateBranchInfo(loginBranchInfo.domain, loginBranchInfo.id);
    }
  }, [loginBranchInfo]);

  return branch.branchId && branch.branchId !== SystemConstant.GLOBAL_BRANCH_ID ? (
    <Box className={classes.component}>
      {isShowWelcomeText && (
        <Box className={classes.titleBox}>
          <Typography className={classes.boldText}>
            {getNSLang(LangConstant.NS_LOGIN, "TXT_LOGIN_WELCOME_TITLE")}
          </Typography>

          <Typography className={classes.welcomeSubtitle}>
            {getNSLang(LangConstant.NS_LOGIN, "TXT_LOGIN_WELCOME_SUBTITLE")}
          </Typography>
        </Box>
      )}
      <Avatar variant="square" className={classes.branchAvatar} src={branch.avatarUrl} alt="Branch Logo">
        {branch.branchName}
      </Avatar>
      <Typography className={classes.boldText}>{branch.branchName}</Typography>
    </Box>
  ) : (
    <CardMedia component="img" src={ImageConstant.LogoImage} className={classes.logoImg} />
  );
};

export default BranchInfo;

const useStyles = makeStyles({
  component: {
    marginBottom: 60,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  boldText: {
    fontWeight: 700,
    fontSize: "1.25rem",
    textAlign: "center",
  },

  welcomeSubtitle: {
    textAlign: "center",
  },

  branchAvatar: {
    width: 160,
    height: 160,
    marginBottom: 20,

    "& img": {
      objectFit: "contain",
    },
  },

  titleBox: {
    marginBottom: 50,
  },

  logoImg: {
    maxWidth: 150,
  },
});
