import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Box, Grid, IconButton } from "@mui/material";
import { LangConstant, KeyConstant } from "const";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Call, VideoCall, Message, Edit, Delete } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ButtonGroupInfo from "../GroupInfoDrawer/ButtonGroupInfo";
import DialogConfirmLeave from "./DialogConfirmLeave";
import PopupChangeContactName from "./PopupChangeContactName";
import { AppAvatar, AppDrawer } from "components";
import { useCreateConversation, useManageContact } from "hooks";
import { getInteractor } from "services/local.service";
import { StorageUtil, toCamel } from "utils";
import BackgroundImage from "../Account/BackgroundImage";
import { createSelector } from "reselect";
import { AddingContactButton } from "components/dialog/AddingContactDialog";
import { BranchSelectors } from "redux-store";

const memoizedReduxState = createSelector(
  [state => state.callingRedux.isInCall, state => state.profileRedux.hasInternet, BranchSelectors.isBranchServer],
  (isInCall, hasInternet, isBranchServer) => {
    return { isInCall, hasInternet, isBranchServer };
  },
);

const AccountInfoDrawer = ({ data, onClose, onBackToMain, ...drawerProps }) => {
  const classes = useStyles();
  const styles = createStyleObj();
  const { createWithPersonal } = useCreateConversation();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const isMyPhone = accountId === data.id;

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const contactObjContent = getLabel(LangConstant.OBJ_ADDING_CONTACT, { returnObjects: true });
  const { executeContact, updateContact, removeContact } = useManageContact();

  const { isInCall, hasInternet, isBranchServer } = useSelector(memoizedReduxState);

  const [isShowChangingName, setIsShowChangingName] = useState(false);
  const [isConfirmDeleteContact, setIsConfirmDeleteContact] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [contactRecordId, setContactRecordId] = useState();
  const [account, setAccount] = useState({ ...data });

  const onMessenger = () => {
    createWithPersonal(account);

    onClose();
    onBackToMain();
  };

  const handleAudioCall = () => {
    createWithPersonal(account, { isDemandVoiceCall: true });

    onClose();
    onBackToMain();
  };

  const handleVideoCall = () => {
    createWithPersonal(account, { isDemandVoiceCall: true });

    onClose();
    onBackToMain();
  };

  const onChangeContactName = () => {
    if (contactRecordId) {
      updateContact(contactRecordId, accountName);
    }

    setIsShowChangingName(false);
  };

  const handleRemoveContact = () => {
    if (contactRecordId) {
      removeContact(contactRecordId);
    }

    setIsConfirmDeleteContact(false);
  };

  useEffect(() => {
    if (!data.id) return;

    if (executeContact && executeContact.contactId === data.id) {
      // Get data again after unfriend, add contact, changing name
      getInteractor()
        .LocalAccountService.get(data.id)
        .then(accountRecord => {
          const camelAccount = toCamel(accountRecord);
          setAccount(camelAccount);
          setAccountName(camelAccount.name);
        });

      // Get record id from contact table
      getInteractor(prefixKey)
        .LocalContactService.getContact(accountId, data.id)
        .then(contactRecord => {
          setContactRecordId(contactRecord?.id);
        });
    }
  }, [data, executeContact]);

  const isFriend = Boolean(account.isContact);
  const isShowAddingIcon = !isBranchServer && !isFriend;
  if (!data?.id) return;
  return (
    <AppDrawer onClose={onClose} title={contactObjContent.title} {...drawerProps}>
      <Box className={classes.rootSearchResult}>
        <BackgroundImage backgroundId={account?.backgroundId} />
        <Box className={classes.avatarBox}>
          <AppAvatar
            avatarId={account?.avatarId}
            className={classes.avatarName}
            sx={account?.avatarId ? styles.avatar : styles.avatarDefault}
          />
        </Box>
      </Box>

      <Box>
        <Typography className={classes.nameSearch}>{isFriend ? account.name : account.phone}</Typography>
        <Grid className={classes.gridSearchResultBtn} container>
          {isMyPhone ? (
            <Typography className={classes.currentUser}>{contactObjContent.currentUser}</Typography>
          ) : (
            <>
              {isShowAddingIcon && (
                <CustomGrid className={classes.gridBtn} item>
                  <AddingContactButton account={account} />
                </CustomGrid>
              )}
              <CustomGrid className={classes.gridBtn} item>
                <IconButton className={classes.iconBtn} disabled={isInCall || !hasInternet} onClick={handleAudioCall}>
                  <Call />
                </IconButton>
                <Typography variant="body2">{contactObjContent.callContact}</Typography>
              </CustomGrid>
              <CustomGrid className={classes.gridBtn} item>
                <IconButton className={classes.iconBtn} disabled={isInCall || !hasInternet} onClick={handleVideoCall}>
                  <VideoCall />
                </IconButton>
                <Typography variant="body2">{contactObjContent.videoContact}</Typography>
              </CustomGrid>
              <CustomGrid className={classes.gridBtn} item onClick={onMessenger}>
                <IconButton className={classes.iconBtn}>
                  <Message />
                </IconButton>
                <Typography variant="body2">{contactObjContent.mesContact}</Typography>
              </CustomGrid>
            </>
          )}
        </Grid>

        {isFriend && (
          <Box className={classes.body}>
            <ButtonGroupInfo
              name={contactObjContent.changeNickname}
              icon={<Edit className={classes.iconSearchInfoBtn} />}
              onClick={() => setIsShowChangingName(true)}
            />

            <ButtonGroupInfo
              name={contactObjContent.deleteContact}
              icon={<Delete className={classes.iconSearchInfoBtn} />}
              onClick={() => setIsConfirmDeleteContact(true)}
            />
          </Box>
        )}

        {isShowChangingName && (
          <PopupChangeContactName
            onChange={setAccountName}
            open
            onClose={() => setIsShowChangingName(false)}
            onSubmit={onChangeContactName}
          />
        )}

        {isConfirmDeleteContact && (
          <DialogConfirmLeave
            open
            onClose={() => setIsConfirmDeleteContact(false)}
            title={contactObjContent.deleteContactPopup}
            content={getLabel(contactObjContent.deleteContactTitlePopup, { name: account?.name })}
            submitProps={{ submitText: contactObjContent.deleteContact, onClick: handleRemoveContact }}
            cancelProps={{ onClick: () => setIsConfirmDeleteContact(false) }}
          />
        )}
      </Box>
    </AppDrawer>
  );
};

AccountInfoDrawer.propTypes = {
  ...AppDrawer.propTypes,
  data: PropTypes.object.isRequired,
};

export default AccountInfoDrawer;

const CustomGrid = ({ isPersonal, children, ...props }) => (
  <Grid {...props} xs={isPersonal ? 4 : 3}>
    {children}
  </Grid>
);

const useStyles = makeStyles(theme => ({
  body: {
    width: "100%",
    display: "grid",
    padding: "0px 6px",
  },

  container: {
    padding: 16,
  },

  title: {
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
  },

  rootSearchResult: {
    position: "relative",
    marginBottom: 12,
  },

  cover: {
    width: "100%",
    height: 180,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 71.67%, rgba(0, 0, 0, 0.5) 100%)",
    marginBottom: 36,
  },

  coverDefault: {
    width: "100%",
    height: 180,
    marginBottom: 36,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, darkgray 71.67%, darkgray 100%)",
  },

  avatarBox: {
    position: "absolute",
    bottom: -36,
    width: "100%",
  },

  nameSearch: {
    marginTop: 50,
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: "1.25rem",
    textAlign: "center",
  },

  gridSearchResultBtn: {
    marginTop: 12,
    padding: "0 10px",
    display: "flex",
    justifyContent: "center",
  },

  gridBtn: {
    textAlign: "center",
  },

  iconBtn: {
    backgroundColor: theme.palette.grey[100],
    marginBottom: 4,
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },

  iconSearchInfoBtn: {
    width: 18,
    height: 18,
  },

  avatarName: {
    fontSize: "3rem",
    fontWeight: 500,
  },

  currentUser: {
    fontSize: "0.9375rem",
    fontWeight: 500,
  },
}));

const createStyleObj = () => ({
  avatar: {
    width: 120,
    height: 120,
    margin: "auto",
    border: "2px solid white",
  },
  avatarDefault: {
    width: 120,
    height: 120,
    margin: "auto",
    border: "2px solid white",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 71.67%, rgba(0, 0, 0, 0.5) 100%)",
  },
  name: {
    fontWeight: 700,
    fontSize: "0.9375rem",
  },
});
