import { createReducer, createActions } from "reduxsauce";
import { REDUX_STATE, requestReducerFunc, successReducerFunc } from "./redux-structure";
import { StorageUtil } from "utils";
import { KeyConstant } from "const";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  synchronizeData: ["prefixKey"],
  executeQueue: [],
  systemSet: ["data"],
});

export const SystemTypes = Types;
export const SystemActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
  ...REDUX_STATE,

  isSynchronizing: false, // true: Synch data when login or switch branch
  isSynchMessage: false, // true: Synch message periodic
  isFocused: true, // true: User focus app
  screenLock: { isShow: Boolean(StorageUtil.getCommonKey(KeyConstant.SCREEN_LOCK_STATUS)), modified: Date.now() }, // flag to trigger check value of KeyConstant.SCREEN_LOCK_STATUS

  /**
   * New notification has 2 types:
   * 1. Invitation: {type: 2, branchId, ...other}
   * 2. Normal notification: { groupId, messageSourceId, prefixKey }
   */
  newNotification: null,
  toast: {}, // { content, severity, modified }

  // TODO: Hotfix - show management file as a dialog
  isShowMnFile: false,
  seenCallHistoryTimestamp: 0, // timestamp seen call history
  screenLockSettingTimestamp: 0,
  isPlayingVideo: false,

  allBranchUnreadObj: {}, // { <branchId>: totalUnread }
  isShowMnRequest: false,

  requestNotice: {}, // request details
};

/* ------------- Selector ------------- */
export const SystemSelectors = {
  isSystemSynchronizing: ({ systemRedux }) => Boolean(systemRedux.isSynchronizing),
  getNewNotification: ({ systemRedux }) => systemRedux.newNotification,
  isShowMnFile: ({ systemRedux }) => systemRedux.isShowMnFile,
  seenCallHistoryTimestamp: ({ systemRedux }) => systemRedux.seenCallHistoryTimestamp,
  isFocused: ({ systemRedux }) => Boolean(systemRedux.isFocused),
  screenLockSettingTimestamp: ({ systemRedux }) => systemRedux.screenLockSettingTimestamp,
  isPlayingVideo: ({ systemRedux }) => systemRedux.isPlayingVideo,
  getAllBranchUnread: ({ systemRedux }) => systemRedux.allBranchUnreadObj,
  isShowMnRequest: ({ systemRedux }) => systemRedux.isShowMnRequest,
  checkScreenLock: ({ systemRedux }) => systemRedux.screenLock,
  getRequestNotice: ({ systemRedux }) => systemRedux.requestNotice,
};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) => requestReducerFunc(state);

const set = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.SYNCHRONIZE_DATA]: request,
  [Types.EXECUTE_QUEUE]: request,
  [Types.SYSTEM_SET]: set,
};

/* ------------- Hookup Reducers To Types ------------- */
export const SystemReducer = createReducer(INITIAL_STATE, HANDLERS);
