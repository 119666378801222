import { MainContent, MainLayout, MainSidebar, Welcome } from "components";
import RequestLoginForm from "./RequestLoginForm";
import { getLoginBranchInfo } from "utils/auth.utils";

const RequestLoginPage = () => {
  const tmpLoginBranch = getLoginBranchInfo();

  return (
    <MainLayout>
      <MainSidebar>
        <RequestLoginForm />
      </MainSidebar>

      <MainContent className="align-items">
        <Welcome branchId={tmpLoginBranch.id} domain={tmpLoginBranch.domain} />
      </MainContent>
    </MainLayout>
  );
};

export default RequestLoginPage;
