import { Box, Dialog, IconButton, styled, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Close, CheckCircleOutline } from "@mui/icons-material";

const SuccessDialog = ({ title, content, IconClose, onClose, ...otherProps }) => {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} onClose={onClose} {...otherProps}>
      <CloseButton aria-label="close" onClick={onClose}>
        {IconClose || <Close />}
      </CloseButton>

      <Box className={classes.iconBox}>
        <CheckCircleOutline fontSize="large" color="primary" />
      </Box>
      {title && <Typography className={classes.title}>{title}</Typography>}
      {content && <Typography className={classes.content}>{content}</Typography>}
    </Dialog>
  );
};

SuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SuccessDialog;

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minWidth: 300,
    maxWidth: 500,
    padding: 20,
    borderRadius: 12,
  },

  iconBox: {
    margin: "auto",

    "& svg": {
      fontSize: 80,
    },
  },

  title: {
    textAlign: "center",
    fontWeight: "bold",
  },

  content: {
    textAlign: "center",
    fontSize: "0.75rem",
  },
}));

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: 6,
  right: 6,
  color: "black",
});
