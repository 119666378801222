import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { LocalAppNotificationService, LocalDbManagement, getInteractor } from "services/local.service";
import {
  CryptoUtil,
  StorageUtil,
  convertString2JSON,
  getDataFromPrefixKey,
  getPrefixKey,
  isExternalLink,
  toCamel,
  toSnake,
} from "utils";
import {
  ARR_NOTICE_NORMAL,
  ARR_NOTICE_SPECIAL,
  checkCurrentBranchByPrefix,
  checkTriggerMessageUI,
  formatPagingParams,
} from "../../../sagas/saga.helper";
import { remoteApiFactory } from "services";
import { saveKeysOfDevice } from "../../../sagas/account-key.saga";
import store, { ConversationActions, SystemActions } from "redux-store";
import { updateThread } from "../../../sagas/thread.saga";
import { getLabel } from "language";
import { updateMessageStatus } from "../../../sagas/conversation-message.saga";
import { getNSLang } from "utils/lang.utils";
import { sortBy, uniqWith, isEqual, uniqBy } from "lodash";
import { getDevice } from "../device.service";
import { handleNewConversation } from "../conversation.service";
import { handleSendErrorMsg, handlingErrorMessage } from "./error-message";
import { handleCallingMessage } from "./call-message";
import { replaceId2Name } from "utils/message.utils";
import { checkCurrentGroup, checkCurrentThread, checkFocusedApp, checkingPushNotification } from "utils/view.utils";

let isFetchingMessage = false;
export const getMessage = async (prefixKey, time2FetchMessage) => {
  if (isFetchingMessage) return;
  isFetchingMessage = true;

  try {
    const localTime2FetchMessage = StorageUtil.getItem(KeyConstant.KEY_TIME_2_FETCH_MESSAGE, prefixKey) || 0;
    let lastMessage = await getInteractor(prefixKey).LocalMessageService.getLastMessage();
    const minTime2Fetching = Math.min(lastMessage?.created, time2FetchMessage, localTime2FetchMessage) || 0;

    // Call API to get ${LIMIT_MESSAGE_API} message until get all new message on server
    const messageParams = formatPagingParams({
      isOnline: true,
      limit: 100,
      offset: 0,
      paging: 1,
      since_time: minTime2Fetching,
    });
    let isContinue = navigator.onLine;
    let currentPage = 1;

    while (isContinue) {
      const response = await remoteApiFactory.getBranchApi(prefixKey).getMessageList(toSnake(messageParams));
      const responseData = Array.isArray(response.data?.data) ? response.data.data : [];

      if (response.status === ApiConstant.STT_OK && responseData.length > 0) {
        const remoteMessageList = sortBy(responseData, [message => message.created]); // ASC sort by created
        await handleRemoteMessageList(prefixKey, remoteMessageList);
        await setUnreadInAppLogo();

        lastMessage = await getInteractor(prefixKey).LocalMessageService.getLastMessage();
        const maxTime2Fetching = Math.max(lastMessage?.created, localTime2FetchMessage) || 0;
        StorageUtil.setItem(KeyConstant.KEY_TIME_2_FETCH_MESSAGE, maxTime2Fetching, prefixKey);
      }

      isContinue =
        response.status === ApiConstant.STT_OK && responseData.length === messageParams.limit && navigator.onLine;
      if (isContinue) {
        // Update params for next api
        messageParams.offset = currentPage * messageParams.limit;
        currentPage = currentPage + 1;
      }
    }
  } catch (error) {
    console.log("fetch message error: ", error);
  }

  isFetchingMessage = false;
  return true;
};

const handleRemoteMessageList = async (prefixKey, remoteMessageList) => {
  if (!Array.isArray(remoteMessageList)) throw new Error("remoteMessageList is not array");

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const noticeMessageList = [];

  // 1. Synch keys of all devices to prepare decrypt remote message
  let deviceIds = remoteMessageList.map(item => ({
    deviceId: item.sender_device_id,
    accountId: item.sender_id,
  }));
  deviceIds = uniqWith(deviceIds, isEqual); // Remove duplicate data
  for (let deviceIndex = 0; deviceIndex < deviceIds.length; deviceIndex++) {
    const deviceFrom = deviceIds[deviceIndex];
    let deviceLocal = await getInteractor(prefixKey).LocalDeviceService.get(deviceFrom.deviceId);
    const deviceLocalByMac = await getInteractor(prefixKey).LocalDeviceService.getDeviceByMac(deviceFrom.deviceId);
    if (deviceLocalByMac && !deviceLocal) deviceLocal = deviceLocalByMac;
    if (!deviceLocal) {
      deviceLocal = await getDevice(prefixKey, deviceFrom.accountId, deviceFrom.deviceId);
    }

    if (deviceLocal && deviceLocal.key_f !== SystemConstant.DEVICE_KEY_STATE.correct) {
      await saveKeysOfDevice(prefixKey, {
        accountId: deviceLocal.account_id,
        deviceId: deviceLocal.id,
      });
    }
  }

  // 2. Handling message
  let isNeedUpdateUI = true;
  const uniqueGroupId = uniqBy(remoteMessageList, message => message.group_id).map(item => item.group_id);
  const handlingMessagePromises = uniqueGroupId.map(async groupId => {
    const group = await getGroup(groupId, prefixKey);
    if (group && group.id) {
      const messageInGroup = sortBy(
        remoteMessageList.filter(item => item.group_id === groupId),
        [message => message.created],
      );

      for (let i = 0; i < messageInGroup.length; i++) {
        const remoteMessage = toCamel(messageInGroup[i]);
        const localMessage = await getInteractor(prefixKey).LocalMessageService.get(remoteMessage.id);

        if (remoteMessage.sendType !== SystemConstant.SEND_TYPE.restoreData && !(localMessage && localMessage.id)) {
          const handlingMessageResult = await handleMessage(remoteMessage, group, prefixKey);
          isNeedUpdateUI = Boolean(handlingMessageResult.isNeedUpdateUI);

          // Check created time message and confirm message need to show or not
          if (checkingPushNotification(handlingMessageResult.noticeMessage?.message)) {
            noticeMessageList.push(handlingMessageResult.noticeMessage);
          }
        }
      }
    }
  });

  await Promise.all(handlingMessagePromises);

  // 3. Show notification
  for (let index = 0; index < noticeMessageList.length; index++) {
    const { prefixKey, message, isInvolvedThread } = noticeMessageList[index];

    // Skip show notification if message is read/ seen
    const seenMessageRecord = await getInteractor(prefixKey).LocalSeenMemberService.findOne({
      source_id: message.sourceId,
      member_account_id: accountId,
    });
    if (seenMessageRecord?.id) {
      continue;
    }

    await handleShowPushNotification(prefixKey, message, isInvolvedThread);
  }

  // 4. Update message status
  // Send "READ" status to server if message is in selectedGroup
  // Message not in selected group: Send "RECEIVED" status to server
  const normalMessages = remoteMessageList.filter(
    mes =>
      mes.send_type !== SystemConstant.SEND_TYPE.senderKey &&
      mes.send_type !== SystemConstant.SEND_TYPE.keyError &&
      mes.sender_id !== accountId,
  );

  const readMessageIds = [];
  const receivedMessageIds = [];
  normalMessages.forEach(mes => {
    if (
      mes.status !== SystemConstant.MESSAGE_STATUS.read &&
      false === Boolean(mes.thread_id) &&
      checkCurrentGroup(mes.group_id) &&
      checkFocusedApp()
    ) {
      readMessageIds.push(mes.id);
    } else if (
      mes.status !== SystemConstant.MESSAGE_STATUS.read &&
      mes.status !== SystemConstant.MESSAGE_STATUS.received
    ) {
      receivedMessageIds.push(mes.id);
    }
  });

  if (readMessageIds.length > 0) {
    await updateMessageStatus({
      data: {
        messageIds: readMessageIds,
        status: SystemConstant.MESSAGE_STATUS.read,
      },
      prefixKey,
    });
  }

  if (receivedMessageIds.length > 0) {
    await updateMessageStatus({
      data: {
        messageIds: receivedMessageIds,
        status: SystemConstant.MESSAGE_STATUS.received,
      },
      prefixKey,
    });
  }

  const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
  if (isCurrentBranch && isNeedUpdateUI) {
    store.dispatch(
      ConversationActions.conversationSet({
        isUpdateViewMode: Date.now(),
      }),
    );
  }
};

const getGroup = async (groupId, prefixKey) => {
  let group = await getInteractor(prefixKey).LocalGroupService.get(groupId);
  // Group is not exist in local db => sync group
  if (!(group && group.id)) {
    const isSynchGroup = await handleNewConversation(prefixKey, groupId);
    if (isSynchGroup) {
      group = await getInteractor(prefixKey).LocalGroupService.get(groupId);
    }
  }

  return group;
};

const handleMessage = async (newMessage, group, prefixKey) => {
  if (false === Boolean(newMessage) || false === Boolean(newMessage.id)) return;
  let isNeedUpdateUI = false;
  let isInvolvedThread = false;
  let noticeMessage;

  try {
    const ERROR_MSG_SEND_TYPE = [
      SystemConstant.SEND_TYPE.senderKeyDeliveryError,
      SystemConstant.SEND_TYPE.keyError,
      SystemConstant.SEND_TYPE.senderKey,
    ];
    if (ERROR_MSG_SEND_TYPE.includes(newMessage.sendType)) {
      await handlingErrorMessage(newMessage, group, prefixKey);
    } else {
      let checkSenderIdBlock = await getInteractor(prefixKey).LocalContactService.getContact(
        newMessage.accountId,
        newMessage.senderId,
      );
      if (newMessage.accountId === newMessage.senderId) {
        checkSenderIdBlock = null;
      }
      const isBlockedSender = checkSenderIdBlock && checkSenderIdBlock.status === SystemConstant.CONTACT_STATUS.block;

      if (isBlockedSender) {
        newMessage.status = SystemConstant.MESSAGE_STATUS.block;
      }

      const messageOptions = convertString2JSON(newMessage.options, {});
      const encryption_type =
        messageOptions?.encryption_f !== SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION
          ? SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION
          : SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION;

      // Decryption message
      let decryptContent = null;
      if (newMessage.sendType === SystemConstant.SEND_TYPE.botMessage) {
        const [_, branchId] = getDataFromPrefixKey(prefixKey);
        const branch = await getInteractor(prefixKey).LocalBranchService.findOne({ id: branchId });
        const branchOptions = branch.options ? convertString2JSON(branch.options, {}) : {};
        const publicKeyRSA = branchOptions.public_key_rsa;
        const encryptKey = messageOptions.s_key;
        const encryptIv = messageOptions.iv;
        if (false === Boolean(publicKeyRSA && encryptKey && encryptIv))
          return false; // Skip execute message if not enough data to decrypt message;
        else {
          const key = await CryptoUtil.decryptRSA(encryptKey, publicKeyRSA);
          const iv = await CryptoUtil.decryptRSA(encryptIv, publicKeyRSA);

          decryptContent = await CryptoUtil.decryptCBC_v2(newMessage.content, key, iv);
          if (isExternalLink(decryptContent)) {
            const newMsgOptions = { ...messageOptions, is_link: 1 };
            newMessage.options = JSON.stringify(newMsgOptions);
          }
        }
      } else {
        if (encryption_type === SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION) {
          if (group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
            decryptContent = await getInteractor(prefixKey).LocalCryptoService.decryptE2EMessage(
              newMessage.senderId,
              newMessage.senderDeviceId,
              newMessage.groupId,
              newMessage.content,
            );
          } else {
            decryptContent = await getInteractor(prefixKey).LocalCryptoService.decryptE2EEMessage(
              newMessage.senderId,
              newMessage.senderDeviceId,
              newMessage.groupId,
              newMessage.content,
            );
          }
        } else {
          decryptContent = newMessage.content;
        }
      }

      // Decrypt fail: resend
      if (decryptContent === null) {
        await handleSendErrorMsg(newMessage, SystemConstant.SEND_TYPE.keyError, group, prefixKey);
        newMessage.sendType = SystemConstant.SEND_TYPE.keyError;

        isNeedUpdateUI =
          (await getInteractor(prefixKey).LocalMessageService.saveFromRemote([toSnake(newMessage)])) || isNeedUpdateUI;
      } else if (decryptContent) {
        newMessage.content = decryptContent;

        const checkError = await getInteractor(prefixKey).LocalMsgErrorSendNullService.findByDeviceIdAndGroupIdAndType(
          newMessage.senderDeviceId,
          newMessage.groupId,
          0,
        );

        if (checkError != null)
          await getInteractor(prefixKey).LocalMsgErrorSendNullService.deleteByGroupIdAndDeviceIdAndType(
            newMessage.groupId,
            newMessage.senderDeviceId,
            0,
          );

        const localMsgSourceId = await getInteractor(prefixKey).LocalMessageService.findOne({
          source_id: newMessage.sourceId,
        });
        if (localMsgSourceId?.id) {
          newMessage.sendType = SystemConstant.SEND_TYPE.keyError;
        }

        isNeedUpdateUI =
          (await getInteractor(prefixKey).LocalMessageService.saveFromRemote([toSnake(newMessage)])) || isNeedUpdateUI;

        isInvolvedThread = updateThread(prefixKey, toSnake(newMessage));

        if (SystemConstant.ARR_CALLING_TYPES.includes(newMessage.sendType)) {
          await handleCallingMessage(prefixKey, newMessage.id, group);
        }

        const isMutedGroup = await getInteractor(prefixKey).LocalGroupSettingService.isMutedNotify(newMessage.groupId);
        const isBlockedOnGlobalBranch = Boolean(
          newMessage.branchId === SystemConstant.GLOBAL_BRANCH_ID && isBlockedSender,
        );
        if (!isMutedGroup && !isBlockedOnGlobalBranch && newMessage.status !== SystemConstant.MESSAGE_STATUS.read) {
          // Save message into noticeMessage that will show notification on OS
          noticeMessage = {
            prefixKey,
            message: newMessage,
            isInvolvedThread,
          };
        }

        // Trigger to UI
        if (checkTriggerMessageUI(prefixKey, newMessage)) {
          store.dispatch(ConversationActions.receivedRemoteMessage(newMessage));
        }
      }
    }
  } catch (error) {
    console.error({ error });

    isNeedUpdateUI =
      (await getInteractor(prefixKey).LocalMessageService.saveFromRemote([toSnake(newMessage)])) || isNeedUpdateUI;
  }

  return { isNeedUpdateUI, noticeMessage };
};

const isTurnOffInvolveThread = true; // Notify all thread message
const handleShowPushNotification = async (prefixKey, newMessage, isInvolvedThread) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

  // Show push notification
  const isEndPersonalCall = newMessage.callStatus === SystemConstant.MESSAGE_CALL_STATUS.end;
  const isEditMessage =
    SystemConstant.EDITABLE_SEND_TYPE.includes(newMessage.sendType) && true === Boolean(newMessage.parentId);
  const isNotifyThreadMsg =
    (isTurnOffInvolveThread || isInvolvedThread) &&
    newMessage.threadId &&
    false === checkCurrentThread(newMessage.threadId) &&
    newMessage.senderId !== accountId;

  const isNotifyMsg =
    false === Boolean(newMessage.threadId) &&
    false === checkCurrentGroup(newMessage.groupId) &&
    (newMessage.senderId !== accountId || isEndPersonalCall);
  const messageSourceId = newMessage.parentId || newMessage.sourceId;
  const { group_name: groupName } = await getInteractor(prefixKey).LocalGroupService.findById(newMessage.groupId);

  if (false === isEditMessage && (isNotifyMsg || isNotifyThreadMsg)) {
    const accountGroupList = await getInteractor(prefixKey).LocalAccountGroupService.find({
      group_id: newMessage.groupId,
    });
    const filteredArray = toCamel(accountGroupList)
      .filter(s => s.state !== SystemConstant.STATE.inactive)
      .map(r => r.accountId);
    const groupMembers = await getInteractor(prefixKey).LocalAccountService.getAccountByIds(filteredArray);
    const senderName = (groupMembers.find(item => item.id === newMessage.senderId) || {}).name;

    if (ARR_NOTICE_NORMAL.includes(newMessage.sendType) && groupMembers.length > 0) {
      const msgContent = await replaceId2Name(newMessage.content, groupMembers, false);
      LocalAppNotificationService.showNotification(groupName, {
        content: `${senderName}: ${msgContent}`,
        groupId: newMessage.groupId,
        threadId: newMessage.threadId,
        messageSourceId,
        prefixKey,
      });
    } else if (ARR_NOTICE_SPECIAL.includes(newMessage.sendType)) {
      const multimedia = getLabel(LangConstant.OBJ_SEND_MES_TYPE, { returnObjects: true });
      LocalAppNotificationService.showNotification(groupName, {
        content: `${senderName}: ${multimedia[newMessage.sendType]}`,
        groupId: newMessage.groupId,
        threadId: newMessage.threadId,
        messageSourceId,
        prefixKey,
      });
    } else if (
      SystemConstant.ARR_CALLING_TYPES.includes(newMessage.sendType) &&
      newMessage.callStatus === SystemConstant.MESSAGE_CALL_STATUS.missed
    ) {
      if(newMessage.sendType === SystemConstant.SEND_TYPE.personalCall) {
        LocalAppNotificationService.showNotification(groupName, {
          content: getNSLang(LangConstant.NS_CALLING, LangConstant.FM_MISSED_CALL_RING),
          groupId: newMessage.groupId,
          prefixKey,
        });
      } else if (newMessage.sendType === SystemConstant.SEND_TYPE.personalVideoCall) {
        LocalAppNotificationService.showNotification(groupName, {
          content: getNSLang(LangConstant.NS_CALLING, LangConstant.FM_MISSED_VIDEO_CALL_RING),
          groupId: newMessage.groupId,
          prefixKey,
        });
      } else {
        LocalAppNotificationService.showNotification(groupName, {
          content: getNSLang(LangConstant.NS_CALLING, LangConstant.FM_MISSED_CALL, {
            first: "Bạn",
            second: senderName,
          }),
          groupId: newMessage.groupId,
          prefixKey,
        });
      }
    } else if (
      SystemConstant.ARR_CALLING_TYPES.includes(newMessage.sendType) &&
      newMessage.callStatus === SystemConstant.MESSAGE_CALL_STATUS.end
    ) {
      LocalAppNotificationService.showNotification(groupName, {
        content: getNSLang(LangConstant.NS_CALLING, "TXT_CALL_ENDED"),
        groupId: newMessage.groupId,
        prefixKey,
      });
    } else if (newMessage.sendType === SystemConstant.SEND_TYPE.pinMessage) {
      const messageContent = newMessage.content ? convertString2JSON(newMessage.content, {}) : {};
      const isPin = messageContent.pin_type === SystemConstant.PIN_TYPE.pin;
      const displayContent = getNSLang(
        LangConstant.NS_HOME_CONVERSATION,
        isPin ? "FM_PIN_MESSAGE" : "FM_UNPIN_MESSAGE",
        {
          senderName: newMessage.senderId === accountId ? "Bạn" : senderName,
          message: "",
        },
      );
      LocalAppNotificationService.showNotification(groupName, {
        content: displayContent,
        groupId: newMessage.groupId,
        prefixKey,
      });
    }
  }
};

// Unread message + thread message + notification in all active branch
export const setUnreadInAppLogo = async () => {
  let allBranchUnread = 0;
  let allBranchUnreadObj = {}; // [{ branchId: totalUnread }]
  const activeBranchList = await LocalDbManagement.find({
    state: SystemConstant.STATE.active,
  });
  if (Array.isArray(activeBranchList) && activeBranchList.length > 0) {
    for (let index = 0; index < activeBranchList.length; index++) {
      const branch = activeBranchList[index];
      const branchPrefixKey = getPrefixKey(branch.account_id, branch.branch_id);
      const branchTotalUnreadInGroup = await getInteractor(branchPrefixKey).LocalGroupService.getTotalUnread();
      const branchTotalUnreadInThread =
        await getInteractor(branchPrefixKey).LocalThreadService.countTotalUnreadMessage();
      const numberUnreadNotice = (
        await getInteractor(branchPrefixKey).LocalNotificationService.getUnreadNormalNoticeInBranch(branch.branch_id)
      ).length;

      const totalUnread = branchTotalUnreadInGroup + branchTotalUnreadInThread + numberUnreadNotice;
      allBranchUnreadObj[branch.branch_id] = totalUnread;
      allBranchUnread = allBranchUnread + totalUnread;
    }
  }

  // Set count unread in all branch to App Logo
  LocalAppNotificationService.setBadgeCount(allBranchUnread);
  store.dispatch(
    SystemActions.systemSet({
      allBranchUnreadObj: allBranchUnreadObj,
    }),
  );
};
