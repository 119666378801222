import MessengerChat from "./components/MessengerChat";
import { InactiveDialog, MainContent, MainLayout, MainSidebar, Welcome } from "components";
import HandlingParallel from "./HandlingParallel";
import { useSelector } from "react-redux";
import { BranchSelectors, ConversationSelectors, SystemSelectors } from "redux-store";
import { createSelector } from "reselect";
import ManageRequest from "../ManageRequest";
import Account from "./components/Account";
import SelectServer from "./components/Sidebar/SelectServer";
import ViewModeTabs from "./components/Sidebar/ViewModeTabs";
import UpgradeApp from "./components/Sidebar/UpgradeApp";

const memoizedReduxState = createSelector(
  [
    ConversationSelectors.getSelectedGroupId,
    ConversationSelectors.getThreadingId,
    BranchSelectors.getSelectedBranch,
    SystemSelectors.isShowMnRequest,
  ],
  (selectedGroupId, selectedThreadId, selectedServer, isShowMnRequest) => {
    return {
      isChatView: Boolean(selectedGroupId || selectedThreadId),
      selectedServer,
      isShowMnRequest,
    };
  },
);

const Home = () => {
  const { isChatView, selectedServer, isShowMnRequest } = useSelector(memoizedReduxState);

  return (
    <>
      <HandlingParallel />

      <MainLayout>
        <MainSidebar>
          <Account />
          <SelectServer />
          <UpgradeApp />
          <ViewModeTabs />
        </MainSidebar>

        <MainContent>
          {isShowMnRequest ? (
            <ManageRequest />
          ) : isChatView ? (
            <MessengerChat />
          ) : (
            <Welcome branchId={selectedServer.id} domain={selectedServer.domain} />
          )}
        </MainContent>
      </MainLayout>

      <InactiveDialog />
    </>
  );
};

export default Home;
