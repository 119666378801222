import React, { memo } from "react";
import PropTypes from "prop-types";
import { Trans as I18Trans } from "react-i18next";
import { Box, Link, Button, Typography } from "@mui/material";

const TransLanguage = ({ href, linkProps, buttonProps, textProps, Component, ...otherProps }) => {
  return (
    <Box
      component={I18Trans}
      components={{
        bold: <strong />,
        a: <Link href={href} target="_blank" underline="none" color="inherit" {...linkProps} />,
        typography: <Typography {...textProps} />,
        button: <Button disableRipple {...buttonProps} />,
        br: <br />,
        component: Component && <Component />,
      }}
      {...otherProps}
    />
  );
};

TransLanguage.propTypes = {
  href: PropTypes.string,
  buttonProps: PropTypes.object,
  textProps: PropTypes.object,
  linkProps: PropTypes.object,
  Component: PropTypes.object,
};

export default memo(TransLanguage);
