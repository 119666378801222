import { StorageUtil, toCamel } from "utils";
import { getInteractor } from "./local.service";
import { KeyConstant, SystemConstant } from "const";
import { checkCurrentGroup } from "utils/view.utils";

// accountId is id of chatting account, not current account
export const updateGroupNameAfterChangingContact = async (prefixKey, accountId) => {
  const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  if (currentAccountId === accountId) return null;

  const groupRecord = await getInteractor(prefixKey).LocalGroupService.findOnePersonalGroup(accountId, branchId);
  const account = groupRecord ? await getInteractor(prefixKey).LocalAccountService.get(accountId, branchId) : null;

  if (account) {
    groupRecord.groupName = account.name;
    // Update related group
    await getInteractor(prefixKey).LocalGroupService.update(
      {
        group_name: groupRecord.groupName,
        name: JSON.stringify({ name: groupRecord.groupName }),
      },
      { id: groupRecord.id },
    );

    return groupRecord;
  }

  return null;
};

export const updateLastMsgAfterChangingContact = async (prefixKey, accountId) => {
  const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  const account = await getInteractor(prefixKey).LocalAccountService.get(accountId, branchId);
  if (currentAccountId === accountId || !Boolean(account?.id)) return;

  const accountGroupRecords = await getInteractor(prefixKey).LocalAccountGroupService.find({
    account_id: accountId,
    state: SystemConstant.STATE.active,
  });

  const relatedGroupIds = accountGroupRecords.reduce((groupIds, accountGroupItem) => {
    if (!groupIds.includes(accountGroupItem.group_id)) {
      groupIds.push(accountGroupItem.group_id);
    }
    return groupIds;
  }, []);
  const affectGroupIds = [];

  for (let index = 0; index < relatedGroupIds.length; index++) {
    const groupId = relatedGroupIds[index];
    const groupRecord = toCamel(await getInteractor(prefixKey).LocalGroupService.findById(groupId));
    const lastMessageRecord = groupRecord.lastMessageId
      ? await getInteractor(prefixKey).LocalMessageService.getMessage(groupRecord.lastMessageId)
      : null;

    // Skip loop if data is invalid
    const isNeedUpdateLastMessage =
      groupRecord.groupType === SystemConstant.GROUP_CHAT_TYPE.personal ||
      checkCurrentGroup(groupId) ||
      (lastMessageRecord && [lastMessageRecord.senderId, lastMessageRecord.content].includes(accountId));
    if (!isNeedUpdateLastMessage) continue;

    // Update senderName of last message in group
    const updatingGroup = lastMessageRecord
      ? await getInteractor(prefixKey).LocalGroupService.updateLastMessageOfGroup(groupRecord, lastMessageRecord)
      : groupRecord;
    if (updatingGroup.last_message) {
      await getInteractor(prefixKey).LocalGroupService.update(
        {
          last_message: updatingGroup.last_message,
        },
        { id: updatingGroup.id },
      );
    }

    affectGroupIds.push(updatingGroup.id);
  }

  return affectGroupIds;
};
