// Copyright 2020 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import type { ReactNode } from "react";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { noop } from "lodash";
import classNames from "classnames";
import type {
  ActiveCallStateType,
  SetLocalAudioType,
  SetLocalPreviewType,
  SetLocalVideoType,
  SetRendererCanvasType,
} from "../state/ducks/calling";
// import { Avatar, AvatarSize } from "./Avatar";
import { CallingHeader, getCallViewIconClassname } from "./CallingHeader";
import { CallingPreCallInfo, RingMode } from "./CallingPreCallInfo";
import { CallingButton, CallingButtonType } from "./CallingButton";
import { Button, ButtonVariant } from "./Button";
import { TooltipPlacement } from "./Tooltip";
import { CallBackgroundBlur } from "./CallBackgroundBlur";
import type { ActiveCallType } from "../types/Calling";
import { CallViewMode, CallState } from "../types/Calling";
import { CallMode } from "../types/CallDisposition";
import type { ConversationType } from "../state/ducks/conversations";
import { CallingButtonToastsContainer, useScreenSharingStoppedToast } from "./CallingToastManager";
import { DirectCallRemoteParticipant } from "./DirectCallRemoteParticipant";
import type { LocalizerType } from "../types/Util";
import { NeedsScreenRecordingPermissionsModal } from "./NeedsScreenRecordingPermissionsModal";
// import { missingCaseError } from "../util/missingCaseError";
import * as KeyboardLayout from "../services/keyboardLayout";
import { usePresenter, useActivateSpeakerViewOnPresenting } from "../hooks/useActivateSpeakerViewOnPresenting";
import { CallingAudioIndicator, SPEAKING_LINGER_MS } from "./CallingAudioIndicator";
import { useActiveCallShortcuts, useKeyboardShortcuts } from "../hooks/useKeyboardShortcuts";
import { useValueAtFixedRate } from "../hooks/useValueAtFixedRate";
// import { isReconnecting as callingIsReconnecting } from "../util/callingIsReconnecting";
import { usePrevious } from "../hooks/usePrevious";
import { PersistentCallingToast, useCallingToasts } from "./CallingToast";
// import { handleOutsideClick } from "../util/handleOutsideClick";
import { Spinner } from "./Spinner";
// import { assertDev } from "../util/assert";
import type { CallingImageDataCache } from "./CallManager";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { StorageUtil, toCamel, getFirstLetter } from "utils";
import { KeyConstant } from "const";
import { BranchSelectors, ProfileSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import { isEqual } from "lodash";
import { useServerList } from "hooks";

const assertDev = window.electronLibs.libs.assertDev;
const missingCaseError = window.electronLibs.libs.missingCaseError;
const callingIsReconnecting = window.electronLibs.libs.isReconnecting;
const handleOutsideClick = window.electronLibs.libs.handleOutsideClick;

export type PropsType = {
  activeCall: ActiveCallType;
  cancelPresenting: () => void;
  getPresentingSources: () => void;
  groupMembers?: Array<Pick<ConversationType, "id" | "firstName" | "title">>;
  hangUpActiveCall: (reason: string) => void;
  i18n: LocalizerType;
  imageDataCache: React.RefObject<CallingImageDataCache>;
  me: ConversationType;
  openSystemPreferencesAction: () => unknown;
  // renderReactionPicker: (props: React.ComponentProps<typeof SmartReactionPicker>) => JSX.Element;
  setLocalAudio: (_: SetLocalAudioType) => void;
  setLocalVideo: (_: SetLocalVideoType) => void;
  setLocalPreview: (_: SetLocalPreviewType) => void;
  setRendererCanvas: (_: SetRendererCanvasType) => void;
  stickyControls: boolean;
  switchToPresentationView: () => void;
  switchFromPresentationView: () => void;
  toggleParticipants: () => void;
  togglePip: () => void;
  toggleScreenRecordingPermissionsDialog: () => unknown;
  toggleSettings: () => void;
  changeCallView: (mode: CallViewMode) => void;
};
// & Pick<ReactionPickerProps, "renderEmojiPicker">;

export const isInSpeakerView = (call: Pick<ActiveCallStateType, "viewMode"> | undefined): boolean => {
  return Boolean(call?.viewMode === CallViewMode.Presentation || call?.viewMode === CallViewMode.Speaker);
};

// How many reactions of the same emoji must occur before a burst.

// Timeframe in which multiple of the same emoji must occur before a burst.

// Timeframe after a burst where new reactions of the same emoji are ignored for
// bursting. They are considered part of the recent burst.

// Max number of bursts in a short timeframe to avoid overwhelming the user.

function CallDuration({ joinedAt }: { joinedAt: number | null }): JSX.Element | null {
  const [acceptedDuration, setAcceptedDuration] = useState<number | undefined>();

  useEffect(() => {
    if (joinedAt == null) {
      return noop;
    }
    // It's really jumpy with a value of 500ms.
    const interval = setInterval(() => {
      setAcceptedDuration(Date.now() - joinedAt);
    }, 100);
    return clearInterval.bind(null, interval);
  }, [joinedAt]);

  if (acceptedDuration) {
    return <>{renderDuration(acceptedDuration)}</>;
  }
  return null;
}

export function CallScreen({
  activeCall,
  cancelPresenting,
  changeCallView,
  getPresentingSources,
  hangUpActiveCall,
  i18n,
  me,
  openSystemPreferencesAction,
  setLocalAudio,
  setLocalVideo,
  setLocalPreview,
  setRendererCanvas,
  stickyControls,
  switchToPresentationView,
  switchFromPresentationView,
  toggleParticipants,
  togglePip,
  toggleScreenRecordingPermissionsDialog,
  toggleSettings,
}: PropsType): JSX.Element {
  const {
    conversation,
    hasLocalAudio,
    hasLocalVideo,
    localAudioLevel,
    presentingSource,
    remoteParticipants,
    showNeedsScreenRecordingPermissionsWarning,
  } = activeCall;
  const callingGroupDetailRing = useSelector((state: any) => state.callingRedux.callingGroupDetailRing || {});

  const isSpeaking = useValueAtFixedRate(localAudioLevel > 0, SPEAKING_LINGER_MS);

  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const updateProfile = useSelector(ProfileSelectors.getUpdateProfile);
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  const [accountDetail, setAccountDetail] = useState<any>({});
  const isInAnotherCall = useSelector((state: any) => state.callingRedux.isInAnotherCall);
  const isCalleeInAnotherCall = useSelector((state: any) => state.callingRedux.isCalleeInAnotherCall);

  const handleSetAccountDetail = async () => {
    const accountRecord = await getInteractor(prefixKey).LocalAccountService.get(accountId, branchId);
    const newAccount = accountRecord ? toCamel(accountRecord) : {};
    if (false === isEqual(newAccount, accountDetail)) setAccountDetail(newAccount);
  };

  useEffect(() => {
    handleSetAccountDetail();
  }, [selectedBranch, updateProfile]);

  useActivateSpeakerViewOnPresenting({
    remoteParticipants,
    switchToPresentationView,
    switchFromPresentationView,
  });

  const activeCallShortcuts = useActiveCallShortcuts(hangUpActiveCall);
  useKeyboardShortcuts(activeCallShortcuts);

  const toggleAudio = useCallback(() => {
    setLocalAudio({
      enabled: !hasLocalAudio,
    });
  }, [setLocalAudio, hasLocalAudio]);

  const toggleVideo = useCallback(() => {
    setLocalVideo({
      enabled: !hasLocalVideo,
    });
  }, [setLocalVideo, hasLocalVideo]);

  const togglePresenting = useCallback(() => {
    if (presentingSource) {
      cancelPresenting();
    } else {
      getPresentingSources();
    }
  }, [getPresentingSources, presentingSource, cancelPresenting]);

  const hangUp = useCallback(() => {
    hangUpActiveCall("button click");
  }, [hangUpActiveCall]);

  const reactButtonRef = React.useRef<null | HTMLDivElement>(null);
  const reactionPickerContainerRef = React.useRef<null | HTMLDivElement>(null);
  const [showReactionPicker, setShowReactionPicker] = useState(false);

  const [controlsHover, setControlsHover] = useState(false);

  const onControlsMouseEnter = useCallback(() => {
    setControlsHover(true);
  }, [setControlsHover]);

  const onControlsMouseLeave = useCallback(() => {
    setControlsHover(false);
  }, [setControlsHover]);

  const [showControls, setShowControls] = useState(true);

  const localVideoRef = useRef<HTMLVideoElement | null>(null);
  const isSendingVideo = hasLocalVideo || presentingSource;

  const { activeServerList } = useServerList();

  useEffect(() => {
    setLocalPreview({ element: localVideoRef });
    return () => {
      setLocalPreview({ element: undefined });
    };
  }, [setLocalPreview, setRendererCanvas, isSendingVideo]);

  useEffect(() => {
    if (!showControls || showReactionPicker || stickyControls || controlsHover) {
      return noop;
    }
    const timer = setTimeout(() => {
      setShowControls(false);
    }, 5000);
    return clearTimeout.bind(null, timer);
  }, [showControls, showReactionPicker, stickyControls, controlsHover]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      let eventHandled = false;

      const key = KeyboardLayout.lookup(event);

      if (event.shiftKey && (key === "V" || key === "v")) {
        toggleVideo();
        eventHandled = true;
      } else if (event.shiftKey && (key === "M" || key === "m")) {
        toggleAudio();
        eventHandled = true;
      }

      if (eventHandled) {
        event.preventDefault();
        event.stopPropagation();
        setShowControls(true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [toggleAudio, toggleVideo]);

  useEffect(() => {
    if (!showReactionPicker) {
      return noop;
    }
    return handleOutsideClick(
      () => {
        setShowReactionPicker(false);
        return true;
      },
      {
        containerElements: [reactButtonRef, reactionPickerContainerRef],
        name: "CallScreen.reactionPicker",
      },
    );
  }, [showReactionPicker]);

  useScreenSharingStoppedToast({ activeCall, i18n });
  useViewModeChangedToast({ activeCall, i18n });

  const currentPresenter = remoteParticipants.find(participant => participant.presenting);

  const hasRemoteVideo = remoteParticipants.some(remoteParticipant => remoteParticipant.hasRemoteVideo);

  const isReconnecting: boolean = callingIsReconnecting(activeCall);

  let isRinging: boolean;
  let hasCallStarted: boolean;
  let isConnected: boolean;
  let isConnecting: boolean;
  let isEnded: boolean;
  let participantCount: number;
  switch (activeCall.callMode) {
    case CallMode.Direct: {
      isRinging = activeCall.callState === CallState.Ringing || activeCall.callState === CallState.Prering;
      hasCallStarted = !isRinging;
      isConnected = activeCall.callState === CallState.Accepted;
      isConnecting = activeCall.callState === CallState.Prering;
      isEnded = activeCall.callState === CallState.Ended;
      participantCount = isConnected ? 2 : 0;
      break;
    }
    default:
      throw missingCaseError(activeCall);
  }

  let lonelyInCallNode: ReactNode;
  let localPreviewNode: ReactNode;

  const isLonelyInCall = !activeCall.remoteParticipants.length;

  if (isLonelyInCall) {
    lonelyInCallNode = (
      <div
        className={classNames(
          "module-ongoing-call__local-preview-fullsize",
          presentingSource && "module-ongoing-call__local-preview-fullsize--presenting",
        )}
      >
        {isSendingVideo ? (
          <video ref={localVideoRef} autoPlay />
        ) : (
          <CallBackgroundBlur avatarUrl={me.avatarUrl}>
            <div className="module-calling__spacer module-calling__camera-is-off-spacer" />
            <div className="module-calling__camera-is-off">{i18n.t("calling__your-video-is-off")}</div>
          </CallBackgroundBlur>
        )}
      </div>
    );
  } else {
    localPreviewNode = isSendingVideo ? (
      <video
        className={classNames(
          "module-ongoing-call__footer__local-preview__video",
          presentingSource && "module-ongoing-call__footer__local-preview__video--presenting",
        )}
        ref={localVideoRef}
        autoPlay
      />
    ) : (
      <CallBackgroundBlur avatarUrl={accountDetail?.avatarUrl}>
        <div className="module-Avatar">
          <Avatar src={accountDetail?.avatarUrl || ""} sizes="40px">
            {getFirstLetter(accountDetail?.name)}
          </Avatar>
        </div>
      </CallBackgroundBlur>
    );
  }

  let videoButtonType: CallingButtonType;
  if (presentingSource) {
    videoButtonType = CallingButtonType.VIDEO_DISABLED;
  } else if (hasLocalVideo) {
    videoButtonType = CallingButtonType.VIDEO_ON;
  } else {
    videoButtonType = CallingButtonType.VIDEO_OFF;
  }

  const audioButtonType = hasLocalAudio ? CallingButtonType.AUDIO_ON : CallingButtonType.AUDIO_OFF;

  const isAudioOnly = !hasLocalVideo && !hasRemoteVideo;

  const controlsFadedOut = !showControls && !isAudioOnly && isConnected;
  const controlsFadeClass = classNames({
    "module-ongoing-call__controls": true,
    "module-ongoing-call__controls--fadeIn": (showControls || isAudioOnly) && !isConnected,
    "module-ongoing-call__controls--fadeOut": controlsFadedOut,
  });

  let presentingButtonType: CallingButtonType;
  if (presentingSource) {
    presentingButtonType = CallingButtonType.PRESENTING_ON;
  } else if (currentPresenter) {
    presentingButtonType = CallingButtonType.PRESENTING_DISABLED;
  } else {
    presentingButtonType = CallingButtonType.PRESENTING_OFF;
  }

  const callStatus: ReactNode | string = React.useMemo(() => {
    if (isInAnotherCall) {
      return i18n.t("calling__in-another-call-tooltip");
    }
    if (isCalleeInAnotherCall) {
      return i18n.t("calling__callee-in-another-call-tooltip");
    }
    if (isRinging) {
      if (isConnecting) {
        return i18n.t("settingUpSecureCall");
      }
      return i18n.t("outgoingCallRinging");
    }
    if (isEnded) {
      return i18n.t("callEnded");
    }
    if (isReconnecting) {
      return i18n.t("callReconnecting");
    }
    // joinedAt is only available for direct calls
    if (isConnected) {
      return <CallDuration joinedAt={activeCall.joinedAt} />;
    }
    if (hasLocalVideo) {
      return i18n.t("ContactListItem__menu__video-call");
    }
    if (hasLocalAudio) {
      return i18n.t("CallControls__InfoDisplay--audio-call");
    }
    return null;
  }, [
    i18n,
    isRinging,
    isConnected,
    activeCall.callMode,
    activeCall.joinedAt,
    isReconnecting,
    participantCount,
    hasLocalVideo,
    hasLocalAudio,
    toggleParticipants,
  ]);

  let remoteParticipantsElement: ReactNode;
  switch (activeCall.callMode) {
    case CallMode.Direct: {
      assertDev(conversation.type === "direct", "direct call must have direct conversation");
      remoteParticipantsElement = hasCallStarted ? (
        <DirectCallRemoteParticipant
          conversation={conversation}
          hasRemoteVideo={hasRemoteVideo}
          i18n={i18n}
          isReconnecting={isReconnecting}
          setRendererCanvas={setRendererCanvas}
        />
      ) : (
        <div className="module-ongoing-call__direct-call-ringing-spacer" />
      );
      break;
    }
    default:
      throw missingCaseError(activeCall);
  }

  return (
    <div
      className={classNames(
        "module-calling__container",
        `module-ongoing-call__container--${getCallModeClassSuffix(activeCall.callMode)}`,
        `module-ongoing-call__container--${hasCallStarted ? "call-started" : "call-not-started"}`,
        { "module-ongoing-call__container--hide-controls": !showControls },
        {
          "module-ongoing-call__container--controls-faded-out": controlsFadedOut,
        },
        "dark-theme",
      )}
      onFocus={() => {
        setShowControls(true);
      }}
      onMouseMove={() => {
        setShowControls(true);
      }}
      role="group"
    >
      {isReconnecting ? (
        <PersistentCallingToast>
          <span className="CallingToast__reconnecting">
            <Spinner svgSize="small" size="16px" />
            {i18n.t("callReconnecting")}
          </span>
        </PersistentCallingToast>
      ) : null}

      {isLonelyInCall && !isRinging ? (
        <PersistentCallingToast>{i18n.t("calling__in-this-call--zero")}</PersistentCallingToast>
      ) : null}

      {currentPresenter ? (
        <PersistentCallingToast>
          {i18n.t("calling__presenting--person-ongoing", {
            name: currentPresenter.title,
          })}
        </PersistentCallingToast>
      ) : null}

      {showNeedsScreenRecordingPermissionsWarning ? (
        <NeedsScreenRecordingPermissionsModal
          toggleScreenRecordingPermissionsDialog={toggleScreenRecordingPermissionsDialog}
          i18n={i18n}
          openSystemPreferencesAction={openSystemPreferencesAction}
        />
      ) : null}
      <div className={controlsFadeClass}>
        <CallingHeader
          callViewMode={activeCall.viewMode}
          changeCallView={changeCallView}
          i18n={i18n}
          participantCount={participantCount}
          togglePip={togglePip}
          toggleSettings={toggleSettings}
          branchInfo={activeServerList.find((server: any) => server.id === callingGroupDetailRing?.branchId)}
        />
      </div>
      {isRinging && (
        <>
          <div className="module-CallingPreCallInfo-spacer " />
          <CallingPreCallInfo
            conversation={conversation}
            i18n={i18n}
            me={me}
            ringMode={isConnecting ? RingMode.IsConnecting : RingMode.IsRinging}
            title={
              isInAnotherCall
                ? i18n.t("calling__in-another-call-tooltip")
                : isCalleeInAnotherCall
                  ? i18n.t("calling__callee-in-another-call-tooltip")
                  : undefined
            }
          />
        </>
      )}
      {remoteParticipantsElement}
      {lonelyInCallNode}
      <CallingButtonToastsContainer hasLocalAudio={hasLocalAudio} outgoingRing={undefined} i18n={i18n} />
      {/* We render the local preview first and set the footer flex direction to row-reverse
      to ensure the preview is visible at low viewport widths. */}
      <div className="module-ongoing-call__footer">
        {localPreviewNode ? (
          <div className="module-ongoing-call__footer__local-preview module-ongoing-call__footer__local-preview--active">
            {localPreviewNode}
            {!isSendingVideo && <div className="CallingStatusIndicator CallingStatusIndicator--Video" />}
            <CallingAudioIndicator
              hasAudio={hasLocalAudio}
              audioLevel={localAudioLevel}
              shouldShowSpeaking={isSpeaking}
            />
          </div>
        ) : (
          <div className="module-ongoing-call__footer__local-preview" />
        )}
        <div className={classNames("CallControls", "module-ongoing-call__footer__actions", controlsFadeClass)}>
          <div className="CallControls__InfoDisplay">
            <div className="CallControls__CallTitle">{callingGroupDetailRing.groupName}</div>
            <div className="CallControls__Status">{callStatus}</div>
          </div>

          <div className="CallControls__ButtonContainer">
            <CallingButton
              buttonType={videoButtonType}
              i18n={i18n}
              onMouseEnter={onControlsMouseEnter}
              onMouseLeave={onControlsMouseLeave}
              onClick={toggleVideo}
              tooltipDirection={TooltipPlacement.Top}
            />
            <CallingButton
              buttonType={audioButtonType}
              i18n={i18n}
              onMouseEnter={onControlsMouseEnter}
              onMouseLeave={onControlsMouseLeave}
              onClick={toggleAudio}
              tooltipDirection={TooltipPlacement.Top}
            />
            <CallingButton
              buttonType={presentingButtonType}
              i18n={i18n}
              onMouseEnter={onControlsMouseEnter}
              onMouseLeave={onControlsMouseLeave}
              onClick={togglePresenting}
              tooltipDirection={TooltipPlacement.Top}
            />
          </div>
          <div
            className="CallControls__JoinLeaveButtonContainer"
            onMouseEnter={onControlsMouseEnter}
            onMouseLeave={onControlsMouseLeave}
          >
            <Button
              className="CallControls__JoinLeaveButton CallControls__JoinLeaveButton--hangup"
              onClick={hangUp}
              variant={ButtonVariant.Destructive}
            >
              {i18n.t("CallControls__JoinLeaveButton--hangup-1-1")}
            </Button>
          </div>
        </div>
        <div className="module-calling__spacer CallControls__OuterSpacer" />
      </div>
    </div>
  );
}

function getCallModeClassSuffix(callMode: CallMode.Direct | CallMode.Group | CallMode.Adhoc): string {
  switch (callMode) {
    case CallMode.Direct:
      return "direct";
    default:
      throw missingCaseError(callMode);
  }
}

function renderDuration(ms: number): string {
  const secs = Math.floor((ms / 1000) % 60)
    .toString()
    .padStart(2, "0");
  const mins = Math.floor((ms / 60000) % 60)
    .toString()
    .padStart(2, "0");
  const hours = Math.floor(ms / 3600000);
  if (hours > 0) {
    return `${hours}:${mins}:${secs}`;
  }
  return `${mins}:${secs}`;
}

function useViewModeChangedToast({ activeCall, i18n }: { activeCall: ActiveCallType; i18n: LocalizerType }): void {
  const { viewMode } = activeCall;
  const previousViewMode = usePrevious(viewMode, viewMode);
  const presenterAci = usePresenter(activeCall.remoteParticipants);

  const VIEW_MODE_CHANGED_TOAST_KEY = "view-mode-changed";
  const { showToast, hideToast } = useCallingToasts();

  useEffect(() => {
    if (viewMode !== previousViewMode) {
      if (
        // If this is an automated change to presentation mode, don't show toast
        viewMode === CallViewMode.Presentation ||
        // if this is an automated change away from presentation mode, don't show toast
        (previousViewMode === CallViewMode.Presentation && !presenterAci)
      ) {
        return;
      }

      hideToast(VIEW_MODE_CHANGED_TOAST_KEY);
      showToast({
        key: VIEW_MODE_CHANGED_TOAST_KEY,
        content: (
          <div className="CallingToast__viewChanged">
            <span className={classNames("CallingToast__viewChanged__icon", getCallViewIconClassname(viewMode))} />
            {i18n.t("calling__view_mode--updated")}
          </div>
        ),
        autoClose: true,
      });
    }
  }, [showToast, hideToast, i18n, activeCall, viewMode, previousViewMode, presenterAci]);
}
