import React, { memo, useEffect, useState } from "react";
import { Box, Card, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useDispatch } from "react-redux";
import { isNotEqual } from "utils";
import UpdateImagePopover from "./UpdateImagePopover";
import { ProfileActions } from "redux-store";
import { AppAvatar } from "components";
import BackgroundImage from "./BackgroundImage";

const AccountCoverEdit = ({ data, onChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [accountDetail, setAccountDetail] = useState({});
  const [isHandlingAvatar, setIsHandlingAvatar] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const handleClosePopup = () => {
    setAnchorEl();
  };

  const handleShowActionFunc = isClickedAvatar => event => {
    setIsHandlingAvatar(isClickedAvatar);
    setAnchorEl(event.currentTarget);
  };

  const handleUploadImage = file => {
    dispatch(ProfileActions.changeProfileImage({ file }, isHandlingAvatar));
  };

  const handleDeleteImage = () => {
    dispatch(ProfileActions.updateProfileInfo(isHandlingAvatar ? { avatarId: "" } : { backgroundId: "" }));

    const updateAccountData = isHandlingAvatar
      ? { avatarId: "", avatarUrl: null }
      : { backgroundId: "", backgroundUrl: null };

    onChange(updateAccountData);
    setAccountDetail(preState => ({ ...preState, ...updateAccountData }));
  };

  useEffect(() => {
    if (data && isNotEqual(data, accountDetail)) {
      setAccountDetail(data);
    }
  }, [data]);

  const isShowActionPopup = Boolean(anchorEl);
  return (
    <Box className={classes.root}>
      <Card className={classes.coverBox} elevation={0}>
        <Box className={classes.cover}>
          <BackgroundImage backgroundId={accountDetail.backgroundId} />
          <IconButton className={classes.addPhotoIcon} onClick={handleShowActionFunc(false)}>
            <AddPhotoAlternateIcon />
          </IconButton>
        </Box>

        <Box className={classes.avatarBox}>
          <AppAvatar
            avatarId={accountDetail.avatarId}
            name={accountDetail.name}
            size={120}
            className={classes.customAvatar}
          />

          <IconButton className={classes.editIcon} onClick={handleShowActionFunc(true)}>
            <EditIcon />
          </IconButton>
        </Box>

        <UpdateImagePopover
          open={isShowActionPopup}
          anchorEl={anchorEl}
          isAvatar={isHandlingAvatar}
          name={accountDetail.name}
          imageId={isHandlingAvatar ? accountDetail.avatarId : accountDetail.backgroundId}
          onUpload={handleUploadImage}
          onDelete={handleDeleteImage}
          onClose={handleClosePopup}
        />
      </Card>
      <Typography className={classes.name}>{data.name}</Typography>
    </Box>
  );
};

export default memo(AccountCoverEdit);

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": { textAlign: "center" },
  },

  coverBox: {
    position: "relative",
    marginBottom: 12,
  },

  cover: {
    width: "100%",
    height: 180,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, darkgray 71.67%, darkgray 100%)",
    marginBottom: 36,

    "& img": {
      width: "100%",
      height: 180,
    },
  },

  avatarBox: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  editIcon: {
    fontSize: "1rem",
    color: theme.palette.white,
    position: "absolute",
    bottom: "0%",
    left: "50%",
    cursor: "pointer",
    transform: "translate(-50%, 0)",
  },

  addPhotoIcon: {
    fontSize: "1.1875rem",
    color: theme.palette.white,
    position: "absolute",
    bottom: 50,
    right: 15,
    zIndex: 1,
  },

  customAvatar: {
    border: "2px solid white",
  },

  name: {
    fontWeight: 700,
    fontSize: "0.9375rem",
  },
}));
