import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import theme from "theme/material";
import { PathConstant } from "const";
import AuthenticationRoute from "components/AuthenticationRoute";
import {
  ChooseBranchPage,
  HomePage,
  LoginPage,
  NotFoundPage,
  LoginPortPage,
  RequestLoginPage,
  WarningDevicePage,
} from "pages";
import { CreateName } from "pages/LoginPage/components";
import { createSelector } from "reselect";
import { ConversationSelectors } from "redux-store";
import { useSelector } from "react-redux";
import { isLoginBranch } from "utils/auth.utils";

export default function AppRouters() {
  let isMobileUI = useMediaQuery(theme.breakpoints.down("xs"));
  let isMobile = detectCallingNotiWindow() ? false : detectMobile() || isMobileUI;

  return (
    <HashRouter>
      <Switch>
        {isMobile && <Route component={WarningDevicePage} />}
        <StrangeRouter exact path={PathConstant.LOGIN_PORT} component={LoginPortPage} />
        <StrangeRouter exact path={PathConstant.REQUEST_LOGIN_PAGE} component={RequestLoginPage} />
        <StrangeRouter exact path={PathConstant.LOGIN} component={LoginPage} />
        <StrangeRouter exact path={PathConstant.CREATE_NAME} component={CreateName} />
        <Route exact path={PathConstant.CHOOSE_BRANCH_PAGE} component={ChooseBranchPage} />

        <AuthenticationRoute path={PathConstant.ROOT} component={HomePage} />
        <Route exact path={PathConstant.NOT_FOUND} component={NotFoundPage} />
        <Redirect to={PathConstant.NOT_FOUND} />
      </Switch>
    </HashRouter>
  );
}

const detectMobile = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem);
  });
};

const detectCallingNotiWindow = () => {
  const regex = new RegExp(`#${PathConstant.CALLING_NOTIFICATION}`);
  const hash = window.location.hash;

  return regex.test(hash);
};

const memoizedGroupState = createSelector(
  [ConversationSelectors.getSelectedGroupId, ConversationSelectors.getThreadingId],

  (selectedGroupId, selectedThreadId) => {
    return {
      categoryId: isLoginBranch() ? selectedThreadId || selectedGroupId : null,
    };
  },
);
const StrangeRouter = props => {
  const { component: Component, ...rest } = props;
  const { categoryId } = useSelector(memoizedGroupState);

  return Boolean(categoryId) ? (
    <Redirect
      to={{
        pathname: PathConstant.HOME,
        state: {
          from: rest.path,
        },
      }}
    />
  ) : (
    <Route {...rest} render={matchProps => <Component {...matchProps} />} />
  );
};
