import { useEffect } from "react";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import { useDispatch } from "react-redux";
import { SystemActions } from "redux-store";
import { StorageUtil } from "utils";
import { LocalDeviceSettingService } from "services/local.service";

const DEFAULT_SCREEN_LOCK = {
  retries: 0,
  unlockTime: 0,
};

const useScreenLock = () => {
  const dispatch = useDispatch();
  const lockScreenApp = () => {
    // Update storage
    StorageUtil.setCommonKey(KeyConstant.SCREEN_LOCK_TIME, Date.now());
    StorageUtil.setCommonKey(KeyConstant.SCREEN_LOCK_STATUS, 1);

    // Trigger UI
    dispatch(SystemActions.systemSet({ screenLock: { isShow: true, modified: Date.now() } }));
  };

  const unlockScreenApp = () => {
    // Update storage
    StorageUtil.setCommonKey(KeyConstant.SCREEN_LOCK_TIME, null);
    StorageUtil.setCommonKey(KeyConstant.SCREEN_LOCK, DEFAULT_SCREEN_LOCK);
    StorageUtil.setCommonKey(KeyConstant.SCREEN_LOCK_STATUS, "");

    // Trigger UI
    dispatch(SystemActions.systemSet({ screenLock: { isShow: false, modified: Date.now() } }));
  };

  const getScreenLockDetail = () => StorageUtil.getCommonKey(KeyConstant.SCREEN_LOCK) || { ...DEFAULT_SCREEN_LOCK };

  useEffect(() => {
    LocalDeviceSettingService.findOne({
      setting_type: AppConstant.DEVICE_SETTING_TYPE.lockScreen,
      state: SystemConstant.STATE.active,
    }).then(setting => {
      if (!(setting && setting.id)) unlockScreenApp();
    });
  }, []);

  return {
    lockScreenApp,
    unlockScreenApp,
    getScreenLockDetail,
  };
};

export default useScreenLock;
