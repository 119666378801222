export default {
  TXT_BLOCK: "Chặn",
  TXT_LEAVE_GROUP: "Rời khỏi nhóm",
  TXT_LEAVE_CHANNEL: "Rời khỏi kênh",
  TXT_PASTE: "Dán",
  TXT_MUTE_CONVERSATION: "Tắt thông báo",
  TXT_UNMUTE_CONVERSATION: "Bật thông báo",
  TXT_CHANGE_ALIAS: "Đổi biệt danh",
  TXT_VIEW: "Xem chi tiết",
  TXT_VOICE_CALL: "Gọi thoại",
  TXT_VIDEO_CALL: "Gọi video",
  TXT_ADD: "Thêm",
  TXT_MUTE: "Thông báo",
  TXT_UNMUTE: "Thông báo",
  TXT_MEMBERS: "Thành viên",
  TXT_SEE_GROUP_MEMBERS: "Xem thành viên nhóm",
  TXT_CHANGE_NAME_GROUP: "Đổi tên nhóm",
  TXT_GROUP_INFO: "Thông tin nhóm",
  TXT_GROUP: "Nhóm",
  TXT_GALLERY: "Thư viện media",
  TXT_SEARCH_IN_CONVERSATION: "Tìm kiếm trong cuộc trò chuyện",
  TXT_CHANGE_GROUP_PHOTO: "Đổi ảnh nhóm",
  TXT_END_TO_END_ENCRYPTION: "Mã hóa đầu cuối",
  TXT_PRIVACY: "Bảo mật",
  TXT_PERSONAL_PRIVACY: "Quyền riêng tư",
  TXT_REMOVE_A_MEMBER: "Xóa thành viên",
  TXT_LEAVE_GROUP_INFO: "Rời nhóm",
  TXT_NO_MEDIA: "Không có media",
  TXT_CONTENT_NO_MEDIA: "Ảnh và video bạn trao đổi sẽ hiển thị ở đây",
  TXT_NO_FILE: "Không có tệp",
  TXT_CONTENT_NO_FILE: "Tệp bạn trao đổi sẽ hiển thị ở đây",
  TXT_NO_LINK: "Không có đường dẫn",
  TXT_CONTENT_NO_LINK: "Đường dẫn bạn trao đổi sẽ hiển thị ở đây",
  TXT_MEDIA: "Media",
  TXT_LINK: "Links",
  TXT_PEOPLE: "Người dùng",
  TXT_ADD_NEW_MEMBER: "Thêm mới thành viên",
  TXT_CHOOSE_NEW_ADMIN: "Chọn quản trị viên mới",
  TXT_MY_CONTACT: "Danh bạ của tôi",
  TXT_MESSENGER_MEMBER: "Nhắn tin",
  TXT_ADD_ADMIN_MEMBER: "Chỉ định làm quản trị viên",
  TXT_REMOVER_MEMBER: "Xóa khỏi nhóm",
  TXT_ALL_MEMBER_LIST: "Tất cả",
  TXT_ADMIN_MEMBER_LIST: "Quản trị viên",
  TXT_ADD_GROUP_ADMIN: "Thêm quản trị viên",
  TXT_MORE_ACTIONS: "Hành động khác",
  TXT_BOOK_MARK_LINK: "Dấu trang",
  TXT_BLOCK_USER: "Chặn người dùng",
  TXT_CHANGE_CHANNEL: "Change channel name",
  TXT_SAVE_CHANNEL_INFO: "Lưu",
  TXT_CHANGE_NAME_INFO: "Change name",
  TXT_CHANGE_GROUP_NAME: "Nhập tên",
  TXT_CHANGE_GROUP_CHANGE_NAME: "Nhập tên nhóm mới",
  TXT_CHANGE_CHANNEL_CHANGE_NAME: "Nhập tên kênh mới",
  TXT_CHANGE_CHANNEL_NAME: "Nhập tên kênh",
  TXT_CHANGE_GROUP_NAME_INFO: "Đổi tên nhóm",
  TXT_DES_CONFIRM_LEAVE: "Are you sure you want to leave this conversation? You will no longer receive new message",
  TXT_CONTENT_BUTTON_GROUP:
    "Bạn có chắc chắn muốn rời khỏi cuộc trò chuyện này? Bạn sẽ không thể nhận được tin nhắn mới nữa",
  TXT_DES_BUTTON_CONFIRM:
    "Bạn có thể chọn một quản trị viên mới từ danh sách thanh viên. Nếu bạn rời nhóm mà không chọn quản trị viên mới thì thành viên tham gia lâu nhất sẽ trở thành quản trị viên",
  TXT_BUTTON_CHOOSE_ADMIN: "CHỌN ADMIN",
  TXT_BLOCK_SINGLE_GROUP: "Chặn tin nhắn và cuộc gọi",
  TXT_CONFIRM_REMOVE: "Xác nhận xoá",
  TXT_CONFIRM_REMOVE_CONTENT: "{{ name }} sẽ không thể nhận được tin nhắn và cuộc gọi từ nhóm này",
  TXT_BUTTON_REMOVE: "Xóa",
  TXT_BLOCK_AND_CALL: "Chặn tin nhắn và cuộc gọi",
  TXT_UN_BLOCK: "Bỏ chặn",
  TXT_UN_BLOCK_USER: "Bỏ chặn người dùng",
  TXT_BLOCKED_MESSAGE: "Bạn đã chặn người này. Cả hai sẽ không thể nhắn tin hay gọi điện với nhau trong chat",
  TXT_BEING_BLOCKED_MESSAGE: "Người này hiện không khả dụng",
  TXT_DELETE_FOR_YOU: "Xóa phía bạn",
  TXT_VIEW_POPUP: "Xem người dùng",
  TXT_DELETE_POPUP: "Xóa",
  TXT_STATIC_REACTION: "Cảm xúc của bạn",
  TXT_CUSTOM_EMOJI: "Nhấn vào cảm xúc rồi chọn một biểu tượng cảm xúc để thay thế",
  TXT_REACTIONS: "Cảm xúc về tin nhắn",
  TXT_THREAD: "Thread",
  TXT_PUBLIC_CHANNEL: "Public channel",
  TXT_FILE_NOT_FOUND: "Không tìm thấy tệp",
  TXT_NO_NAME: "No name",
  TXT_EDITTED: "(Đã chỉnh sửa)",
  TXT_DELETE_CONTACT: "Xóa liên hệ",
  TXT_CONTENT_DELETE_CONTACT:
    "Bạn có muốn xóa liên hệ này khỏi danh bạ? \n Nó có nghĩa là xóa {{ name }} khỏi danh sách bạn bè",
  TXT_SEND_FILE_CONFIRM_TEXT: "Xác nhận gửi tệp",
  TXT_CONFIRM_SENDING_MEDIA: "Xác nhận gửi phương tiện media",
  TXT_INPUT_CAPTION: "Nhập tin nhắn đính kèm",
  TXT_UPLOAD_FAILED: "Tải file không thành công, vui lòng thử lại!",
  TXT_INVITE_TO_SERVER: "muốn bạn kết nối tới máy chủ.",
  TXT_SENDING: "Đang gửi",
  TXT_SENT: "Đã gửi",
  TXT_RECEIVED: "Đã nhận",
  TXT_SEND_FAILED: "Có lỗi xảy ra, nhấn nút thử lại để gửi lại",
  TXT_SEEN: "Đã xem",
  TXT_INVITE_REJECTED: "Lời mời đã bị từ chối",
  TXT_INVITE_DISMISSED: "Lời mời đã bị thu hồi",
  TXT_INVITE_ACCEPTED: "Lời mời đã được chấp nhận",
  TXT_CONFERENCE_CALL: "Gọi hội nghị",
  TXT_LEAVE_CHANNEL_INFO: "Rời kênh",
  TXT_SEE_CHANNEL_MEMBERS: "Xem thành viên kênh",
  TXT_SUBTITLE_LEAVE_CHANNEL:
    "Bạn có thể chọn một quản trị viên mới từ danh sách thanh viên. Nếu bạn rời kênh mà không chọn quản trị viên mới thì thành viên tham gia lâu nhất sẽ trở thành quản trị viên",
  TXT_CHANGE_NAME_CHANNEL: "Đổi tên kênh",
  TXT_CHANNEL_INFO: "Thông tin kênh",
  TXT_PLACEHODLER_CHANGE_CHANNEL_NAME: "Nhập tên kênh mới",
  TXT_DELETE_CONVERSATION_TITLE: "Xóa tin nhắn và cuộc gọi",
  TXT_DELETE_CONVERSATION_SUBTITLE: "Bạn có muốn xóa cuộc trò chuyện này không?",
  TXT_REMOVE_MEMBER_CHANNEL: "Xóa khỏi kênh",
  TXT_INACTIVE_USER: "Bạn đang xem tin nhắn của một tài khoản đã bị vô hiệu hóa",
  TXT_UNDERSTAND: "Đã hiểu",
  TXT_IN_BRANCH: "Tổ chức",
  TXT_IN_GLOBAL: "Ngoài tổ chức",
  TXT_CALL: "Cuộc gọi",
  TXT_HISTORY: "Lịch sử",
  TXT_DELETE_CALL_HISTORY_TITLE: "Xóa lịch sử cuộc gọi",
  TXT_DELETE_CALL_HISTORY_CONTENT: "Bạn có muốn xóa lịch sử cuộc gọi đã chọn?",
  TXT_DELETE_CALL_HISTORY_SUCCESS: "Xóa lịch sử cuộc gọi thành công!",
  TXT_DELETE_CALL_HISTORY_FAIL: "Xóa lịch sử cuộc gọi thất bại!",
  TXT_MENTION_ALL_SUBTITLE: "Nhắc tới tất cả mọi người",
  TXT_MARK_AS_READ: "Đánh dấu đã đọc",
  TXT_READ_ALL_THREAD: "Đã đọc hết tin nhắn trong luồng",
  TXT_READ_ALL_THREAD_FAIL: "Đọc hết tin nhắn trong luồng không thành công",
  TXT_DELETE_GROUP: "Giải tán nhóm",
  TXT_DELETE_GROUP_CONFIRM: "Sau khi thực hiện, nhóm sẽ không thể khôi phục",
  TXT_DELETE_CHANNEL: "Giải tán kênh",
  TXT_DELETE_CHANNEL_CONFIRM: "Sau khi thực hiện, kênh sẽ không thể khôi phục",
  TXT_BLOCK_DELETE_GROUP: "Không thể giải tán khi đang diễn ra cuộc gọi hội nghị",
  TXT_DELETE_ALL: "Xóa tất cả lịch sử",
  TXT_CONFIRM_DELETE_ALL: "Bạn có muốn xóa tất cả lịch sử cuộc gọi?",
  TXT_ADD_FAVORITE: "Thêm vào mục ưa thích",
  TXT_ADD_FAVORITE_SUCCESS: "Thêm mục ưa thích thành công",
  TXT_ADD_FAVORITE_FAIL: "Thêm mục ưa thích thất bại",
  TXT_REMOVE_FAVORITE: "Bỏ khỏi mục ưa thích",
  TXT_REMOVE_FAVORITE_SUCCESS: "Bỏ mục ưa thích thành công",
  TXT_REMOVE_FAVORITE_FAIL: "Bỏ mục ưa thích thất bại",
  TXT_EMPTY_CALL_HISTORY: "Bạn chưa có lịch sử cuộc gọi",
  TXT_DELETE_GROUP_SUCCESS: "Giải tán nhóm thành công",
  TXT_DELETE_GROUP_FAIL: "Giải tán nhóm thất bại",
  TXT_DELETE_CHANNEL_SUCCESS: "Giải tán kênh thành công",
  TXT_DELETE_CHANNEL_FAIL: "Giải tán kênh thất bại",
  TXT_VIEW_PIN: "Xem",
  TXT_CONFIRM_UNPIN_TITLE: "Xác nhận bỏ ghim",
  TXT_CONFIRM_UNPIN_SUBTITLE: "Bạn có muốn bỏ ghim nội dung này không?",
  TXT_ACCOUNT_DESCRIPTION: "Thông tin mô tả",

  FM_LAST_MESSAGE_MINE: "Bạn: {{ message }}",
  FM_CONFIRM_DETAIL:
    "Khi trở thành quản trị viên, {{ confirm }} sẽ có thể quản lý nhóm và xóa người khỏi hội thoại. Họ có thể xóa tư cách quản trị viên của người khác",
  FM_CONFIRM_BLOCK_DETAIL: "Bạn sẽ không thể nhận tin nhắn và cuộc gọi từ {{ groupName }}",
  FM_CONFIRM_REMOVE: "{{ memberName }} sẽ không thể nhận được tin nhắn và cuộc gọi từ nhóm này",
  FM_FILE_SIZE_MEGA_BYTES: "{{ size }}MB",
  FM_INVITE_TO_SERVER: `đã mời bạn tham gia vào máy chủ "{{ server }}"`,
  FM_SINGULAR_RESULT: "{{number}} kết quả",
  FM_MANY_RESULTS: "{{number}} kết quả",
  FM_REPLY_COUNT: "{{ count }} trả lời",
  FM_NEW_REPLY_COUNT: " trả lời mới",
  FM_SEEN_MEMBER: "{{ memberNames }} đã xem",
  FM_CONFIRM_REMOVE_CHANNEL_MEMBER: "{{ memberName }} sẽ không thể nhận được tin nhắn và cuộc gọi từ kênh này",
  FM_SENT_TIME: "Đã gửi: {{time}}",
  FM_RECEIVED_TIME: "Đã nhận: {{time}}",
  FM_DELETED_MSG_BY_ME: "Bạn đã xóa tin nhắn",
  FM_DELETED_MSG_BY_OTHER: "{{name}} đã xóa tin nhắn",
  FM_PIN_MESSAGE: "{{senderName}} đã ghim tin nhắn {{message}}",
  FM_UNPIN_MESSAGE: "{{senderName}} đã bỏ ghim tin nhắn {{message}}",
  FM_PIN_OWNER: "Tin nhắn của {{senderName}}",
  FM_TOTAL_PIN: "Tin nhắn ({{number}})",
};
