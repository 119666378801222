import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { remoteApiFactory } from "services";
import { LocalDbManagement, getInteractor } from "services/local.service";
import { AttachmentUtil, StorageUtil, getPrefixKey, toCamel, uuid } from "utils";
import { isEqual } from "lodash";
import store, { BranchActions } from "redux-store";
import { checkCurrentBranchByPrefix } from "sagas/saga.helper";
import { syncRemoteAccountList } from "./account.service";
import { getFileFromTus } from "services/multiple-file";
const redis = window.electronLibs.libs.redis;

export const branchUpdateService = async (prefixKey, branchId) => {
  try {
    await getRemoteBranches(prefixKey);
    const currentBranchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
    if (currentBranchId === branchId) {
      store.dispatch(
        BranchActions.branchSuccess({
          fetchBranchTimestamp: Date.now(),
        }),
      );
    }

    return true;
  } catch (error) {
    console.log("update branch service fail: ", error);
  }

  return false;
};

export const branchDeleteService = (prefixKey, branchId) => {
  return true;
};

export const getRemoteBranches = async prefixKey => {
  try {
    const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
    const response = await remoteApiFactory.getBranchApi(prefixKey).getBranchList();
    const responseData = response?.data;
    if (response.status === ApiConstant.STT_OK && responseData && Array.isArray(responseData)) {
      await getInteractor(prefixKey).LocalBranchService.save(responseData);
      const currentBranchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
      const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
      const updateBranchData = responseData.find(item => item.id === currentBranchId);
      redis.updateRedisInstances(updateBranchData, prefixKey);
      await saveFileFromBranchList(prefixKey, responseData);

      const savingDbManagement = [];
      for (let index = 0; index < responseData.length; index++) {
        const remoteBranch = responseData[index];
        const dbManagementList = await LocalDbManagement.find({
          branch_id: remoteBranch.id,
        });

        dbManagementList.forEach(item => {
          savingDbManagement.push({
            ...item,
            branch_name: remoteBranch.name,
            logo_app: remoteBranch.attachment_id,
            branch_domain: remoteBranch.domain,
            modified: Date.now(),
          });
        });
      }
      await LocalDbManagement.save(savingDbManagement);

      const currentDBManagement = await LocalDbManagement.findOne({
        account_id: currentAccountId,
        branch_id: currentBranchId,
      });

      const currentBranchRemote = responseData.find(item => item.id === currentBranchId);
      let tmpSelectedBranch = {
        account_id: currentAccountId,
        phone: currentDBManagement.owner_name,
        login_state: currentDBManagement.state,
      };
      tmpSelectedBranch = toCamel({ ...tmpSelectedBranch, ...currentBranchRemote });
      const storageSelectedServer = StorageUtil.getCommonKey(KeyConstant.KEY_SELECTED_SERVER);
      if (isCurrentBranch && false === isEqual(storageSelectedServer, tmpSelectedBranch)) {
        StorageUtil.setItem(KeyConstant.KEY_BRANCH_INFO, tmpSelectedBranch, prefixKey);
        StorageUtil.setCommonKey(KeyConstant.KEY_SELECTED_SERVER, tmpSelectedBranch);

        store.dispatch(BranchActions.updateSelectedBranch(tmpSelectedBranch));
      }
    }
  } catch (error) {
    console.log("get remote branch fail: ", error);
  }
};

export const getRemoteBranchAccount = async (prefixKey, params) => {
  prefixKey = prefixKey || StorageUtil.getCurrentPrefixKey();
  if (!params) {
    const branchList = await getInteractor(prefixKey).LocalBranchService.getActiveBranch();
    const data2Request = branchList.map(item => ({ branch_id: item.id, last_time: item.created + 1 }));
    params = {
      branch_ids: JSON.stringify(data2Request),
    };
  }

  const response = await remoteApiFactory.getBranchApi(prefixKey).getBranchAccount(params);
  if (response.status === ApiConstant.STT_OK) {
    const remoteBranchAccountList = response.data;
    await getInteractor(prefixKey).LocalBranchAccountService.save(remoteBranchAccountList);
    store.dispatch(
      BranchActions.branchSuccess({
        fetchBranchTimestamp: new Date().getTime(),
      }),
    );
    const accountIdList = remoteBranchAccountList.map(item => item.account_id);
    await syncRemoteAccountList(prefixKey, accountIdList);

    return remoteBranchAccountList;
  }

  return null;
};

export const saveFileFromBranchList = async (prefixKey, branchArr) => {
  const branchList = toCamel(branchArr);

  for (let index = 0; index < branchList.length; index++) {
    const branch = branchList[index];
    if (branch.attachmentId) {
      const fileName = branch.attachmentId + ".png";
      if (!AttachmentUtil.exitsLocalFile(prefixKey, branch.attachmentId, fileName)) {
        getFileFromTus(prefixKey, { attachmentId: branch.attachmentId, metaData: { fileName: fileName } });
      }
    }
  }
};

export const getBranchMapping = async () => {
  try {
    const localActiveServer = await LocalDbManagement.find({ state: SystemConstant.STATE.active });

    for (let index = 0; index < localActiveServer.length; index++) {
      const item = localActiveServer[index];
      const result = await syncMappingBranch(item);
      if (!result) console.log("ERROR_MAPPING_BRANCH", item);
    }

    store.dispatch(
      BranchActions.branchSuccess({
        fetchBranchTimestamp: Date.now(),
      }),
    );
  } catch (error) {
    console.log({ error });
  }
};

const syncMappingBranch = async branch => {
  try {
    const prefixKey = getPrefixKey(branch.account_id, branch.branch_id);
    const response = await remoteApiFactory.getBranchApi(prefixKey).syncMappingBranch({
      owner_name: branch.owner_name,
    });
    const branchMapping = response.data;

    if (response.status === ApiConstant.STT_OK && Array.isArray(branchMapping)) {
      const branchMappingId = branchMapping.map(item => item.branch_id);
      const localBranch = await LocalDbManagement.getAll();
      const localBranchId = localBranch.map(item => item.branch_id);

      // Update local branch suggest with current owner_name
      const suggestBranchInLocal = localBranch.filter(
        item => branchMappingId.includes(item.branch_id) && item.state === SystemConstant.STATE.suggested,
      );
      const branch2UpdateLocal = suggestBranchInLocal.map(item => ({
        ...item,
        owner_name: branch.owner_name,
        modified: Date.now(),
      }));

      await LocalDbManagement.updateList(branch2UpdateLocal);

      // Save new suggest branch to local
      const suggestBranch = branchMapping.filter(item => false === localBranchId.includes(item.branch_id));
      const suggestBranch2SaveLocal = suggestBranch.map(item => ({
        id: uuid(),
        account_id: "",
        branch_id: item.branch_id,
        owner_name: item.owner_name,
        state: SystemConstant.STATE.suggested,
        options: null,
        token: null,
        branch_name: item.branch_name,
        branch_domain: item.branch_domain,
        logo_app: item.branch_logo,
        created: Date.now(),
        modified: Date.now(),
      }));

      await LocalDbManagement.save(suggestBranch2SaveLocal);

      // Update to server
      const localServerByPhone = localBranch.filter(item => item.owner_name === branch.owner_name);
      if (localServerByPhone.length > branchMapping.length) {
        const branchMappingId = branchMapping.map(item => item.branch_id);
        const updateBranches = localServerByPhone.filter(item => false === branchMappingId.includes(item.branch_id));

        const dataToUpdate = updateBranches.map(item => ({
          owner_name: item.owner_name,
          branch_id: item.branch_id,
          branch_domain: item.branch_domain,
          branch_logo: item.logo_app,
          branch_name: item.branch_name,
        }));

        await remoteApiFactory.getBranchApi(prefixKey).createBranchMapping({
          account_branch_mappings: JSON.stringify(dataToUpdate),
        });
      }
      return true;
    }
  } catch (error) {
    console.log({ error });
  }

  return false;
};

export const getBranchAccount = async (prefixKey, accountId, branchId) => {
  const branchAccount = await getInteractor(prefixKey).LocalBranchAccountService.getBranchAccount(accountId, branchId);

  if (!branchAccount) {
    const response = await remoteApiFactory.getBranchApi(prefixKey).getBranchAccount({
      account_id: accountId,
    });
    const remoteBranchAccount = response.data;
    if (response.status === ApiConstant.STT_OK) {
      await getInteractor(prefixKey).LocalBranchAccountService.save(remoteBranchAccount);

      return remoteBranchAccount[0];
    }
  }

  return branchAccount;
};
