const redis = window.electronLibs.libs.redis;

export const publishMessageToRedis = async (type: any, raw: any, redisAddress?: any) => {
  await redis.publishMessage(type, raw, redisAddress);
};

export const subscribeToRedisTopic = async () => {
  await redis.subscribeToTopic();
};

export const setRedisChannel = async (roomId: string) => {
  await redis.setChannel(roomId);
};

export const subscribeRedisPubsub = async (accountId: string, branchId: string) => {
  await redis.subscribePubsub(accountId, branchId);
};

export const unSubscribeRedisPubsub = async (accountId: string, branchId: string) => {
  await redis.unSubscribePubsub(accountId, branchId);
};
export const getRedisClient = async (accountId: string, branchId: string): Promise<any> => {
  return await redis.getRedisClient(accountId, branchId);
};
