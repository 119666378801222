import React, { memo } from "react";
import PropTypes from "prop-types";
import { SystemConstant } from "const";
import { Grid3x3, Lock, Group } from "@mui/icons-material/";
import AppAvatar from "./AppAvatar";

const AvatarGroup = ({ group, avatarId, ...otherProps }) => {
  const { groupType, groupName, privateF, isServerLogo } = group || {};
  const isPrivate = Boolean(privateF);

  switch (true) {
    case groupType === SystemConstant.GROUP_CHAT_TYPE.channel:
      return (
        <AppAvatar name={groupName} {...otherProps}>
          {isPrivate ? <Lock /> : <Grid3x3 />}
        </AppAvatar>
      );

    case Boolean(avatarId):
      return <AppAvatar name={groupName} avatarId={avatarId} isServerLogo={isServerLogo} {...otherProps} />;

    case groupType === SystemConstant.GROUP_CHAT_TYPE.group:
      return (
        <AppAvatar name={groupName} {...otherProps}>
          <Group />
        </AppAvatar>
      );

    default:
      break;
  }

  return <AppAvatar name={groupName} {...otherProps} />;
};

AvatarGroup.propTypes = {
  group: PropTypes.shape({
    groupType: PropTypes.oneOf([
      SystemConstant.GROUP_CHAT_TYPE.personal,
      SystemConstant.GROUP_CHAT_TYPE.group,
      SystemConstant.GROUP_CHAT_TYPE.channel,
    ]),
    groupName: PropTypes.string,
    groupMembers: PropTypes.array,
    privateF: PropTypes.number,
  }),
  avatarId: PropTypes.string,
  prefixKey: PropTypes.string,
};
AvatarGroup.defaultProps = {};

export default memo(AvatarGroup);
