// Copyright 2023 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

// Note that this file should not important any binary addons or Node.js modules
// because it can be imported by storybook
import { CallState } from "../../types/Calling";
import { CallMode } from "../../types/CallDisposition";
import type { AciString } from "../../types/ServiceId";
// import { missingCaseError } from "../../util/missingCaseError";
import type { DirectCallStateType, CallsByConversationType } from "./calling";
const missingCaseError = window.electronLibs.libs.missingCaseError;

export const MAX_CALL_PARTICIPANTS_FOR_DEFAULT_MUTE = 8;

// In theory, there could be multiple incoming calls, or an incoming call while there's
//   an active call. In practice, the UI is not ready for this, and RingRTC doesn't
//   support it for direct calls.
export const getIncomingCall = (
  callsByConversation: Readonly<CallsByConversationType>,
): undefined | DirectCallStateType =>
  Object.values(callsByConversation).find(call => {
    switch (call.callMode) {
      case CallMode.Direct:
        return call.isIncoming && call.callState === CallState.Ringing;
      default:
        throw missingCaseError(call);
    }
  });
