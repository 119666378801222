import { AppConstant, SystemConstant } from "const";
import { createReducer, createActions } from "reduxsauce";
import { ElectronService } from "services";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  changeDeviceRole: ["prefixKey"],
  verifyChangeDeviceRole: ["data", "prefixKey"],
  restore: ["data", "prefixKey"], // now, data is {} that restore latest file on server. It will be changed to get specific file in next time
  restoreToLocal: ["data", "prefixKey"],
  backup: ["data", "prefixKey"],

  restoreSet: ["data"],
  backupSet: ["data"],
});

export const RestoreTypes = Types;
export const RestoreActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.notStart,
  restoreStatus: SystemConstant.RESTORE_STATUS.notStart,
  backupStatus: SystemConstant.RESTORE_STATUS.notStart,
  changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.notStart,
  fileSize: 0,
  backupInfo: null, // information about backup file: attachmentId, ckx, createdTime
};

/* ------------- Selector ------------- */
export const RestoreSelectors = {
  isSuccessRestore: ({ restoreRedux }) => restoreRedux.restoreStatus === SystemConstant.RESTORE_STATUS.success,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.notStart,
  verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.notStart,
  backupStatus: SystemConstant.RESTORE_STATUS.notStart,
  restoreStatus: SystemConstant.RESTORE_STATUS.notStart,
});

export const set = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.data,
});

export const backupSet = (state = INITIAL_STATE, action) => {
  const { backupStatus } = action.data;

  ElectronService.saveBackupStatus(backupStatus);
  return {
    ...state,
    ...action.data,
    backupStatus: backupStatus,
  };
};

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.CHANGE_DEVICE_ROLE]: request,
  [Types.VERIFY_CHANGE_DEVICE_ROLE]: request,
  [Types.RESTORE]: request,
  [Types.RESTORE_TO_LOCAL]: request,
  [Types.BACKUP]: request,
  [Types.RESTORE_SET]: set,
  [Types.BACKUP_SET]: backupSet,
};

/* ------------- Hookup Reducers To Types ------------- */
export const RestoreReducer = createReducer(INITIAL_STATE, HANDLERS);
