import { Box, IconButton, ListItem as MuiListItem, ListItemText, Typography, Stack } from "@mui/material";
import { ForwardingIcon } from "components/icons";
import { DownloadOutlined } from "@mui/icons-material";
import styled from "@emotion/styled";
import { AttachmentUtil } from "utils";
import { useAlertContext } from "components/context/AlertContext";
import withForwardMessage from "components/hoc/withForwardMessage";
import FileAvatar from "components/avatar/FileAvatar";
import { SystemConstant } from "const";
import { AvatarGroup, ListItemByDate } from "components";
import { useDownloadFile } from "hooks";

export const ListFileByDate = ({ date, list }) => {
  const { showAlert } = useAlertContext();

  return (
    <ListItemByDate date={date}>
      {list.map(item => (
        <FileItem key={`item-${item.sourceMessageId}-${item.id}`} fileItem={item} onShowAlert={showAlert} />
      ))}
    </ListItemByDate>
  );
};

const FileItem = withForwardMessage(({ fileItem, onForward }) => {
  const { downloadFile } = useDownloadFile();

  const handleDownload = () => {
    downloadFile([fileItem.metaData], fileItem.groupId);
  };

  return (
    <ListItem key={`item-${fileItem.id}`}>
      <FileAvatar url={AttachmentUtil.getLocalPath(fileItem.url, fileItem.fileName)} file={fileItem.metaData} />
      <ListItemText
        disableTypography
        primary={
          <Typography variant="subtitle1" className="ellipsis">
            {fileItem.fileName}
          </Typography>
        }
        secondary={
          <>
            <Typography component="span" variant="body2" fontWeight={600} className="ellipsis">
              {fileItem.shareBy}
            </Typography>
            {fileItem.groupName && (
              <SharingGroupItem
                groupType={fileItem.groupType}
                groupName={fileItem.groupName}
                privateF={fileItem.privateF}
              />
            )}
          </>
        }
      />
      <Box className="icon-list">
        <IconButton
          aria-label="forward"
          onClick={() => onForward({ attachmentId: fileItem.fileId, sourceMessageId: fileItem.sourceMessageId })}
        >
          <ForwardingIcon />
        </IconButton>

        <IconButton edge="end" aria-label="download" onClick={handleDownload}>
          <DownloadOutlined />
        </IconButton>
      </Box>
    </ListItem>
  );
});

const SharingGroupItem = ({ groupType, groupName, privateF }) => {
  const isShowIcon = [SystemConstant.GROUP_CHAT_TYPE.group, SystemConstant.GROUP_CHAT_TYPE.channel].includes(groupType);

  return (
    <Stack direction="row" className="align-items">
      {isShowIcon && (
        <CustomAvatarGroup group={{ groupType, groupName, privateF }} sx={{ bgcolor: "unset", color: "#707070" }} />
      )}
      <Typography component="span" variant="body2" fontWeight={600} className="ellipsis">
        {groupName}
      </Typography>
    </Stack>
  );
};

const ListItem = styled(MuiListItem)(props => ({
  background: props.theme.palette.grey[100],
  borderRadius: 16,
  "&, & span, & p": { color: props.theme.palette.black },

  "& .icon-list": {
    minWidth: 68,
    display: "none",
    "& svg": { color: props.theme.palette.black },
  },

  "&:hover": {
    ".icon-list": {
      display: "block",
    },
  },
}));

const CustomAvatarGroup = styled(AvatarGroup)({
  width: 24,
  height: 24,

  "& svg": {
    fontSize: "1rem",
  },
});
