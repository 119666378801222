import { useAlertContext } from "components/context/AlertContext";
import { ApiConstant, AppConstant, LangConstant } from "const";
import { useEffect, useState } from "react";
import { checkQrCode } from "services/auth.service";
import { getNSLang } from "utils/lang.utils";
import useQRCodeReader from "hooks/useQRCodeReader";

const useQrLogin = file => {
  const { showAlert } = useAlertContext();
  const { qrData } = useQRCodeReader(file);

  const [loginData, setLoginData] = useState(null);
  const [checkStatus, setCheckStatus] = useState(AppConstant.PROCESS_STATUS.nothing);

  const checkQrLogin = async qrData => {
    setCheckStatus(AppConstant.PROCESS_STATUS.calling);
    try {
      const invitationInfo = Object.fromEntries(new URLSearchParams(qrData));
      // Check QR Code
      const { branch_id, branch_domain, qr_token } = invitationInfo;
      if (branch_id && branch_domain && qr_token) {
        const responseCheck = await checkQrCode(branch_domain, qr_token, branch_id);
        if (responseCheck.status === ApiConstant.STT_OK) {
          setLoginData(invitationInfo);
        } else {
          setLoginData(null);
          throw new Error("Invalid QR");
        }
      } else {
        throw new Error("Invalid QR");
      }
    } catch (error) {
      console.log("Qr is invalid");
      showAlert({ content: getNSLang(LangConstant.NS_LOGIN, "TXT_QR_ERROR"), severity: "error" });
    }

    setCheckStatus(AppConstant.PROCESS_STATUS.nothing);
  };

  useEffect(() => {
    if (qrData) checkQrLogin(qrData);
  }, [qrData]);

  return { loginData, checkStatus };
};

export default useQrLogin;
