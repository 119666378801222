import { createReducer, createActions } from "reduxsauce";
import { REDUX_STATE, requestReducerFunc, successReducerFunc, setReducerFunc } from "./redux-structure";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  findContact: ["data", "prefixKey"],
  updateContact: ["data", "prefixKey"],
  addContact: ["data", "prefixKey"],
  removeContact: ["contactRecordId", "prefixKey"],

  contactSuccess: ["data"],
  contactSet: ["data"],
});

export const ContactTypes = Types;
export const ContactActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  ...REDUX_STATE,

  executeContact: null, // Finding contact, update contact, unfriend contact, new contact,
  findAccountList: null, // []: Not found, [<account>]: account matching with phone
  updateSender: null, // {affectGroupIds, accountId, name} => changing data of account that is unfriend/ add/ update name. Need to check account is member of selected group (personal group)
};

/* ------------- Selector ------------- */
export const ContactSelectors = {};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) =>
  requestReducerFunc({
    ...state,
    executeContact: null,
  });

const set = (state = INITIAL_STATE, action) => setReducerFunc(state, action);

const success = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.FIND_CONTACT]: request,
  [Types.UPDATE_CONTACT]: request,
  [Types.ADD_CONTACT]: request,
  [Types.REMOVE_CONTACT]: request,

  [Types.CONTACT_SET]: set,
  [Types.CONTACT_SUCCESS]: success,
};

/* ------------- Hookup Reducers To Types ------------- */
export const ContactReducer = createReducer(INITIAL_STATE, HANDLERS);
