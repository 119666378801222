import { forwardRef, memo, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyConstant, SystemConstant } from "const";
import { SendInputIcon } from "components/icons";
import PeopleTagList from "./PeopleTagList";
import SendMessageFile from "./SendMessageFile";
import FullEmojiLayout from "./FullEmojiLayout";
import PastePopper from "./PastePopper";
import { getAllLetterIndex, removeDuplicateInArray } from "utils";
import { StorageUtil } from "utils";
import { useConversationContext } from "../ConversationContext";
import { isGroupOrChannelType } from "utils/view.utils";
import { getCursorPosition } from "hooks/useInputEvent";
import { replaceName2Id } from "utils/message.utils";
import { useDraftMessage } from "hooks";
import { getFilterArray, getWordAtCursor, replaceByMentionName } from "./ChatInput.helper";

const MessengerChatInput = ({ placeholder, onSendMessage, isAllowAttach = true }) => {
  const classes = useStyles();
  const mentionIdListRef = useRef([]);
  const { groupDetail } = useConversationContext();
  const { inputRef, isShowUploadIcon, insertContentInput, updateMessageContent, resetDraftMessage } =
    useDraftMessage(isAllowAttach);

  const [isShowTagList, setIsShowTagList] = useState(false);
  const [filteredGroupMembers, setFilteredGroupMembers] = useState([]);
  const [pasterAnchorEl, setPasterAnchorEl] = useState(null);

  const mentionList = useMemo(() => {
    if (!groupDetail.groupMembers) {
      return [];
    }

    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const mentionMemberList = groupDetail.groupMembers.filter(item => item.id !== accountId);

    if (isGroupOrChannelType(groupDetail.groupType)) mentionMemberList.push(SystemConstant.MENTION_ALL);

    return mentionMemberList;
  }, [groupDetail.groupMembers]);

  const handleResetInput = () => {
    mentionIdListRef.current = [];
    updateMessageContent("");

    handleCloseTagList();
    setFilteredGroupMembers(mentionList);
    setPasterAnchorEl(null);
  };

  const handleKeyDown = event => {
    if (event.keyCode === 32) {
      // Space
      handleCloseTagList();
    } else if (event.keyCode === 13) {
      if (event.shiftKey) {
        if (event.type === "keypress" && inputRef.current) {
          // ShiftEnter
          updateMessageContent(inputRef.current.value + "\n");
        }
      } else if (inputRef.current.value?.trim()) {
        // Enter
        handleButtonSend(event);
      }
    }
  };

  const handlePasterAnchor = isShow => () => setPasterAnchorEl(isShow ? inputRef.current : null);

  const handleChangeInput = event => {
    const inputValue = event.target.value;
    const cursorIndex = getCursorPosition(inputRef.current);
    const previousCharacter = inputValue[cursorIndex - 1];

    const cursorWord = getWordAtCursor(inputValue, cursorIndex);
    const mentionInCurrentWord = getAllLetterIndex(cursorWord, "@");
    const isValidMention = cursorWord.startsWith("@") && mentionInCurrentWord.length === 1;

    if (!inputValue) {
      handleResetInput();
    } else if (cursorIndex > 0 && mentionIdListRef.current.length < mentionList.length && isValidMention) {
      const mentionIndexes = getAllLetterIndex(inputValue, "@");
      const currentIndexes = mentionIndexes.filter(item => item < cursorIndex);

      // Filter member by name
      if (false === [" ", "\n"].includes(previousCharacter) && currentIndexes.length > 0) {
        const nearestIndex = Math.max(...currentIndexes);

        const value2Search = inputValue.slice(nearestIndex + 1, cursorIndex);
        const filteredArray = getFilterArray(mentionList, value2Search).filter(
          item => !mentionIdListRef.current.includes(item.id),
        );

        setFilteredGroupMembers(filteredArray);
        setIsShowTagList(filteredArray.length > 0);
      }
    } else {
      handleCloseTagList();
    }

    const contentMention = replaceName2Id(inputValue, mentionList);
    const mentionArr = mentionList.reduce((results, member) => {
      const mention = "@{" + member.id + "}";
      if (contentMention.includes(mention)) {
        results = [...results, member.id];
      }
      return results;
    }, []);

    mentionIdListRef.current = removeDuplicateInArray(mentionArr);

    updateMessageContent(inputValue);
  };

  const handleCloseTagList = () => setIsShowTagList(false);

  const handleSelectTag = person => {
    const isValid = person && person.id;
    if (!isValid) return;
    mentionIdListRef.current = [...mentionIdListRef.current, person.id];

    // Replace mention name by searching value
    const cursorIndex = getCursorPosition(inputRef.current);
    const preContent = inputRef.current.value.slice(0, cursorIndex);
    const otherContent = inputRef.current.value.slice(cursorIndex);
    const preContentWithMention = replaceByMentionName(preContent, `@${person.name || ""}`);
    const newContent = `${preContentWithMention}${otherContent.startsWith(" ") ? "" : " "}${otherContent}`;
    updateMessageContent(newContent);

    const newCursorIndex = preContentWithMention.length + 1;
    inputRef.current.setSelectionRange(newCursorIndex, newCursorIndex);
    handleCloseTagList();
  };

  const handleButtonSend = event => {
    event.preventDefault();

    let mentionIds = mentionIdListRef.current;
    const mentionAllIndex = mentionIdListRef.current.indexOf(SystemConstant.MENTION_ALL.id);
    if (mentionAllIndex >= 0) {
      const mentionAll = `@{${SystemConstant.MENTION_ALL.id}}`;
      mentionIds[mentionAllIndex] = mentionAll;
    }
    onSendMessage(replaceName2Id(inputRef.current.value?.trim(), mentionList), mentionIds);
    resetDraftMessage();
    handleResetInput();
  };

  const handlePasteTagMessage = pasteContent => {
    insertContentInput(pasteContent);
    const contentMention = replaceName2Id(pasteContent, mentionList);
    const mentionArr = mentionList.reduce((results, member) => {
      const mention = "@{" + member.id + "}";
      if (contentMention.includes(mention)) {
        results = [...results, member.id];
      }
      return results;
    }, []);

    const currentTagList = mentionIdListRef.current;

    mentionIdListRef.current = removeDuplicateInArray(currentTagList.concat(mentionArr));
  };

  useEffect(() => {
    if (groupDetail) handleResetInput();
  }, [groupDetail]);

  return (
    <Box className={classes.inputType}>
      <TextField
        autoFocus
        multiline
        minRows={1}
        maxRows={5}
        onKeyDown={handleKeyDown}
        className={classes.input}
        onChange={handleChangeInput}
        placeholder={placeholder}
        inputRef={inputRef}
        InputProps={{
          onContextMenu: handlePasterAnchor(true),
          classes: {
            notchedOutline: classes.noBorder,
          },
          startAdornment: (
            <CustomInputAdornment position="start">
              <FullEmojiLayout onSelect={insertContentInput} />
            </CustomInputAdornment>
          ),
          endAdornment: <InputEndAdornment isShowUploadIcon={isShowUploadIcon} onClickSend={handleButtonSend} />,
        }}
      />

      {isShowTagList && (
        <PeopleTagList
          isShow={isShowTagList}
          mentionMembers={filteredGroupMembers}
          onClick={handleSelectTag}
          onClose={handleCloseTagList}
        />
      )}

      <PastePopper anchorEl={pasterAnchorEl} onPaste={handlePasteTagMessage} onClose={handlePasterAnchor(false)} />
    </Box>
  );
};

MessengerChatInput.propTypes = {
  placeholder: PropTypes.string,
  isAllowAttach: PropTypes.bool,

  onSendMessage: PropTypes.func,
  onInputChange: PropTypes.func,
};

MessengerChatInput.defaultProps = {
  placeholder: "",
  isAllowAttach: true,

  onSendMessage: () => {},
  onInputChange: () => {},
};

export default memo(MessengerChatInput);

const CustomInputAdornment = forwardRef((props, ref) => <InputAdornment ref={ref} {...props} />);

const InputEndAdornment = ({ isShowUploadIcon, onClickSend }) => {
  return (
    <CustomInputAdornment position="end">
      <Box>
        {isShowUploadIcon ? (
          <SendMessageFile />
        ) : (
          <IconButton onClick={onClickSend}>
            <SendInputIcon />
          </IconButton>
        )}
      </Box>
    </CustomInputAdornment>
  );
};

const useStyles = makeStyles(theme => ({
  noBorder: {
    border: "none",
  },

  inputType: {
    width: "100%",
    position: "relative",
    backgroundColor: theme.palette.white,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },

  input: {
    width: "100%",
    backgroundColor: "#F3F3F5",
    borderRadius: 10,
    zIndex: 10,
  },
}));
