import { call, put } from "redux-saga/effects";
import { ApiConstant } from "const";
import { toCamel, toSnake } from "utils";
import store, { ContactActions, GroupInfoActions } from "redux-store";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services";
import { getInteractor } from "../services/local.service";
import { updateGroupNameAfterChangingContact, updateLastMsgAfterChangingContact } from "services/group.service";
import { checkCurrentBranchByPrefix, throwExceptionToast } from "./saga.helper";

export function* findContact(action) {
  let isSuccess = false;
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const params = {
      paging: 0,
      asc: 1,
      offset: 0,
      limit: 1,
      sinceTime: 0,
      isCorrect: 1,
      ...data,
    };

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).getAccountList, toSnake(params));
    const findingAccountList = Array.isArray(response.data?.data) ? toCamel(response.data.data) : [];
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ContactActions.contactSuccess({
          findAccountList: findingAccountList,
        }),
      );
      isSuccess = true;
    }
  } catch (error) {
    console.log(error);
  }

  if (!isSuccess) {
    throwExceptionToast();
  }
}

export function* updateContact(action) {
  let isSuccess = false;
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateContact, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      const responseData = toCamel(response.data);
      // Update contact name
      yield getInteractor(prefixKey).LocalContactService.updateNameContact(responseData);

      // Trigger UI
      yield put(
        ContactActions.contactSuccess({
          executeContact: responseData,
        }),
      );

      // Check/ update group_name if user set nickname for other account
      if (responseData.contactId) {
        yield updateGroupName(prefixKey, responseData.contactId);
        yield updateLastMessage(prefixKey, responseData.contactId);
      }
      isSuccess = true;
    }
  } catch (error) {
    console.log(error);
  }

  // Failed
  if (!isSuccess) {
    yield put(
      ContactActions.contactSet({
        executeContact: { modified: Date.now() },
      }),
    );
  }
}

export function* addContact(action) {
  let isSuccess = false;
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).addContact, toSnake(data));
    if ([ApiConstant.STT_OK, ApiConstant.STT_CONFLICT].includes(response.status)) {
      const executeContact = toCamel(
        ApiConstant.STT_OK === response.status
          ? response.data
          : yield getInteractor(prefixKey).LocalContactService.getContactByPhone(data.phoneNumber),
      );

      if (ApiConstant.STT_OK === response.status) {
        yield getInteractor(prefixKey).LocalContactService.save([response.data]);
        yield put(
          ContactActions.contactSuccess({
            executeContact: executeContact,
          }),
        );

        // Check/ update group_name if user set nickname for other account
        if (executeContact?.contactId) {
          yield updateGroupName(prefixKey, executeContact.contactId);
          yield updateLastMessage(prefixKey, executeContact.contactId);
        }
      } else if (executeContact?.id) {
        // Trigger to update contact logic
        yield call(updateContact, { data: { id: executeContact.id, contactName: data.contactName } });
      }
      isSuccess = true;
    }
  } catch (error) {
    console.log(error);
  }

  // Failed
  if (!isSuccess) {
    yield put(
      ContactActions.contactSet({
        executeContact: { modified: Date.now() },
      }),
    );
  }
}

export function* removeContact(action) {
  let isSuccess = false;
  try {
    const contactRecordId = action.contactRecordId; // contactId: id of record in contact table
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).deleteContact, contactRecordId);
    if ([ApiConstant.STT_OK, ApiConstant.STT_NOT_FOUND].includes(response.status)) {
      if (ApiConstant.STT_OK === response.status) {
        yield getInteractor(prefixKey).LocalContactService.delete(contactRecordId);
      }
      const executeContact = toCamel(
        ApiConstant.STT_OK === response.status
          ? response.data
          : yield getInteractor(prefixKey).LocalContactService.get(contactRecordId),
      );

      yield put(
        ContactActions.contactSuccess({
          executeContact: executeContact,
        }),
      );

      // Check/ update group_name if user set nickname for other account
      if (executeContact.contactId) {
        yield updateGroupName(prefixKey, executeContact.contactId);
        yield updateLastMessage(prefixKey, executeContact.contactId);
      }
      isSuccess = true;
    }
  } catch (error) {
    console.log(error);
  }

  // Failed
  if (!isSuccess) {
    yield put(
      ContactActions.contactSet({
        executeContact: { modified: Date.now() },
      }),
    );
  }
}

const updateLastMessage = async (prefixKey, accountId) => {
  const affectGroupIds = await updateLastMsgAfterChangingContact(prefixKey, accountId);
  const account = await getInteractor(prefixKey).LocalAccountService.get(accountId);

  if (account) {
    // Trigger update account
    store.dispatch(
      ContactActions.contactSuccess({
        updateSender: {
          affectGroupIds: affectGroupIds,
          accountId: account.id,
          name: account.name,
        },
      }),
    );
  }
};

const updateGroupName = async (prefixKey, accountId) => {
  const group = await updateGroupNameAfterChangingContact(prefixKey, accountId);
  if (group) {
    if (checkCurrentBranchByPrefix(prefixKey)) {
      // Trigger update group name
      store.dispatch(
        GroupInfoActions.groupInfoSet({
          updatingGroupData: { id: group.id, groupName: group.groupName },
        }),
      );
    }
  }
};
