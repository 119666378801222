import { KeyConstant, PathConstant, SystemConstant } from "const";
import { camelizeKeys } from "humps";
import store, { BranchActions, ConversationActions, PubSubActions } from "redux-store";
import { getInteractor, LocalDbManagement } from "services/local.service";
import { clearAllStorage, getDataFromPrefixKey, getPrefixKey, StorageUtil } from "utils";

export const isLoginBranch = branch => {
  branch = camelizeKeys(branch || StorageUtil.getCommonKey(KeyConstant.KEY_SELECTED_SERVER) || {});

  const accountId = branch.accountId;
  const branchId = branch.id || branch.branchId;

  if (Boolean(accountId && branchId)) {
    const prefixKey = getPrefixKey(accountId, branchId);
    return Boolean(StorageUtil.getItem(KeyConstant.KEY_TOKEN, prefixKey));
  }

  return false;
};

export const getLoginBranchInfo = () => StorageUtil.getCommonKey(KeyConstant.KEY_TMP_BRANCH) || {};

export const setLoginData = (camelResponse, phoneNumber) => {
  const prefixKey = getPrefixKey(camelResponse.accountId, camelResponse.branchId);
  const isValid =
    Object.keys(camelResponse).length > 0 && phoneNumber && prefixKey && false === prefixKey.includes("undefined");
  if (!isValid) throw new Error("CAN NOT LOGIN - Invalid data");

  // Update domain after login
  const tmpDomain = getLoginBranchInfo().domain;
  StorageUtil.setCommonKey(KeyConstant.KEY_CURRENT_DOMAIN, tmpDomain);
  const branchInfo = {
    id: camelResponse.branchId,
    domain: tmpDomain,
  };

  // Storage branch data
  StorageUtil.setCommonKey(KeyConstant.KEY_PREFIX, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_BRANCH_INFO, branchInfo, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_TOKEN, camelResponse.accessToken, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_ACCOUNT_ID, camelResponse.accountId, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_BRANCH_ID, camelResponse.branchId, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_DEVICE_ID, camelResponse.deviceId, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_PHONE, phoneNumber, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_LOGIN_TIME, new Date().getTime(), prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_MEET_TOKEN, camelResponse.meetToken, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_EXPIRED_TIME, camelResponse.expiredTime, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_REFRESH_EXPIRED_TIME, camelResponse.refreshExpiredTime, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_REFRESH_TOKEN, camelResponse.refreshToken, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_SCOPES, camelResponse.scopes, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_FIRST_UPLOAD_F, false, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_TRIOS_ACCESS, camelResponse.triosAccess, prefixKey);

  // Copy key to branch data
  const identityPublicKey = StorageUtil.getCommonKey(KeyConstant.KEY_IDENTITY_PUBLIC_KEY);
  const identityPrivateKey = StorageUtil.getCommonKey(KeyConstant.KEY_IDENTITY_PRIVATE_KEY);
  const activeSignedPrekeyId = StorageUtil.getCommonKey(KeyConstant.KEY_ACTIVE_SIGNED_PREKEY_ID);
  const nextSignedPrekeyId = StorageUtil.getCommonKey(KeyConstant.KEY_NEXT_SIGNED_PREKEY_ID);
  const nextPrekeyId = StorageUtil.getCommonKey(KeyConstant.KEY_NEXT_PREKEY_ID);

  StorageUtil.setItem(KeyConstant.KEY_IDENTITY_PUBLIC_KEY, identityPublicKey, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_IDENTITY_PRIVATE_KEY, identityPrivateKey, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_ACTIVE_SIGNED_PREKEY_ID, activeSignedPrekeyId, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_NEXT_SIGNED_PREKEY_ID, nextSignedPrekeyId, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_NEXT_PREKEY_ID, nextPrekeyId, prefixKey);

  // Set selected branch in storage
  StorageUtil.setCommonKey(KeyConstant.KEY_SELECTED_SERVER, {
    id: camelResponse.branchId,
    accountId: camelResponse.accountId,
    phone: phoneNumber,
    loginState: SystemConstant.STATE.active,
    ...camelizeKeys(branchInfo),
  });
};

export const refreshTokenData = (prefixKey, responseData) => {
  const camelResponse = camelizeKeys(responseData);
  const isValid = camelResponse.accountId && prefixKey && prefixKey.includes(camelResponse.accountId);

  if (isValid) {
    StorageUtil.setItem(KeyConstant.KEY_TOKEN, camelResponse.accessToken, prefixKey);
    StorageUtil.setItem(KeyConstant.KEY_EXPIRED_TIME, camelResponse.expiredTime, prefixKey);
    StorageUtil.setItem(KeyConstant.KEY_REFRESH_EXPIRED_TIME, camelResponse.refreshExpiredTime, prefixKey);
    StorageUtil.setItem(KeyConstant.KEY_REFRESH_TOKEN, camelResponse.refreshToken, prefixKey);
    StorageUtil.setItem(KeyConstant.KEY_SCOPES, camelResponse.scopes, prefixKey);
    StorageUtil.setItem(KeyConstant.KEY_TRIOS_ACCESS, camelResponse.triosAccess, prefixKey);
  }
};

export const handlingLogoutBranch = async prefixKey => {
  const [accountId, branchId] = getDataFromPrefixKey(prefixKey);
  if (false === Boolean(accountId && branchId)) return false;

  const activeBranchList = await LocalDbManagement.find({ state: SystemConstant.STATE.active });

  if (activeBranchList && activeBranchList.length === 0) {
    clearAllStorage();
    return true;
  }

  const logoutBranchRecord = activeBranchList.find(
    item => item.account_id === accountId && item.branch_id === branchId,
  );

  if (logoutBranchRecord) {
    await LocalDbManagement.save([
      {
        ...logoutBranchRecord,
        state: SystemConstant.STATE.inactive,
        modified: new Date().getTime(),
      },
    ]);
    await getInteractor(prefixKey).LocalInitDataService.deleteTable();
  }

  store.dispatch(
    BranchActions.branchSuccess({
      fetchBranchTimestamp: new Date().getTime(),
    }),
  );

  StorageUtil.setItem(KeyConstant.KEY_FIRST_UPLOAD_F, false, prefixKey);
  StorageUtil.removeCommonKey(prefixKey);

  // Destroy pubsub after logout
  store.dispatch(PubSubActions.destroyLogoutBranchThread());

  if (logoutBranchRecord && activeBranchList.length === 1) clearAllStorage();

  return true;
};

// Logout branch and return if having logged in branch
export const hasMoreLoggedInBranch = async () => {
  const currentPrefixKey = StorageUtil.getCommonKey(KeyConstant.KEY_PREFIX);
  const currentBranchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, currentPrefixKey);
  const activeDBList = await LocalDbManagement.find({ state: SystemConstant.STATE.active }); // list branch is logging in
  const activeDB = activeDBList.find(item => item.branch_id !== currentBranchId);

  if (activeDB) {
    const prefixKey = getPrefixKey(activeDB.account_id, activeDB.branch_id);
    const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey);

    StorageUtil.setCommonKey(KeyConstant.KEY_PREFIX, prefixKey);
    StorageUtil.setCommonKey(KeyConstant.KEY_CURRENT_DOMAIN, branchInfo.domain);
    StorageUtil.setCommonKey(KeyConstant.KEY_SELECTED_SERVER, branchInfo);

    return true;
  }

  return false;
};

export const goToLoginPortal = history => {
  if (history) history.replace(PathConstant.LOGIN_PORT);

  store.dispatch(
    ConversationActions.conversationSet({
      threadingId: null,
      selectedGroupId: null,
    }),
  );
};
