import { ListSubheader, Stack } from "@mui/material";
import { styled } from "@mui/styles";

const ListItemByDate = ({ children, date }) => {
  return (
    <li key={`date-${date}`}>
      <LimitContainer component="ul" gap={1}>
        <DateTypography>{date}</DateTypography>
        <Stack spacing={1}>{children}</Stack>
      </LimitContainer>
    </li>
  );
};

export default ListItemByDate;

const LimitContainer = styled(Stack)({
  width: "80%",
  maxWidth: 900,
  margin: "auto",
});

const DateTypography = styled(ListSubheader)({
  padding: 0,
  lineHeight: "normal",
});
