import React, { memo } from "react";
import PropTypes from "prop-types";
import { ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import { AppConstant } from "const";
import AvatarGroup from "./avatar/AvatarGroup";
import { getCommonLang } from "utils/lang.utils";

const AvatarInfo = props => {
  const {
    avatar,
    title,
    secondTitle,
    endComponent,
    size,
    avatarProps,
    titleProps,
    privateF,
    itemProps,
    avatarRootProps,
    sendToMe,
    BadgeIcon,
    ...otherProps
  } = props;
  const classes = useStyleBySize(size, Boolean(avatarProps?.onClick));

  return (
    <ListItem secondaryAction={endComponent} {...otherProps} classes={{ root: itemProps }}>
      <ListItemAvatar sx={{ minWidth: 48 }} {...avatarProps}>
        <AvatarGroup
          group={{
            groupType: avatar.type,
            groupName: avatar?.name,
            privateF: privateF,
            isServerLogo: avatar.isServerLogo,
          }}
          avatarId={avatar.avatarId}
          sx={classes.avatar}
          classes={{ root: avatarRootProps }}
          BadgeIcon={BadgeIcon}
        />
      </ListItemAvatar>
      <ListItemText
        sx={{ paddingLeft: 2 }}
        primary={
          <Stack direction="row" spacing={0.5}>
            <Typography sx={classes.title} className="ellipsis" {...titleProps}>
              {title || ""}
            </Typography>
            {sendToMe && <span>{getCommonLang("TXT_ME_LABEL")}</span>}
          </Stack>
        }
        secondary={
          <Typography sx={classes.secondTitle} className="ellipsis" dangerouslySetInnerHTML={{ __html: secondTitle }} />
        }
      />
    </ListItem>
  );
};

AvatarInfo.propTypes = {
  avatar: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string,
    avatarId: PropTypes.string,
    type: PropTypes.number,
  }),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  endComponent: PropTypes.node,
  size: PropTypes.string,
  button: PropTypes.bool,
  avatarProps: PropTypes.object,
  titleProps: PropTypes.object,

  onClick: PropTypes.func,
};

export default memo(AvatarInfo);

const useStyleBySize = (size, hasClickEvent) => {
  const commonStyle = { minWidth: "unset", cursor: hasClickEvent ? "pointer" : "default" };
  switch (size) {
    case AppConstant.SIZE_OBJ.small:
      return {
        avatar: { width: 36, height: 36, ...commonStyle },
        title: { fontWeight: 600, fontSize: "0.75rem" },
        secondTitle: { fontWeight: 500, fontSize: "0.625rem", color: "#6C7078" },
      };
    case AppConstant.SIZE_OBJ.large:
      return {
        avatar: { width: 54, height: 54, ...commonStyle },
        title: { fontWeight: 700, fontSize: "0.9375rem" },
        secondTitle: { fontWeight: 700, fontSize: "0.75rem", color: "#6C7078" },
      };

    default:
      return {
        avatar: { width: 48, height: 48, ...commonStyle },
        title: { fontWeight: 600, fontSize: "0.875rem" },
        secondTitle: { fontWeight: 600, fontSize: "0.75rem", color: "#6C7078" },
      };
  }
};
