import { memo, useEffect, useState } from "react";
import { List, Stack, Typography } from "@mui/material";
import Scrollable from "pages/ManageFilePage/components/Scrollable";
import RequestListByDate from "./RequestListByDate";
import { getNSLang } from "utils/lang.utils";
import { AppConstant, LangConstant } from "const";
import { getRequestList } from "services/manageRequest.service";
import { StorageUtil } from "utils";
import { groupDataByDate } from "utils/date.utils";
import { FileListLoading } from "pages/ManageFilePage/components";
import { EmptyResult } from "components";

const ManageRequest = () => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  const [viewMode, setViewMode] = useState(AppConstant.VIEW_MODE.fetching);
  const [requestByDate, setRequestByDate] = useState({}); // { <date>: [] }: List request group by date (date is formatted to view)
  const [pagination, setPagination] = useState({
    paging: 0,
    isEnd: false,
  });

  const handleGetRequestList = async () => {
    if (false === pagination.isEnd) {
      const remoteList = await getRequestList(prefixKey, pagination.paging + 1);
      const { data, paging, isEnd } = remoteList;
      if (Array.isArray(data)) {
        const refactorList = groupDataByDate(data, { ...requestByDate });
        setRequestByDate(refactorList);
        setPagination({ paging, isEnd });

        return refactorList;
      }
    }

    return requestByDate;
  };

  useEffect(() => {
    setViewMode(AppConstant.VIEW_MODE.fetching);
    handleGetRequestList().then(result =>
      setViewMode(Object.keys(result).length === 0 ? AppConstant.VIEW_MODE.empty : AppConstant.VIEW_MODE.list),
    );
  }, []);

  return (
    <Stack width="100%" height="100%" alignItems="center" pt={4} spacing={3}>
      <Typography variant="subtitle1" fontWeight={700}>
        {getNSLang(LangConstant.NS_HOME, "OBJ_ACCOUNT_ME.manageRequestLogin", {
          returnObjects: true,
        })}
      </Typography>

      <Scrollable onBottom={handleGetRequestList}>
        {viewMode === AppConstant.VIEW_MODE.list && (
          <Stack component={List} gap={2} disablePadding paddingBottom={2}>
            {Object.keys(requestByDate).map(date => (
              <RequestListByDate key={date} date={date} list={requestByDate[date]} />
            ))}
          </Stack>
        )}

        {viewMode === AppConstant.VIEW_MODE.fetching && <FileListLoading />}
      </Scrollable>
      {viewMode === AppConstant.VIEW_MODE.empty && (
        <Stack width="100%" height="100%" justifyContent="center">
          <EmptyResult />
        </Stack>
      )}
    </Stack>
  );
};

export default memo(ManageRequest);
