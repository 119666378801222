import { memo, useEffect, useState } from "react";
import { FormControl, MenuItem, OutlinedInput, Select, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppDrawer, TransLanguage } from "components";
import { LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import AccountItem from "../../../AccountItem";
import { NavigateNext } from "@mui/icons-material";
import { useAlertContext } from "components/context/AlertContext";
import { useDispatch } from "react-redux";
import { SystemActions } from "redux-store";

const AutoLock = ({ defaultSetting, onChangeSetting, disabled }) => {
  const classes = useStyles();
  const { t: getScreenLockLabel } = useTranslation(LangConstant.NS_SCREEN_LOCK);
  const dispatch = useDispatch();

  const { showAlert } = useAlertContext();

  const [isOpen, setIsOpen] = useState(false);
  const [defaultLockTimeLabel, setDefaultLockTimeLabel] = useState("");
  const [time2Lock, setTime2Lock] = useState(""); // milliseconds

  const handleOpenFunc = open => () => setIsOpen(open);

  const handleChangeTime = async event => {
    const newTime = event.target.value;
    if (newTime !== time2Lock) {
      setTime2Lock(newTime);
      // update to local db device-setting
      onChangeSetting({
        options: {
          lock_time: newTime,
        },
        state: SystemConstant.STATE.active,
      });

      showAlert({ content: getScreenLockLabel("TXT_UPDATE_LOCK_TIME_SUCCESS") });

      dispatch(
        SystemActions.systemSet({
          screenLockSettingTimestamp: Date.now(),
        }),
      );
    }
  };

  useEffect(() => {
    setTime2Lock(defaultSetting.lockTime);
    setDefaultLockTimeLabel(convert2TimeLabel(defaultSetting.lockTime));
  }, [defaultSetting]);

  return (
    <>
      <AccountItem
        text={getScreenLockLabel("TXT_AUTO_LOCK")}
        secondaryText={getScreenLockLabel("FM_AUTO_SCREEN_LOCK", { time: convert2TimeLabel(time2Lock) })}
        endIcon={<NavigateNext />}
        onClick={handleOpenFunc(true)}
        disabled={disabled}
      />

      {isOpen && (
        <AppDrawer open={isOpen} onClose={handleOpenFunc(false)} title={getScreenLockLabel("TXT_AUTO_LOCK")}>
          <Stack className={classes.root} spacing={1}>
            <Typography>{getScreenLockLabel("TXT_AUTO_LOCK")}</Typography>

            <FormControl fullWidth>
              <Select
                value={time2Lock}
                onChange={handleChangeTime}
                input={<OutlinedInput />}
                defaultValue={defaultLockTimeLabel}
              >
                {TIME_LIST.map(time => (
                  <MenuItem key={time.label} value={time.value}>
                    {time.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography>
              <TransLanguage>
                {getScreenLockLabel("FM_SETTING_AUTO_LOCK", { time: convert2TimeLabel(time2Lock) })}
              </TransLanguage>
            </Typography>
          </Stack>
        </AppDrawer>
      )}
    </>
  );
};

export default memo(AutoLock);

const TIME_LIST = [
  { label: "1 phút", value: 60 * 1000 },
  { label: "5 phút", value: 5 * 60 * 1000 },
  { label: "30 phút", value: 30 * 60 * 1000 },
  { label: "1 giờ", value: 60 * 60 * 1000 },
  { label: "5 giờ", value: 5 * 60 * 60 * 1000 },
];

const convert2TimeLabel = lockTime => {
  const label = TIME_LIST.find(item => item.value === lockTime)?.label || TIME_LIST[0].label;

  return label;
};

const useStyles = makeStyles({
  root: {
    padding: "8px 16px",
  },
});
