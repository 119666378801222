import { useSelector } from "react-redux";
import { Box, styled } from "@mui/material";
import { SystemSelectors } from "./redux-store";
import { KeyConstant } from "const";
import { ScreenLockPage, ManageFilePage } from "pages";
import { StorageUtil } from "utils";
import { createSelector } from "reselect";
import { isLoginBranch } from "utils/auth.utils";

const memoizedReduxState = createSelector(
  [SystemSelectors.checkScreenLock, SystemSelectors.isShowMnFile],
  (screenLock, isShowMnFile) => {
    return {
      isShowScreenLock: Boolean(screenLock?.isShow || StorageUtil.getCommonKey(KeyConstant.SCREEN_LOCK_STATUS)),
      isShowMnFile,
    };
  },
);

const AppTopLayer = () => {
  const { isShowScreenLock, isShowMnFile } = useSelector(memoizedReduxState);

  if (!isLoginBranch()) return null; // Skip if user logout
  return (
    <>
      {isShowMnFile && (
        <TopLayerBox id="mn-file-page" zIndex={11}>
          <ManageFilePage />
        </TopLayerBox>
      )}

      {isShowScreenLock && (
        <TopLayerBox id="screen-lock-page" zIndex={1300}>
          <ScreenLockPage />
        </TopLayerBox>
      )}
    </>
  );
};

export default AppTopLayer;

const TopLayerBox = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  background: "white",
});
