import { AppDrawer } from "components";
import { LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import AccountItem from "../../../AccountItem";
import { NavigateNext } from "@mui/icons-material";
import { useState } from "react";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getCommonLang } from "utils/lang.utils";
import { useAlertContext } from "components/context/AlertContext";
import { convertScreenLockPassword } from "utils/view.utils";

const ChangeScreenPassword = ({ defaultSetting, onChangeSetting, disabled }) => {
  const classes = useStyles();
  const { t: getScreenLockLabel } = useTranslation(LangConstant.NS_SCREEN_LOCK);

  const { showAlert } = useAlertContext();

  const [isOpen, setIsOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorOld, setErrorOld] = useState("");
  const [errorNewPw, setErrorNewPw] = useState("");
  const [errorConfirm, setErrorConfirm] = useState("");

  const handleOpenFunc = open => () => setIsOpen(open);

  const handleSubmit = () => {
    onChangeSetting({
      options: {
        password: newPassword,
      },
      state: SystemConstant.STATE.active,
    });

    showAlert({ content: getScreenLockLabel("TXT_CHANGE_PASSWORD_SUCCESS") });
    handleClose();
  };

  const handleChangeOldPassword = event => {
    const password = convertScreenLockPassword(event.target.value);
    setOldPassword(password);

    if (password.length === 4 && password !== defaultSetting.password) {
      setErrorOld(getScreenLockLabel("TXT_OLD_PASSWORD_ERROR"));
    } else {
      setErrorOld("");
    }
  };

  const handleChangeNewPassword = event => {
    const newPassword = convertScreenLockPassword(event.target.value);
    setNewPassword(newPassword);

    if (newPassword.length < 4) {
      setErrorNewPw(getScreenLockLabel("TXT_PASSWORD_PLACEHOLDER"));
    } else {
      setErrorNewPw("");
    }

    if (confirmPassword && confirmPassword !== newPassword) {
      setErrorConfirm(getScreenLockLabel("TXT_CONFIRM_PASSWORD_ERROR"));
    } else {
      setErrorConfirm("");
    }
  };

  const handleChangeConfirm = event => {
    const confirmPw = convertScreenLockPassword(event.target.value);
    setConfirmPassword(confirmPw);

    if (confirmPw !== newPassword) {
      setErrorConfirm(getScreenLockLabel("TXT_CONFIRM_PASSWORD_ERROR"));
    } else {
      setErrorConfirm("");
    }
  };

  const handleClose = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setErrorOld("");
    setErrorNewPw("");
    setErrorConfirm("");
    setIsOpen(false);
  };

  const isDisableCreate =
    false === Boolean(oldPassword && newPassword && confirmPassword) || Boolean(errorOld || errorNewPw || errorConfirm);

  return (
    <>
      <AccountItem
        text={getScreenLockLabel("TXT_CHANGE_PASSWORD")}
        endIcon={<NavigateNext />}
        disabled={disabled}
        onClick={handleOpenFunc(true)}
      />

      {isOpen && (
        <AppDrawer open={isOpen} onClose={handleClose} title={getScreenLockLabel("TXT_CHANGE_PASSWORD")}>
          <Box component="form" onSubmit={handleSubmit}>
            <Stack spacing={1} className={classes.paddingRoot}>
              <Typography className={classes.title}>{getScreenLockLabel("TXT_OLD_PASSWORD")}</Typography>
              <TextField
                fullWidth
                type="password"
                required
                placeholder={getScreenLockLabel("TXT_PASSWORD_PLACEHOLDER")}
                value={oldPassword}
                onChange={handleChangeOldPassword}
                error={Boolean(errorOld)}
                helperText={errorOld}
                FormHelperTextProps={{ classes: { root: classes.helperError } }}
              />
            </Stack>

            <Stack spacing={1} className={classes.paddingRoot}>
              <Typography className={classes.title}>{getScreenLockLabel("TXT_NEW_PASSWORD")}</Typography>
              <TextField
                fullWidth
                type="password"
                required
                placeholder={getScreenLockLabel("TXT_PASSWORD_PLACEHOLDER")}
                value={newPassword}
                onChange={handleChangeNewPassword}
                FormHelperTextProps={{ classes: { root: classes.helperError } }}
                error={Boolean(errorNewPw)}
                helperText={errorNewPw}
              />
            </Stack>

            <Stack spacing={1} className={classes.paddingRoot}>
              <Typography className={classes.title}>{getScreenLockLabel("TXT_CONFIRM_NEW_PASSWORD")}</Typography>
              <TextField
                fullWidth
                type="password"
                required
                placeholder={getScreenLockLabel("TXT_CONFIRM_PASSWORD_PLACEHOLDER")}
                value={confirmPassword}
                onChange={handleChangeConfirm}
                FormHelperTextProps={{ classes: { root: classes.helperError } }}
                error={Boolean(errorConfirm)}
                helperText={errorConfirm}
              />
            </Stack>

            <Stack direction="row" spacing={1} className={classes.paddingRoot} justifyContent="flex-end">
              <Button variant="contained" className={classes.cancelButton} onClick={handleClose}>
                {getCommonLang("TXT_CANCEL")}
              </Button>
              <Button type="submit" variant="contained" disabled={isDisableCreate}>
                {getCommonLang("TXT_CONTINUE")}
              </Button>
            </Stack>
          </Box>
        </AppDrawer>
      )}
    </>
  );
};

export default ChangeScreenPassword;

const useStyles = makeStyles(theme => ({
  paddingRoot: {
    padding: "8px 16px",
  },

  cancelButton: {
    color: "#000000",
    backgroundColor: theme.palette.grey[100],
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },

  helperError: {
    margin: "4px 0px",
    textAlign: "right",
    fontSize: "0.75rem",
  },
}));
