import { ApiConstant, LangConstant, SystemConstant } from "const";
import { getLabel } from "language";
import { ExecuteEvent } from "pubsub/PubSub.const";
import store, { SystemActions } from "redux-store";
import { formatPagingParams } from "sagas/saga.helper";
import { remoteApiFactory } from "services";
import { getInteractor, LocalAppNotificationService } from "services/local.service";
import { toCamel } from "utils";

export const branchReqNotification = async (prefixKey, notificationId) => {
  try {
    const localNotification = getInteractor(prefixKey).LocalNotificationService.findOne({ id: notificationId });
    // If notification is not exited in local db => get notification from remote
    if (false === Boolean(localNotification && localNotification.id)) {
      const params = formatPagingParams({
        filter: JSON.stringify({
          id: notificationId,
        }),
      });
      const response = await remoteApiFactory.getBranchApi(prefixKey).getNotificationList(params);
      if (response.status === ApiConstant.STT_OK) {
        const remoteData = response.data;
        if (Array.isArray(remoteData) && remoteData.length > 0) {
          const remoteNotice = remoteData[0];
          await handleShowBranchReqNotice(prefixKey, remoteNotice);
          await getInteractor(prefixKey).LocalNotificationService.save(remoteData);
        }
      }
    }
    return ExecuteEvent.SUCCESSES;
  } catch (error) {
    console.log("branch request event create fail: ", error);
  }
  return ExecuteEvent.FAIL;
};

export const handleShowBranchReqNotice = async (prefixKey, branchReqNotice) => {
  try {
    if (branchReqNotice && branchReqNotice.id) {
      const localParentNotice = await getInteractor(prefixKey).LocalNotificationService.findOne({
        parent: branchReqNotice.parent,
      });

      if (
        false === Boolean(localParentNotice && localParentNotice.id) &&
        branchReqNotice.state === SystemConstant.BRANCH_REQ_NOTICE_STATE.pending
      ) {
        LocalAppNotificationService.showNotification(
          getLabel(LangConstant.TXT_APP_NAME),
          {
            content: `Thông báo: ${branchReqNotice.content}`,
            prefixKey,
          },
          toCamel(branchReqNotice),
        );
      } else {
        store.dispatch(
          SystemActions.systemSet({
            newNotification: toCamel(branchReqNotice),
          }),
        );
      }

      return true;
    }
  } catch (error) {
    console.log("owner branchReqNotice fail: ", error);
  }

  return false;
};
