import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { uuid } from "utils";
import { ImageConstant, LangConstant } from "const";
import NoData from "./NoData";
import DataLink from "./DataLink";
import { getInteractor } from "services/local.service";
import clsx from "clsx";
import { groupDataByDate } from "utils/date.utils";

const Link = ({ data, ...otherProps }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const [dateTimeData, setDateTimeData] = useState({});

  const { className, ...others } = otherProps;

  useEffect(() => {
    Promise.all(
      data.map(async item => {
        let result = { ...item };
        let childMsgs = await getInteractor().LocalMessageService.getChildMessages(item.sourceId, item.sendType);

        if (childMsgs.length > 0) {
          result.content = childMsgs[0].content;
        }

        return result;
      }),
    ).then(messageList => setDateTimeData(groupDataByDate(messageList, {}, true)));
  }, [data]);

  return (
    <Box className={clsx(classes.root, className)} {...others}>
      {dateTimeData && Object.keys(dateTimeData).length > 0 ? (
        Object.keys(dateTimeData).map(key => (
          <Box key={uuid()}>
            <Typography className={classes.day}>{key}</Typography>
            {dateTimeData[key].map((dataMap, item) => (
              <Box key={dataMap.id}>
                <DataLink data={dataMap} isDivider={Boolean(dateTimeData[key].length - 1 !== item)} />
              </Box>
            ))}
          </Box>
        ))
      ) : (
        <NoData
          media={<CardMedia component="img" alt="cover" className={classes.cover} src={ImageConstant.NoLinkImage} />}
          textOne={getLabel(LangConstant.TXT_CONTENT_NO_LINK)}
          textTwo={getLabel(LangConstant.TXT_NO_LINK)}
        />
      )}
    </Box>
  );
};

Link.propTypes = {
  data: PropTypes.array,
};

Link.defaultProps = {
  data: [],
};

export default memo(Link);

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
  },
  cover: {
    position: "relative",
    margin: "12px auto",
    height: "auto",
    maxWidth: "80%",
    width: "unset",
  },
  day: {
    fontSize: "0.9375rem",
    fontWeight: 700,
    lineHeight: "1.25rem",
    color: theme.palette.black,
  },
}));
