import { useEffect, useState } from "react";
import { sortBy } from "lodash";
import { StorageUtil, toCamel } from "utils";
import { useSelector } from "react-redux";
import { BranchSelectors, SystemSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import { createSelector } from "reselect";

export const memoizedSelectedBranchId = createSelector(
  [
    BranchSelectors.getSelectedBranch,
    SystemSelectors.isSystemSynchronizing,
    state => state.contactRedux.executeContact,
  ],
  (selectedBranch, isSynchronizing, executeContact) => {
    return {
      selectedBranch,
      isSynchronizing,
      executeContact: executeContact?.contactId ? executeContact : null,
    };
  },
);

const useAccountList = () => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { selectedBranch, isSynchronizing, executeContact } = useSelector(memoizedSelectedBranchId);

  const [accountList, setAccountList] = useState([]); // List accounts of current branch include current account

  const getAccountList = async (keyword = "") => {
    keyword = keyword ? keyword.trim() : "";
    const accountsInBranch = await getInteractor(prefixKey).LocalAccountService.searchAccountList({
      keyword,
      branch_id: selectedBranch.id,
    });

    const sortedAccountList = sortBy(accountsInBranch, [account => account.name?.toLowerCase()?.trim()]);
    setAccountList(toCamel(sortedAccountList));
  };

  useEffect(() => {
    const isValidData = selectedBranch.id && !isSynchronizing;
    if (isValidData) {
      getAccountList();
    }
  }, [selectedBranch, executeContact, isSynchronizing]);

  return { accountList, getAccountList };
};

export default useAccountList;
