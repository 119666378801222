import { AppConstant } from "const";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContactActions } from "redux-store";
import { createSelector } from "reselect";

const memoizedReduxState = createSelector([state => state.contactRedux.findAccountList], findAccountList => {
  return { findAccountList };
});

// Finding account by phone
const useFindAccount = () => {
  const dispatch = useDispatch();
  const { findAccountList } = useSelector(memoizedReduxState);

  const [actionStatus, setActionStatus] = useState(AppConstant.PROCESS_STATUS.nothing);

  const findContact = phoneNumber => {
    setActionStatus(AppConstant.PROCESS_STATUS.calling);
    dispatch(
      ContactActions.findContact({
        filter: JSON.stringify({ phone: phoneNumber }),
      }),
    );
  };

  const resetData = () => setActionStatus(AppConstant.PROCESS_STATUS.nothing);

  useEffect(() => {
    if (actionStatus === AppConstant.PROCESS_STATUS.calling) {
      if (findAccountList) {
        setActionStatus(AppConstant.PROCESS_STATUS.success);
      } else {
        setActionStatus(AppConstant.PROCESS_STATUS.error);
      }
    }
  }, [findAccountList]);

  return { actionStatus, findAccountList, findContact, resetData };
};

export default useFindAccount;
