import { useEffect, useState } from "react";
import { QrCode } from "@mui/icons-material";
import { Box, Button, CardMedia, Container, Stack, Typography } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { HandleUploadingInput, ServerItem } from "components";
import { AppConstant, ImageConstant, KeyConstant, LangConstant, PathConstant, SystemConstant } from "const";
import { useQrLogin, useServerList, useServerMode } from "hooks";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { useHistory } from "react-router-dom";
import { AttachmentUtil, FileUtil, StorageUtil } from "utils";
import theme from "theme/material";
import { BranchActions } from "redux-store";
import { useDispatch } from "react-redux";
import { LocalDbManagement } from "services/local.service";

const LoginPortPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { globalServerList, branchList } = useServerList();
  const { switchServer } = useServerMode();

  const [qrImage, setQrImage] = useState(null);
  const [appLogo, setAppLogo] = useState({
    src: "",
    name: "",
  });

  const { loginData, checkStatus } = useQrLogin(qrImage);

  const handleSelectQr = files => {
    const qr = files[0];
    if (qr) setQrImage(qr);
  };

  const handleSelectServer = server => () => switchServer(server);

  const handleGetAppLogo = async attachmentId => {
    const logoSrc = await FileUtil.getUrlFileByPath(AttachmentUtil.getAppIcon(attachmentId));
    const branchInfo = await LocalDbManagement.findOne({ branch_id: attachmentId });

    if (branchInfo && branchInfo.id && logoSrc) {
      setAppLogo({
        src: logoSrc,
        name: branchInfo.branch_name,
      });
    } else {
      setAppLogo({
        src: ImageConstant.LogoImage,
        name: getCommonLang("TXT_APP_NAME"),
      });
    }
  };

  useEffect(() => {
    if (loginData) {
      const existedBranch = branchList.find(
        item => item.id === loginData.branch_id && item.loginState === SystemConstant.STATE.active,
      );
      if (existedBranch) {
        switchServer(existedBranch);
      } else {
        StorageUtil.setCommonKey(KeyConstant.KEY_TMP_BRANCH, {
          id: loginData.branch_id,
          domain: loginData.branch_domain,
          qrToken: loginData.qr_token,
        });
        dispatch(
          BranchActions.branchSet({
            loginBranch: { id: loginData.branch_id, domain: loginData.branch_domain },
          }),
        );
        history.replace(`${PathConstant.LOGIN}?qr=1`);
      }
    } else {
      setQrImage(null);
    }
  }, [loginData]);

  useEffect(() => {
    const settingApp = StorageUtil.getCommonKey(KeyConstant.KEY_SETTING_APP) || {};
    const logoId = settingApp[KeyConstant.KEY_BRANCH_ID];
    if (logoId && logoId !== SystemConstant.GLOBAL_BRANCH_ID) {
      handleGetAppLogo(logoId);
    } else {
      setAppLogo({
        src: ImageConstant.LogoImage,
        name: getCommonLang("TXT_APP_NAME"),
      });
    }
  }, []);

  return (
    <Container
      component="main"
      maxWidth="xl"
      classes={{
        root: classes.container,
        maxWidthXl: classes.container,
      }}
    >
      <Box className={classes.root}>
        <LeftSideContainer>
          <Stack flexGrow={1} justifyContent="center">
            <Stack spacing={1} alignItems="center" textAlign="center">
              <CardMedia component="img" src={appLogo.src} className={classes.logoImg} />
              <Typography fontWeight={700} fontSize="2rem" color={theme.palette.primary.main}>
                {appLogo.name}
              </Typography>
            </Stack>

            <Stack spacing={1} width="100%" my={4}>
              <Button
                component="label"
                variant="contained"
                fullWidth
                className={classes.continueButton}
                startIcon={<QrCode sx={{ width: 50, height: 50 }} />}
                disabled={checkStatus === AppConstant.PROCESS_STATUS.calling}
              >
                <Stack>
                  <Typography variant="subtitle1" color="#ffffff">
                    {getNSLang(LangConstant.NS_LOGIN, "TXT_JOIN_BRANCH_SERVER")}
                  </Typography>
                  <Typography variant="body2" color="#ffffff">
                    {getNSLang(LangConstant.NS_LOGIN, "TXT_CONTACT_ADMIN")}
                  </Typography>
                </Stack>
                <HandleUploadingInput
                  accept="image/*"
                  className="hidden"
                  id="btn-select-qr"
                  type="file"
                  onChange={handleSelectQr}
                />
              </Button>

              {branchList.length > 0 && (
                <ServerContained>
                  <Typography variant="subtitle1" textAlign="center" fontWeight={700} mb={1}>
                    {getNSLang(LangConstant.NS_LOGIN, "TXT_PRIVATE_SERVERS")}
                  </Typography>
                  {branchList.map(item => (
                    <ServerItem key={item.id} data={item} onClick={handleSelectServer(item)} isShowStatus={false} />
                  ))}
                </ServerContained>
              )}

              <ServerContained>
                <Typography variant="subtitle1" textAlign="center" fontWeight={700} mb={1}>
                  {getNSLang(LangConstant.NS_LOGIN, "TXT_PUBLIC_SERVERS")}
                </Typography>
                {globalServerList.map(item => (
                  <ServerItem key={item.id} data={item} onClick={handleSelectServer(item)} isShowStatus={false} />
                ))}
              </ServerContained>
            </Stack>
          </Stack>

          <CardMedia component="img" src={ImageConstant.BGlobalImage} className={classes.bglobalLogo} />
        </LeftSideContainer>

        <Box className={classes.imageContainer}>
          <CardMedia component="img" src={ImageConstant.ImageLogin} className={classes.loginWelcomeImage} />
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPortPage;

const ServerContained = styled(Box)(props => ({
  width: "100%",
  height: "fit-content",
  maxHeight: 262,
  overflow: "auto",
  padding: 8,
  border: "solid",
  borderWidth: "thin",
  borderRadius: 8,
  borderColor: props.theme.palette.grey[200],
}));

const LeftSideContainer = styled(Stack)(props => ({
  width: "30%",
  height: "100%",
  minWidth: 400,
  justifyContent: "space-between",
  alignItems: "center",
  padding: 24,
  overflow: "auto",

  "& > div": {
    width: "100%",
  },

  [props.theme.breakpoints.up(1500)]: {
    width: AppConstant.SIDEBAR_WIDTH.large,
  },
}));

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100%",
  },

  logoImg: {
    width: 100,
  },

  continueButton: {
    padding: "14px 8px",
    textTransform: "none",
  },

  bglobalLogo: {
    width: 120,
  },

  container: {
    height: "100%",
    padding: 0,
    position: "relative",
  },

  imageContainer: {
    width: "70%",
    backgroundColor: "#FAFAFA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loginWelcomeImage: {
    width: "60%",
  },
});
