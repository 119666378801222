// Copyright 2019 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import memoizee from "memoizee";
import { createSelector } from "reselect";

import type { StateType } from "../reducer";

import type { ConversationType } from "../ducks/conversations";
// import { getOwn } from "../../util/getOwn";
import { normalizeServiceId } from "../../types/ServiceId";

import { getRegionCode, getUserConversationId, getUserNumber } from "./user";

// import * as log from "../../../../public/ring-rtc/logging/log";
const getOwn = window.electronLibs.libs.getOwn;

const log = window.electronLibs.libs.log;

let placeholderContact: ConversationType;
export const getPlaceholderContact = (): ConversationType => {
  if (placeholderContact) {
    return placeholderContact;
  }

  placeholderContact = {
    acceptedMessageRequest: false,
    badges: [],
    id: "placeholder-contact",
    type: "direct",
    title: ``,
    isMe: false,
    sharedGroupNames: [],
  };
  return placeholderContact;
};

export const getConversations = (state: StateType): any => state.conversations;

export const getConversationLookup = createSelector(getConversations, (state: any): any => {
  return state.conversationLookup;
});

export const getConversationsByServiceId = createSelector(getConversations, (state: any): any => {
  return state.conversationsByServiceId;
});

export const getConversationsByE164 = createSelector(getConversations, (state: any): any => {
  return state.conversationsByE164;
});

export const getConversationsByGroupId = createSelector(getConversations, (state: any): any => {
  return state.conversationsByGroupId;
});

//*********************************************************************** */
export const getMe = createSelector(
  [getConversationLookup, getUserConversationId],
  (lookup: any, ourConversationId: string | undefined): ConversationType => {
    if (!ourConversationId) {
      return getPlaceholderContact();
    }

    return lookup[ourConversationId] || getPlaceholderContact();
  },
);

/**
 * getComposableContacts/getCandidateContactsForNewGroup both return contacts for the
 * composer and group members, a different list from your primary system contacts.
 * This list may include false positives, which is better than missing contacts.
 *
 * Note: the key difference between them:
 *   getComposableContacts includes Note to Self
 *   getCandidateContactsForNewGroup does not include Note to Self
 *
 * Because they filter unregistered contacts and that's (partially) determined by the
 * current time, it's possible for them to return stale contacts that have unregistered
 * if no other conversations change. This should be a rare false positive.
 */

// This is where we will put Conversation selector logic, replicating what
// is currently in models/conversation.getProps()
// What needs to happen to pull that selector logic here?
//   1) contactTypingTimers - that UI-only state needs to be moved to redux
//   2) all of the message selectors need to be reselect-based; today those
//      Backbone-based prop-generation functions expect to get Conversation information
//      directly via ConversationController
export function _conversationSelector(
  conversation?: ConversationType,
  // regionCode: string,
  // userNumber: string
): ConversationType {
  if (conversation) {
    return conversation;
  }

  return getPlaceholderContact();
}

// A little optimization to reset our selector cache when high-level application data
//   changes: regionCode and userNumber.
type CachedConversationSelectorType = (conversation?: ConversationType) => ConversationType;
export const getCachedSelectorForConversation = createSelector(
  getRegionCode,
  getUserNumber,
  (): CachedConversationSelectorType => {
    // Note: memoizee will check all parameters provided, and only run our selector
    //   if any of them have changed.
    return memoizee(_conversationSelector, { max: 2000 });
  },
);

export type GetConversationByAnyIdSelectorType = (id?: string) => ConversationType | undefined;
export const getConversationByAnyIdSelector = createSelector(
  getConversationLookup,
  getConversationsByServiceId,
  getConversationsByE164,
  getConversationsByGroupId,
  (byId: any, byServiceId: any, byE164: any, byGroupId: any): GetConversationByAnyIdSelectorType => {
    return (id?: string) => {
      if (!id) {
        return undefined;
      }

      const onGroupId = getOwn(byGroupId, id);
      if (onGroupId) {
        return onGroupId;
      }
      const onServiceId = getOwn(byServiceId, normalizeServiceId(id, "getConversationSelector"));
      if (onServiceId) {
        return onServiceId;
      }
      const onE164 = getOwn(byE164, id);
      if (onE164) {
        return onE164;
      }
      const onId = getOwn(byId, id);
      if (onId) {
        return onId;
      }

      return undefined;
    };
  },
);

export type GetConversationByIdType = (id?: string) => ConversationType;
export const getConversationSelector = createSelector(
  getCachedSelectorForConversation,
  getConversationByAnyIdSelector,
  (selector: CachedConversationSelectorType, getById: GetConversationByAnyIdSelectorType): GetConversationByIdType => {
    return (id?: string) => {
      if (!id) {
        return selector(undefined);
      }

      const byId = getById(id);
      if (byId) {
        return selector(byId);
      }

      log.warn(`getConversationSelector: No conversation found for id ${id}`);
      // This will return a placeholder contact
      return selector(undefined);
    };
  },
);
