import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const VerifiedIcon = ({ className, width, height }) => {
  const classes = useStyles({ width, height });

  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(classes.root, className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.68182 16.7727L10 15.7727L12.3636 16.7727L13.6364 14.5909L16.1364 14L15.9091 11.4545L17.5909 9.54545L15.9091 7.59091L16.1364 5.04545L13.6364 4.5L12.3182 2.31818L10 3.31818L7.63636 2.31818L6.36364 4.5L3.86364 5.04545L4.09091 7.59091L2.40909 9.54545L4.09091 11.4545L3.86364 14.0455L6.36364 14.5909L7.68182 16.7727ZM14.1818 7.63636L9.04545 12.7727L5.81818 9.54545L7.09091 8.27273L9.04545 10.1818L12.9091 6.31818L14.1818 7.63636Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.18182 16.1818L6.90909 19.0909L10 17.7727L13.0909 19.0909L14.8182 16.1818L18.0909 15.4545L17.7727 12.0909L20 9.54545L17.7727 7L18.0909 3.63636L14.8182 2.90909L13.0909 0L10 1.31818L6.90909 0L5.18182 2.90909L1.90909 3.63636L2.22727 7L0 9.54545L2.22727 12.0909L1.90909 15.4545L5.18182 16.1818ZM10 15.7727L7.68182 16.7727L6.36364 14.5909L3.86364 14.0455L4.09091 11.4545L2.40909 9.54545L4.09091 7.59091L3.86364 5.04545L6.36364 4.5L7.63636 2.31818L10 3.31818L12.3182 2.31818L13.6364 4.5L16.1364 5.04545L15.9091 7.59091L17.5909 9.54545L15.9091 11.4545L16.1364 14L13.6364 14.5909L12.3636 16.7727L10 15.7727Z"
        fill="currentColor"
      />
      <path
        d="M9.04545 12.7727L14.1818 7.63636L12.9091 6.31818L9.04545 10.1818L7.09091 8.27273L5.81818 9.54545L9.04545 12.7727Z"
        fill="white"
      />
    </SvgIcon>
  );
};
VerifiedIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
VerifiedIcon.defaultProps = {
  width: 18,
  height: 18,
};
export default memo(VerifiedIcon);

const useStyles = makeStyles(theme => ({
  root: {
    width: props => props.width,
    height: props => props.height,
    color: theme.palette.primary.main,
  },
}));
