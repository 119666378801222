// Copyright 2020 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

import type { ThunkAction } from "redux-thunk";
// import { hasScreenCapturePermission, openSystemPreferences } from "mac-screen-capture-permissions";
import { omit } from "lodash";
import type { ReadonlyDeep } from "type-fest";
// import { getOwn } from "../../util/getOwn";
import * as Errors from "../../types/errors";
import { isConversationTooBigToRing } from "../../conversations/isConversationTooBigToRing";
// import { missingCaseError } from "../../util/missingCaseError";
// import { drop } from "../../util/drop";
// import { DesktopCapturer, type DesktopCapturerBaton } from "../../util/desktopCapturer";
// import { calling } from "../../../../public/ring-rtc/services/calling";
import type { StateType as RootStateType } from "../reducer";
import type {
  ActiveCallReactionsType,
  ChangeIODevicePayloadType,
  MediaDeviceSettings,
  PresentedSource,
  PresentableSource,
} from "../../types/Calling";
import { CallEndedReason, CallingDeviceType, CallViewMode, CallState } from "../../types/Calling";
import { CallMode } from "../../types/CallDisposition";
import { callingTones } from "../../util/callingTones";
// import { requestCameraPermissions } from "../../util/callingPermissions";
import type { ServiceIdString } from "../../types/ServiceId";
import type {
  ConversationChangedActionType,
  ConversationRemovedActionType,
  ConversationAddedActionType,
} from "./conversations";
import { conversationAdded } from "./conversations";
// import * as log from "../../../../public/ring-rtc/logging/log";
// import { strictAssert } from "../../util/assert";
// import { isCallSafe } from "../../util/isCallSafe";
// import { isDirectConversation } from "../../util/whatTypeOfConversation";
import type { BoundActionCreatorsMapObject } from "../../hooks/useBoundActions";
import { useBoundActions } from "../../hooks/useBoundActions";
import { MAX_CALL_PARTICIPANTS_FOR_DEFAULT_MUTE } from "./callingHelpers";
import { SafetyNumberChangeSource } from "../../components/SafetyNumberChangeDialog";
import type { ToggleConfirmLeaveCallModalActionType } from "./globalModals";
import { toggleConfirmLeaveCallModal } from "./globalModals";
// import { getConversationIdForLogging } from "../../util/idForLogging";
// import { saveDraftRecordingIfNeeded } from "./composer";
import type { StartCallData } from "../../components/ConfirmLeaveCallModal";
import { getPrefixKey, StorageUtil, isMacOS } from "utils";
import { getInteractor } from "services/local.service";
import { KeyConstant } from "const";

const log = window.electronLibs.libs.log;
const getOwn = window.electronLibs.libs.getOwn;
const missingCaseError = window.electronLibs.libs.missingCaseError;
const drop = window.electronLibs.libs.drop;
// const callingTones = window.electronLibs.libs.callingTones;
const requestCameraPermissions = window.electronLibs.libs.requestCameraPermissions;
const callingUtil = window.electronLibs.libs.callingUtil;
const redis = window.electronLibs.libs.redis;
type DesktopCapturerBaton = Readonly<{
  __desktop_capturer_baton: never;
}>;
// State

// eslint-disable-next-line local-rules/type-alias-readonlydeep
export type DirectCallStateType = {
  callMode: CallMode.Direct;
  conversationId: string;
  callState?: CallState;
  callEndedReason?: CallEndedReason;
  isIncoming: boolean;
  isSharingScreen?: boolean;
  isVideoCall: boolean;
  hasRemoteVideo?: boolean;
};

// eslint-disable-next-line local-rules/type-alias-readonlydeep
export type ActiveCallStateType = {
  state: "Active";
  callMode: CallMode;
  conversationId: string;
  hasLocalAudio: boolean;
  hasLocalVideo: boolean;
  localAudioLevel: number;
  viewMode: CallViewMode;
  viewModeBeforePresentation?: CallViewMode;
  joinedAt: number | null;
  outgoingRing: boolean;
  pip: boolean;
  presentingSource?: PresentedSource;
  presentingSourcesAvailable?: ReadonlyArray<PresentableSource>;
  capturerBaton?: DesktopCapturerBaton;
  settingsDialogOpen: boolean;
  showNeedsScreenRecordingPermissionsWarning?: boolean;
  showParticipantsList: boolean;
  reactions?: ActiveCallReactionsType;
};
export type WaitingCallStateType = ReadonlyDeep<{
  state: "Waiting";
  conversationId: string;
}>;

// eslint-disable-next-line local-rules/type-alias-readonlydeep
export type CallsByConversationType = {
  [conversationId: string]: DirectCallStateType;
};

// eslint-disable-next-line local-rules/type-alias-readonlydeep
export type CallingStateType = MediaDeviceSettings & {
  callsByConversation: CallsByConversationType;
  activeCallState?: ActiveCallStateType | WaitingCallStateType;
};

export type AcceptCallType = ReadonlyDeep<{
  conversationId: string;
  asVideoCall: boolean;
}>;

export type CallStateChangeType = ReadonlyDeep<{
  conversationId: string;
  acceptedTime: number | null;
  callState: CallState;
  callEndedReason?: CallEndedReason;
}>;

export type CancelCallType = ReadonlyDeep<{
  conversationId: string;
}>;

export type DeclineCallType = ReadonlyDeep<{
  conversationId: string;
}>;

type HangUpActionPayloadType = ReadonlyDeep<{
  conversationId: string;
}>;

export type IncomingDirectCallType = ReadonlyDeep<{
  conversationId: string;
  isVideoCall: boolean;
}>;

type StartDirectCallType = ReadonlyDeep<{
  conversationId: string;
  hasLocalAudio: boolean;
  hasLocalVideo: boolean;
}>;

export type StartCallType = ReadonlyDeep<
  StartDirectCallType & {
    callMode: CallMode.Direct | CallMode.Group | CallMode.Adhoc;
    remoteId: string;
  }
>;

export type RemoteVideoChangeType = ReadonlyDeep<{
  conversationId: string;
  hasVideo: boolean;
}>;

type RemoteSharingScreenChangeType = ReadonlyDeep<{
  conversationId: string;
  isSharingScreen: boolean;
}>;

export type RemoveClientType = ReadonlyDeep<{
  demuxId: number;
}>;

export type SetLocalAudioType = ReadonlyDeep<{
  enabled: boolean;
}>;

export type SetLocalVideoType = ReadonlyDeep<{
  enabled: boolean;
}>;

export type StartCallingLobbyType = ReadonlyDeep<{
  conversationId: string;
  isVideoCall: boolean;
  accountId: string;
  branchId: string;
  deviceId: string;
}>;

// eslint-disable-next-line local-rules/type-alias-readonlydeep
type StartCallingLobbyPayloadType = {
  callMode: CallMode.Direct;
  conversationId: string;
  hasLocalAudio: boolean;
  hasLocalVideo: boolean;
};

// eslint-disable-next-line local-rules/type-alias-readonlydeep
export type SetLocalPreviewType = {
  element: React.RefObject<HTMLVideoElement> | undefined;
};

// eslint-disable-next-line local-rules/type-alias-readonlydeep
export type SetRendererCanvasType = {
  element: React.RefObject<HTMLCanvasElement> | undefined;
};

// Helpers

export const getActiveCall = ({
  activeCallState,
  callsByConversation,
}: CallingStateType): undefined | DirectCallStateType => {
  if (!activeCallState) {
    return;
  }

  const { state, conversationId } = activeCallState;
  if (state === "Waiting") {
    return;
  }

  return getOwn(callsByConversation, conversationId);
};

// We might call this function many times in rapid succession (for example, if lots of
//   people are joining and leaving at once). We want to make sure to update eventually
//   (if people join and leave for an hour, we don't want you to have to wait an hour to
//   get an update), and we also don't want to update too often. That's why we use a
//   "latest queue".

// Actions

const ACCEPT_CALL_PENDING = "calling/ACCEPT_CALL_PENDING";
const CANCEL_CALL = "calling/CANCEL_CALL";
const CHANGE_CALL_VIEW = "calling/CHANGE_CALL_VIEW";
const START_CALLING_LOBBY = "calling/START_CALLING_LOBBY";
const WAITING_FOR_CALLING_LOBBY = "calling/WAITING_FOR_CALLING_LOBBY";
const CALL_LOBBY_FAILED = "calling/CALL_LOBBY_FAILED";
const CALL_STATE_CHANGE_FULFILLED = "calling/CALL_STATE_CHANGE_FULFILLED";
const CHANGE_IO_DEVICE_FULFILLED = "calling/CHANGE_IO_DEVICE_FULFILLED";
const CLOSE_NEED_PERMISSION_SCREEN = "calling/CLOSE_NEED_PERMISSION_SCREEN";
const DECLINE_DIRECT_CALL = "calling/DECLINE_DIRECT_CALL";
const HANG_UP = "calling/HANG_UP";
const INCOMING_DIRECT_CALL = "calling/INCOMING_DIRECT_CALL";
const OUTGOING_CALL = "calling/OUTGOING_CALL";
const REFRESH_IO_DEVICES = "calling/REFRESH_IO_DEVICES";
const REMOTE_SHARING_SCREEN_CHANGE = "calling/REMOTE_SHARING_SCREEN_CHANGE";
const REMOTE_VIDEO_CHANGE = "calling/REMOTE_VIDEO_CHANGE";
const RETURN_TO_ACTIVE_CALL = "calling/RETURN_TO_ACTIVE_CALL";
const SELECT_PRESENTING_SOURCE = "calling/SELECT_PRESENTING_SOURCE";
const SET_LOCAL_AUDIO_FULFILLED = "calling/SET_LOCAL_AUDIO_FULFILLED";
const SET_LOCAL_VIDEO_FULFILLED = "calling/SET_LOCAL_VIDEO_FULFILLED";
const SET_OUTGOING_RING = "calling/SET_OUTGOING_RING";
const SET_PRESENTING = "calling/SET_PRESENTING";
const SET_PRESENTING_SOURCES = "calling/SET_PRESENTING_SOURCES";
const TOGGLE_NEEDS_SCREEN_RECORDING_PERMISSIONS = "calling/TOGGLE_NEEDS_SCREEN_RECORDING_PERMISSIONS";
const START_DIRECT_CALL = "calling/START_DIRECT_CALL";
const TOGGLE_PARTICIPANTS = "calling/TOGGLE_PARTICIPANTS";
const TOGGLE_PIP = "calling/TOGGLE_PIP";
const TOGGLE_SETTINGS = "calling/TOGGLE_SETTINGS";
const SWITCH_TO_PRESENTATION_VIEW = "calling/SWITCH_TO_PRESENTATION_VIEW";
const SWITCH_FROM_PRESENTATION_VIEW = "calling/SWITCH_FROM_PRESENTATION_VIEW";

type AcceptCallPendingActionType = ReadonlyDeep<{
  type: "calling/ACCEPT_CALL_PENDING";
  payload: AcceptCallType;
}>;

type ApproveUserActionType = ReadonlyDeep<{
  type: "calling/APPROVE_USER";
}>;

type CancelCallActionType = ReadonlyDeep<{
  type: "calling/CANCEL_CALL";
}>;

type DenyUserActionType = ReadonlyDeep<{
  type: "calling/DENY_USER";
}>;

// eslint-disable-next-line local-rules/type-alias-readonlydeep
type StartCallingLobbyActionType = {
  type: typeof START_CALLING_LOBBY;
  payload: StartCallingLobbyPayloadType;
};

type WaitingForCallingLobbyActionType = ReadonlyDeep<{
  type: typeof WAITING_FOR_CALLING_LOBBY;
  payload: { conversationId: string };
}>;

type CallLobbyFailedActionType = ReadonlyDeep<{
  type: typeof CALL_LOBBY_FAILED;
  payload: { conversationId: string };
}>;

type CallStateChangeFulfilledActionType = ReadonlyDeep<{
  type: "calling/CALL_STATE_CHANGE_FULFILLED";
  payload: CallStateChangeType;
}>;

type ChangeIODeviceFulfilledActionType = ReadonlyDeep<{
  type: "calling/CHANGE_IO_DEVICE_FULFILLED";
  payload: ChangeIODevicePayloadType;
}>;

type CloseNeedPermissionScreenActionType = ReadonlyDeep<{
  type: "calling/CLOSE_NEED_PERMISSION_SCREEN";
  payload: null;
}>;

type DeclineCallActionType = ReadonlyDeep<{
  type: "calling/DECLINE_DIRECT_CALL";
  payload: DeclineCallType;
}>;

type HangUpActionType = ReadonlyDeep<{
  type: "calling/HANG_UP";
  payload: HangUpActionPayloadType;
}>;

type IncomingDirectCallActionType = ReadonlyDeep<{
  type: "calling/INCOMING_DIRECT_CALL";
  payload: IncomingDirectCallType;
}>;

type OutgoingCallActionType = ReadonlyDeep<{
  type: "calling/OUTGOING_CALL";
  payload: StartDirectCallType;
}>;

export type PendingUserActionPayloadType = ReadonlyDeep<{
  serviceId: ServiceIdString | undefined;
}>;

// eslint-disable-next-line local-rules/type-alias-readonlydeep
type RefreshIODevicesActionType = {
  type: "calling/REFRESH_IO_DEVICES";
  payload: MediaDeviceSettings;
};

type RemoteSharingScreenChangeActionType = ReadonlyDeep<{
  type: "calling/REMOTE_SHARING_SCREEN_CHANGE";
  payload: RemoteSharingScreenChangeType;
}>;

type RemoteVideoChangeActionType = ReadonlyDeep<{
  type: "calling/REMOTE_VIDEO_CHANGE";
  payload: RemoteVideoChangeType;
}>;

type RemoveClientActionType = ReadonlyDeep<{
  type: "calling/REMOVE_CLIENT";
}>;

type ReturnToActiveCallActionType = ReadonlyDeep<{
  type: "calling/RETURN_TO_ACTIVE_CALL";
}>;

type SelectPresentingSourceActionType = ReadonlyDeep<{
  type: "calling/SELECT_PRESENTING_SOURCE";
  payload: string;
}>;

type SetLocalAudioActionType = ReadonlyDeep<{
  type: "calling/SET_LOCAL_AUDIO_FULFILLED";
  payload: SetLocalAudioType;
}>;

type SetLocalVideoFulfilledActionType = ReadonlyDeep<{
  type: "calling/SET_LOCAL_VIDEO_FULFILLED";
  payload: SetLocalVideoType;
}>;

type SetPresentingFulfilledActionType = ReadonlyDeep<{
  type: "calling/SET_PRESENTING";
  payload?: PresentedSource;
}>;

type SetPresentingSourcesActionType = ReadonlyDeep<{
  type: "calling/SET_PRESENTING_SOURCES";
  payload: {
    presentableSources: ReadonlyArray<PresentableSource>;
    capturerBaton: DesktopCapturerBaton;
  };
}>;

type SetOutgoingRingActionType = ReadonlyDeep<{
  type: "calling/SET_OUTGOING_RING";
  payload: boolean;
}>;

type StartDirectCallActionType = ReadonlyDeep<{
  type: "calling/START_DIRECT_CALL";
  payload: StartDirectCallType;
}>;

type ToggleNeedsScreenRecordingPermissionsActionType = ReadonlyDeep<{
  type: "calling/TOGGLE_NEEDS_SCREEN_RECORDING_PERMISSIONS";
}>;

type ToggleParticipantsActionType = ReadonlyDeep<{
  type: "calling/TOGGLE_PARTICIPANTS";
}>;

type TogglePipActionType = ReadonlyDeep<{
  type: "calling/TOGGLE_PIP";
}>;

type ToggleSettingsActionType = ReadonlyDeep<{
  type: "calling/TOGGLE_SETTINGS";
}>;

type ChangeCallViewActionType = ReadonlyDeep<{
  type: "calling/CHANGE_CALL_VIEW";
  viewMode: CallViewMode;
}>;

type SwitchToPresentationViewActionType = ReadonlyDeep<{
  type: "calling/SWITCH_TO_PRESENTATION_VIEW";
}>;

type SwitchFromPresentationViewActionType = ReadonlyDeep<{
  type: "calling/SWITCH_FROM_PRESENTATION_VIEW";
}>;

// eslint-disable-next-line local-rules/type-alias-readonlydeep
export type CallingActionType =
  | ApproveUserActionType
  | AcceptCallPendingActionType
  | CallLobbyFailedActionType
  | CancelCallActionType
  | ChangeCallViewActionType
  | DenyUserActionType
  | StartCallingLobbyActionType
  | CallStateChangeFulfilledActionType
  | ChangeIODeviceFulfilledActionType
  | CloseNeedPermissionScreenActionType
  | ConversationAddedActionType
  | ConversationChangedActionType
  | ConversationRemovedActionType
  | DeclineCallActionType
  | HangUpActionType
  | IncomingDirectCallActionType
  | OutgoingCallActionType
  | RefreshIODevicesActionType
  | RemoteSharingScreenChangeActionType
  | RemoteVideoChangeActionType
  | RemoveClientActionType
  | ReturnToActiveCallActionType
  | SelectPresentingSourceActionType
  | SetLocalAudioActionType
  | SetLocalVideoFulfilledActionType
  | SetPresentingSourcesActionType
  | SetOutgoingRingActionType
  | StartDirectCallActionType
  | ToggleNeedsScreenRecordingPermissionsActionType
  | ToggleParticipantsActionType
  | TogglePipActionType
  | SetPresentingFulfilledActionType
  | ToggleSettingsActionType
  | SwitchToPresentationViewActionType
  | SwitchFromPresentationViewActionType
  | WaitingForCallingLobbyActionType;

// Action Creators

function acceptCall(payload: AcceptCallType): ThunkAction<void, RootStateType, unknown, AcceptCallPendingActionType> {
  return async (dispatch, getState) => {
    const { conversationId, asVideoCall } = payload;

    const callingState = getState().calling;
    const call = getOwn(callingState.callsByConversation, conversationId);
    if (!call) {
      log.error("Trying to accept a non-existent call");
      return;
    }

    // saveDraftRecordingIfNeeded()(dispatch, getState, undefined);

    switch (call.callMode) {
      case CallMode.Direct:
        await callingUtil.acceptDirectCall(conversationId, asVideoCall);
        break;
      default:
        throw missingCaseError(call);
    }

    dispatch({
      type: ACCEPT_CALL_PENDING,
      payload,
    });
  };
}

function callStateChange(
  payload: CallStateChangeType,
): ThunkAction<void, RootStateType, unknown, CallStateChangeFulfilledActionType> {
  return async dispatch => {
    const { callState, acceptedTime, callEndedReason } = payload;

    const wasAccepted = acceptedTime != null;
    const isEnded = callState === CallState.Ended && callEndedReason != null;
    const isTimeoutHangup = callEndedReason === CallEndedReason.Timeout;
    const isConnectionFailure = callEndedReason === CallEndedReason.ConnectionFailure;
    const isLocalHangup = callEndedReason === CallEndedReason.LocalHangup;
    const isRemoteHangup = callEndedReason === CallEndedReason.RemoteHangup;
    const isSignalingFailure = callEndedReason === CallEndedReason.SignalingFailure;
    if (isEnded && (isTimeoutHangup || isConnectionFailure)) {
      callingUtil.handleHangupDirectCall(isTimeoutHangup);
    }
    // Play the hangup noise if:
    if (
      isEnded
      // // 1. I hungup (or declined)
      // (isEnded && isLocalHangup) ||
      // // 2. I answered and then the call ended
      // (isEnded && wasAccepted) ||
      // // 3. I called and they declined
      // (isEnded && !wasAccepted && isRemoteHangup) ||
      // (isEnded && isSignalingFailure)
    ) {
      console.log("END_CALL", callEndedReason);
      callingUtil.reset();
      redis.unsubscribe();
      await callingTones.playEndCall();
    }

    dispatch({
      type: CALL_STATE_CHANGE_FULFILLED,
      payload,
    });

    function delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    if (isEnded && isRemoteHangup) {
      await delay(1500);
      dispatch({
        type: CALL_STATE_CHANGE_FULFILLED,
        payload: { ...payload, callEndedReason: undefined },
      });
    }
  };
}

function changeIODevice(
  payload: ChangeIODevicePayloadType,
): ThunkAction<void, RootStateType, unknown, ChangeIODeviceFulfilledActionType> {
  return async dispatch => {
    // Only `setPreferredCamera` returns a Promise.
    if (payload.type === CallingDeviceType.CAMERA) {
      await callingUtil.setPreferredCamera(payload.selectedDevice);
    } else if (payload.type === CallingDeviceType.MICROPHONE) {
      callingUtil.setPreferredMicrophone(payload.selectedDevice);
    } else if (payload.type === CallingDeviceType.SPEAKER) {
      callingUtil.setPreferredSpeaker(payload.selectedDevice);
    }
    dispatch({
      type: CHANGE_IO_DEVICE_FULFILLED,
      payload,
    });
  };
}

function closeNeedPermissionScreen(): CloseNeedPermissionScreenActionType {
  return {
    type: CLOSE_NEED_PERMISSION_SCREEN,
    payload: null,
  };
}

function cancelCall(payload: CancelCallType): CancelCallActionType {
  callingUtil.stopCallingLobby(payload.conversationId);

  return {
    type: CANCEL_CALL,
  };
}

function declineCall(payload: DeclineCallType): ThunkAction<void, RootStateType, unknown, DeclineCallActionType> {
  return (dispatch, getState) => {
    const { conversationId } = payload;

    const call = getOwn(getState().calling.callsByConversation, conversationId);
    if (!call) {
      log.error("Trying to decline a non-existent call");
      return;
    }

    switch (call.callMode) {
      case CallMode.Direct:
        callingUtil.declineDirectCall(conversationId);
        dispatch({
          type: DECLINE_DIRECT_CALL,
          payload,
        });
        break;
      default:
        throw missingCaseError(call);
    }
  };
}
// const globalCapturers = new WeakMap<DesktopCapturerBaton, DesktopCapturer>();

function getPresentingSources(): ThunkAction<
  void,
  RootStateType,
  unknown,
  SetPresentingSourcesActionType | ToggleNeedsScreenRecordingPermissionsActionType
> {
  return async (dispatch, getState) => {
    // const i18n = getIntl(getState());

    // We check if the user has permissions first before calling desktopCapturer
    // Next we call getPresentingSources so that one gets the prompt for permissions,
    // if necessary.
    // Finally, we have the if statement which shows the modal, if needed.
    // It is in this exact order so that during first-time-use one will be
    // prompted for permissions and if they so happen to deny we can still
    // capture that state correctly.
    const needsPermission = isMacOS() && !callingUtil.hasScreenCapturePermissionMacOs();
    // const needsPermission = false;
    const capturer = callingUtil.createDesktopCapturer({
      // i18n,
      onPresentableSources(presentableSources) {
        if (needsPermission) {
          // Abort
          capturer.selectSource(undefined);
          return;
        }

        dispatch({
          type: SET_PRESENTING_SOURCES,
          payload: {
            presentableSources,
            capturerBaton: capturer.baton,
          },
        });
      },
      onMediaStream(mediaStream) {
        let presentingSource: PresentedSource | undefined;
        const { activeCallState } = getState().calling;
        if (activeCallState?.state === "Active") {
          ({ presentingSource } = activeCallState);
        }

        dispatch(
          _setPresenting(
            presentingSource || {
              id: "media-stream",
              name: "",
            },
            mediaStream,
          ),
        );
      },
      onError(error) {
        log.error("getPresentingSources: got error", Errors.toLogFormat(error));
      },
    });
    // globalCapturers.set(capturer.baton, capturer);

    if (needsPermission) {
      dispatch({
        type: TOGGLE_NEEDS_SCREEN_RECORDING_PERMISSIONS,
      });
    }
  };
}

function hangUpActiveCall(reason: string): ThunkAction<void, RootStateType, unknown, HangUpActionType> {
  return async (dispatch, getState) => {
    const state = getState();

    const activeCall = getActiveCall(state.calling);
    if (!activeCall) {
      return;
    }

    const { conversationId } = activeCall;
    const isRinging = activeCall.callState === CallState.Prering || activeCall.callState === CallState.Ringing;

    callingUtil.hangup(conversationId, reason, isRinging);

    dispatch({
      type: HANG_UP,
      payload: {
        conversationId,
      },
    });
  };
}

function receiveIncomingDirectCall(
  payload: IncomingDirectCallType,
): ThunkAction<void, RootStateType, unknown, IncomingDirectCallActionType> {
  return (dispatch, getState) => {
    const callState = getState().calling;

    if (callState.activeCallState && callState.activeCallState.conversationId === payload.conversationId) {
      callingUtil.stopCallingLobby();
    }
    dispatch({
      type: INCOMING_DIRECT_CALL,
      payload,
    });
  };
}

function openSystemPreferencesAction(): ThunkAction<void, RootStateType, unknown, never> {
  return () => {
    // void openSystemPreferences();
  };
}

function outgoingCall(payload: StartDirectCallType): OutgoingCallActionType {
  return {
    type: OUTGOING_CALL,
    payload,
  };
}

function refreshIODevices(payload: MediaDeviceSettings): RefreshIODevicesActionType {
  return {
    type: REFRESH_IO_DEVICES,
    payload,
  };
}

function remoteSharingScreenChange(payload: RemoteSharingScreenChangeType): RemoteSharingScreenChangeActionType {
  return {
    type: REMOTE_SHARING_SCREEN_CHANGE,
    payload,
  };
}

function remoteVideoChange(payload: RemoteVideoChangeType): RemoteVideoChangeActionType {
  return {
    type: REMOTE_VIDEO_CHANGE,
    payload,
  };
}

function returnToActiveCall(): ReturnToActiveCallActionType {
  return {
    type: RETURN_TO_ACTIVE_CALL,
  };
}

function selectPresentingSource(id: string): SelectPresentingSourceActionType {
  return {
    type: SELECT_PRESENTING_SOURCE,
    payload: id,
  };
}

function setIsCallActive(isCallActive: boolean): ThunkAction<void, RootStateType, unknown, never> {
  return () => {
    // window.SignalContext.setIsCallActive(isCallActive);
  };
}

function setLocalPreview(payload: SetLocalPreviewType): ThunkAction<void, RootStateType, unknown, never> {
  return () => {
    callingUtil.videoCapturerSetLocalPreview(payload.element);
  };
}

function setRendererCanvas(payload: SetRendererCanvasType): ThunkAction<void, RootStateType, unknown, never> {
  return () => {
    callingUtil.videoRendererSetCanvas(payload.element);
  };
}

function setLocalAudio(payload: SetLocalAudioType): ThunkAction<void, RootStateType, unknown, SetLocalAudioActionType> {
  return (dispatch, getState) => {
    const activeCall = getActiveCall(getState().calling);
    if (!activeCall) {
      log.warn("Trying to set local audio when no call is active");
      return;
    }

    callingUtil.setOutgoingAudio(activeCall.conversationId, payload.enabled);

    dispatch({
      type: SET_LOCAL_AUDIO_FULFILLED,
      payload,
    });
  };
}

function setLocalVideo(
  payload: SetLocalVideoType,
): ThunkAction<void, RootStateType, unknown, SetLocalVideoFulfilledActionType> {
  return async (dispatch, getState) => {
    const activeCall = getActiveCall(getState().calling);
    if (!activeCall) {
      log.warn("Trying to set local video when no call is active");
      return;
    }

    let enabled: boolean;
    if (await requestCameraPermissions()) {
      if (activeCall.callMode === CallMode.Direct && activeCall.callState) {
        callingUtil.setOutgoingVideo(activeCall.conversationId, payload.enabled);
      } else if (payload.enabled) {
        callingUtil.enableLocalCamera();
      } else {
        callingUtil.disableLocalVideo();
      }
      ({ enabled } = payload);
    } else {
      enabled = false;
    }

    dispatch({
      type: SET_LOCAL_VIDEO_FULFILLED,
      payload: {
        ...payload,
        enabled,
      },
    });
  };
}
function _setPresenting(
  sourceToPresent?: PresentedSource,
  mediaStream?: MediaStream,
): ThunkAction<void, RootStateType, unknown, SetPresentingFulfilledActionType> {
  return async (dispatch, getState) => {
    const state = getState();
    const callingState = state.calling;

    const { activeCallState } = callingState;
    const activeCall = getActiveCall(callingState);
    if (!activeCall || !activeCallState || activeCallState.state === "Waiting") {
      log.warn("Trying to present when no call is active");
      return;
    }

    let rootKey: string | undefined;
    await callingUtil.setPresenting({
      conversationId: activeCall.conversationId,
      hasLocalVideo: activeCallState.hasLocalVideo,
      mediaStream,
      source: sourceToPresent,
      callLinkRootKey: rootKey,
    });

    dispatch({
      type: SET_PRESENTING,
      payload: sourceToPresent,
    });

    if (mediaStream) {
      await callingTones.someonePresenting();
    }
  };
}

function cancelPresenting(): ThunkAction<void, RootStateType, unknown, SetPresentingFulfilledActionType> {
  return _setPresenting(undefined, undefined);
}

function setOutgoingRing(payload: boolean): SetOutgoingRingActionType {
  return {
    type: SET_OUTGOING_RING,
    payload,
  };
}

function onOutgoingAudioCallInConversation(
  conversationId: string,
  accountId: string,
  branchId: string,
  deviceId: string,
): ThunkAction<void, RootStateType, unknown, StartCallingLobbyActionType> {
  return async (dispatch, getState) => {
    const prefixKey = getPrefixKey(accountId, branchId);
    // const conversation = window.ConversationController.get(conversationId);
    const conversation = await getInteractor(prefixKey).LocalGroupService.get(conversationId);
    if (!conversation) {
      throw new Error(`onOutgoingAudioCallInConversation: No conversation found for conversation ${conversationId}`);
    }

    const source = SafetyNumberChangeSource.InitiateCall;

    log.info("onOutgoingAudioCallInConversation: about to start an audio call");
    if (true) {
      log.info('onOutgoingAudioCallInConversation: call is deemed "safe". Starting lobby');
      startCallingLobby({
        conversationId,
        isVideoCall: false,
        accountId,
        branchId,
        deviceId,
      })(dispatch, getState, undefined);
    } else {
      log.info('onOutgoingAudioCallInConversation: call is deemed "unsafe". Stopping');
    }
  };
}
function onOutgoingVideoCallInConversation(
  conversationId: string,
  accountId: string,
  branchId: string,
  deviceId: string,
): ThunkAction<void, RootStateType, unknown, StartCallingLobbyActionType> {
  return async (dispatch, getState) => {
    const prefixKey = StorageUtil.getCurrentPrefixKey();
    // const conversation = window.ConversationController.get(conversationId);
    const conversation = await getInteractor(prefixKey).LocalGroupService.get(conversationId);
    if (!conversation) {
      throw new Error(`onOutgoingVideoCallInConversation: No conversation found for conversation ${conversationId}`);
    }

    const source = SafetyNumberChangeSource.InitiateCall;

    log.info("onOutgoingVideoCallInConversation: about to start an video call");
    if (true) {
      log.info('onOutgoingVideoCallInConversation: call is deemed "safe". Starting lobby');
      startCallingLobby({
        conversationId,
        isVideoCall: true,
        accountId,
        branchId,
        deviceId,
      })(dispatch, getState, undefined);
    } else {
      log.info('onOutgoingVideoCallInConversation: call is deemed "unsafe". Stopping');
    }
  };
}

function leaveCurrentCallAndStartCallingLobby(
  data: StartCallData,
  accountId: string,
  branchId: string,
  deviceId: string,
): ThunkAction<void, RootStateType, unknown, HangUpActionType> {
  return async (dispatch, getState) => {
    hangUpActiveCall("Leave call button pressed in ConfirmLeaveCurrentCallModal")(dispatch, getState, undefined);

    const { type } = data;
    if (type === "conversation") {
      const { conversationId, isVideoCall } = data;
      startCallingLobby({
        conversationId,
        isVideoCall,
        accountId,
        branchId,
        deviceId,
      })(dispatch, getState, undefined);
    } else {
      throw missingCaseError(type);
    }
  };
}

function startCallingLobby({
  conversationId,
  isVideoCall,
  accountId,
  branchId,
  deviceId,
}: StartCallingLobbyType): ThunkAction<
  void,
  RootStateType,
  unknown,
  | CallLobbyFailedActionType
  | StartCallingLobbyActionType
  | ToggleConfirmLeaveCallModalActionType
  | TogglePipActionType
  | WaitingForCallingLobbyActionType
> {
  return async (dispatch, getState) => {
    const state = getState();
    // const conversation = getOwn(state.conversations.conversationLookup, conversationId);
    // strictAssert(conversation, "startCallingLobby: can't start lobby without a conversation");
    //TODO change
    callingUtil.setSelfCall(accountId, deviceId, branchId);
    const prefixKey = getPrefixKey(accountId, branchId);
    // const conversation = getOwn(state.conversations.conversationLookup, conversationId);
    const conversation = await getInteractor(prefixKey).LocalGroupService.get(conversationId);
    console.log("conversationconversation", conversation);
    let placeholderContact = {
      ...conversation,
      acceptedMessageRequest: false,
      badges: [],
      id: conversationId,
      type: "direct",
      title: `${conversation.groupName}`,
      isMe: false,
      sharedGroupNames: [],
    };
    dispatch(
      conversationAdded({
        id: conversationId,
        data: placeholderContact,
      }),
    );

    // const logId = `startCallingLobby(${getConversationIdForLogging(conversation)})`;
    const { activeCallState } = state.calling;
    if (activeCallState && activeCallState.conversationId === conversationId) {
      if (activeCallState.state === "Active") {
        dispatch(togglePip());
      } else {
        log.warn(`${conversationId}: Attempted to start lobby while already waiting for it!`);
      }
      return;
    }
    if (activeCallState) {
      dispatch(
        toggleConfirmLeaveCallModal({
          type: "conversation",
          conversationId,
          isVideoCall,
          accountId: accountId,
          branchId: branchId,
          deviceId: deviceId,
        }),
      );
      return;
    }

    try {
      dispatch({
        type: WAITING_FOR_CALLING_LOBBY,
        payload: {
          conversationId,
        },
      });

      // The group call device count is considered 0 for a direct call.
      // const groupCall = getGroupCall(conversationId, state.calling, CallMode.Group);
      // const groupCallDeviceCount = groupCall?.peekInfo?.deviceCount || groupCall?.remoteParticipants.length || 0;
      const groupCall = undefined;
      const groupCallDeviceCount = 0;
      const callLobbyData = await callingUtil.startCallingLobby({
        placeholderContact,
        hasLocalAudio: groupCallDeviceCount < MAX_CALL_PARTICIPANTS_FOR_DEFAULT_MUTE,
        hasLocalVideo: isVideoCall,
      });
      if (!callLobbyData) {
        throw new Error("Failed to start call lobby");
      }
      dispatch({
        type: START_CALLING_LOBBY,
        payload: {
          ...callLobbyData,
          conversationId,
          isConversationTooBigToRing: isConversationTooBigToRing(placeholderContact),
          // isConversationTooBigToRing: false,
        },
      });
    } catch (error) {
      // log.error(`${logId}: Failed to start lobby`, Errors.toLogFormat(error));

      try {
        callingUtil.stopCallingLobby(conversationId);
      } catch (innerError) {
        // log.error(`${logId}: Failed to stop calling lobby`, Errors.toLogFormat(innerError));
      }

      dispatch({
        type: CALL_LOBBY_FAILED,
        payload: { conversationId },
      });
    }
  };
}

function startCall(payload: StartCallType): ThunkAction<void, RootStateType, unknown, StartDirectCallActionType> {
  return async (dispatch, getState) => {
    const { callMode, conversationId, hasLocalAudio, hasLocalVideo, remoteId } = payload;
    const logId = `startCall(${conversationId})`;
    const state = getState();
    const { activeCallState } = state.calling;

    log.info(`${logId}: starting, mode ${callMode}`);

    if (activeCallState?.state === "Waiting") {
      log.error(`${logId}: Call is not ready; `);
      return;
    }
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
    switch (callMode) {
      case CallMode.Direct:
        const prefixKey = StorageUtil.getCurrentPrefixKey();
        await callingUtil.startOutgoingDirectCall(
          conversationId,
          hasLocalAudio,
          hasLocalVideo,
          prefixKey,
          remoteId,
          deviceId,
        );
        dispatch({
          type: START_DIRECT_CALL,
          payload,
        });
        break;
      default:
        throw missingCaseError(callMode);
    }
  };
}

function toggleParticipants(): ToggleParticipantsActionType {
  return {
    type: TOGGLE_PARTICIPANTS,
  };
}

function togglePip(): TogglePipActionType {
  return {
    type: TOGGLE_PIP,
  };
}

function toggleScreenRecordingPermissionsDialog(): ToggleNeedsScreenRecordingPermissionsActionType {
  return {
    type: TOGGLE_NEEDS_SCREEN_RECORDING_PERMISSIONS,
  };
}

function toggleSettings(): ToggleSettingsActionType {
  return {
    type: TOGGLE_SETTINGS,
  };
}

function changeCallView(mode: CallViewMode): ChangeCallViewActionType {
  return {
    type: CHANGE_CALL_VIEW,
    viewMode: mode,
  };
}

function switchToPresentationView(): SwitchToPresentationViewActionType {
  return {
    type: SWITCH_TO_PRESENTATION_VIEW,
  };
}

function switchFromPresentationView(): SwitchFromPresentationViewActionType {
  return {
    type: SWITCH_FROM_PRESENTATION_VIEW,
  };
}
export const actions = {
  acceptCall,
  callStateChange,
  cancelCall,
  changeCallView,
  changeIODevice,
  getPresentingSources,
  cancelPresenting,
  closeNeedPermissionScreen,
  declineCall,
  hangUpActiveCall,
  leaveCurrentCallAndStartCallingLobby,
  onOutgoingAudioCallInConversation,
  onOutgoingVideoCallInConversation,
  openSystemPreferencesAction,
  outgoingCall,
  receiveIncomingDirectCall,
  refreshIODevices,
  remoteSharingScreenChange,
  remoteVideoChange,
  returnToActiveCall,
  selectPresentingSource,
  setIsCallActive,
  setLocalAudio,
  setLocalPreview,
  setLocalVideo,
  setOutgoingRing,
  setRendererCanvas,
  startCall,
  startCallingLobby,
  switchToPresentationView,
  switchFromPresentationView,
  toggleParticipants,
  togglePip,
  toggleScreenRecordingPermissionsDialog,
  toggleSettings,
};

export const useCallingActions = (): BoundActionCreatorsMapObject<typeof actions> => useBoundActions(actions);

export type ActionsType = ReadonlyDeep<typeof actions>;

// Reducer

export function getEmptyState(): CallingStateType {
  return {
    availableCameras: [],
    availableMicrophones: [],
    availableSpeakers: [],
    selectedCamera: undefined,
    selectedMicrophone: undefined,
    selectedSpeaker: undefined,

    callsByConversation: {},
    activeCallState: undefined,
  };
}

function removeConversationFromState(state: Readonly<CallingStateType>, conversationId: string): CallingStateType {
  return {
    ...(conversationId === state.activeCallState?.conversationId ? omit(state, "activeCallState") : state),
    callsByConversation: omit(state.callsByConversation, conversationId),
  };
}

export function reducer(
  state: Readonly<CallingStateType> = getEmptyState(),
  action: Readonly<CallingActionType>,
): CallingStateType {
  const { callsByConversation } = state;

  if (action.type === WAITING_FOR_CALLING_LOBBY) {
    const { conversationId } = action.payload;

    return {
      ...state,
      activeCallState: {
        state: "Waiting",
        conversationId,
      },
    };
  }

  if (action.type === CALL_LOBBY_FAILED) {
    const { conversationId } = action.payload;

    const { activeCallState } = state;
    if (!activeCallState || activeCallState.conversationId !== conversationId) {
      log.warn(`${action.type}: Active call does not match target conversation`);
    }

    return removeConversationFromState(state, conversationId);
  }
  if (action.type === START_CALLING_LOBBY) {
    const { callMode, conversationId } = action.payload;

    let call: DirectCallStateType;
    let outgoingRing: boolean;
    switch (callMode) {
      case CallMode.Direct:
        call = {
          callMode: CallMode.Direct,
          conversationId,
          isIncoming: false,
          isVideoCall: action.payload.hasLocalVideo,
        };
        outgoingRing = true;
        break;
      default:
        throw missingCaseError(action.payload);
    }

    const newCallsByConversation = {
      ...callsByConversation,
      [conversationId]: call,
    };

    return {
      ...state,
      callsByConversation: newCallsByConversation,
      activeCallState: {
        state: "Active",
        callMode,
        conversationId,
        hasLocalAudio: action.payload.hasLocalAudio,
        hasLocalVideo: action.payload.hasLocalVideo,
        localAudioLevel: 0,
        viewMode: CallViewMode.Paginated,
        pip: false,
        settingsDialogOpen: false,
        showParticipantsList: false,
        outgoingRing,
        joinedAt: null,
      },
    };
  }

  if (action.type === START_DIRECT_CALL) {
    return {
      ...state,
      callsByConversation: {
        ...callsByConversation,
        [action.payload.conversationId]: {
          callMode: CallMode.Direct,
          conversationId: action.payload.conversationId,
          callState: CallState.Prering,
          isIncoming: false,
          isVideoCall: action.payload.hasLocalVideo,
        },
      },
      activeCallState: {
        state: "Active",
        callMode: CallMode.Direct,
        conversationId: action.payload.conversationId,
        hasLocalAudio: action.payload.hasLocalAudio,
        hasLocalVideo: action.payload.hasLocalVideo,
        localAudioLevel: 0,
        viewMode: CallViewMode.Paginated,
        pip: false,
        settingsDialogOpen: false,
        showParticipantsList: false,
        outgoingRing: true,
        joinedAt: null,
      },
    };
  }

  if (action.type === ACCEPT_CALL_PENDING) {
    const call = getOwn(state.callsByConversation, action.payload.conversationId);
    if (!call) {
      log.warn("Unable to accept a non-existent call");
      return state;
    }

    return {
      ...state,
      activeCallState: {
        state: "Active",
        callMode: call.callMode,
        conversationId: action.payload.conversationId,
        hasLocalAudio: true,
        hasLocalVideo: action.payload.asVideoCall,
        localAudioLevel: 0,
        viewMode: CallViewMode.Paginated,
        pip: false,
        settingsDialogOpen: false,
        showParticipantsList: false,
        outgoingRing: false,
        joinedAt: null,
      },
    };
  }

  if (action.type === CANCEL_CALL || action.type === HANG_UP || action.type === CLOSE_NEED_PERMISSION_SCREEN) {
    const activeCall = getActiveCall(state);
    if (!activeCall) {
      log.warn(`${action.type}: No active call to remove`);
      return state;
    }
    switch (activeCall.callMode) {
      case CallMode.Direct:
        return removeConversationFromState(state, activeCall.conversationId);
      default:
        throw missingCaseError(activeCall);
    }
  }

  if (action.type === "CONVERSATION_CHANGED") {
    const { activeCallState } = state;
    if (
      activeCallState?.state === "Waiting" ||
      !activeCallState?.outgoingRing ||
      activeCallState.conversationId !== action.payload.id ||
      !isConversationTooBigToRing(action.payload.data)
    ) {
      return state;
    }

    return {
      ...state,
      activeCallState: { ...activeCallState, outgoingRing: false },
    };
  }

  if (action.type === "CONVERSATION_REMOVED") {
    return removeConversationFromState(state, action.payload.id);
  }

  if (action.type === DECLINE_DIRECT_CALL) {
    return removeConversationFromState(state, action.payload.conversationId);
  }

  if (action.type === INCOMING_DIRECT_CALL) {
    return {
      ...state,
      callsByConversation: {
        ...callsByConversation,
        [action.payload.conversationId]: {
          callMode: CallMode.Direct,
          conversationId: action.payload.conversationId,
          callState: CallState.Prering,
          isIncoming: true,
          isVideoCall: action.payload.isVideoCall,
        },
      },
    };
  }

  if (action.type === OUTGOING_CALL) {
    return {
      ...state,
      callsByConversation: {
        ...callsByConversation,
        [action.payload.conversationId]: {
          callMode: CallMode.Direct,
          conversationId: action.payload.conversationId,
          callState: CallState.Prering,
          isIncoming: false,
          isVideoCall: action.payload.hasLocalVideo,
        },
      },
      activeCallState: {
        state: "Active",
        callMode: CallMode.Direct,
        conversationId: action.payload.conversationId,
        hasLocalAudio: action.payload.hasLocalAudio,
        hasLocalVideo: action.payload.hasLocalVideo,
        localAudioLevel: 0,
        viewMode: CallViewMode.Paginated,
        pip: false,
        settingsDialogOpen: false,
        showParticipantsList: false,
        outgoingRing: true,
        joinedAt: null,
      },
    };
  }

  if (action.type === CALL_STATE_CHANGE_FULFILLED) {
    const call = getOwn(state.callsByConversation, action.payload.conversationId);

    if (call?.callMode === CallMode.Direct && call?.callState !== action.payload.callState) {
      drop(
        callingUtil.notifyScreenShareStatus({
          callMode: CallMode.Direct,
          callState: action.payload.callState,
          isPresenting: state.activeCallState?.state === "Active" && state.activeCallState?.presentingSource != null,
          conversationId: state.activeCallState?.conversationId,
        }),
      );
    }

    // We want to keep the state around for ended calls if they resulted in a message
    //   request so we can show the "needs permission" screen.
    if (
      action.payload.callState === CallState.Ended &&
      action.payload.callEndedReason !== CallEndedReason.RemoteHangupNeedPermission &&
      action.payload.callEndedReason !== CallEndedReason.RemoteHangup
    ) {
      return removeConversationFromState(state, action.payload.conversationId);
    }

    if (call?.callMode !== CallMode.Direct) {
      log.warn("Cannot update state for a non-direct call");
      return state;
    }

    let activeCallState: undefined | ActiveCallStateType | WaitingCallStateType;
    log.info("activeCallState", activeCallState);
    if (
      state.activeCallState?.conversationId === action.payload.conversationId &&
      state.activeCallState.state === "Active"
    ) {
      activeCallState = {
        ...state.activeCallState,
        joinedAt: action.payload.acceptedTime ?? null,
      };
    } else {
      ({ activeCallState } = state);
    }

    return {
      ...state,
      callsByConversation: {
        ...callsByConversation,
        [action.payload.conversationId]: {
          ...call,
          callState: action.payload.callState,
          callEndedReason: action.payload.callEndedReason,
        },
      },
      activeCallState,
    };
  }

  if (action.type === REMOTE_SHARING_SCREEN_CHANGE) {
    const { conversationId, isSharingScreen } = action.payload;
    const call = getOwn(state.callsByConversation, conversationId);
    if (call?.callMode !== CallMode.Direct) {
      log.warn("Cannot update remote video for a non-direct call");
      return state;
    }

    return {
      ...state,
      callsByConversation: {
        ...callsByConversation,
        [conversationId]: {
          ...call,
          isSharingScreen,
        },
      },
    };
  }

  if (action.type === REMOTE_VIDEO_CHANGE) {
    const { conversationId, hasVideo } = action.payload;
    const call = getOwn(state.callsByConversation, conversationId);
    if (call?.callMode !== CallMode.Direct) {
      log.warn("Cannot update remote video for a non-direct call");
      return state;
    }

    return {
      ...state,
      callsByConversation: {
        ...callsByConversation,
        [conversationId]: {
          ...call,
          hasRemoteVideo: hasVideo,
        },
      },
    };
  }

  if (action.type === RETURN_TO_ACTIVE_CALL) {
    const { activeCallState } = state;
    if (activeCallState?.state !== "Active") {
      log.warn("Cannot return to active call if there is no active call");
      return state;
    }

    return {
      ...state,
      activeCallState: {
        ...activeCallState,
        pip: false,
      },
    };
  }

  if (action.type === SET_LOCAL_AUDIO_FULFILLED) {
    if (state.activeCallState?.state !== "Active") {
      log.warn("Cannot set local audio with no active call");
      return state;
    }

    return {
      ...state,
      activeCallState: {
        ...state.activeCallState,
        hasLocalAudio: action.payload.enabled,
      },
    };
  }

  if (action.type === SET_LOCAL_VIDEO_FULFILLED) {
    if (state.activeCallState?.state !== "Active") {
      log.warn("Cannot set local video with no active call");
      return state;
    }
    console.log("SET_LOCAL_VIDEO_FULFILLED");
    return {
      ...state,
      activeCallState: {
        ...state.activeCallState,
        hasLocalVideo: action.payload.enabled,
      },
    };
  }

  if (action.type === CHANGE_IO_DEVICE_FULFILLED) {
    const { selectedDevice } = action.payload;
    const nextState = Object.create(null);

    if (action.payload.type === CallingDeviceType.CAMERA) {
      nextState.selectedCamera = selectedDevice;
    } else if (action.payload.type === CallingDeviceType.MICROPHONE) {
      nextState.selectedMicrophone = selectedDevice;
    } else if (action.payload.type === CallingDeviceType.SPEAKER) {
      nextState.selectedSpeaker = selectedDevice;
    }

    return {
      ...state,
      ...nextState,
    };
  }

  if (action.type === REFRESH_IO_DEVICES) {
    const {
      availableMicrophones,
      selectedMicrophone,
      availableSpeakers,
      selectedSpeaker,
      availableCameras,
      selectedCamera,
    } = action.payload;

    return {
      ...state,
      availableMicrophones,
      selectedMicrophone,
      availableSpeakers,
      selectedSpeaker,
      availableCameras,
      selectedCamera,
    };
  }

  if (action.type === TOGGLE_SETTINGS) {
    const { activeCallState } = state;
    if (activeCallState?.state !== "Active") {
      log.warn("Cannot toggle settings when there is no active call");
      return state;
    }

    return {
      ...state,
      activeCallState: {
        ...activeCallState,
        settingsDialogOpen: !activeCallState.settingsDialogOpen,
      },
    };
  }

  if (action.type === TOGGLE_PARTICIPANTS) {
    const { activeCallState } = state;
    if (activeCallState?.state !== "Active") {
      log.warn("Cannot toggle participants list when there is no active call");
      return state;
    }

    return {
      ...state,
      activeCallState: {
        ...activeCallState,
        showParticipantsList: !activeCallState.showParticipantsList,
      },
    };
  }

  if (action.type === TOGGLE_PIP) {
    const { activeCallState } = state;
    if (activeCallState?.state !== "Active") {
      log.warn("Cannot toggle PiP when there is no active call");
      return state;
    }

    return {
      ...state,
      activeCallState: {
        ...activeCallState,
        pip: !activeCallState.pip,
      },
    };
  }

  if (action.type === SET_PRESENTING) {
    const { activeCallState } = state;
    if (activeCallState?.state !== "Active") {
      log.warn("Cannot toggle presenting when there is no active call");
      return state;
    }

    // Cancel source selection if running
    const { capturerBaton } = activeCallState;
    if (capturerBaton != null) {
      callingUtil.selectSource(capturerBaton, undefined);
      // const capturer = globalCapturers.get(capturerBaton);
      // capturer?.selectSource(undefined);
    }

    return {
      ...state,
      activeCallState: {
        ...activeCallState,
        presentingSource: action.payload,
        presentingSourcesAvailable: undefined,
        capturerBaton: undefined,
      },
    };
  }

  if (action.type === SET_PRESENTING_SOURCES) {
    const { activeCallState } = state;
    if (activeCallState?.state !== "Active") {
      log.warn("Cannot set presenting sources when there is no active call");
      return state;
    }

    return {
      ...state,
      activeCallState: {
        ...activeCallState,
        presentingSourcesAvailable: action.payload.presentableSources,
        capturerBaton: action.payload.capturerBaton,
      },
    };
  }

  if (action.type === SELECT_PRESENTING_SOURCE) {
    const { activeCallState } = state;
    if (activeCallState?.state !== "Active") {
      log.warn("Cannot set presenting sources when there is no active call");
      return state;
    }

    const { capturerBaton, presentingSourcesAvailable } = activeCallState;
    if (!capturerBaton || !presentingSourcesAvailable) {
      log.warn("Cannot set presenting sources when there is no presenting modal");
      return state;
    }

    const capturer = callingUtil.selectSource(capturerBaton, action.payload);

    // const capturer = globalCapturers.get(capturerBaton);
    // capturer.selectSource(action.payload);
    if (!capturer) {
      log.warn("Cannot toggle presenting when there is no capturer");
      return state;
    }

    return {
      ...state,
      activeCallState: {
        ...activeCallState,
        presentingSource: presentingSourcesAvailable.find(source => source.id === action.payload),
        presentingSourcesAvailable: undefined,
        capturerBaton: undefined,
      },
    };
  }

  if (action.type === SET_OUTGOING_RING) {
    const { activeCallState } = state;
    if (activeCallState?.state !== "Active") {
      log.warn("Cannot set outgoing ring when there is no active call");
      return state;
    }

    return {
      ...state,
      activeCallState: {
        ...activeCallState,
        outgoingRing: action.payload,
      },
    };
  }

  if (action.type === TOGGLE_NEEDS_SCREEN_RECORDING_PERMISSIONS) {
    const { activeCallState } = state;
    if (activeCallState?.state !== "Active") {
      log.warn("Cannot set presenting sources when there is no active call");
      return state;
    }

    return {
      ...state,
      activeCallState: {
        ...activeCallState,
        showNeedsScreenRecordingPermissionsWarning: !activeCallState.showNeedsScreenRecordingPermissionsWarning,
      },
    };
  }

  if (action.type === CHANGE_CALL_VIEW) {
    const { activeCallState } = state;
    if (activeCallState?.state !== "Active") {
      log.warn("Cannot change call view when there is no active call");
      return state;
    }

    if (activeCallState.viewMode === action.viewMode) {
      return state;
    }

    return {
      ...state,
      activeCallState: {
        ...activeCallState,
        viewMode: action.viewMode,
        viewModeBeforePresentation:
          action.viewMode === CallViewMode.Presentation ? activeCallState.viewMode : undefined,
      },
    };
  }

  if (action.type === SWITCH_TO_PRESENTATION_VIEW) {
    const { activeCallState } = state;
    if (activeCallState?.state !== "Active") {
      log.warn("Cannot switch to speaker view when there is no active call");
      return state;
    }

    if (activeCallState.viewMode === CallViewMode.Presentation) {
      return state;
    }

    return {
      ...state,
      activeCallState: {
        ...activeCallState,
        viewMode: CallViewMode.Presentation,
        viewModeBeforePresentation: activeCallState.viewMode,
      },
    };
  }

  if (action.type === SWITCH_FROM_PRESENTATION_VIEW) {
    const { activeCallState } = state;
    if (activeCallState?.state !== "Active") {
      log.warn("Cannot switch to speaker view when there is no active call");
      return state;
    }

    if (activeCallState.viewMode !== CallViewMode.Presentation) {
      return state;
    }

    return {
      ...state,
      activeCallState: {
        ...activeCallState,
        viewMode: activeCallState.viewModeBeforePresentation ?? CallViewMode.Paginated,
      },
    };
  }

  return state;
}
