import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { convertString2JSON, StorageUtil, toCamel } from "utils";
import { AppDrawer, PrimaryTab } from "components";
import Media from "./Media";
import Link from "./Link";
import File from "./File";
import { getInteractor } from "services/local.service";
import { Box } from "@mui/material";
import { groupDataByDate } from "utils/date.utils";
import { getCommonLang } from "utils/lang.utils";

const DEFAULT_LIMIT = 30;
const DEFAULT_LIMIT_SMALL = 10;

const Gallery = ({ groupId, onClose, open }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const [selectedTab, setSelectedTab] = useState(GALLERY_TYPE.media);
  const [dataLink, setDataLink] = useState([]);
  const [dataFile, setDataFile] = useState({});
  const [dataImage, setDataImage] = useState({
    displayData: {}, //[{ date: [ mediaDetails ] }]
    isEnd: false,
    offset: 0,
  });
  const [galleryTabArray] = useState([
    {
      label: getLabel(LangConstant.TXT_MEDIA),
    },
    {
      label: getLabel(LangConstant.TXT_LINK),
    },
    {
      label: getCommonLang("TXT_FILE"),
    },
  ]);

  const onChangeTab = newValue => {
    setSelectedTab(newValue);
  };

  const handleGetDataImage = async () => {
    const limit = dataImage.offset === 0 ? DEFAULT_LIMIT : DEFAULT_LIMIT_SMALL;
    const mediaList = await getInteractor(prefixKey).LocalFileGroupService.searchFileByCondition({
      group_id: groupId,
      fileType: [SystemConstant.FILE_TYPE.IMAGE, SystemConstant.FILE_TYPE.VIDEO],
      offset: dataImage.offset,
      limit: limit,
    });

    const mediaData = mediaList.map(item => {
      const fileData = convertString2JSON(item.fileData, {});
      return toCamel({
        ...fileData,
        created: item.created,
        groupId: item.groupId,
        mediaId: `${item.sourceMessageId}_${item.fileId}`,
      });
    });
    let newDataDisplay = [];
    Object.keys(dataImage.displayData).forEach(key => {
      newDataDisplay = newDataDisplay.concat(dataImage.displayData[key]);
    });
    newDataDisplay = newDataDisplay.concat(mediaData);

    setDataImage(state => ({
      displayData: groupDataByDate(mediaData, state.displayData, true),
      isEnd: mediaList.length < limit,
      offset: state.offset + mediaList.length,
    }));
  };

  const handleGetDataFile = async () => {
    const fileList = await getInteractor(prefixKey).LocalFileGroupService.searchFileByCondition({
      group_id: groupId,
      fileType: [
        SystemConstant.FILE_TYPE.AUDIO,
        SystemConstant.FILE_TYPE.WORD,
        SystemConstant.FILE_TYPE.EXCEL,
        SystemConstant.FILE_TYPE.PPT,
        SystemConstant.FILE_TYPE.PDF,
        SystemConstant.FILE_TYPE.OTHER,
      ],
    });

    const fileDataList = fileList.map(item => {
      const fileData = convertString2JSON(item.fileData, {});
      return toCamel({ ...fileData, created: item.created, groupId: item.groupId });
    });

    setDataFile(groupDataByDate(fileDataList, {}, true));
  };

  const handleGetDataLink = async () => {
    const messageLink = await getInteractor(prefixKey).LocalMessageService.getMediaMessages(
      {
        group_id: groupId,
        branch_id: branchId,
        send_type: SystemConstant.SEND_TYPE.link,
      },
      `message.options LIKE '%is_link%'`,
    );

    setDataLink(messageLink);
  };

  useEffect(() => {
    if (!open) {
      setDataImage({
        displayData: {}, //[{ date: [ mediaDetails ] }]
        isEnd: false,
        offset: 0,
      });
    } else if (open && groupId) {
      handleGetDataImage();
      handleGetDataFile();
      handleGetDataLink();
    }
  }, [groupId, open]);

  return (
    <AppDrawer
      open={open}
      onClose={onClose}
      anchor="right"
      title={getLabel(LangConstant.TXT_GALLERY)}
      hideBackdrop={false}
      classes={{ root: classes.drawerRoot }}
    >
      <PrimaryTab
        tabArray={galleryTabArray}
        classes={{
          flexContainer: classes.flexContainer,
          tabButton: classes.tabButton,
          tabLabel: classes.tabLabel,
          tabLabelSelected: classes.tabLabelSelected,
          tabButtonSelected: classes.tabButtonSelected,
          indicator: "hidden",
        }}
        onChange={onChangeTab}
        selectedTabIndex={selectedTab}
      />

      <Box className={selectedTab !== GALLERY_TYPE.media && "hidden"}>
        <Media mediaList={dataImage} onLoadMore={handleGetDataImage} />
      </Box>

      <Box className={selectedTab !== GALLERY_TYPE.link && "hidden"}>
        <Link data={dataLink} />
      </Box>

      <Box className={selectedTab !== GALLERY_TYPE.file && "hidden"}>
        <File data={dataFile} groupId={groupId} />
      </Box>
    </AppDrawer>
  );
};

Gallery.propTypes = { onClose: PropTypes.func };

Gallery.defaultProps = { onClose: () => {} };

export default Gallery;

const GALLERY_TYPE = { media: 0, link: 1, file: 2, dataImage: 3, dataAudio: 5, dataVideo: 6, dataLinks: 9 };

const useStyles = makeStyles(theme => ({
  flexContainer: {
    padding: "12px 14px",
  },

  tabButton: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    height: 30,
    minHeight: "unset",
    borderRadius: 15.5,
  },

  tabLabel: {
    marginTop: 0,
    color: theme.palette.grey[500],
  },

  tabLabelSelected: {
    color: theme.palette.black,
  },

  tabButtonSelected: {
    backgroundColor: theme.palette.grey[100],
  },

  drawerRoot: {
    "&>.MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },
}));
