import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, List, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppConstant, LangConstant, SystemConstant } from "const";
import FlowMessageActive from "components/icons/FlowMessageActive";
import ThreadListDrawer from "./ThreadListDrawer";
import { FlowMessage } from "components/icons";
import { useUnreadThread } from "hooks";
import { BranchSelectors } from "redux-store";
import ChatAllTab from "./ChatAllTab";
import SearchConversation from "./SearchConversation";
import { getNSLang } from "utils/lang.utils";

const ChatTab = () => {
  const classes = useStyles();
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const isBranchServer = selectedBranch.type === SystemConstant.SERVER_TYPE.branch;

  return (
    <Box className={classes.chatTabRoot}>
      <SearchConversation selectedBranch={selectedBranch} />
      <Box className={classes.viewFullChat}>
        <ThreadList />

        <List>
          <ChatAllTab type={AppConstant.CONVERSATION_CATEGORY.unread} />
          <ChatAllTab type={AppConstant.CONVERSATION_CATEGORY.favorite} />

          {isBranchServer && <ChatAllTab type={AppConstant.CONVERSATION_CATEGORY.channel} />}
          <ChatAllTab type={AppConstant.CONVERSATION_CATEGORY.group} />
          <ChatAllTab type={AppConstant.CONVERSATION_CATEGORY.personal} />
        </List>
      </Box>
    </Box>
  );
};

export default ChatTab;

const ThreadList = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const classes = useStyles();
  const { unreadThreadNum } = useUnreadThread();

  const [isShowThreadList, setIsThreadList] = useState(false);

  const handleShowThreadFlow = () => setIsThreadList(true);

  return (
    <>
      <Tooltip title={getLabel(LangConstant.TXT_VIEW_MESSAGE_THREAD)}>
        <Button
          className={classes.flowMessage}
          classes={{ root: unreadThreadNum === 0 && classes.buttonRoot }}
          variant="text"
          startIcon={
            unreadThreadNum > 0 ? (
              <FlowMessageActive className={classes.flowMessageBtn} />
            ) : (
              <FlowMessage className={classes.flowMessageBtn} />
            )
          }
          onClick={handleShowThreadFlow}
        >
          {unreadThreadNum > 0
            ? getLabel(LangConstant.FM_NUMBER_FLOW_MESSAGE, { number: unreadThreadNum })
            : getNSLang(LangConstant.NS_HOME, "TXT_FLOW_MESSAGE")}
        </Button>
      </Tooltip>

      {isShowThreadList && <ThreadListDrawer isOpen={isShowThreadList} onClose={() => setIsThreadList(false)} />}
    </>
  );
};

const useStyles = makeStyles({
  chatTabRoot: {
    height: "100%",
    paddingTop: 12,
  },

  viewFullChat: {
    height: "calc(100vh - 278px)",
    overflowY: "auto",
    margin: "0 14px",
    width: "calc(100% - 28px)",
  },

  flowMessage: {
    marginTop: 10,
    textTransform: "none",
  },

  flowMessageBtn: {},

  buttonRoot: {
    color: "#8E8E8E",
  },
});
