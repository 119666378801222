import { EventState, EventSubType, EventType, ExecuteEvent } from "pubsub/PubSub.const";
import IEvent from "./IEvent";
import { EventDto, IEventDto } from "pubsub/dto";
import { getInteractor } from "services/local.service";
import { refactorSavingEvent } from "pubsub/PubSub.helper";
import {
  deviceChangeMasterService,
  deviceDeleteService,
  deviceNewService,
  deviceUpdateKeyService,
  deviceUpdateService,
} from "pubsub/services/device.service";

export default class DeviceEvent extends IEvent {
  constructor(prefixKey: string) {
    super(prefixKey, EventType.DEVICE);
  }

  override handleEvent = async (limitRetry: number) => {
    const eventList: EventDto[] = (
      await getInteractor(this.prefixKey).LocalEventService.find({
        type: this.eventType,
        state: [EventState.NEW, EventState.RESENT, EventState.TIMEOUT],
      })
    ).map((item: IEventDto) => new EventDto(item));
    const savingEvents: IEventDto[] = [];
    for (let index = 0; index < eventList.length; index++) {
      const eventItem = eventList[index];
      const eventContent = eventItem.contentObj;
      const accountId = eventContent.account_id;
      const deviceId = eventContent.device_id;
      let executeEventValue: ExecuteEvent = ExecuteEvent.FAIL;

      if (deviceId || accountId) {
        switch (eventItem.subtype) {
          case EventSubType.NEW:
            executeEventValue = (await deviceNewService(this.prefixKey, accountId, deviceId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.UPDATE:
            executeEventValue = (await deviceUpdateService(this.prefixKey, accountId, deviceId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.DELETE:
            executeEventValue = (await deviceDeleteService(this.prefixKey, accountId, deviceId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.KEY_UPDATE:
            executeEventValue = (await deviceUpdateKeyService(this.prefixKey, accountId, deviceId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          case EventSubType.CHANGE_MASTER:
            executeEventValue = (await deviceChangeMasterService(this.prefixKey, accountId))
              ? ExecuteEvent.SUCCESSES
              : ExecuteEvent.RETRY;
            break;

          default:
            break;
        }
      }

      // Update event before saving in local db
      const refactorEvent = refactorSavingEvent(eventItem, executeEventValue, limitRetry);
      if (refactorEvent) savingEvents.push(refactorEvent);
    }

    await getInteractor(this.prefixKey).LocalEventService.save(savingEvents);
    return true;
  };
}
