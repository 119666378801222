import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AppAvatar, AppDrawer } from "components";
import { useTranslation } from "react-i18next";
import {
  List as MuiList,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import { getCommonLang } from "utils/lang.utils";
import { FormatConstant, KeyConstant, LangConstant } from "const";
import { makeStyles } from "@mui/styles";
import { StorageUtil, toCamel } from "utils";
import clsx from "clsx";
import { getCreatedTimeText, useMessageStyle } from "./ChatItem.helper";
import { convertTimeStampToDate, formatSentTime } from "utils/date.utils";
import { getInteractor } from "services/local.service";
import { useDispatch, useSelector } from "react-redux";
import { ConversationActions, ConversationSelectors } from "redux-store";
import PreviewMessage from "components/dialog/ForwardDialog/PreviewMessage";
import orderBy from "lodash/orderBy";
import { useConversationContext } from "../ConversationContext";

const MessageDetailDrawer = ({ message, onClose, ...otherProps }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  const isMine = message.senderId === accountId;
  const fetchSeenMember = useSelector(ConversationSelectors.getFetchSeenMember);
  const { groupDetail } = useConversationContext();

  const [seenMembers, setSeenMembers] = useState([]);
  const [receivedMembers, setReceivedMembers] = useState([]);

  const handleDisplaySeenMember = async () => {
    const groupMemberIds = groupDetail.groupMembers.map(item => item.id);
    // Handle received members
    const seenRecords = await getInteractor(prefixKey).LocalSeenMemberService.find({
      source_id: message.sourceId,
      member_account_id: groupMemberIds,
    });
    const seenList = await refactorSeenMember(prefixKey, seenRecords);
    const seenIds = seenList.map(item => item.id);
    if (accountId !== message.senderId && !seenIds.includes(accountId)) {
      const account = await getInteractor(prefixKey).LocalAccountService.get(accountId, branchId);
      seenList.push({ ...account, displayDate: Date.now() });
      seenIds.push(accountId);
    }
    setSeenMembers(orderBy(seenList, "displayDate", "desc"));

    // Handle received members
    const receivedRecords = await getInteractor(prefixKey).LocalReceivedMemberService.find({
      source_id: message.sourceId,
      member_account_id: groupMemberIds,
    });
    const receivedList = await refactorSeenMember(
      prefixKey,
      receivedRecords.filter(item => false === seenIds.includes(item.member_account_id)),
    );
    setReceivedMembers(orderBy(receivedList, "displayDate", "desc"));

    // The condition that we need fetch server or not
    return receivedList.length > 0;
  };

  useEffect(() => {
    const isValid = message && message.sourceId;
    if (!isValid) return;

    handleDisplaySeenMember().then(hasData => {
      // Call server to synch data if local db does not have any data
      if (!hasData) {
        dispatch(ConversationActions.getMessageDetail(message.sourceId, prefixKey));
      }
    });
  }, [message]);

  useEffect(() => {
    if (fetchSeenMember > 0) {
      handleDisplaySeenMember();
    }
  }, [fetchSeenMember]);

  const [strSentTime, strReceivedTime] = getMessageTime(
    message,
    receivedMembers.concat(seenMembers),
    accountId,
    getLabel,
  );

  return (
    <AppDrawer
      title={getCommonLang("TXT_DETAIL_MESSAGE")}
      anchor="right"
      hideBackdrop={false}
      onClose={onClose}
      {...otherProps}
    >
      <Stack spacing={2}>
        <MuiList disablePadding>
          <Message data={message} onClick={onClose} />

          <ListItem>
            <Stack spacing={1}>
              <Typography>{strSentTime}</Typography>
              {!isMine && <Typography>{strReceivedTime}</Typography>}
            </Stack>
          </ListItem>
        </MuiList>

        <Stack spacing={2} flexGrow={1}>
          <List
            subHeader={{ id: "receiver-list-subheader", content: getLabel("TXT_RECEIVED") }}
            users={receivedMembers}
          />

          <List subHeader={{ id: "seen-list-subheader", content: getLabel("TXT_SEEN") }} users={seenMembers} />
        </Stack>
      </Stack>
    </AppDrawer>
  );
};

MessageDetailDrawer.propTypes = {
  message: PropTypes.object,
  ...AppDrawer.propTypes,
};

MessageDetailDrawer.defaultProps = {};

export default MessageDetailDrawer;

const List = ({ users = [], subHeader = {}, ...otherProps }) => {
  const classes = useStyle();

  if (users.length === 0) return <Fragment />;

  return (
    <MuiList
      component="nav"
      aria-labelledby={subHeader.id}
      subheader={
        <ListSubheader id={subHeader.id} component="div" sx={{ lineHeight: "normal" }}>
          {subHeader.content}
        </ListSubheader>
      }
      disablePadding
      className={clsx(classes.userList, { [users.length > 3]: classes.userListHeigh })}
      {...otherProps}
    >
      {users.map((userItem, index) => (
        <ListItem key={index}>
          <ListItemAvatar>
            <AppAvatar avatarId={userItem.avatar_id} name={userItem.name} />
          </ListItemAvatar>
          <ListItemText
            primary={userItem.name || "No name"}
            secondary={formatTime(userItem.displayDate)}
            sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
          />
        </ListItem>
      ))}
    </MuiList>
  );
};

const Message = ({ data, onClick }) => {
  const classes = useStyle();
  const messengerClasses = useMessageStyle();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const [senderAccount, setSenderAccount] = useState({});
  const [message, setMessage] = useState({});

  const handleChangeData = async () => {
    const account = await getInteractor(prefixKey).LocalAccountService.get(data.senderId, branchId);
    setSenderAccount(toCamel(account) || {});

    setMessage({
      ...data,
      isAvatar: true,
      avatarId: account.avatar_id,
      avatarUrl: account.avatar_url,
      senderName: account.name,
      sentTime: formatSentTime(data.created),
    });
  };

  useEffect(() => {
    handleChangeData();
  }, [data]);

  const msgCreatedTime = getCreatedTimeText(message);
  return (
    <ListItem>
      <Box className={clsx(messengerClasses.messengerRoot, classes.messageDetail)}>
        <ListItemAvatar>
          <AppAvatar avatarId={senderAccount.avatarId} name={senderAccount.name} isServerLogo={senderAccount.isBot} />
        </ListItemAvatar>

        <Stack flexGrow={1}>
          {msgCreatedTime && (
            <Typography className={clsx("ellipsis", messengerClasses.createdTimeText)}>{msgCreatedTime}</Typography>
          )}
          <PreviewMessage message={data} imageProps={{ onClick: onClick, imageWidth: 90 }} />
        </Stack>
      </Box>
    </ListItem>
  );
};

const getMessageTime = (message, receivedMembers, accountId, getLabel) => {
  const strSentTime = convertTimeStampToDate(message.created / 1000, FormatConstant.FM_FULL_DATE);

  let strReceivedTime;
  const receivedMember = receivedMembers.find(item => item.id === accountId);
  if (receivedMember && receivedMember.displayDate) {
    strReceivedTime = convertTimeStampToDate(receivedMember.displayDate / 1000, FormatConstant.FM_FULL_DATE);
  }

  return [
    getLabel("FM_SENT_TIME", { time: strSentTime || "--" }),
    getLabel("FM_RECEIVED_TIME", { time: strReceivedTime || "--" }),
  ];
};

const formatTime = time => {
  try {
    return convertTimeStampToDate(time / 1000, FormatConstant.FM_HH_MM_DD_MM_YYYY);
  } catch (error) {
    console.log(error);
  }

  return "--";
};

const refactorSeenMember = async (prefixKey, members) => {
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const memberList = [];
  for (let index = 0; index < members.length; index++) {
    const item = members[index];
    const account = await getInteractor(prefixKey).LocalAccountService.get(item.member_account_id, branchId);
    if (account && account.id) {
      memberList.push({ ...account, displayDate: item.seen_at || item.received_at });
    }
  }

  return memberList;
};

export const useStyle = makeStyles({
  messageDetail: {
    display: "flex",
    width: "100%",
    padding: 8,
    borderRadius: 8,
    backgroundColor: "#e3e3e3",

    "& audio": {
      maxWidth: "100%",
    },
  },

  userList: {
    maxHeight: "50%",
    overflow: "auto",
  },

  userListHeigh: {
    minHeight: 300,
  },

  myMessage: {
    "&, & *": {
      color: "white",
    },
  },
});
