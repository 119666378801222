import { RemoteApi, remoteApiFactory } from "services";
import { ApiResponse } from "apisauce";
import { ApiConstant, KeyConstant, AppConstant } from "const";
import { getInteractor } from "services/local.service";
import {
  CHECKING_INTERVAL_TIME,
  EventChannel,
  EventState,
  EventSubType,
  EventType,
  PROCESS_STATUS,
  LIMIT_FINISH_EVENT,
} from "./PubSub.const";
import { StorageUtil, convertString2JSON } from "utils";
import { formatPagingParams } from "sagas/saga.helper";
import { CheckingDto, EventDto, FinishEventDto, PaginationDto } from "./dto";
import {
  AccountEvent,
  BranchEvent,
  BranchRequestEvent,
  CallEvent,
  CallHistoryEvent,
  DeviceEvent,
  FileEvent,
  GroupEvent,
  MessageEvent,
  MessageStatusEvent,
  PersonalEvent,
} from "./events";
import { getMessage } from "pubsub/services/message/getMessage";
import { synchNotificationList } from "./services/personal.service";
import { getRedisClient } from "../services/redisService";

type ReduceEvent = { definedEvents: EventDto[]; otherEvents: EventDto[] };
export default class PubSubEvent {
  prefixKey: string;
  api: RemoteApi;
  processStatus: PROCESS_STATUS;
  eventServices: {
    account: AccountEvent;
    branch: BranchEvent;
    call: CallEvent;
    device: DeviceEvent;
    group: GroupEvent;
    message: MessageEvent;
    messageStatus: MessageStatusEvent;
    person: PersonalEvent;
    file: FileEvent;
    callHistory: CallHistoryEvent;
    branchRequest: BranchRequestEvent;
  };
  checkingInterval: NodeJS.Timeout | null;
  limitRetry = 3;
  deviceId: string;
  isFirstCall: boolean;
  lastFetchEvent: number;

  constructor(prefixKey: string, domain?: string) {
    this.prefixKey = prefixKey;
    this.api = remoteApiFactory.getBranchApi(prefixKey, domain);
    this.checkingInterval = null;
    this.processStatus = PROCESS_STATUS.nothing;
    this.eventServices = {
      account: new AccountEvent(prefixKey),
      branch: new BranchEvent(prefixKey),
      call: new CallEvent(prefixKey),
      device: new DeviceEvent(prefixKey),
      group: new GroupEvent(prefixKey),
      message: new MessageEvent(prefixKey),
      messageStatus: new MessageStatusEvent(prefixKey),
      person: new PersonalEvent(prefixKey),
      file: new FileEvent(prefixKey),
      callHistory: new CallHistoryEvent(prefixKey),
      branchRequest: new BranchRequestEvent(prefixKey),
    };
    this.deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, this.prefixKey);
    this.isFirstCall = true;
    this.lastFetchEvent = 0;
  }

  // Initial
  startCheckingNews = async () => {
    // Only create interval one
    if (this.checkingInterval) return;

    // Ensure that device_id is newest
    this.deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, this.prefixKey);
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, this.prefixKey);
    const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, this.prefixKey);
    this.checkingInterval = setInterval(async () => {
      const currentTime = Date.now();
      const redisClient = await getRedisClient(accountId, branchId);
      const timeEventCover = redisClient?.timeEventCover
        ? Number(redisClient?.timeEventCover) * 1000
        : AppConstant.ONE_MINUTE;

      if (this.isFirstCall) {
        this.fetchNewEvent();
        this.isFirstCall = false; // Đặt cờ thành false sau lần gọi đầu tiên
      } else if (!redisClient?.isSubscriberPubsub) {
        this.fetchNewEvent();
      } else if (currentTime > this.lastFetchEvent + timeEventCover) {
        console.log("this.lastFetchEvent", this.lastFetchEvent);
        this.fetchNewEvent();
      }
    }, CHECKING_INTERVAL_TIME);
  };

  // Using when user logout
  destroyParallelThread = () => {
    if (this.checkingInterval) {
      clearInterval(this.checkingInterval);
      this.checkingInterval = null;
    }
  };
  fetchNewEvent = () => {
    const isValid = this.processStatus === PROCESS_STATUS.nothing && navigator.onLine && !window.isStopSynchronize;
    if (!isValid) return;
    this.processStatus = PROCESS_STATUS.checking;
    this.api
      .checkEvents(this.deviceId)
      .then((response: ApiResponse<CheckingDto>) => {
        const responseData = new CheckingDto(response.data || {});
        if (response.status === ApiConstant.STT_OK && Object.keys(responseData).length > 0) {
          this.lastFetchEvent = responseData.timestamp;
          // [Hight priority] Synch message right now
          if (Boolean(responseData.isNewMessage)) {
            // Call api to synch message
            getMessage(this.prefixKey, responseData.lastDeviceMessage)
              .catch(error => console.error("getLastMessages - error", error))
              .finally(() => {
                console.log("getLastMessages - Finish");
              });
          }

          // Call api to synch notification
          if (Boolean(responseData.isNewNotification)) {
            synchNotificationList(this.prefixKey, responseData.lastNotificationEvent)
              .catch(error => console.error("synchNotificationList - error", error))
              .finally(() => {
                console.log("synchNotificationList - Finish");
              });
          }

          // Synch event if there are new events
          if (Boolean(responseData.isNewEvent)) {
            this.synchEvents(responseData);
          } else {
            // Executing old events
            this.executeEvent(responseData);
          }
        } else {
          // Complete checking event
          this.processStatus = PROCESS_STATUS.nothing;
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  private synchEvents = async (checkingDto: CheckingDto) => {
    const isValid = navigator.onLine && !window.isStopSynchronize;
    if (!isValid) {
      this.processStatus = PROCESS_STATUS.nothing;
      return;
    }

    this.processStatus = PROCESS_STATUS.synchEvent;

    // Call server to save even list at db
    const lastDeviceEventLocal = StorageUtil.getItem(KeyConstant.KEY_TIME_LAST_DEVICE_EVENT, this.prefixKey) || 0;
    const lastDeviceEvent = Math.min(checkingDto.lastDeviceEvent, lastDeviceEventLocal) || 0;
    const eventParams = formatPagingParams({
      sinceTime: lastDeviceEvent,
      limit: 300,
      offset: 0,
      paging: 1,
    });

    const response: ApiResponse<PaginationDto<EventDto>> = await this.api.getEventsList(eventParams);
    const events = response?.data?.data && Array.isArray(response?.data?.data) ? response.data.data : [];

    if (response.status === ApiConstant.STT_OK) {
      StorageUtil.setItem(KeyConstant.KEY_TIME_LAST_DEVICE_EVENT, checkingDto.lastDeviceEvent);

      // Skips existed event at DB
      // Skips event TIMEOUT for events not in [MESSAGE_STATUS_EVENTS, ACCOUNT_DELETE, DEVICE_DELETE]
      const checkingNewEvents: number[] = await Promise.all(
        events.map(item =>
          getInteractor(this.prefixKey).LocalEventService.count({ source_event_id: item.source_event_id }),
        ),
      );
      const newEvents = events.filter((item, index) => {
        const isNewEvent = checkingNewEvents[index] === 0;
        if (isNewEvent) {
          if (item.state !== EventState.TIMEOUT) return true;

          switch (item.type) {
            case EventType.MESSAGE_STATUS:
              return true;

            case EventType.ACCOUNT:
              if (item.subtype === EventSubType.DELETE) return true;
              break;

            case EventType.DEVICE:
              if (item.subtype === EventSubType.DELETE) return true;
              break;

            default:
              return false;
          }
        }

        return false;
      });

      const { definedEvents, otherEvents } = newEvents.reduce<ReduceEvent>(
        ({ definedEvents, otherEvents }, eventItem): ReduceEvent => {
          if (eventItem.type in EventType) {
            definedEvents.push(eventItem);
          } else {
            otherEvents.push(eventItem);
          }

          return { definedEvents, otherEvents };
        },
        { definedEvents: [], otherEvents: [] },
      );

      await getInteractor(this.prefixKey).LocalEventService.save(definedEvents);
      // Set failed state for all events that dont be defined
      if (otherEvents.length > 0) {
        await getInteractor(this.prefixKey).LocalEventService.save(
          otherEvents.map(item => ({ ...item, state: EventState.FAIL, modified: Date.now() })),
        );
      }

      // Execute events
      await this.executeEvent(checkingDto);
    } else {
      this.processStatus = PROCESS_STATUS.nothing;
    }
  };

  private executeEvent = async (checkingDto: CheckingDto) => {
    this.processStatus = PROCESS_STATUS.executeEvent;

    try {
      // Handling event
      await Promise.all(Object.values(this.eventServices).map(eventItem => eventItem.execute(this.limitRetry)));

      // Finish event
      await this.finishEvent(this.limitRetry, checkingDto);
    } catch (error) {
      console.log("[Error] executeEvent", error);
    }

    // Completed synchronize events
    this.processStatus = PROCESS_STATUS.nothing;
  };

  private finishEvent = async (limitRetry: number, checkingDto: CheckingDto): Promise<boolean> => {
    let isResult = false;
    let isContinue = true;

    while (isContinue) {
      const finishEventList: EventDto[] = await getInteractor(this.prefixKey).LocalEventService.getReady2Finish(
        [EventState.SUCCESSES, EventState.FAIL],
        limitRetry,
        LIMIT_FINISH_EVENT,
      );
      if (finishEventList.length === 0) {
        isResult = true;
        break;
      }

      // Finish event by batch (100)
      isResult = await this.finishEventByBatch(finishEventList, limitRetry, checkingDto);

      isContinue = isResult && finishEventList.length === LIMIT_FINISH_EVENT;
    }

    return isResult;
  };

  private finishEventByBatch = async (
    finishEventList: EventDto[],
    limitRetry: number,
    checkingDto: CheckingDto,
    retries = 1,
  ): Promise<boolean> => {
    if (finishEventList.length === 0) return true;

    const { successEventIds, failedEventIds } = splitEventByType(finishEventList);
    const payload = {
      device_id: this.deviceId,
      timestamp: checkingDto.timestamp,
      success_source_event_ids: successEventIds,
      failed_source_event_ids: failedEventIds,
      chanel_type: EventChannel.SYSTEM,
    };
    const response: ApiResponse<FinishEventDto> = await this.api.postEventsFinish(payload);
    if (response.status === ApiConstant.STT_OK && response.data && Array.isArray(response.data.updated_events)) {
      const updatedSourceEventIds = response.data.updated_events.map(item => item.source_event_id);
      const deletedSourceEventIds = [...updatedSourceEventIds];

      // Check time to live with events not appear in response server
      if (finishEventList.length > deletedSourceEventIds.length) {
        finishEventList.forEach(eventItem => {
          if (
            false === updatedSourceEventIds.includes(eventItem.source_event_id) &&
            eventItem.created + eventItem.ttl < Date.now()
          ) {
            deletedSourceEventIds.push(eventItem.source_event_id);
          }
        });
      }

      await getInteractor(this.prefixKey).LocalEventService.deleteWithCondition({
        source_event_id: deletedSourceEventIds,
      });
      return true;
    } else if (retries > 3 || ApiConstant.SERVER_MAINTAIN_STATUS.includes(response.status)) {
      await getInteractor(this.prefixKey).LocalEventService.deleteWithCondition({
        source_event_id: finishEventList.map(item => item.source_event_id),
      });
      return true;
    } else if (false === Boolean(ApiConstant.SERVER_MAINTAIN_STATUS.includes(response.status))) {
      return await this.finishEventByBatch(finishEventList, limitRetry, checkingDto, retries + 1);
    }

    return false;
  };
}

const splitEventByType = (eventList: EventDto[]) => {
  const { successEventIds, failedEventIds } = eventList.reduce<{
    successEventIds: string[];
    failedEventIds: string[];
  }>(
    ({ successEventIds, failedEventIds }, item) => {
      switch (item.state) {
        case EventState.SUCCESSES:
          successEventIds.push(item.source_event_id);
          break;

        case EventState.FAIL:
          failedEventIds.push(item.source_event_id);
          break;

        default:
          break;
      }

      return { successEventIds, failedEventIds };
    },
    { successEventIds: [], failedEventIds: [] },
  );

  return { successEventIds, failedEventIds };
};
