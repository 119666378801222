import { useEffect, useState } from "react";
import { ListItemByDate } from "components";
import RequestItem from "./RequestItem";

const RequestListByDate = ({ date, list }) => {
  const [display, setDisplay] = useState([]);

  const handleUpdateList = id => {
    const newList = display.filter(item => item.id !== id);
    setDisplay(newList);
  };

  useEffect(() => {
    setDisplay(list);
  }, [list]);

  return (
    <ListItemByDate date={date}>
      {display.map(item => (
        <RequestItem key={item.id} request={item} updateRequestList={handleUpdateList} />
      ))}
    </ListItemByDate>
  );
};

export default RequestListByDate;
