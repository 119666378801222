export type NotificationClickData = Readonly<{
  conversationId: string;
  messageId: string | null;
  storyId: string | null;
}>;
export type WindowsNotificationData = {
  avatarPath?: string;
  body: string;
  conversationId: string;
  heading: string;
  messageId?: string;
  storyId?: string;
  type: NotificationType;
};
export enum NotificationType {
  IncomingCall = "IncomingCall",
  IncomingGroupCall = "IncomingGroupCall",
  IsPresenting = "IsPresenting",
  Message = "Message",
  Reaction = "Reaction",
}

// The keys and values don't match here. This is because the values correspond to old
//   setting names. In the future, we may wish to migrate these to match.
export enum NotificationSetting {
  Off = "off",
  NoNameOrMessage = "count",
  NameOnly = "name",
  NameAndMessage = "message",
}

export const FALLBACK_NOTIFICATION_TITLE = "Signal";

// Electron, at least on Windows and macOS, only shows one notification at a time (see
//   issues [#15364][0] and [#21646][1], among others). Because of that, we have a
//   single slot for notifications, and once a notification is dismissed, all of
//   Signal's notifications are dismissed.
// [0]: https://github.com/electron/electron/issues/15364
// [1]: https://github.com/electron/electron/issues/21646
