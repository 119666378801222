import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import {
  MissedVideoCall,
  NotificationsOff,
  PhoneCallback,
  PhoneMissed,
  PhoneForwarded,
  VideoCall,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { convertString2JSON, isJSONString, StorageUtil, toCamel } from "utils";
import { formatSentTime } from "utils/date.utils";
import { BlockIcon, VerifiedIcon } from "components/icons";
import { AvatarGroup } from "components";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import ChatItemMenu from "./ChatItemMenu";
import { isGroupOrChannelType } from "pages/Call";
import { getInteractor } from "services/local.service";
import { useMuteGroup } from "hooks";
import { AccountSelectors, CallingActions, ConversationActions, ConversationSelectors } from "redux-store";
import ChatTypography from "../../MessengerChat/ChatItem/ChatTypography";
import { createSelector } from "reselect";
import { getLabel } from "language";

const memoizedReduxState = createSelector(
  [
    ConversationSelectors.getSelectedGroupId,
    AccountSelectors.getBlockAccountId,
    state => state.callingRedux.isFetchHistoryTimestamp,
    state => state.callingRedux.lastCallingCall,
    state => state.contactRedux.updateSender,
  ],
  (selectedGroupId, blockAccountId, isFetchHistoryTimestamp, lastCallingCall, updateSender) => {
    return {
      selectedGroupId,
      blockAccountId,
      isFetchHistoryTimestamp,
      lastCallingCall,
      affectGroupIds: Boolean(Array.isArray(updateSender?.affectGroupIds) && updateSender.affectGroupIds.length > 0)
        ? updateSender.affectGroupIds
        : null,
    };
  },
);

const ChatGroupItem = ({ data, ...otherProps }) => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
  const isValidData = data && data.id;

  const classes = useStyles();
  const chatItemRef = useRef(null);
  const dispatch = useDispatch();
  const { isMuted, muteGroup, checkGroupNoticeStt } = useMuteGroup(data.id);

  const { selectedGroupId, blockAccountId, isFetchHistoryTimestamp, lastCallingCall, affectGroupIds } =
    useSelector(memoizedReduxState);

  const [lastMessage, setLastMessage] = useState({});
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const [onGoingCallText, setOnGoingCallText] = useState(null);
  const [groupDetail, setGroupDetail] = useState({
    ...GROUP_DATA_DEFAULT,
  });

  const handleShowMenu = () => setMenuAnchorEl(chatItemRef.current);

  const handleClickItem = async () => {
    const groupId = groupDetail?.id;
    if (groupId) {
      dispatch(
        ConversationActions.setSelectGroupId({
          selectedGroupId: groupId,
          threadingId: null,
          scrollToChildId: null,
        }),
      );
    }
  };

  const handleBlockAccount = groupId => {
    getInteractor(prefixKey)
      .LocalGroupService.checkBlockGroup(groupId, branchId, accountId)
      .then(isBlockedGroup => setIsBlocked(Boolean(isBlockedGroup)));
  };

  const handleUpdateLastMessage = async group => {
    const lastMessageJson =
      (isJSONString(group.lastMessage) ? convertString2JSON(group.lastMessage, {}) : group.lastMessage) || {};

    if (group.draftMessage) {
      lastMessageJson.displayContent = group.draftMessage;
      lastMessageJson.messageType = AppConstant.LAST_MESSAGE_TYPE.draftMessage;
    } else {
      lastMessageJson.displayContent = lastMessageJson.content;
    }

    if (lastMessageJson !== lastMessage) setLastMessage(lastMessageJson);
  };

  const handleInitData = group => {
    setGroupDetail(preState => ({ ...preState, ...group }));
    handleBlockAccount(group.id);
    checkGroupNoticeStt();
    handleUpdateLastMessage(group);
  };

  const handleCheckCalling = async () => {
    const lastCall = toCamel(await getInteractor(prefixKey).LocalCallHistoryService.getLastByGroupId(data.id));

    if (lastCall && lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling) {
      const callingMessage = toCamel(
        (await getInteractor(prefixKey).LocalMessageService.findOne({ source_id: lastCall.sourceId })) || {},
      );

      if (convertString2JSON(lastCall.callingMembers, []).includes(deviceId)) {
        let newArr = [...lastCallingCall].concat([
          {
            groupId: groupDetail.id,
            callingDetail: callingMessage,
          },
        ]);

        if (newArr.length > 0) {
          dispatch(
            CallingActions.callingSet({
              lastCallingCall: newArr,
            }),
          );
        }
      }

      if (isGroupOrChannelType(lastCall.groupType)) {
        setOnGoingCallText(getNSLang(LangConstant.NS_CALLING, LangConstant.TXT_GROUP_CALLING));
      } else {
        if (callingMessage.senderId === accountId) {
          setOnGoingCallText(getNSLang(LangConstant.NS_CALLING, LangConstant.TXT_GROUP_ITEM_OUTGOING_CALL));
        } else {
          setOnGoingCallText(getNSLang(LangConstant.NS_CALLING, LangConstant.TXT_INCOMING_CALL));
        }
      }
    } else if (onGoingCallText) {
      setOnGoingCallText(null);

      dispatch(
        CallingActions.callingSet({
          lastCallingCall: lastCallingCall.filter(item => item?.groupId !== groupDetail.id),
        }),
      );
    }
  };

  useEffect(() => {
    if (isValidData) handleInitData(data);
  }, [data]);

  useEffect(() => {
    if (blockAccountId && groupDetail.groupMembers.findIndex(item => item.id === blockAccountId) >= 0) {
      handleBlockAccount(groupDetail.id);
    }
  }, [blockAccountId]);

  useEffect(() => {
    if (isValidData) handleCheckCalling();
  }, [isFetchHistoryTimestamp]);

  useEffect(() => {
    const isAffected = data?.id && Array.isArray(affectGroupIds) && affectGroupIds.includes(data.id);
    if (isAffected) {
      getInteractor(prefixKey)
        .LocalGroupService.get(data.id)
        .then(group => handleInitData(group));
    }
  }, [affectGroupIds]);

  const isDraftMessage = lastMessage.messageType === AppConstant.LAST_MESSAGE_TYPE.draftMessage;
  const isCallingMessage =
    !isDraftMessage &&
    [
      AppConstant.LAST_MESSAGE_TYPE.endedCall,
      AppConstant.LAST_MESSAGE_TYPE.endedVideoCall,
      AppConstant.LAST_MESSAGE_TYPE.endedConferenceCall,
      AppConstant.LAST_MESSAGE_TYPE.missingVideo,
      AppConstant.LAST_MESSAGE_TYPE.missingAudio,
    ].includes(lastMessage.messageType);
  return (
    <>
      <Box
        className={clsx(
          classes.conversationRoot,
          "no-select-text",
          selectedGroupId && selectedGroupId === groupDetail.id && classes.selectedBox,
        )}
        onContextMenu={handleShowMenu}
        ref={chatItemRef}
        onClick={handleClickItem}
        {...otherProps}
      >
        <Box className={classes.chatItemAvatar}>
          <AvatarGroup
            group={{
              groupType: groupDetail.groupType,
              groupName: groupDetail.groupName,
              privateF: groupDetail.privateF,
              isServerLogo: groupDetail.isBot,
            }}
            avatarId={groupDetail.avatarId}
            BadgeIcon={groupDetail.isBot && <VerifiedIcon />}
            backgroundColor="#bebebe"
          />
        </Box>
        <Box className={classes.groupContent}>
          <Box className={classes.conversationDetail}>
            <Stack direction="row" spacing={0.5}>
              <Typography className={clsx(classes.chatName, "ellipsis")}>{groupDetail.groupName}</Typography>
              {groupDetail.sendToMe && <span className={classes.chatName}>{getCommonLang("TXT_ME_LABEL")}</span>}
            </Stack>
            {isCallingMessage ? (
              <CallIcon
                messageType={lastMessage.messageType}
                callContent={lastMessage.displayContent}
                isMine={lastMessage.senderId === accountId}
              />
            ) : (
              <ChatTypography
                className={clsx(
                  classes.lastMessage,
                  data.unread > 0 && !isBlocked ? "semiBold-md-txt" : "regular-md-txt",
                  "ellipsis",
                )}
                preText={
                  isDraftMessage ? (
                    <Box component={"span"} sx={{ color: "red" }} pr={0.5}>
                      {getLabel("TXT_DRAFT_LABEL")}
                    </Box>
                  ) : null
                }
                messageContent={lastMessage.displayContent}
                mentionList={groupDetail.groupMembers}
                isEnableSearching={false}
              />
            )}

            {onGoingCallText && !isBlocked && (
              <Typography className={clsx("regular-md-txt", classes.callingText)}>{onGoingCallText}</Typography>
            )}
          </Box>
        </Box>
        <Box className={classes.timeAndAction}>
          <Box className={classes.timeAndMutedIcon}>
            {isMuted && <NotificationsOff className={classes.muteNotificationIcon} />}
            <Typography className={clsx("regular-sm-txt", classes.lastMessageTime)}>
              {lastMessage.created && <TimeShow lastMessage={lastMessage} />}
            </Typography>
          </Box>

          {isBlocked ? (
            <BlockIcon />
          ) : (
            data.unread > 0 && (
              <Box className={classes.unreadCountBox}>
                <Typography
                  component="p"
                  className={clsx(classes.unreadCounter, isMuted && classes.unreadCounterMuted)}
                  variant="caption"
                >
                  {data.unread}
                </Typography>
              </Box>
            )
          )}
        </Box>
      </Box>

      {Boolean(groupDetail.id && menuAnchorEl) && (
        <ChatItemMenu
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          group={{
            ...groupDetail,
            isBlocked: isBlocked,
            isMuted,
          }}
          onMutedGroup={muteGroup}
        />
      )}
    </>
  );
};

ChatGroupItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default memo(ChatGroupItem);

const GROUP_DATA_DEFAULT = {
  groupName: "",
  groupMembers: [],
};

export const CallIcon = ({ messageType, callContent, className, isMine }) => {
  const classes = useStyles();
  switch (messageType) {
    case AppConstant.LAST_MESSAGE_TYPE.endedCall:
      return (
        <Box className={classes.iconWrapper}>
          {isMine ? (
            <PhoneCallback className={clsx(classes.icon, className)} />
          ) : (
            <PhoneForwarded className={clsx(classes.icon, className)} />
          )}
          <Typography className={clsx(classes.lastMessage, "ellipsis", "regular-md-txt", className)}>
            {callContent}
          </Typography>
        </Box>
      );
    case AppConstant.LAST_MESSAGE_TYPE.endedConferenceCall:
    case AppConstant.LAST_MESSAGE_TYPE.endedVideoCall:
      return (
        <Box className={classes.iconWrapper}>
          <VideoCall className={clsx(classes.icon, className)} />
          <Typography className={clsx(classes.lastMessage, "ellipsis", "regular-md-txt", className)}>
            {callContent}
          </Typography>
        </Box>
      );
    case AppConstant.LAST_MESSAGE_TYPE.missingVideo:
      return (
        <Box className={clsx(classes.iconWrapper)}>
          <MissedVideoCall className={clsx(classes.icon, className)} />
          <Typography className={clsx(classes.lastMessage, "ellipsis", "regular-md-txt", className)}>
            {callContent}
          </Typography>
        </Box>
      );

    case AppConstant.LAST_MESSAGE_TYPE.missingAudio:
      return (
        <Box className={clsx(classes.iconWrapper)}>
          <PhoneMissed className={clsx(classes.icon, className)} />
          <Typography className={clsx(classes.lastMessage, "ellipsis", "regular-md-txt", className)}>
            {callContent}
          </Typography>
        </Box>
      );
    default:
      break;
  }

  return null;
};

const TimeShow = ({ lastMessage }) => {
  if (lastMessage) {
    const { created } = lastMessage;
    let result = "";
    if (created) {
      const nowTime = new Date().getTime() / 1000;
      const state = nowTime - created / 1000 > 60;
      result = state ? formatSentTime(Math.floor(lastMessage?.created)) || "" : "now";
    }
    return <>{result}</>;
  }

  return <Typography>Checking...</Typography>;
};

const useStyles = makeStyles(theme => ({
  conversationRoot: {
    display: "flex",
    paddingTop: 10,
    padding: "0 14px",
    cursor: "pointer",
    width: "100%",
    justifyContent: "space-between",

    "&:hover": {
      backgroundColor: "#F1F3F6",
    },
  },

  groupContent: {
    display: "flex",
    marginLeft: 16,
    flex: 1,
    paddingBottom: 10,
    borderBottom: "0.5px solid " + theme.palette.divider,
    justifyContent: "space-between",
    maxWidth: "calc(100% - 100px)",
    "&>*": {
      maxWidth: "100%",
    },
  },

  conversationDetail: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: 25,
  },

  chatItemAvatar: {
    marginBottom: 10,
    width: 50,
    height: 50,
  },

  lastMessageTime: {
    lineHeight: "1.375rem",
    color: "#666666",
    minWidth: 30,
    textAlign: "center",
  },

  chatName: {
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    marginBottom: 2,
  },

  lastMessage: {
    lineHeight: "1.125rem",
    whiteSpace: "break-spaces",
  },

  unreadCounter: {
    padding: "2px 8px",
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    lineHeight: "0.875rem",
    alignSelf: "flex-end",
    marginTop: 4,
  },

  unreadCounterMuted: {
    backgroundColor: "#E0E0E0",
  },

  timeAndAction: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    paddingBottom: 9,
  },

  timeAndMutedIcon: {
    display: "flex",
    justifyContent: "end",
  },

  muteNotificationIcon: {
    color: "#E0E0E0",
    fontSize: "1rem",
    marginRight: 4,
  },

  selectedBox: {
    "&, &:hover": {
      backgroundColor: "#cccdcd",
    },
  },

  iconWrapper: {
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&>svg": {
      marginRight: 0,
    },
  },

  icon: {
    color: theme.palette.grey[500],
    marginRight: 10,
    width: 24,
    height: 24,
  },

  callingText: {
    color: "#30CC45",
    marginTop: 2,
  },

  unreadCountBox: {
    display: "flex",
  },
}));
