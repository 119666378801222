import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ConfirmDialog from "./ConfirmDialog";
import { useTranslation } from "react-i18next";
import { AppConstant, LangConstant } from "const";
import { IconButton as MuiIconButton, OutlinedInput as MuiOutlinedInput, Stack, Typography } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useManageContact } from "hooks";
import { useAlertContext } from "components/context/AlertContext";
import { getCommonLang } from "utils/lang.utils";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";

const AddingContactDialog = ({ account, ...dialogProps }) => {
  const { onClose } = dialogProps;
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const contactObjContent = getLabel(LangConstant.OBJ_ADDING_CONTACT, { returnObjects: true });
  const contactRef = useRef(account.name);
  const { showAlert } = useAlertContext();
  const { actionStatus, addContact, resetData } = useManageContact();

  const handleSubmit = () => {
    addContact(account.phone, contactRef.current.value);
  };

  useEffect(() => {
    if (actionStatus === AppConstant.PROCESS_STATUS.success) {
      onClose();
    } else if (actionStatus === AppConstant.PROCESS_STATUS.error) {
      showAlert({ content: getCommonLang("TXT_SERVER_ERROR"), severity: "error" });
      resetData();
    }
  }, [actionStatus]);

  const isFriend = Boolean(account.isContact);
  return (
    <>
      <ConfirmDialog
        title={contactObjContent.title}
        content={
          <Stack alignItems="flex-start" spacing={0.5}>
            <Typography>{contactObjContent.name}: </Typography>
            <OutlinedInput
              inputRef={contactRef}
              classes={{
                input: "regular-md-txt",
              }}
              defaultValue={isFriend ? account.name : account.phone}
            />
          </Stack>
        }
        submitProps={{
          color: "primary",
          disabled: actionStatus === AppConstant.PROCESS_STATUS.calling,
          onClick: handleSubmit,
        }}
        cancelProps={{
          cancelText: getCommonLang("TXT_CLOSE"),
          onClick: onClose,
        }}
        {...dialogProps}
      />
    </>
  );
};

AddingContactDialog.propTypes = { ...ConfirmDialog.propTypes, phoneNumber: PropTypes.number };

export default memo(AddingContactDialog);

export const AddingContactButton = props => {
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const contactObjContent = getLabel(LangConstant.OBJ_ADDING_CONTACT, { returnObjects: true });
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);

  const [isShowDrawer, setIsShowDrawer] = useState(false);

  const onCloseDrawer = () => setIsShowDrawer(false);
  const onShowDrawer = () => setIsShowDrawer(true);

  if (isBranchServer) return null;
  return (
    <>
      <Stack alignItems="center" spacing={1}>
        <IconButton onClick={onShowDrawer}>
          <PersonAdd />
        </IconButton>
        <Typography variant="body2">{contactObjContent.title}</Typography>
      </Stack>

      {isShowDrawer && <AddingContactDialog open onClose={onCloseDrawer} {...props} />}
    </>
  );
};

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],

  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));

const OutlinedInput = styled(MuiOutlinedInput)({
  minWidth: 300,
});
