export default {
  TXT_SCREEN_LOCK: "Khóa màn hình",
  TXT_SCREEN_LOCK_NOTE: "Mã khóa sẽ chỉ sử dụng cho thiết bị này",
  TXT_CREATE_PASSWORD: "Đặt mã khóa",
  TXT_CHANGE_PASSWORD: "Đổi mã khóa",
  TXT_FORGOT_PASSWORD: "Quên mã khóa",
  TXT_AUTO_LOCK: "Tự động khóa",
  TXT_WAY_LOCK: "Bạn có thể khóa màn hình bằng cách:",
  TXT_LOCK_BY_BUTTON: "Nhấn vào icon <component /> tại màn hình chính",
  TXT_LOCK_BY_KEYBOARD: "Dùng tổ hợp phím Ctrl + L",
  TXT_LOCK_BY_KEYBOARD_MAC: "Dùng tổ hợp phím Cmd + L",
  TXT_FORGOT_PASSWORD_NOTE: "Trong trường hợp quên mã khóa, xin vui lòng gỡ bỏ ứng dụng để xóa mã khóa.",
  TXT_PASSWORD: "Nhập mã khóa",
  TXT_PASSWORD_PLACEHOLDER: "Mã khóa gồm 4 ký tự số",
  TXT_CONFIRM_PASSWORD: "Nhập lại mã khóa",
  TXT_CONFIRM_PASSWORD_PLACEHOLDER: "Nhập lại mã khóa ở trên",
  TXT_CONFIRM_PASSWORD_ERROR: "Mã khóa không khớp, vui lòng nhập lại",
  TXT_OLD_PASSWORD: "Nhập mã khóa cũ",
  TXT_NEW_PASSWORD: "Nhập mã khóa mới",
  TXT_CONFIRM_NEW_PASSWORD: "Xác nhận mã khóa mới",
  TXT_OLD_PASSWORD_ERROR: "Mã khóa không đúng, vui lòng nhập lại",
  TXT_CREATE_PASSWORD_SUCCESS: "Đặt mã khóa thành công",
  TXT_CHANGE_PASSWORD_SUCCESS: "Đổi mã khóa thành công",
  TXT_UPDATE_LOCK_TIME_SUCCESS: "Cập nhật thời gian thành công",
  TXT_LOCK_SCREEN_ICON_TITLE: "Khóa màn hình (Ctrl + L)",
  TXT_LOCK_SCREEN_ICON_TITLE_MAC: "Khóa màn hình (Command + L)",
  TXT_WRONG_PASSWORD: "Sai mã khóa, vui lòng nhập lại",
  TXT_NOTE: "Ghi chú",
  TXT_DELETE_PASSWORD_TITLE: "Xóa mã khóa",
  TXT_DELETE_PASSWORD_SUBTITLE: "Để xóa mã khóa, vui lòng nhập mã khóa hiện tại",

  LABEL_CREATE: "Tạo",
  LABEL_OPEN: "Mở",

  FM_AUTO_SCREEN_LOCK: "{{time}} sau khi ngừng hoạt động",
  FM_SETTING_AUTO_LOCK: "<bold>Lưu ý: </bold>Ứng dụng sẽ tự động khóa màn hình sau {{time}} ngừng hoạt động",
  FM_LOCK_PASSWORD: "Sai quá số lần quy định, vui lòng thử lại sau {{time}} giây",
};
