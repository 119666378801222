// Copyright 2023 Signal Messenger, LLC
// SPDX-License-Identifier: AGPL-3.0-only

// These are strings (1) for the backup (2) for Storybook.
export enum CallMode {
  Direct = "Direct",
  Group = "Group",
  Adhoc = "Adhoc",
}

export enum CallType {
  Audio = "Audio",
  Video = "Video",
  Group = "Group",
  Adhoc = "Adhoc",
  // Only used for backup roundtripping
  Unknown = "Unknown",
}

export enum CallDirection {
  Incoming = "Incoming",
  Outgoing = "Outgoing",
  // Only used for backup roundtripping
  Unknown = "Unknown",
}

export enum CallLogEvent {
  Clear = "Clear",
  MarkedAsRead = "MarkedAsRead",
  MarkedAsReadInConversation = "MarkedAsReadInConversation",
}

export enum LocalCallEvent {
  Started = "LocalStarted",
  Ringing = "LocalRinging",
  Accepted = "LocalAccepted",
  Declined = "LocalDeclined",
  Hangup = "LocalHangup", // Incoming = Declined, Outgoing = Missed
  RemoteHangup = "LocalRemoteHangup", // Incoming = Missed, Outgoing = Declined
  Missed = "LocalMissed",
  Delete = "LocalDelete",
}

export enum RemoteCallEvent {
  Accepted = "Accepted",
  NotAccepted = "NotAccepted",
  Delete = "Delete",
  Observed = "Observed",
}

export type CallEvent = LocalCallEvent | RemoteCallEvent;

export enum CallStatusValue {
  Pending = "Pending",
  Accepted = "Accepted",
  Missed = "Missed",
  // TODO: DESKTOP-3483 - not generated locally
  MissedNotificationProfile = "MissedNotificationProfile",
  Declined = "Declined",
  Deleted = "Deleted",
  GenericGroupCall = "GenericGroupCall",
  GenericAdhocCall = "GenericAdhocCall",
  OutgoingRing = "OutgoingRing",
  Ringing = "Ringing",
  Joined = "Joined",
  JoinedAdhoc = "JoinedAdhoc",
  // Only used for backup roundtripping
  Unknown = "Unknown",
}
