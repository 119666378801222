import { memo, useEffect, useState } from "react";
import { ErrorOutline, LockOutlined } from "@mui/icons-material";
import { Box, Divider, List, ListItem, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppDrawer, TransLanguage } from "components";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { getNSLang } from "utils/lang.utils";
import { useTranslation } from "react-i18next";
import AutoLock from "./AutoLock";
import ChangeScreenPassword from "./ChangeScreenPassword";
import CreateScreenPassword from "./CreateScreenPassword";
import { LocalDeviceSettingService } from "services/local.service";
import { convertString2JSON, isMacOS, StorageUtil } from "utils";

const DEFAULT_SCREEN_LOCK = {
  isOn: false,
  lockTime: AppConstant.ONE_MINUTE,
  password: "",
};
const ScreenLock = ({ open, onClose }) => {
  const [screenLock, setScreenLock] = useState(DEFAULT_SCREEN_LOCK);

  const handleUpdateSetting = async setting => {
    await LocalDeviceSettingService.updateDeviceSetting({
      ...setting,
      setting_type: AppConstant.DEVICE_SETTING_TYPE.lockScreen,
      modified: Date.now(),
    });

    if (setting.state === SystemConstant.STATE.inactive) {
      StorageUtil.setCommonKey(KeyConstant.SCREEN_LOCK_TIME, null);
    } else if (setting.options.lock_time) {
      StorageUtil.setCommonKey(KeyConstant.SCREEN_LOCK_TIME, Date.now() + setting.options.lock_time);
    }

    setScreenLock(state => ({
      isOn: setting.state === SystemConstant.STATE.active,
      lockTime: setting.options.lock_time || state.lockTime,
      password: setting.options.password || state.password,
    }));
  };

  useEffect(() => {
    LocalDeviceSettingService.findOne({
      setting_type: AppConstant.DEVICE_SETTING_TYPE.lockScreen,
      state: SystemConstant.STATE.active,
    }).then(setting => {
      if (setting && setting.id) {
        const settingOptions = setting.options ? convertString2JSON(setting.options, {}) : {};
        setScreenLock({
          isOn: setting.state === SystemConstant.STATE.active,
          lockTime: settingOptions.lock_time || DEFAULT_SCREEN_LOCK.lockTime,
          password: settingOptions.password || DEFAULT_SCREEN_LOCK.password,
        });
      } else {
        setScreenLock(DEFAULT_SCREEN_LOCK);
      }
    });
  }, []);

  return (
    <AppDrawer open={open} onClose={onClose} title={getNSLang(LangConstant.NS_SCREEN_LOCK, "TXT_SCREEN_LOCK")}>
      <CreateScreenPassword defaultSetting={screenLock} onChangeSetting={handleUpdateSetting} />
      <ChangeScreenPassword
        defaultSetting={screenLock}
        disabled={!screenLock.isOn}
        onChangeSetting={handleUpdateSetting}
      />
      <AutoLock defaultSetting={screenLock} onChangeSetting={handleUpdateSetting} disabled={!screenLock.isOn} />

      <ScreenLockNote />
    </AppDrawer>
  );
};

export default memo(ScreenLock);

const ScreenLockNote = () => {
  const classes = useStyles();
  const { t: getScreenLockLabel } = useTranslation(LangConstant.NS_SCREEN_LOCK);

  return (
    <Box className={classes.screenLockNoteContained}>
      <Stack direction="row" spacing={1} alignItems="center">
        <ErrorOutline />
        <Typography variant="body1" className={classes.screenLockNote}>
          {getScreenLockLabel("TXT_NOTE")}
        </Typography>
      </Stack>

      <Stack mt={2} spacing={1}>
        <Typography variant="body1">{getScreenLockLabel("TXT_SCREEN_LOCK")}</Typography>
        <Typography variant="body2">{getScreenLockLabel("TXT_WAY_LOCK")}</Typography>
        <List style={{ listStyle: "disc", padding: "0px 16px" }}>
          <ListItem sx={{ display: "list-item", fontSize: "0.75rem", fontWeight: 400 }} disableGutters>
            <TransLanguage Component={LockOutlined} i18nKey={getScreenLockLabel("TXT_LOCK_BY_BUTTON")} />
          </ListItem>
          <ListItem sx={{ display: "list-item" }} disableGutters>
            <Typography variant="body2">
              {getScreenLockLabel(isMacOS() ? "TXT_LOCK_BY_KEYBOARD_MAC" : "TXT_LOCK_BY_KEYBOARD")}
            </Typography>
          </ListItem>
        </List>
      </Stack>
      <Divider />
      <Stack mt={2} spacing={1}>
        <Typography variant="body1">{getScreenLockLabel("TXT_FORGOT_PASSWORD")}</Typography>
        <Typography variant="body2">{getScreenLockLabel("TXT_FORGOT_PASSWORD_NOTE")}</Typography>
      </Stack>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  paddingRoot: {
    padding: 16,
  },

  screenLockNoteContained: {
    margin: 16,
    padding: "8px 16px",
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
  },

  screenLockNote: {
    display: "flex",
    alignItems: "center",
  },
}));
